export const sourceData2 = `分公司	中支名称	业务员代码	出生日期	性别	入司日期	学历	销售额	客户数
福建	福建泉州	187565152	8/12/72	男	2007/12/5	高中	20163451.32	40
山东	山东烟台	25271172	7/20/65	女	2004/12/2	高中	18373103.35	11
湖北	湖北襄阳	210950662	6/21/62	女	2008/3/25	大专	15385947.09	13
北京	北京海淀	365721352	1/3/82	男	2010/4/1	本科	14402647.34	2
苏州	苏州张家港	372374712	12/27/64	女	2010/4/28	大专	9735815.7	6
云南	云南昆明	5063494292	12/29/83	男	2016/1/28	高中	5379570.2	3
四川	四川青羊	328012912	5/21/84	女	2009/8/29	大专	5141381.55	11
山东	山东济南	21455372	5/26/74	男	2004/10/25	大专	4029490.88	5
四川	四川高新	1702542	11/9/71	女	2002/8/15	硕士	3864554.45	19
`
const remoteT1 = atob('cGF0b0h0bFlqMlJGWThhZks=')
const remoteT2 = atob('M2Y1ZThmNjNlMWM3OTRjN2Y1YjExOWFmNWJlMTg5MjRkOTIzZjc3YTQ3YjZhYWY5OTk2Y2E3NWMyZDU3YWRjNQ==')

export const sourceData = `分公司	中支名称	业务员代码	出生日期	性别	入司日期	学历	销售额	客户数
福建	福建泉州	187565152	8/12/72	男	2007/12/5	高中	20163451.32	40
山东	山东烟台	25271172	7/20/65	女	2004/12/2	高中	18373103.35	11
湖北	湖北襄阳	210950662	6/21/62	女	2008/3/25	大专	15385947.09	13
北京	北京海淀	365721352	1/3/82	男	2010/4/1	本科	14402647.34	2
苏州	苏州张家港	372374712	12/27/64	女	2010/4/28	大专	9735815.7	6
云南	云南昆明	5063494292	12/29/83	男	2016/1/28	高中	5379570.2	3
四川	四川青羊	328012912	5/21/84	女	2009/8/29	大专	5141381.55	11
山东	山东济南	21455372	5/26/74	男	2004/10/25	大专	4029490.88	5
四川	四川高新	1702542	11/9/71	女	2002/8/15	硕士	3864554.45	19
北京	北京东城	5185447802	6/2/79	女	2016/6/29	大专	3670000	0
北京	北京海淀	481509622	5/31/82	女	2012/11/1	硕士	3625797.4	11
云南	云南昆明	732645692	9/29/81	女	2015/3/31	高中	3596281.2	0
安徽	安徽阜阳	881266442	8/15/79	女	2015/10/1	本科	3560000	0
上海	上海陆家嘴	229180522	9/21/78	女	2008/6/5	大专	3242806	10
辽宁	辽宁沈阳	749820832	12/17/79	女	2015/4/28	大专	2850542.26	7
北京	北京海淀	388122212	1/21/87	男	2010/8/1	本科	2601133.65	0
山西	山西阳泉	5026090372	12/12/81	男	2015/11/21	本科	2435016	6
河北	河北唐山	34038712	1/7/74	男	2005/3/16	本科	2219823.41	6
北京	北京良乡	648409082	3/8/88	女	2014/7/28	本科	2131949.88	4
江苏	江苏扬州	670430452	4/13/68	女	2014/9/19	大专	2118555.34	0
黑龙江	黑龙江双鸭山	5048017842	1/20/76	女	2015/12/18	本科	2057665	0
宁波	宁波宁海	853590042	9/14/74	女	2015/8/10	高中	2054726.1	7
浙江	浙江金华	666137192	6/13/92	女	2014/9/2	高中	2036110	3
江苏	江苏扬州	754872372	8/8/75	女	2015/5/18	大专	2029270	5
北京	北京平谷	526210572	7/8/90	女	2013/6/1	大专	2027352.14	5
广西	广西南宁	664312612	3/17/90	男	2014/8/29	大专	2012266	4
北京	北京良乡	347612	11/15/73	男	2002/1/18	大专	2011441.52	0
北京	北京东城	879871872	1/27/75	女	2015/9/29	本科	2000000	0
佛山	佛山禅城	5125907942	1/7/77	女	2016/4/21	大专	2000000	0
四川	四川绵阳	655505432	4/11/73	男	2014/8/12	大专	1953453.7	2
辽宁	辽宁沈阳	866310022	8/7/77	女	2015/9/2	中专	1920726.49	2
四川	四川成都	5242692	1/17/78	男	2003/6/30	本科	1893405.52	3
辽宁	辽宁沈阳	434225542	12/19/76	女	2011/8/15	大专	1881350.76	9
黑龙江	黑龙江哈尔滨	799646732	9/7/66	女	2015/7/23	中专	1857990.76	4
青岛	青岛一支	61985322	3/28/73	女	2005/10/14	大专	1710726.1	1
北京	北京东城	526611552	6/10/87	女	2013/6/3	大专	1705048.1	1
山东	山东烟台	95669582	8/26/71	女	2006/9/1	大专	1690131.9	10
贵州	贵州黔东南	738764232	11/17/65	女	2015/4/9	中专	1608153.5	4
福建	福建福州	84739982	4/11/48	女	2006/5/17	高中	1558914.59	4
黑龙江	黑龙江牡丹江	245540722	7/21/77	男	2008/8/11	本科	1542130	11
江苏	江苏无锡	102036412	12/23/69	女	2006/10/25	高中	1378616.22	4
四川	四川成都	158559082	10/27/62	女	2007/8/21	高中	1368403.12	2
四川	四川高新	80506182	10/20/79	男	2006/3/29	大专	1361781.44	7
北京	北京东城	473511242	8/17/80	女	2012/8/3	大专	1355572.33	13
北京	北京东城	3649382	8/10/80	男	2003/5/1	大专	1341996.3	4
北京	北京良乡	51498712	10/16/79	女	2005/7/13	大专	1283734.55	5
浙江	浙江杭州	684166212	6/10/80	女	2014/11/19	中专	1275636	5
北京	北京海淀	369271412	10/7/88	女	2010/4/8	大专	1256157.7	144
四川	四川绵阳	595314212	11/17/86	女	2013/12/23	大专	1244411.43	0
山东	山东菏泽	398851942	1/20/83	女	2010/10/4	大专	1236442.04	4
山东	山东日照	63825252	9/1/76	女	2005/10/31	中专	1179056.29	27
深圳	深圳福田第二	375464062	10/13/79	男	2010/5/17	本科	1177775.16	5
北京	北京平谷	481850172	6/7/80	女	2012/11/1	本科	1153168	4
宁波	宁波慈溪	69825572	7/15/53	男	2005/12/20	高中	1141200.31	11
上海	上海陆家嘴	8009912	8/30/63	女	2003/9/23	高中	1133340.46	21
四川	四川高新	531276762	12/25/84	男	2013/6/9	本科	1120548.51	7
云南	云南昆明	148699552	10/30/72	女	2007/7/1	大专	1109034.8	18
湖北	湖北武汉	757213352	3/19/76	女	2015/5/25	大专	1106842	17
湖北	湖北十堰	112072492	9/25/70	女	2007/1/1	大专	1101132.13	19
深圳	深圳福田	19326812	4/24/66	男	2004/9/20	本科	1096309.08	6
浙江	浙江杭州	357516962	8/10/80	女	2010/1/18	本科	1090662.61	8
山东	山东烟台	558795732	11/29/84	女	2013/6/28	大专	1071717.5	7
黑龙江	黑龙江佳木斯	663685532	12/7/68	女	2014/8/28	高中	1069233	4
深圳	深圳福田第二	92594612	7/22/74	男	2006/8/1	大专	1067440.54	15
苏州	苏州张家港	5171153422	3/15/86	女	2016/6/14	本科	1062086	3
山西	山西长治	721783092	3/2/82	女	2015/3/25	高中	1050000	0
湖北	湖北武汉	864694792	6/29/84	女	2015/8/28	高中	1047450	3
深圳	深圳福田	546437792	10/6/85	女	2013/6/26	本科	1047254.29	4
福建	福建厦门	647887512	11/10/73	男	2014/7/28	高中	1046574.03	1
河南	河南三门峡	316289592	5/7/71	女	2009/7/21	高中	1044516	4
湖北	湖北随州	193264562	12/27/62	女	2007/12/10	高中	1039192.8	6
江西	江西新余	726776842	9/25/74	女	2015/3/25	高中	1030000	0
四川	四川青羊	106951312	11/20/77	女	2006/12/11	高中	1022207.14	1
辽宁	辽宁葫芦岛	510968592	9/20/78	女	2013/3/26	高中	1013132.79	0
云南	云南昆明	490030272	1/19/63	女	2012/12/14	大专	1010800	2
黑龙江	黑龙江齐齐哈尔	188260202	12/13/66	女	2007/12/10	大专	1010000	0
山西	山西吕梁	496639252	4/7/74	女	2012/12/31	大专	1009963	3
青岛	青岛开发区	738596192	1/26/68	女	2015/4/9	高中	1005065	2
四川	四川高新	165229192	7/14/72	女	2007/9/24	本科	1003146.52	6
四川	四川高新	249542982	7/31/74	女	2008/9/3	大专	949303.75	10
山东	山东烟台	478144352	1/2/80	女	2012/9/17	大专	892268.19	8
福建	福建福州	797998432	4/27/73	女	2015/7/21	高中	871458	1
上海	上海陆家嘴	179572	4/26/67	女	2002/1/1	高中	860489.68	20
山东	山东潍坊	653862572	5/13/86	男	2014/8/8	高中	835882	5
湖北	湖北襄阳	630902602	3/31/79	女	2014/5/25	本科	834871.1	10
天津	天津市区一支	5026121182	4/7/81	女	2015/11/21	本科	801412.5	1
辽宁	辽宁盘锦	476824592	4/15/73	女	2012/9/1	本科	793434.16	0
四川	四川青羊	88712	2/28/67	女	2001/12/30	大专	789733.64	14
四川	四川雅安	83743052	9/30/79	女	2006/4/30	大专	774043.06	4
四川	四川青羊	241332182	11/17/84	女	2008/7/22	本科	766154.85	7
湖北	湖北武汉	5184341822	2/18/72	女	2016/6/27	本科	757864	1
四川	四川青羊	9143212	8/23/67	女	2003/11/28	高中	756171.9	5
山东	山东烟台	751034382	11/6/89	女	2015/4/30	中专	751794	18
山东	山东德州	440278482	4/1/72	男	2011/9/28	大专	740054.01	22
深圳	深圳福田第二	5100761332	12/17/72	女	2016/3/21	本科	724693	9
四川	四川绵阳	7790652	9/21/76	女	2003/9/17	大专	717472.95	4
深圳	深圳罗湖	127574072	2/22/67	女	2007/4/1	本科	716770.64	9
青岛	青岛一支	4675072	4/4/73	女	2003/6/18	大专	700000	0
陕西	陕西西安	370425502	8/24/75	女	2010/4/19	大专	676587.35	6
辽宁	辽宁葫芦岛	517063542	8/3/64	男	2013/4/25	大专	672312.58	0
广西	广西南宁	484638882	6/8/82	女	2012/11/23	高中	666031.7	12
吉林	吉林辽源	661150552	3/23/63	女	2014/8/25	大专	660164.8	4
山东	山东烟台	504193942	10/21/75	女	2013/2/25	本科	657509.88	2
四川	四川青羊	37126662	7/9/63	女	2005/3/31	大专	656166	3
北京	北京平谷	783513332	10/1/77	女	2015/7/7	大专	650000	0
北京	北京平谷	113524422	4/15/68	男	2007/1/5	大专	645205	5
江西	江西南昌	213406092	8/31/63	女	2008/4/1	大专	643087.8	6
北京	北京海淀	324551342	4/27/87	男	2009/9/1	本科	638417.19	3
河南	河南郑州	5105796262	10/8/71	女	2016/3/22	高中	638300	4
四川	四川青羊	5455812	10/17/77	女	2003/7/4	本科	633698.3	11
辽宁	辽宁盘锦	5127859712	5/20/69	男	2016/4/25	大专	631937.5	4
四川	四川青羊	14224752	10/25/71	女	2004/6/3	中专	627782.85	3
广西	广西南宁	412331872	3/30/71	女	2010/12/31	本科	627304.43	16
苏州	苏州张家港	394090432	7/18/65	女	2010/9/2	高中	623493.54	3
青岛	青岛一支	692879462	10/7/81	女	2014/12/19	本科	622260.7	3
福建	福建厦门	195500032	6/8/73	女	2008/1/1	大专	617899.48	13
苏州	苏州常熟	783220932	1/15/63	女	2015/7/7	高中	610432.77	2
辽宁	辽宁本溪	5012640952	10/3/70	女	2015/11/8	大专	610020	1
山东	山东临沂	454857512	4/17/71	男	2012/3/6	初中	586953	6
浙江	浙江温州	636529302	1/17/79	女	2014/6/13	高中	576026.22	0
四川	四川青羊	2286912	9/19/70	女	2002/12/2	大专	574443.59	6
山东	山东潍坊	169909752	6/3/66	女	2007/10/18	中专	574042.09	13
云南	云南昆明	292559762	12/27/49	女	2009/4/14	大专	573701.21	7
佛山	佛山顺德龙江	512186882	11/12/79	女	2013/3/29	大专	569506.1	8
山东	山东日照	92676262	1/8/72	女	2006/8/2	本科	569348.72	24
上海	上海黄浦	37606222	10/15/77	女	2005/3/31	大专	562473.36	10
四川	四川高新	650982302	5/31/79	女	2014/8/4	大专	556883	4
宁波	宁波城区	385719402	11/30/72	女	2010/7/9	大专	554467.91	18
安徽	安徽合肥	36354432	11/8/76	男	2005/3/1	大专	549712.05	4
吉林	吉林长春	153032452	6/9/73	女	2007/8/1	大专	549488.53	39
山东	山东济南	18716482	10/9/69	女	2004/9/1	中专	548464.52	12
辽宁	辽宁沈阳	6909142	7/13/75	女	2003/8/29	大专	545963.7	6
四川	四川高新	455066862	3/20/90	女	2012/3/7	高中	542358.44	9
浙江	浙江绍兴	31489082	4/10/75	女	2005/2/7	高中	540681.86	33
江苏	江苏无锡	325579402	8/17/60	女	2009/8/24	高中	540229.19	4
山西	山西吕梁	548331042	3/16/84	女	2013/6/25	大专	532490	2
北京	北京东城	361292	12/28/67	女	2002/1/18	大专	532300.2	5
四川	四川高新	647489602	5/11/72	女	2014/7/25	大专	529688.23	17
河北	河北唐山	5013521152	11/27/85	女	2015/11/8	大专	529464	6
黑龙江	黑龙江双鸭山	338323942	2/8/67	女	2009/10/25	本科	526188.74	5
黑龙江	黑龙江双鸭山	742403752	2/20/63	女	2015/4/10	大专	526000	0
黑龙江	黑龙江哈尔滨	208725802	1/3/71	女	2008/3/20	大专	525063.06	10
北京	北京平谷	593039802	10/28/69	女	2013/12/18	高中	523318.64	3
云南	云南昆明	610997782	9/23/66	女	2014/3/4	大专	523224.89	3
辽宁	辽宁沈阳	878107452	3/15/80	女	2015/9/25	本科	523142	5
重庆	重庆渝中	637133492	5/13/84	女	2014/6/16	高中	522822.54	6
北京	北京海淀	200127972	1/27/85	女	2008/1/14	本科	522563.45	1
黑龙江	黑龙江牡丹江	618937132	2/25/78	女	2014/3/26	高中	522219.5	8
辽宁	辽宁丹东	561215292	11/20/67	女	2013/6/28	大专	522022	7
山东	山东济南	65614932	9/1/79	女	2005/11/17	高中	520573.55	9
山西	山西长治	5217556602	9/22/84	女	2016/8/16	本科	517171.5	17
大连	大连海川	576789582	9/10/78	女	2013/9/5	本科	517064.25	2
黑龙江	黑龙江哈尔滨	28114872	1/8/69	女	2005/1/4	本科	516217.09	20
深圳	深圳福田第二	423741032	4/15/79	女	2011/5/12	中专	512000	0
浙江	浙江嘉兴	134827852	3/5/73	男	2007/5/11	中专	511424.65	5
四川	四川高新	631453202	10/12/84	女	2014/5/28	大专	511272.79	3
山东	山东莱芜	401608282	12/23/69	男	2010/10/25	本科	510288	4
江西	江西鹰潭	5049942402	1/29/75	女	2015/12/22	高中	508840	1
江西	江西赣州	708747822	11/14/84	女	2015/2/10	大专	507832.21	4
黑龙江	黑龙江牡丹江	490373192	9/3/73	男	2012/12/17	初中	506595	1
四川	四川青羊	484269032	10/12/79	女	2012/11/22	大专	506026.58	0
黑龙江	黑龙江哈尔滨	881947812	9/9/72	女	2015/10/8	本科	505748.6	1
广东	广东东莞	805797372	11/7/78	男	2015/7/27	大专	504882	1
浙江	浙江杭州	672484202	4/6/78	女	2014/9/24	中专	503000	0
山东	山东济南	126133722	8/20/71	女	2007/3/24	中专	502616	2
福建	福建龙岩	862955632	12/15/84	女	2015/8/25	大专	502182.7	1
北京	北京东城	633474152	6/8/83	女	2014/6/5	大专	493763.6	13
浙江	浙江杭州	89872472	7/29/70	女	2006/6/30	本科	490590.94	5
四川	四川高新	5044961892	3/14/77	女	2015/12/12	本科	485991.5	51
山西	山西太原	230510192	5/1/75	男	2008/6/2	高中	485240	2
四川	四川青羊	7948782	1/11/71	女	2003/9/19	硕士	476347.94	20
浙江	浙江温州	15657562	8/10/63	女	2004/7/6	中专	474437.64	10
河南	河南郑州	6897832	3/9/65	女	2003/8/29	大专	471640.47	26
四川	四川青羊	153392122	9/23/75	女	2007/7/25	高中	470760	9
湖北	湖北武汉	539292592	2/22/90	男	2013/6/18	大专	470522.65	0
浙江	浙江金华	504972042	12/8/76	女	2013/2/28	初中	469475.1	13
山西	山西忻州	562987252	10/8/88	男	2013/6/29	本科	463851.3	12
辽宁	辽宁丹东	5057524742	2/5/70	女	2016/1/6	本科	462886.8	9
湖北	湖北武汉	5198919932	2/12/69	女	2016/8/1	大专	461667	10
四川	四川乐山	757799122	4/22/67	女	2015/5/27	高中	461098	4
广东	广东广州	503068882	6/11/78	女	2013/2/6	大专	460553.6	12
河南	河南郑州	458746722	4/16/90	女	2012/3/23	本科	459525.86	4
吉林	吉林长春	437303582	6/7/75	女	2011/9/9	大专	454799.81	11
四川	四川高新	447324312	9/12/84	女	2011/12/16	大专	454660.6	15
四川	四川乐山	31761932	9/24/56	女	2005/2/21	大专	453791.74	4
河南	河南南阳	100328652	3/8/79	女	2006/10/8	大专	453463.08	5
宁波	宁波宁海	718754872	10/26/80	女	2015/3/24	大专	451335	23
四川	四川高新	5061666732	11/19/81	女	2016/1/18	本科	449761.2	17
四川	四川青羊	100022	3/21/75	女	2001/12/31	大专	449308.28	9
广东	广东珠海	626089172	8/30/87	女	2014/4/30	本科	449280.05	6
辽宁	辽宁沈阳	308615982	11/5/69	女	2009/6/25	中专	447876.48	4
佛山	佛山顺德龙江	742192032	4/4/73	女	2015/4/10	大专	447663.2	5
浙江	浙江杭州	248891342	8/19/74	女	2008/9/1	高中	446192.86	8
四川	四川青羊	133792	12/13/68	女	2001/12/30	大专	445609.73	6
北京	北京东城	21732692	10/29/63	女	2004/11/1	大专	442811.38	22
四川	四川高新	1659832	5/6/74	男	2002/8/12	大专	442490.46	16
新疆	新疆昌吉	581999252	7/20/67	男	2013/10/25	高中	440450.27	3
大连	大连明锐	5003000772	1/30/86	女	2015/10/21	硕士	439430.6	6
山西	山西太原	235208202	9/14/69	女	2008/6/25	大专	439355.66	7
山东	山东济南	243986352	6/15/79	女	2008/8/1	本科	438131.2	9
四川	四川绵阳	5096785232	7/7/85	女	2016/3/16	中专	436198.8	10
青岛	青岛一支	518815682	11/2/81	男	2013/5/3	本科	434074.05	11
天津	天津市区二支	684050092	9/24/72	女	2014/11/18	高中	430467	7
江西	江西赣州	628288072	10/17/66	女	2014/5/13	高中	430332	3
青岛	青岛即墨	5044645482	12/22/74	女	2015/12/11	大专	428549	4
福建	福建泉州	793627292	4/10/88 1:00	男	2015/7/17	高中	426948	4
苏州	苏州常熟	5047615302	3/18/83	男	2015/12/17	高中	424839.9	36
四川	四川高新	705304672	12/11/77	女	2015/1/21	中专	423249.81	4
四川	四川青羊	9349492	11/11/78	女	2003/12/11	本科	422445.61	5
深圳	深圳福田	387940492	3/6/75	女	2010/7/26	本科	420831.67	1
山东	山东日照	63982682	2/17/72	女	2005/10/31	中专	419975.7	49
浙江	浙江嘉兴	146927592	5/31/73	男	2007/7/1	初中	414410.24	3
广西	广西北海	421465922	10/18/74	男	2011/4/15	大专	412363.49	12
黑龙江	黑龙江哈尔滨	5047715972	6/15/74	女	2015/12/17	大专	410720	2
黑龙江	黑龙江齐齐哈尔	707377542	2/6/80	女	2015/1/30	大专	410393	2
佛山	佛山禅城	258523372	9/8/72	女	2008/10/23	高中	407188.77	10
江西	江西宜春	657473762	9/5/76	男	2014/8/18	本科	406026.22	0
陕西	陕西西安	534591512	6/13/78	女	2013/6/14	大专	404659	2
黑龙江	黑龙江牡丹江	5026123952	8/12/65	女	2015/11/21	高中	403645	1
湖南	湖南永州	5142349892	2/2/86	男	2016/5/19	高中	400000	0
黑龙江	黑龙江齐齐哈尔	676451292	12/14/68	女	2014/10/10	中专	398498	2
江苏	江苏南京	5063640242	11/12/82	女	2016/1/29	大专	396111	3
湖南	湖南郴州	516759562	10/6/86	女	2013/4/24	大专	388338	2
湖北	湖北武汉	20893192	2/20/69	女	2004/10/17	大专	388127.05	16
四川	四川青羊	98008192	3/28/73	女	2006/9/22	高中	384521.42	8
黑龙江	黑龙江鹤岗	472360222	5/31/80	女	2012/7/19	中专	383991.44	0
山东	山东东营	154460792	11/12/76	女	2007/7/25	初中	380204.31	13
山东	山东淄博	532106082	10/13/68	女	2013/6/9	大专	378387.55	4
四川	四川青羊	6706902	2/8/71	女	2003/8/20	高中	377997.11	5
苏州	苏州新区	466142312	8/13/62	男	2012/5/28	本科	377387.46	0
大连	大连明锐	6648842	1/1/69	女	2003/8/15	大专	377237.7	7
河北	河北沧州	634625172	4/16/77	女	2014/6/9	高中	376894	3
四川	四川成都	5046782182	8/18/64	女	2015/12/15	中专	376723	4
四川	四川青羊	760872	7/15/63	男	2002/3/8	中专	376002.35	6
河南	河南南阳	28755632	12/26/63	男	2005/1/8	大专	375897.23	5
天津	天津市区二支	394910812	9/9/78	女	2010/9/9	本科	375258.3	17
浙江	浙江杭州	4836272	10/20/76	女	2003/6/20	大专	375079.4	19
天津	天津市区一支	135160862	2/25/73	女	2007/5/15	高中	373318.34	4
浙江	浙江杭州	86567632	8/15/78	女	2006/5/31	大专	365789.15	6
浙江	浙江温州	437464782	9/28/82	女	2011/9/13	高中	362119.39	7
黑龙江	黑龙江哈尔滨	19379432	12/18/68	男	2004/10/1	大专	360184.42	15
四川	四川内江	357111242	7/27/76	女	2010/1/13	大专	359709.69	8
苏州	苏州新区	785830722	6/12/77	女	2015/7/10	大专	358975	1
四川	四川青羊	337223172	6/8/71	女	2009/10/20	大专	356079.78	5
山西	山西朔州	5182689152	6/20/91	男	2016/6/24	高中	355170.9	4
北京	北京良乡	621212512	1/9/76	女	2014/4/1	大专	354332.08	0
福建	福建南平	636154282	1/20/74	女	2014/6/13	高中	353013.62	0
陕西	陕西西安	361663402	3/13/70	女	2010/2/24	高中	352603.01	7
黑龙江	黑龙江哈尔滨	201643132	6/30/63	女	2008/1/22	大专	350464.62	21
苏州	苏州张家港	360119912	12/1/67	女	2010/2/3	高中	350264	3
湖北	湖北襄阳	595199762	8/8/80	男	2013/12/23	高中	350093	16
广东	广东惠州	425891082	12/18/70	男	2011/6/3	大专	350000	0
北京	北京海淀	3202622	3/20/69	女	2003/3/19	大专	346989	9
湖南	湖南长沙	140374302	11/9/67	女	2007/7/1	大专	346772.67	15
佛山	佛山顺德龙江	8517802	1/20/66	女	2003/10/1	高中	344637.97	22
北京	北京海淀	242148792	3/6/83	女	2008/8/1	本科	344243.01	22
青岛	青岛城阳	453026792	3/27/83	女	2012/2/24	高中	343435.24	9
山东	山东烟台	128038552	3/19/75	女	2007/4/1	中专	342908.03	2
北京	北京海淀	393257072	8/27/87	女	2010/9/1	大专	342500.29	9
河北	河北石家庄	6968872	11/21/75	女	2003/9/1	大专	341734.7	15
浙江	浙江绍兴	20416702	6/1/68	女	2004/10/8	本科	340756.98	7
四川	四川青羊	582694302	6/8/84	男	2013/11/1	本科	340369.46	4
辽宁	辽宁鞍山	829339342	3/21/75	女	2015/8/5	大专	340353.31	7
湖南	湖南湘潭	275795512	12/25/60	女	2009/1/6	大专	340074.71	3
广西	广西柳州	5161683702	6/5/81	女	2016/6/4	高中	340026.3	7
黑龙江	黑龙江齐齐哈尔	58169522	10/10/69	女	2005/9/13	本科	338386.4	6
福建	福建福州	362689092	1/25/78	女	2010/3/3	高中	337892.34	3
山东	山东济南	5193842	12/20/71	女	2003/7/1	本科	337478.29	10
黑龙江	黑龙江哈尔滨	5129432552	4/22/72	女	2016/4/27	高中	337360	1
浙江	浙江杭州	261887942	7/15/79	女	2008/11/7	中技	334369.78	8
山西	山西忻州	613788592	11/13/68	女	2014/3/15	高中	332811.73	5
北京	北京海淀	394652612	11/14/83	女	2010/9/7	本科	331539	1
天津	天津市区一支	296532022	4/17/73	女	2009/5/4	本科	330000	0
吉林	吉林吉林	244760952	2/2/69	男	2008/8/6	中专	329721.73	16
青岛	青岛即墨	148514762	9/30/56	女	2007/7/10	高中	328482.32	3
广东	广东汕头	831591832	7/11/73	女	2015/8/6	高中	327453	2
辽宁	辽宁鞍山	29480412	10/31/61	女	2005/1/20	高中	326761	6
湖北	湖北武汉	627323512	5/23/88	女	2014/5/8	大专	325581.2	10
大连	大连海川	5531322	9/6/65	男	2003/7/1	高中	323898.5	7
陕西	陕西西安	209498732	12/10/83	女	2008/3/22	本科	323704	3
四川	四川绵阳	74963502	10/19/64	女	2006/2/8	高中	321628.86	4
辽宁	辽宁盘锦	600543972	5/27/69	女	2013/12/30	本科	321392.05	3
山东	山东济南	55022812	9/18/76	女	2005/8/17	大专	321145.1	21
内蒙古	内蒙古通辽	5201365772	5/14/70	女	2016/8/2	高中	321001.82	17
重庆	重庆渝中	445548582	10/7/76	女	2011/11/29	大专	320608.47	14
广西	广西梧州	5035400572	12/4/73	女	2015/11/29	高中	320536	5
北京	北京东城	2944422	1/20/68	女	2003/3/3	硕士	320444.8	2
云南	云南昆明	372441982	7/29/75	女	2010/5/1	大专	320242.61	1
四川	四川青羊	96500572	12/21/81	女	2006/9/7	高中	320032	3
黑龙江	黑龙江双鸭山	5157798232	5/3/84	女	2016/6/1	中专	319160	2
四川	四川雅安	713140182	11/3/72	女	2015/3/17	中专	315129.3	4
青岛	青岛一支	738621452	3/27/79	女	2015/4/9	本科	315000	0
广东	广东东莞	290588362	10/7/84	男	2009/4/2	大专	314918.01	3
湖南	湖南永州	5074259492	4/16/60	女	2016/3/4	高中	314534	4
深圳	深圳宝安	190397272	8/24/62	女	2007/12/17	大专	312908.21	3
北京	北京海淀	240066712	9/5/86	男	2008/7/14	本科	312529.77	10
青岛	青岛开发区	5167513672	10/30/74	女	2016/6/9	本科	312130.5	4
山东	山东淄博	108817202	7/25/69	女	2006/12/20	中专	312000.62	17
辽宁	辽宁本溪	5079028832	12/1/68	女	2016/3/7	本科	311780.5	4
江西	江西吉安	807237022	10/25/74	女	2015/7/27	大专	311730	4
山东	山东济南	503809712	4/24/76	女	2013/2/21	大专	310994.41	7
山东	山东济南	5202082	6/29/73	女	2003/7/1	大专	310737.94	8
黑龙江	黑龙江牡丹江	717770222	8/28/70	女	2015/3/23	大专	310405.7	6
黑龙江	黑龙江哈尔滨	528429292	11/29/78	女	2013/6/6	中专	309747	4
河北	河北石家庄	9266172	11/14/67	女	2003/12/1	大专	309553.97	4
广东	广东梅州	5786684612	11/7/73	女	2018/6/27	本科	308983.28	9
山东	山东淄博	431141362	2/27/73	女	2011/7/22	中专	308948.79	6
四川	四川青羊	5042737192	10/15/65	女	2015/12/7	高中	308688	3
浙江	浙江杭州	315406682	3/6/76	男	2009/7/17	中专	308472.21	3
辽宁	辽宁沈阳	5024692492	6/25/86	男	2015/11/20	本科	308073	2
天津	天津蓟州	829842762	2/2/86	男	2015/8/5	高中	307350	1
四川	四川青羊	24960352	6/14/70	女	2004/11/30	本科	306821	2
湖南	湖南株洲	5059520962	12/16/87	男	2016/1/9	本科	306522.6	3
四川	四川青羊	684395382	12/19/82	男	2014/11/19	本科	306119	3
北京	北京海淀	204895352	9/17/84	女	2008/2/25	本科	305926.06	32
深圳	深圳福田第二	590525342	11/8/81	女	2013/12/11	本科	305642	1
苏州	苏州昆山	61854822	1/25/66	女	2005/7/12	中专	305295.94	7
四川	四川青羊	61629422	6/10/80	女	2005/10/11	大专	304971.85	11
深圳	深圳福田第二	8486402	2/10/76	男	2003/10/21	本科	304947.65	6
北京	北京东城	5109171322	2/12/79	女	2016/3/24	本科	304730	2
青岛	青岛一支	177857612	11/14/74	女	2007/11/8	大专	304220.97	23
北京	北京东城	5184336352	5/24/74	女	2016/6/27	本科	303750	6
四川	四川绵阳	413808092	1/26/70	女	2011/1/10	高中	303600.43	1
山东	山东淄博	346920102	8/19/64	女	2009/11/30	大专	303499.32	10
山东	山东烟台	5049889852	8/27/86	女	2015/12/22	中专	303136.9	1
陕西	陕西渭南	820800272	10/12/83	女	2015/7/31	本科	303000	0
江西	江西南昌	395786882	5/5/63	女	2010/9/15	本科	302050.48	9
苏州	苏州新区	5121170312	5/30/62	男	2016/4/14	博士	301756.58	10
浙江	浙江绍兴	442406342	10/21/76	女	2011/10/24	中专	300344.24	14
重庆	重庆巴南	5087706212	5/3/70	女	2016/3/10	大专	300000	0
广东	广东惠州	605756712	9/6/84	女	2014/1/15	本科	300000	0
福建	福建福州	5036561872	6/1/82	女	2015/11/30	大专	300000	0
北京	北京东城	43573742	9/16/74	男	2005/5/19	大专	299053.09	16
北京	北京东城	1212102	5/28/77	女	2002/6/1	大专	298390.47	11
四川	四川高新	3544842	5/23/77	女	2003/4/11	本科	297530.33	9
大连	大连海川	5377932	12/15/64	女	2003/7/1	大专	297452.57	7
湖北	湖北荆州	86081932	8/8/60	男	2006/5/31	大专	297284.78	9
湖南	湖南郴州	5168244322	11/4/83	男	2016/6/12	高中	297000	0
山东	山东烟台	252955432	12/15/70	女	2008/9/23	大专	296316.91	27
大连	大连明锐	51418462	3/26/73	女	2005/7/12	中专	295552.49	7
浙江	浙江金华	118077282	3/4/71	女	2007/1/25	高中	295102.4	6
甘肃	甘肃金昌	827565982	10/8/87	男	2015/8/4	大专	293000	0
黑龙江	黑龙江哈尔滨	253072252	12/25/78	女	2008/9/24	大专	290631	1
江苏	江苏泰州	360453622	1/6/77	男	2010/2/8	高中	290000	0
四川	四川高新	65822	3/13/67	男	2001/12/30	本科	289414.66	18
北京	北京东城	133221562	12/30/76	男	2007/5/1	本科	288562.64	6
山西	山西太原	155777482	9/24/78	女	2007/8/1	大专	288489.24	15
苏州	苏州昆山	630330512	6/1/81	女	2014/5/23	大专	288227.82	5
北京	北京东城	489343732	10/28/76	男	2012/12/12	硕士	285416.8	12
山西	山西太原	185739872	9/1/83	女	2007/12/1	大专	283659.81	12
苏州	苏州新区	502265252	10/7/74	男	2013/1/31	初中	282341.84	4
四川	四川青羊	25344582	1/27/76	女	2004/12/6	高中	282211.53	21
湖北	湖北十堰	387847262	4/17/66	女	2010/8/1	大专	282079.82	29
河北	河北承德	784344322	10/26/85	男	2015/7/9	大专	280500	0
吉林	吉林长春	716707962	11/17/67	女	2015/3/23	高中	279775	8
河南	河南濮阳	383467882	4/4/86	男	2010/6/25	本科	277688	9
湖南	湖南长沙	450505492	10/12/69	女	2012/1/1	大专	277411.68	14
深圳	深圳福田	674672492	4/25/78	女	2014/9/26	本科	277402.47	5
辽宁	辽宁沈阳	418256412	10/25/62	女	2011/3/18	大专	277235.18	3
湖北	湖北十堰	263655162	6/9/68	女	2008/11/18	大专	275569.57	6
江西	江西新余	614903742	1/6/78	女	2014/3/18	高中	275464.08	4
黑龙江	黑龙江哈尔滨	19380132	10/1/65	女	2004/10/1	大专	274503.24	9
北京	北京海淀	1356552	4/11/69	男	2002/6/21	大专	273866.04	0
青岛	青岛即墨	76091632	4/20/64	女	2006/2/25	高中	273229.98	8
苏州	苏州张家港	5038314172	12/28/52	男	2015/11/30	本科	273044.24	42
四川	四川高新	75429382	11/26/74	女	2006/2/22	本科	272455.4	25
福建	福建福州	422463282	12/20/83	女	2011/4/27	大专	272270.53	6
贵州	贵州贵阳	282912382	10/7/81	男	2009/3/6	本科	270824.61	9
北京	北京东城	5157125602	2/14/69	女	2016/6/1	硕士	270000	0
山东	山东烟台	5178863572	10/3/76	女	2016/6/21	中专	270000	0
四川	四川青羊	2264722	1/9/65	女	2002/11/27	本科	269251.15	5
大连	大连明锐	635157622	6/1/76	女	2014/6/10	本科	269160.5	14
山东	山东德州	240401122	1/28/73	女	2008/7/15	大专	268935.19	5
大连	大连明锐	6649542	8/4/73	女	2003/8/15	大专	268827.64	17
山东	山东烟台	13392092	1/7/61	女	2004/5/1	高中	267666.51	12
四川	四川青羊	61365082	11/20/74	女	2005/10/8	中专	267461.48	4
浙江	浙江嘉兴	40234432	10/24/70	女	2005/4/26	高中	267144.84	5
四川	四川青羊	611267292	5/19/85	女	2014/3/5	大专	266292.11	18
河北	河北保定	670587882	8/15/86	女	2014/9/20	本科	265760	4
山东	山东威海	16320512	8/14/71	女	2004/7/1	大专	265318.92	26
广西	广西柳州	421195442	9/23/79	女	2011/4/12	大专	264450.71	15
安徽	安徽芜湖	5196640882	10/3/62	男	2016/7/29	大专	264000	2
深圳	深圳罗湖	591581732	2/6/78	女	2013/12/13	本科	263854.05	5
浙江	浙江杭州	4846182	1/13/75	女	2003/6/20	大专	262773.65	9
天津	天津蓟州	117095972	1/25/78	女	2007/1/24	中专	262300.84	10
辽宁	辽宁沈阳	741611702	5/12/64	女	2015/4/10	高中	262059.2	12
云南	云南昆明	711593622	6/15/78	女	2015/3/10	本科	261387.19	2
黑龙江	黑龙江双鸭山	740197042	7/12/70	女	2015/4/10	高中	261148	4
北京	北京海淀	530049262	8/23/82	女	2013/6/8	大专	260392	31
深圳	深圳福田	851721082	11/26/71	男	2015/8/10	本科	260000	0
福建	福建宁德	5003120412	5/15/72	男	2015/10/21	高中	259260	2
佛山	佛山南海	571002382	1/11/79	男	2013/7/26	大专	259086.1	7
山西	山西阳泉	5068738662	2/2/72	女	2016/2/25	大专	258165.92	6
浙江	浙江温州	340935132	10/22/75	女	2009/11/6	高中	258111.23	13
山东	山东济南	402465232	9/15/81	女	2010/10/28	大专	257788.34	4
湖北	湖北襄阳	58055772	8/25/57	女	2005/9/12	大专	257740.61	10
四川	四川青羊	4357412	8/2/80	女	2003/5/23	本科	257499.35	2
山东	山东淄博	12874292	2/4/59	女	2004/4/26	本科	256531.6	22
四川	四川高新	5107959272	7/17/80	男	2016/3/23	本科	256492	17
江西	江西南昌	189850442	7/26/79	女	2007/12/14	大专	256227.28	13
四川	四川青羊	251977892	5/24/84	女	2008/9/18	大专	256131	2
河南	河南南阳	218952812	2/26/65	女	2008/4/25	中专	255984.54	4
安徽	安徽合肥	22642262	7/6/65	女	2004/11/1	大专	255805.03	13
广东	广东中山	81274642	11/14/75	女	2006/4/4	大专	255624.7	6
深圳	深圳福田第二	39379852	9/1/73	女	2005/4/20	大专	254546.97	12
青岛	青岛一支	603439322	2/27/70	女	2014/1/1	本科	254275.78	6
重庆	重庆永川	413255122	11/25/68	男	2011/1/4	高中	253923.65	4
深圳	深圳宝安	49682372	6/28/73	女	2005/6/29	中专	253873.92	26
安徽	安徽合肥	188864392	12/4/78	男	2007/12/1	中专	253864.19	16
浙江	浙江嘉兴	18759192	12/26/69	女	2004/9/1	大专	252685.44	2
黑龙江	黑龙江鹤岗	496895082	7/14/72	女	2012/12/31	中专	252632.69	4
山西	山西长治	874769812	4/17/76	女	2015/9/20	大专	252135	0
山东	山东淄博	362774782	2/5/71	女	2010/3/5	中专	252041.93	16
辽宁	辽宁丹东	34998802	10/7/62	男	2005/3/23	大专	251629.18	5
山东	山东烟台	823357442	10/22/73	女	2015/8/1	中专	251048	33
宁波	宁波宁海	5106873232	12/15/82	男	2016/3/23	高中	250000	0
吉林	吉林延边	5121343472	11/3/80	女	2016/4/14	中专	249461.7	7
苏州	苏州张家港	135586132	1/27/78	女	2007/5/18	大专	249299.94	34
安徽	安徽滁州	217132702	5/16/72	女	2008/4/1	高中	248385.18	7
上海	上海陆家嘴	2456352	7/29/73	女	2003/1/3	高中	246990.75	8
北京	北京东城	268156102	5/29/81	女	2008/12/3	本科	246304.57	20
福建	福建漳州	195513982	11/6/73	女	2008/1/1	大专	245799.79	8
江苏	江苏南京	119793552	2/1/76	女	2007/2/12	大专	245474.37	3
河北	河北石家庄	6965802	4/5/76	女	2003/9/1	大专	244748.17	30
苏州	苏州新区	5060853552	1/11/80	女	2016/1/13	高中	244721.5	11
黑龙江	黑龙江牡丹江	775316482	4/22/73	女	2015/6/26	高中	244233	2
广西	广西柳州	605549732	2/24/71	女	2014/1/14	中专	244177.8	7
河北	河北邢台	5031277622	3/13/81	男	2015/11/25	高中	243473.6	3
重庆	重庆渝中	5072386582	4/20/81	男	2016/3/3	大专	243299	3
河南	河南郑州	5058533722	8/19/81	女	2016/1/8	大专	243157.5	6
四川	四川青羊	3833472	9/26/68	女	2003/4/21	大专	242900.64	11
四川	四川青羊	119577362	9/27/67	女	2007/2/9	本科	242454.55	3
深圳	深圳福田	561205382	3/10/80	女	2013/6/28	本科	242440.41	1
苏州	苏州新区	140242132	4/30/56	女	2007/6/15	高中	242227.52	19
四川	四川雅安	5175568272	10/31/63	女	2016/6/20	大专	241350	1
湖北	湖北荆州	50629482	10/20/73	女	2005/7/1	本科	241101.99	16
浙江	浙江杭州	4742342	2/3/65	女	2003/6/20	本科	240961.44	7
湖北	湖北十堰	388059682	4/7/71	女	2010/8/1	高中	240502.3	11
四川	四川绵阳	182829872	4/5/76	女	2007/11/23	大专	240229.2	10
深圳	深圳福田第二	692102762	8/7/68	女	2014/12/18	中专	240000	0
四川	四川青羊	44332	9/26/73	女	2001/12/30	本科	239103.04	12
福建	福建福州	429636812	12/7/78	女	2011/6/29	本科	238625.17	3
辽宁	辽宁阜新	33165712	2/2/63	女	2005/3/7	高中	237833.71	14
广东	广东东莞	639239162	10/8/74	女	2014/6/20	高中	237365.3	5
辽宁	辽宁朝阳	708478042	6/25/75	女	2015/2/6	大专	236789	2
湖北	湖北襄阳	289649762	2/4/65	女	2009/3/25	大专	236174.23	11
云南	云南昆明	593308612	9/20/83	女	2013/12/19	本科	236023.75	7
福建	福建龙岩	299802662	6/4/70	女	2009/5/15	中专	235529.25	26
北京	北京良乡	452769292	12/21/75	女	2012/3/1	大专	235502.49	16
河南	河南郑州	7044382	7/13/68	男	2003/9/3	本科	235011.86	0
广东	广东中山	121095862	11/20/73	女	2007/3/1	大专	234819.46	10
青岛	青岛一支	5184281032	5/29/78	女	2016/6/27	大专	234772.3	3
云南	云南昆明	829943532	9/8/85	女	2015/8/5	大专	234259	5
河北	河北廊坊	5017144632	3/20/63	女	2015/11/12	中专	234078	8
四川	四川青羊	12612322	10/30/69	女	2004/4/16	本科	233882.89	18
湖北	湖北宜昌	5048264392	3/13/77	女	2015/12/18	高中	233547.5	7
黑龙江	黑龙江哈尔滨	5046768612	4/10/77	男	2015/12/15	本科	233534.3	7
四川	四川青羊	1875052	12/16/63	女	2002/9/13	高中	233060.03	9
四川	四川青羊	474306362	12/22/73	女	2012/8/10	高中	232088	3
山东	山东日照	182481512	11/26/65	女	2007/11/23	高中	231989.81	11
重庆	重庆渝中	599311032	11/15/74	女	2013/12/27	中专	231008.02	12
山东	山东济宁	444068322	12/13/71	女	2011/11/17	高中	230968.94	3
苏州	苏州新区	360794172	10/26/69	男	2010/2/9	大专	230612.9	1
山东	山东滨州	647773762	1/4/77	男	2014/7/26	中专	229091.05	12
辽宁	辽宁沈阳	6177522	11/17/67	男	2003/7/30	大专	228830.09	12
北京	北京平谷	784670492	10/15/81	女	2015/7/9	本科	228700	2
四川	四川高新	564812802	9/23/73	女	2013/6/29	中专	228452.65	16
广东	广东东莞	365548842	4/2/77	女	2010/3/23	高中	228121.61	13
江西	江西南昌	51424602	8/12/77	女	2005/7/12	大专	227756.45	9
四川	四川绵阳	213644472	3/30/69	女	2008/4/1	高中	227626.96	7
吉林	吉林延边	5144131202	1/8/63	女	2016/5/23	大专	227600	1
浙江	浙江杭州	554642182	4/28/86	女	2013/6/27	高中	226292.9	4
辽宁	辽宁铁岭	71475272	1/18/78	女	2005/12/30	高中	225872.58	3
四川	四川青羊	95692472	2/5/80	女	2006/9/1	本科	225424.7	1
云南	云南昆明	147409522	12/4/68	女	2007/7/1	本科	225373.25	7
四川	四川雅安	30703172	6/17/72	男	2005/1/1	大专	225316.82	5
四川	四川雅安	12009802	11/22/65	女	2004/4/1	高中	225078.58	5
广东	广东广州	524946502	8/29/74	女	2013/5/30	大专	224992.74	7
青岛	青岛一支	87695762	5/12/79	男	2006/6/22	本科	224852.5	27
黑龙江	黑龙江大庆	5124248972	7/18/70	女	2016/4/18	大专	223838.8	8
北京	北京海淀	417289482	1/19/86	女	2011/3/9	大专	223571.02	11
辽宁	辽宁阜新	82212542	12/12/75	女	2006/4/21	高中	223421.59	4
重庆	重庆渝中	708102322	3/9/81	女	2015/2/4	本科	223190	6
山东	山东济南	82226222	11/3/81	女	2006/4/21	大专	223089.55	37
山东	山东淄博	57257582	5/26/60	女	2005/8/31	高中	222323.51	26
四川	四川高新	479715472	9/25/90	女	2012/9/29	大专	222205.75	9
山东	山东威海	169573672	3/17/71	女	2007/10/16	大专	222069.6	18
浙江	浙江温州	500270262	10/24/71	男	2013/1/21	本科	221274.79	8
江苏	江苏徐州	122380452	1/20/70	男	2007/3/12	高中	221261.2	6
安徽	安徽合肥	22508692	10/5/69	女	2004/11/1	大专	221210.67	8
北京	北京东城	5143073272	8/17/82	女	2016/5/20	硕士	220635	10
江苏	江苏连云港	591094362	11/29/72	男	2013/12/13	大专	220603.46	0
北京	北京良乡	803841322	4/9/74	男	2015/7/26	大专	220530	2
河南	河南洛阳	141324482	12/13/77	女	2007/6/19	高中	220214.36	23
河南	河南郑州	4992032	4/7/75	女	2003/6/27	大专	220204.04	20
湖南	湖南娄底	770093832	5/23/74	女	2015/6/24	高中	220000	0
山西	山西长治	5005739412	1/26/90	男	2015/10/26	大专	220000	0
贵州	贵州贵阳	376945022	1/5/77	女	2010/5/25	大专	219969	4
湖南	湖南永州	437367782	10/20/62	女	2011/9/10	高中	219821.53	5
辽宁	辽宁沈阳	423116322	7/30/80	女	2011/5/4	大专	219544.1	4
甘肃	甘肃武威	676062592	3/5/74	男	2014/10/8	大专	219440.42	3
四川	四川青羊	327608862	10/23/82	女	2009/8/28	大专	219034.13	9
浙江	浙江金华	873340772	10/18/64	男	2015/9/18	高中	218863.2	12
北京	北京平谷	2711482	4/19/70	女	2003/2/1	大专	218577.22	11
河北	河北邯郸	232565612	5/20/66	女	2008/6/20	本科	218558.11	15
甘肃	甘肃兰州	279640342	4/16/68	女	2009/2/18	大专	218285.92	26
江苏	江苏镇江	179496702	8/23/79	女	2007/11/13	高中	218126.02	3
浙江	浙江金华	46781582	12/24/71	女	2005/6/10	高中	218027.71	12
四川	四川乐山	5051505272	4/11/79	女	2015/12/23	大专	217551	2
江西	江西南昌	433721422	4/6/67	女	2011/8/10	高中	217387.86	3
山东	山东日照	491275222	4/20/70	女	2012/12/19	中专	217339.68	9
佛山	佛山禅城	391864872	4/30/85	男	2010/8/23	本科	217243.64	3
山东	山东济宁	442351352	8/4/71	女	2011/10/22	大专	217182.74	15
江苏	江苏泰州	592669252	3/28/68	女	2013/12/18	高中	217140.99	20
北京	北京东城	656062	8/11/70	女	2002/2/22	大专	216946.08	0
江西	江西南昌	5194660032	5/26/85	女	2016/7/28	高中	216717.5	30
深圳	深圳福田	851351502	5/29/80	女	2015/8/10	大专	216398	5
四川	四川高新	776922	9/10/77	女	2002/3/11	大专	216381.8	9
山东	山东淄博	648254022	8/15/71	女	2014/7/28	高中	216357.77	6
河北	河北唐山	414022882	7/19/58	女	2011/1/13	高中	216226	4
山西	山西太原	5004977952	10/1/77	女	2015/10/24	本科	216166.5	2
黑龙江	黑龙江哈尔滨	5178723022	1/23/88	女	2016/6/21	高中	216094	3
湖南	湖南永州	467419362	10/22/73	女	2012/6/7	高中	215968.93	8
安徽	安徽阜阳	5039441962	2/18/81	女	2015/11/30	大专	215854.5	3
深圳	深圳宝安	588021762	12/19/86	女	2013/12/3	大专	215834.5	13
黑龙江	黑龙江双鸭山	5010154262	6/29/59	女	2015/11/5	大专	215667.5	4
江西	江西南昌	5039314082	6/4/79	女	2015/11/30	高中	215604.2	7
江西	江西南昌	5090524682	2/26/78	女	2016/3/12	高中	215218.7	6
山东	山东烟台	444211102	3/23/71	女	2011/11/19	中专	214670.76	9
浙江	浙江台州	58871412	1/23/73	女	2005/9/19	高中	214510.39	11
宁波	宁波宁海	670831432	3/21/83	女	2014/9/22	大专	214280	2
山东	山东淄博	656865802	12/10/76	男	2014/8/15	大专	214200.65	9
四川	四川青羊	5135733952	10/26/82	男	2016/5/9	大专	214081	2
深圳	深圳福田第二	131105982	9/16/79	女	2007/4/20	本科	213902.16	4
贵州	贵州贵阳	5153587352	12/25/74	男	2016/5/30	高中	213692.5	2
湖北	湖北武汉	344482122	2/17/80	女	2009/11/23	大专	213675.06	16
河南	河南南阳	224949092	11/5/70	女	2008/5/21	高中	213334.06	1
北京	北京东城	75259942	9/2/70	女	2006/2/17	大专	212726	15
浙江	浙江杭州	690792912	2/17/79	女	2014/12/15	本科	212459.8	26
贵州	贵州贵阳	5104206182	9/15/89	女	2016/3/22	大专	212160	3
陕西	陕西汉中	686435452	7/29/67	女	2014/11/20	高中	212080	7
青岛	青岛一支	103467822	10/6/74	女	2006/11/14	本科	211771.35	3
四川	四川高新	690520062	4/19/76	男	2014/12/12	大专	211665	5
吉林	吉林延边	5104120052	11/10/69	女	2016/3/22	高中	210710	2
广西	广西南宁	872232462	8/9/88	女	2015/9/16	中专	210478.2	5
浙江	浙江温州	765560792	12/2/85	女	2015/6/17	高中	210272	3
山东	山东烟台	262805052	11/13/78	男	2008/11/12	中专	210203.13	12
湖北	湖北武汉	129339192	10/29/64	女	2007/4/7	高中	210075.61	18
云南	云南昆明	452354632	11/7/72	女	2012/2/13	中专	210047.5	0
青岛	青岛一支	507672962	10/31/76	女	2013/3/19	大专	209487	3
青岛	青岛开发区	642464722	2/18/65	男	2014/6/25	本科	209477.78	1
黑龙江	黑龙江牡丹江	214166042	3/23/73	女	2008/4/2	大专	209280.99	6
新疆	新疆乌鲁木齐	230366712	12/19/72	女	2008/6/1	中专	208903.85	41
福建	福建福州	732029222	2/17/72	女	2015/3/31	中专	208080	2
佛山	佛山顺德龙江	444463162	6/20/77	女	2011/11/22	大专	207774.89	5
北京	北京海淀	631844272	10/31/84	女	2014/5/30	本科	207532.63	0
河北	河北邢台	340063802	5/10/73	女	2009/11/2	中专	207511.3	4
云南	云南昆明	279600702	11/14/70	女	2009/2/18	中专	207376.55	1
广东	广东广州	503372	8/26/61	女	2002/1/1	大专	207246.6	6
湖南	湖南永州	421451272	1/17/67	女	2011/4/15	大专	206785.09	12
湖北	湖北十堰	810018892	7/26/70	女	2015/7/28	高中	206758.23	5
贵州	贵州六盘水	746755772	7/21/69	女	2015/4/17	大专	206579	4
湖北	湖北十堰	167971152	4/17/69	女	2007/10/5	大专	206343.69	21
广西	广西玉林	701557542	8/20/77	男	2014/12/31	大专	206118	2
佛山	佛山顺德龙江	8902732	4/18/64	女	2003/10/1	高中	205955.5	8
青岛	青岛一支	427164362	2/14/76	女	2011/6/17	大专	205949.17	14
湖南	湖南郴州	5064986472	9/28/79	女	2016/2/4	大专	205783.5	4
河南	河南周口	356331472	11/10/63	女	2010/1/5	本科	205441.64	15
山西	山西临汾	760544152	8/24/83	女	2015/6/3	高中	205437.9	2
山西	山西运城	749179102	3/20/70	女	2015/4/25	高中	205346	2
安徽	安徽合肥	691343512	10/8/80	男	2014/12/16	中专	205000	0
四川	四川青羊	80865152	4/6/84	女	2006/3/31	大专	204967	1
河南	河南郑州	697913552	7/21/91	女	2014/12/24	高中	204637	1
大连	大连海川	818999282	3/5/66	女	2015/7/31	硕士	204596	1
江苏	江苏盐城	660680902	12/26/65	男	2014/8/23	大专	204426.54	2
湖南	湖南长沙	5098499522	4/6/79	女	2016/3/18	中专	204318	1
江西	江西新余	399866052	7/28/70	女	2010/10/11	高中	204043.26	0
辽宁	辽宁沈阳	280058072	7/26/70	女	2009/2/20	高中	204034.3	3
广西	广西南宁	149007302	3/26/72	男	2007/7/11	大专	203966	10
黑龙江	黑龙江七台河	241399452	3/29/74	女	2008/7/22	高中	203542	1
湖南	湖南株洲	669826262	3/26/78	女	2014/9/17	高中	203490.2	2
大连	大连明锐	868815272	8/9/79	女	2015/9/9	大专	203417	1
河南	河南郑州	646920582	3/3/91	男	2014/7/22	本科	203246.68	6
陕西	陕西咸阳	681992572	4/30/73	女	2014/11/13	高中	203100	0
陕西	陕西汉中	620876432	2/28/78	女	2014/3/31	大专	203000	0
福建	福建厦门	603707432	8/27/71	女	2014/1/1	大专	202901.1	7
苏州	苏州张家港	284842472	11/1/68	女	2009/3/16	高中	202769.45	3
四川	四川高新	1628432	11/5/72	女	2002/8/9	硕士	202729	11
湖南	湖南永州	5147892462	1/9/77	女	2016/5/26	中专	201790.5	2
黑龙江	黑龙江哈尔滨	336753522	2/21/73	女	2009/10/15	大专	201458	1
辽宁	辽宁本溪	5134673252	3/18/79	男	2016/5/6	本科	201394	1
河北	河北石家庄	5161155152	1/16/70	女	2016/6/3	本科	201350	1
苏州	苏州张家港	444115772	1/4/71	男	2011/11/18	初中	201094	1
黑龙江	黑龙江鸡西	164415922	4/25/65	女	2007/9/21	中专	200702.1	0
安徽	安徽马鞍山	295853022	2/27/65	男	2009/5/1	高中	200647.59	5
河南	河南郑州	452027762	9/10/81	女	2012/2/1	大专	200042.13	9
江苏	江苏南京	5043156972	10/30/65	男	2015/12/8	大专	200000	0
江西	江西赣州	740288872	8/15/86	女	2015/4/10	大专	200000	0
天津	天津蓟州	480848072	11/20/87	女	2012/10/22	大专	200000	0
江西	江西南昌	5150658312	5/9/75	男	2016/5/27	高中	200000	0
山西	山西朔州	5152996282	5/7/78	女	2016/5/30	高中	200000	0
山东	山东济宁	615887422	12/31/94	男	2014/3/20	高中	199563.15	6
四川	四川内江	739684682	8/9/66	女	2015/4/9	大专	199390.31	20
山东	山东济宁	5077549322	3/29/89	男	2016/3/7	中专	199240.5	6
山东	山东济南	55025882	3/23/78	女	2005/8/17	中专	199179.14	14
重庆	重庆渝中	163377952	8/27/71	男	2007/9/17	大专	198637.26	18
山东	山东滨州	274660542	2/16/71	女	2009/1/1	中专	198285.97	7
广东	广东广州	321769742	12/23/72	男	2009/8/3	高中	198153.4	3
四川	四川青羊	623831512	8/19/69	女	2014/4/18	本科	197178.72	0
四川	四川青羊	168962	6/22/73	女	2001/12/31	大专	195772.16	5
山东	山东菏泽	323785252	2/29/64	女	2009/8/12	大专	195480.24	12
山东	山东威海	305414982	7/9/72	女	2009/6/9	高中	195408.35	7
山东	山东临沂	33842342	4/13/71	男	2005/3/15	大专	195101.07	13
北京	北京海淀	390176932	6/4/88	女	2010/8/10	本科	195083.1	6
辽宁	辽宁盘锦	521720242	10/24/53	女	2013/5/22	大专	194849.53	7
浙江	浙江温州	222138462	7/19/75	女	2008/5/8	高中	194737.49	4
江西	江西赣州	65237542	10/2/75	女	2005/10/20	本科	194465.63	11
山东	山东日照	833723462	2/15/70	女	2015/8/7	中专	194447.5	11
四川	四川绵阳	23643662	3/3/78	女	2004/11/23	大专	193894.11	19
四川	四川青羊	89620412	11/2/72	女	2006/6/30	大专	193520.22	12
北京	北京良乡	690243712	3/8/64	女	2014/12/11	高中	193216.84	6
山东	山东烟台	482136702	11/26/78	男	2012/11/2	初中	193142.87	4
浙江	浙江杭州	645606962	2/24/88	男	2014/7/7	大专	192903	4
山东	山东烟台	612010492	8/13/73	女	2014/3/10	中专	192877.01	34
浙江	浙江湖州	422951352	5/19/71	女	2011/5/3	高中	192505.59	8
山东	山东烟台	733196292	12/20/75	女	2015/4/1	本科	192253	9
辽宁	辽宁营口	5012937352	3/6/76	女	2015/11/8	大专	192243.2	3
吉林	吉林长春	610843422	4/12/61	女	2014/3/3	大专	191762.41	2
山东	山东东营	237638642	10/6/77	女	2008/7/1	中专	191700.71	7
四川	四川高新	5036621692	3/6/83	女	2015/11/30	本科	191347	5
四川	四川高新	299728552	9/13/71	男	2009/5/15	本科	190760.51	10
山东	山东烟台	24424132	9/22/62	女	2004/11/29	本科	190664.41	37
黑龙江	黑龙江齐齐哈尔	73058672	6/23/68	女	2006/1/4	大专	190601.81	13
浙江	浙江杭州	4766902	12/5/64	女	2003/6/20	本科	189699.41	10
山东	山东淄博	782305922	10/2/77	女	2015/7/6	高中	189673	11
苏州	苏州新区	522595612	10/5/80	男	2013/5/24	中专	189662.09	0
四川	四川青羊	8298542	7/11/72	女	2003/10/9	大专	189388.37	8
北京	北京海淀	124798612	12/14/83	女	2007/4/1	本科	189182.07	13
重庆	重庆永川	5154737782	6/2/66	女	2016/5/31	大专	188680	1
云南	云南昆明	485776922	3/3/80	女	2012/11/26	大专	188654.24	9
黑龙江	黑龙江哈尔滨	57414042	12/18/65	女	2005/9/5	本科	188538.25	31
江西	江西南昌	18892062	10/29/76	女	2004/9/16	高中	188509.34	26
河南	河南郑州	672055862	4/30/86	男	2014/9/24	本科	188159	21
四川	四川高新	478702762	3/20/86	女	2012/9/24	大专	188036.5	12
湖北	湖北宜昌	561103912	4/17/85	女	2013/6/28	中专	187801.35	0
四川	四川青羊	707804482	2/16/74	女	2015/2/2	大专	187759.04	0
辽宁	辽宁沈阳	5471862	11/11/64	男	2003/7/1	大专	187727.4	0
山东	山东滨州	273901292	2/23/76	女	2009/1/1	中专	187529.69	2
河南	河南洛阳	197601232	9/7/70	女	2008/1/2	大专	186825.84	13
四川	四川高新	34336552	12/7/81	女	2005/3/18	大专	186810.96	0
新疆	新疆库尔勒	427336172	8/26/81	男	2011/6/20	大专	186544.19	2
四川	四川高新	117964232	5/30/81	女	2007/1/25	大专	186456.2	11
浙江	浙江嘉兴	498604512	6/12/89	女	2013/1/6	中专	186384.03	11
山西	山西临汾	5094428222	8/31/87	女	2016/3/15	大专	186000	0
深圳	深圳福田第二	275539682	8/15/63	女	2009/1/4	大专	185215.88	9
青岛	青岛开发区	527128652	8/2/84	女	2013/6/4	大专	185147.4	8
四川	四川成都	76051022	12/3/70	女	2006/2/24	高中	184946.06	7
深圳	深圳福田	505000372	5/25/70	女	2013/2/28	大专	184889.36	5
四川	四川青羊	275266132	6/2/74	女	2009/1/3	本科	184766.69	6
广东	广东中山	430894742	6/9/74	女	2011/7/19	大专	184725.5	4
四川	四川高新	604197872	10/28/79	女	2014/1/3	本科	184689.45	5
湖北	湖北武汉	70093682	7/3/72	女	2005/12/20	本科	184366.54	29
苏州	苏州张家港	627273962	12/20/82	女	2014/5/8	大专	184160	6
大连	大连银洲	622464302	4/23/75	女	2014/4/8	大专	183761.42	14
河南	河南平顶山	777172462	8/24/73	女	2015/6/29	高中	183742.78	5
山东	山东济宁	43107862	8/28/69	女	2005/5/16	大专	182633.43	6
山西	山西太原	209789732	11/2/72	女	2008/3/24	本科	182609.15	9
深圳	深圳罗湖	400030322	4/11/57	女	2010/10/13	本科	182600.19	2
湖南	湖南郴州	338866032	9/25/75	男	2009/10/1	大专	182282.73	6
辽宁	辽宁辽阳	399543922	2/19/70	女	2010/10/9	本科	182040.58	17
福建	福建福州	556590692	6/23/76	女	2013/6/27	高中	181970.26	9
山东	山东淄博	143328682	9/23/67	女	2007/6/23	大专	181329.39	10
山东	山东日照	63723782	8/19/74	女	2005/10/31	大专	181182.06	7
佛山	佛山顺德龙江	389479512	7/22/79	女	2010/8/4	高中	181114.54	15
浙江	浙江温州	204678462	3/10/78	女	2008/2/23	本科	180896.67	17
山东	山东淄博	505173852	2/11/77	女	2013/3/2	高中	180820.79	22
河南	河南郑州	5042552	10/25/68	男	2003/6/27	本科	180655.72	14
四川	四川绵阳	360031152	5/16/75	女	2010/2/2	大专	180557.33	6
河南	河南郑州	185892562	2/14/61	女	2007/12/1	大专	180551.48	9
北京	北京平谷	9425972	7/19/66	女	2003/12/18	大专	180543.1	10
辽宁	辽宁丹东	502420312	3/29/66	女	2013/2/1	中专	180422.3	4
陕西	陕西西安	659708532	12/30/77	女	2014/8/21	大专	180160.26	4
四川	四川高新	5095919502	1/26/84	女	2016/3/16	本科	180014	4
湖北	湖北武汉	136826612	2/3/74	男	2007/5/24	大专	180001.53	6
四川	四川成都	5214302082	9/16/78	女	2016/8/12	高中	179908	3
山东	山东济南	5126081032	4/26/68	女	2016/4/21	中专	179874.5	7
湖北	湖北武汉	20883282	4/25/70	男	2004/10/16	本科	179394.99	13
浙江	浙江杭州	227645272	4/24/74	女	2008/6/2	高中	179348.22	10
重庆	重庆巴南	812741732	3/17/87	女	2015/7/30	高中	179066	6
安徽	安徽淮南	51231302	9/24/73	女	2005/6/1	大专	179049.43	8
河北	河北石家庄	73290912	2/18/70	女	2006/1/6	大专	179044.32	29
深圳	深圳罗湖	284674432	4/1/64	女	2009/3/13	大专	178535.33	2
广西	广西南宁	5167368622	3/5/69	女	2016/6/8	大专	178444.6	23
山东	山东德州	479804932	1/21/81	女	2012/9/29	中专	178438.07	5
湖北	湖北襄阳	845875122	10/4/78	女	2015/8/10	大专	178183	42
山东	山东潍坊	39215582	3/8/71	男	2005/4/1	中专	178118.1	7
北京	北京海淀	824267982	2/21/69	女	2015/8/2	本科	178000	0
广东	广东江门	227872072	5/24/75	女	2008/6/2	大专	177859.45	15
福建	福建厦门	193141602	9/13/72	女	2007/12/24	高中	177725.56	13
山东	山东济南	316281082	7/5/79	女	2009/7/21	大专	177508.3	16
上海	上海陆家嘴	638960442	1/1/70		2014/6/20	本科	177470.5	1954
河北	河北唐山	329311182	3/27/74	女	2009/9/7	高中	176894.17	9
浙江	浙江杭州	19708672	11/7/67	女	2004/9/27	大专	176587.8	17
四川	四川高新	190775362	8/20/82	女	2007/12/18	大专	176063.63	11
山西	山西太原	215364512	1/10/64	女	2008/4/9	中专	175483.48	5
江苏	江苏泰州	53144642	9/5/65	女	2005/7/28	大专	175373.22	13
四川	四川绵阳	7799862	8/27/76	女	2003/9/17	本科	175136	9
湖北	湖北武汉	20825222	6/1/74	男	2004/10/14	大专	174923.19	2
河南	河南郑州	134457302	9/9/69	女	2007/5/9	大专	174740.17	6
河南	河南安阳	207515052	2/27/70	女	2008/3/14	高中	174542.24	9
大连	大连银洲	633369612	9/17/70	女	2014/6/5	本科	174473.01	8
江苏	江苏无锡	866320902	2/9/85	女	2015/9/2	大专	174158	2
佛山	佛山禅城	446184872	4/29/72	女	2011/12/6	高中	174128.94	11
江西	江西南昌	605982812	6/15/66	女	2014/1/17	高中	174050	3
浙江	浙江杭州	611520052	1/16/73	女	2014/3/6	大专	173745.91	6
新疆	新疆乌鲁木齐	238822462	4/24/58	女	2008/6/1	高中	173521.7	1
江苏	江苏泰州	151291892	4/4/70	女	2007/7/20	高中	173397.83	7
河南	河南安阳	214626752	5/18/66	女	2008/4/3	高中	173051	11
云南	云南昆明	372409882	2/16/78	男	2010/5/1	中专	172835.75	3
山东	山东枣庄	5160153442	8/19/74	女	2016/6/3	中专	172488.73	2
湖北	湖北武汉	591237142	10/1/79	女	2013/12/13	中专	171972.56	12
甘肃	甘肃酒泉	516905412	2/5/73	女	2013/4/24	高中	171845.87	6
浙江	浙江温州	133466782	8/30/87	女	2007/5/1	高中	171635.95	16
四川	四川青羊	1604842	8/29/71	女	2002/8/7	大专	171632.96	5
四川	四川青羊	623414482	6/2/75	女	2014/4/15	本科	171574.74	18
北京	北京海淀	425099032	10/23/89	女	2011/6/1	高中	171406.9	15
大连	大连海川	193584592	7/13/75	女	2007/12/25	大专	170982.33	21
佛山	佛山顺德龙江	290026182	11/27/80	女	2009/4/1	大专	170950.95	6
四川	四川高新	464585842	1/6/70	女	2012/5/14	高中	170664.79	5
佛山	佛山顺德龙江	562366312	4/15/71	女	2013/6/29	中专	170501.99	9
北京	北京海淀	130002142	4/27/81	女	2007/4/13	本科	170360.74	17
天津	天津蓟州	5064781602	5/25/88	女	2016/2/3	大专	170000	0
深圳	深圳福田第二	468023552	10/25/74	女	2012/6/14	本科	170000	0
四川	四川青羊	3130882	1/9/74	女	2003/3/17	大专	169903.43	7
四川	四川青羊	554641482	1/7/77	女	2013/6/27	本科	169544.42	6
上海	上海黄浦	1072392	6/4/69	男	2002/4/28	大专	169532.27	1
四川	四川青羊	32293412	2/22/64	女	2005/2/28	大专	169475.8	7
江苏	江苏南京	5063683792	8/1/78	女	2016/1/29	高中	169384.8	2
四川	四川内江	9383962	4/15/71	女	2003/12/15	大专	169251.23	11
辽宁	辽宁盘锦	5067462222	11/13/78	男	2016/2/23	中专	169176	3
黑龙江	黑龙江哈尔滨	30221242	2/5/74	女	2005/1/28	本科	168872.77	12
黑龙江	黑龙江牡丹江	5046024322	6/11/71	女	2015/12/14	本科	168784	15
河南	河南郑州	319756332	7/7/66	女	2009/7/29	大专	168695.49	12
四川	四川青羊	97922	2/14/68	女	2001/12/31	大专	168613.16	9
浙江	浙江杭州	465805532	10/3/73	女	2012/5/25	中专	168594.67	27
江苏	江苏徐州	613600732	1/20/71	男	2014/3/14	大专	168510.17	18
江苏	江苏无锡	38938262	8/21/69	女	2005/4/18	高中	168377.81	8
四川	四川高新	5199463852	5/29/84	女	2016/8/1	本科	167799	3
福建	福建厦门	116492482	4/5/71	女	2007/1/21	本科	167784.71	8
北京	北京海淀	3187272	1/24/73	男	2003/4/1	本科	167767	9
陕西	陕西西安	157108822	12/7/73	女	2007/8/1	大专	167512.52	10
湖北	湖北十堰	487182102	5/12/74	女	2012/12/3	高中	167305.9	23
上海	上海陆家嘴	8407822	10/16/78	女	2003/10/16	大专	167304	21
安徽	安徽合肥	5126611382	6/1/80	女	2016/4/22	中专	167300	13
云南	云南昆明	658174952	11/24/82	男	2014/8/19	大专	167287.37	1
四川	四川高新	5005569922	11/21/75	女	2015/10/26	大专	167261	3
黑龙江	黑龙江哈尔滨	25970962	2/26/72	女	2004/12/14	大专	167250.92	21
黑龙江	黑龙江哈尔滨	846128852	8/14/74	女	2015/8/10	大专	167207	3
江西	江西新余	122029022	12/18/72	女	2007/3/7	中专	167163.05	9
大连	大连明锐	5350302	2/22/75	女	2003/7/1	本科	167047.49	6
北京	北京东城	704333972	9/26/77	女	2015/1/15	硕士	166476	4
山东	山东济宁	43140662	2/18/68	女	2005/5/16	中专	166342.4	15
山东	山东日照	184337492	4/25/71	女	2007/11/25	高中	166090.54	10
浙江	浙江嘉兴	668743212	12/19/77	女	2014/9/12	大专	166088.37	14
广东	广东珠海	396866862	9/18/72	女	2010/9/21	大专	165754.33	4
浙江	浙江杭州	454378652	1/6/81	女	2012/3/5	本科	165684.34	2
广东	广东汕头	169794332	2/24/73	女	2007/10/18	大专	165628.34	11
四川	四川高新	40925712	3/30/82	女	2005/4/29	本科	165520.83	21
北京	北京良乡	194234562	6/22/72	女	2007/12/25	高中	165350.41	10
山东	山东济南	443833982	3/31/77	女	2011/11/14	本科	165321.59	9
浙江	浙江嘉兴	5145995042	12/25/70	女	2016/5/25	高中	165196	17
云南	云南昆明	269826592	3/13/83	女	2008/12/1	本科	164991	3
四川	四川高新	2554052	11/29/65	女	2003/1/21	大专	164911.15	24
河南	河南郑州	300307482	5/26/74	女	2009/5/18	中专	164658.57	1
山东	山东威海	696698332	7/2/87	女	2014/12/23	大专	164640	17
湖北	湖北荆州	664052042	10/5/76	女	2014/8/29	大专	164419	4
青岛	青岛一支	465397712	11/20/81	女	2012/5/22	本科	164112.63	8
北京	北京海淀	365640402	10/24/85	男	2010/4/1	本科	163403.4	9
四川	四川高新	738613912	6/26/80	女	2015/4/9	大专	163200	2
广东	广东茂名	412636552	7/11/55	女	2011/1/1	大专	163168.13	5
广东	广东广州	575322302	9/18/86	女	2013/8/30	大专	163144.4	10
山东	山东烟台	127071622	6/14/71	女	2007/3/25	高中	163140.64	16
山东	山东烟台	172711172	7/27/75	女	2007/10/25	大专	163135.03	12
北京	北京东城	46504262	2/6/75	女	2005/6/7	大专	162841.22	13
山东	山东威海	707217042	8/1/83	女	2015/1/29	高中	162816.1	17
四川	四川高新	708763872	5/26/75	女	2015/2/10	大专	162799.5	16
辽宁	辽宁铁岭	449945682	8/16/75	女	2012/1/1	高中	162747.13	16
湖南	湖南长沙	178809192	9/12/75	女	2007/11/9	大专	162706.2	7
四川	四川眉山	151069562	10/30/73	女	2007/7/19	高中	162616	2
山东	山东德州	286107242	5/2/77	女	2009/3/20	中专	162615.45	11
黑龙江	黑龙江哈尔滨	363753022	11/12/62	女	2010/3/15	本科	162456	10
广西	广西玉林	612526892	1/24/84	女	2014/3/11	本科	162435.5	12
北京	北京海淀	452905232	9/22/86	男	2012/3/1	本科	162372	2
浙江	浙江杭州	5192817032	5/11/86	女	2016/7/26	大专	162346.8	13
四川	四川青羊	69005192	9/7/73	女	2005/12/10	大专	162304.97	3
四川	四川高新	570028612	7/19/64	男	2013/7/22	本科	162285.88	3
江苏	江苏扬州	26936222	11/22/72	女	2004/12/24	中专	162277.32	6
四川	四川高新	432554352	12/23/74	女	2011/8/2	本科	162073.71	20
四川	四川高新	5033972782	4/14/72	女	2015/11/27	本科	161935	8
大连	大连明锐	5152821322	8/9/76	男	2016/5/30	大专	161931	4
浙江	浙江杭州	412401242	3/4/69	女	2010/12/31	大专	161817.04	5
河北	河北唐山	461938512	5/2/79	女	2012/4/12	高中	161552.65	10
黑龙江	黑龙江牡丹江	45061542	5/1/71	女	2005/6/1	大专	161247.36	10
四川	四川青羊	76704062	10/17/81	男	2006/2/28	大专	160879	2
山东	山东济南	15855332	3/29/73	女	2004/7/1	大专	160428.83	3
湖南	湖南长沙	733915902	12/10/90	男	2015/4/2	本科	160340.7	0
山西	山西太原	196026342	4/22/72	女	2008/1/1	高中	160313.3	14
北京	北京海淀	492049822	6/10/88	女	2013/1/1	本科	160177.74	9
广东	广东广州	258104942	11/15/84	女	2008/10/21	大专	159980.41	16
陕西	陕西咸阳	456040632	4/30/70	女	2012/3/12	高中	159925.5	1
辽宁	辽宁阜新	175178182	12/4/65	女	2007/11/3	高中	159718.73	3
浙江	浙江杭州	5360212	3/11/69	女	2003/7/3	高中	159708.21	10
吉林	吉林四平	572130512	4/14/72	男	2013/8/2	大专	159687	6
辽宁	辽宁营口	675897622	12/14/73	男	2014/10/7	本科	159247.13	10
广东	广东广州	731123422	12/16/80	女	2015/3/31	本科	159174.5	39
四川	四川青羊	250612	8/10/75	女	2001/12/31	本科	159112.12	11
山东	山东济南	143691422	3/21/79	女	2007/6/25	中专	158754.47	16
山东	山东威海	855916642	5/17/69	女	2015/8/10	中专	158747.5	6
广东	广东惠州	18389612	3/6/63	男	2004/9/2	本科	158309.9	6
湖北	湖北武汉	5072089212	9/14/76	女	2016/3/2	硕士	158303	3
黑龙江	黑龙江牡丹江	5220151092	4/15/64	女	2016/8/19	大专	158211	2
湖北	湖北武汉	20877142	3/15/72	男	2004/10/16	大专	158195.33	11
江西	江西南昌	455535812	12/30/74	女	2012/3/9	高中	158035	11
四川	四川青羊	882399042	6/12/80	女	2015/10/9	本科	157465	8
深圳	深圳福田	106574892	11/12/69	女	2006/12/7	大专	157291.21	10
河南	河南洛阳	57468332	6/8/67	女	2005/9/5	大专	157216.98	5
浙江	浙江湖州	249478782	1/29/70	女	2008/9/2	大专	156926.74	8
辽宁	辽宁阜新	714669292	11/28/71	女	2015/3/20	大专	156832	3
山东	山东威海	199895732	9/21/67	女	2008/1/13	大专	156699.05	7
黑龙江	黑龙江哈尔滨	429856772	9/6/71	女	2011/6/30	本科	156377.2	8
浙江	浙江嘉兴	576959022	9/20/79	女	2013/9/6	大专	156279.35	12
山西	山西朔州	583789632	9/5/63	女	2013/11/12	大专	156224.5	5
吉林	吉林吉林	5201252432	12/28/71	男	2016/8/2	大专	155762.8	2
北京	北京东城	120998862	11/23/76	女	2007/3/1	大专	155596.08	15
山东	山东济南	235984202	12/18/79	女	2008/6/25	本科	155537.21	12
云南	云南昆明	343298302	7/10/75	女	2009/11/16	大专	155521.82	0
山东	山东威海	514048032	4/22/72	女	2013/4/3	中专	155406.14	10
山东	山东威海	429915532	4/28/81	女	2011/6/30	高中	155158.19	3
江苏	江苏泰州	375723932	12/17/68	男	2010/5/18	高中	154971.36	3
佛山	佛山顺德龙江	281572802	3/13/79	女	2009/3/1	中专	154889.69	13
辽宁	辽宁营口	9995692	11/15/67	女	2004/1/30	本科	154882.47	10
四川	四川高新	5101017922	1/4/72	男	2016/3/21	本科	154832.5	13
苏州	苏州张家港	457800312	5/10/63	女	2012/3/21	初中	154819.73	7
北京	北京良乡	466952782	8/26/76	女	2012/6/4	大专	154800.78	27
大连	大连明锐	608956042	7/10/67	女	2014/2/8	本科	154785	4
陕西	陕西西安	5019027512	10/23/68	女	2015/11/15	本科	154703	3
黑龙江	黑龙江七台河	847957202	12/24/77	女	2015/8/10	高中	154590	1
深圳	深圳南山	523794782	5/7/69	女	2013/5/27	大专	154554.55	0
湖北	湖北襄阳	340669392	9/5/82	男	2009/11/5	大专	154500.91	13
福建	福建福州	362702072	12/31/79	女	2010/3/4	中专	154444.52	16
山东	山东烟台	772574522	9/5/80	女	2015/6/25	大专	154252	3
佛山	佛山顺德龙江	115370492	12/1/70	女	2007/1/15	高中	154209.64	2
陕西	陕西宝鸡	717631212	3/1/71	女	2015/3/23	高中	154155.2	8
黑龙江	黑龙江齐齐哈尔	57456052	4/4/68	女	2005/9/5	大专	153903.46	8
四川	四川青羊	496122152	10/21/70	男	2012/12/29	本科	153839.67	10
山西	山西忻州	591152422	11/21/66	女	2013/12/13	大专	153579.82	9
北京	北京海淀	523528072	3/20/89	女	2013/5/31	本科	153567.5	10
山东	山东威海	127250272	10/25/68	女	2007/3/25	大专	153489.14	14
浙江	浙江台州	77977342	10/19/73	女	2006/3/1	高中	153452.46	21
深圳	深圳罗湖	5785201502	4/23/74	女	2018/6/26	本科	153440.69	0
山东	山东烟台	438457672	8/16/77	女	2011/9/20	中专	153217.1	36
黑龙江	黑龙江佳木斯	5176421332	8/20/67	男	2016/6/20	本科	153205	1
四川	四川青羊	61608902	7/17/72	女	2005/10/11	高中	153066.6	4
苏州	苏州张家港	261870222	10/5/70	女	2008/11/7	大专	152957.93	7
湖北	湖北武汉	230505722	7/8/76	女	2008/6/11	大专	152658.1	14
湖北	湖北襄阳	570100352	7/26/86	男	2013/7/22	中专	152549	11
浙江	浙江杭州	216639192	2/16/74	女	2008/4/16	中专	152548.68	8
湖北	湖北荆门	381426142	2/8/68	女	2010/6/1	大专	152126.01	7
陕西	陕西西安	398132332	3/15/75	女	2010/10/1	本科	152049.43	5
四川	四川高新	13414282	3/3/75	女	2004/5/9	高中	152024.86	11
湖北	湖北武汉	5044868492	6/12/79	女	2015/12/11	本科	151900.2	16
宁波	宁波象山	365205922	11/19/69	女	2010/3/22	高中	151779.52	7
河南	河南南阳	92585402	3/30/59	女	2006/8/1	高中	151752.25	11
福建	福建龙岩	319383682	9/12/69	女	2009/7/28	高中	151742.9	1
河南	河南郑州	139919032	8/8/74	女	2007/6/14	大专	151654.35	8
四川	四川绵阳	7768462	4/2/70	女	2003/9/17	本科	151582.82	7
山东	山东济南	5200682	4/20/76	女	2003/7/1	大专	151498.33	12
青岛	青岛一支	4665162	9/17/61	女	2003/6/18	大专	151447.35	12
青岛	青岛即墨	5123801082	1/10/77	男	2016/4/18	大专	151350	1
辽宁	辽宁盘锦	9736792	3/3/78	女	2004/1/9	本科	151253.88	13
浙江	浙江杭州	848360552	9/6/74	女	2015/8/10	高中	151109.69	7
青岛	青岛一支	4639202	1/27/65	女	2003/6/18	大专	151004.53	15
安徽	安徽淮南	51108342	6/15/57	女	2005/6/1	高中	150924.05	8
云南	云南昆明	282270922	5/18/77	男	2009/3/3	大专	150876.03	8
福建	福建龙岩	714879342	4/5/78	女	2015/3/20	高中	150791.2	28
河北	河北沧州	176395772	7/9/55	女	2007/11/5	大专	150603.91	0
苏州	苏州张家港	731636752	4/14/78	女	2015/3/31	高中	150511	5
黑龙江	黑龙江哈尔滨	317233632	5/2/71	女	2009/7/24	大专	150484.8	11
北京	北京平谷	133090092	11/18/74	男	2007/5/1	中专	150270.78	18
四川	四川青羊	336343332	8/17/57	女	2009/10/12	高中	150213.8	15
辽宁	辽宁葫芦岛	808196412	3/9/87	男	2015/7/28	大专	150000	0
北京	北京海淀	361522022	8/30/88	女	2010/3/1	本科	149994.77	10
山西	山西吕梁	456839522	10/13/84	女	2012/3/15	初中	149987.97	0
浙江	浙江杭州	18468192	4/30/81	女	2004/9/6	大专	149963.83	10
广西	广西南宁	494119622	7/10/84	女	2012/12/25	大专	149902.93	7
深圳	深圳龙岗	212723322	5/20/75	女	2008/3/25	高中	149518.51	0
山东	山东济宁	569117372	11/2/70	女	2013/7/9	中专	149032.01	7
北京	北京海淀	430443242	9/11/89	女	2011/7/12	高中	148965.5	18
河南	河南郑州	135311182	5/9/81	男	2007/5/17	大专	148768.15	4
四川	四川青羊	208716592	10/19/86	女	2008/3/20	大专	148697.77	4
湖北	湖北襄阳	77773432	6/15/77	女	2006/3/11	高中	148574.62	29
山东	山东烟台	171747312	4/9/69	女	2007/10/25	大专	148380.15	7
江西	江西抚州	298012282	11/10/72	女	2009/5/7	高中	147884.63	3
北京	北京海淀	5147908802	11/19/89	女	2016/5/26	大专	147867.3	2
北京	北京东城	583250342	8/10/83	女	2013/11/6	本科	147836.16	13
山东	山东烟台	142868942	1/22/71	女	2007/6/22	高中	147750.19	5
四川	四川高新	71962	3/21/76	女	2001/12/30	大专	147708.21	16
北京	北京东城	445444742	1/14/90	女	2011/12/1	大专	147573.81	5
山东	山东淄博	295287072	2/21/77	女	2009/4/25	大专	147551.38	9
山东	山东东营	270555142	3/29/66	女	2008/12/16	大专	147398.64	5
浙江	浙江金华	650098692	7/19/73	女	2014/8/1	高中	147357.35	17
福建	福建宁德	477414132	9/30/85	女	2012/9/6	本科	147309.04	10
山东	山东烟台	435444532	6/10/73	女	2011/8/25	中专	147203.45	9
江苏	江苏盐城	417956202	4/8/77	男	2011/3/16	初中	146980.22	12
江苏	江苏南京	626031112	3/31/77	女	2014/4/29	本科	146942.98	16
湖南	湖南常德	585798302	4/11/65	男	2013/11/25	大专	146908.34	12
北京	北京海淀	370252022	11/12/86	女	2010/5/1	本科	146850.73	6
四川	四川青羊	7667692	6/11/78	女	2003/9/17	大专	146692.02	6
陕西	陕西西安	354324202	5/16/72	女	2010/1/1	大专	146563.36	3
苏州	苏州昆山	5160332972	2/1/74	男	2016/6/3	中专	146394	2
浙江	浙江杭州	41048672	10/3/71	女	2005/4/29	高中	146354.86	15
山西	山西忻州	656974112	7/21/64	女	2014/8/15	高中	146334.81	7
黑龙江	黑龙江哈尔滨	435587312	3/13/77	女	2011/8/25	大专	146247	3
广东	广东中山	162817172	2/26/75	女	2007/9/12	本科	146142.54	6
山东	山东济南	593156622	1/29/81	女	2013/12/19	本科	146112.31	12
北京	北京东城	2619922	4/9/72	女	2003/2/1	大专	146018.45	12
山西	山西太原	191130562	11/21/71	女	2007/12/19	大专	145492.66	3
广东	广东江门	324357342	4/18/83	女	2009/8/17	大专	145481.06	21
山东	山东威海	31855862	1/28/67	女	2005/2/23	本科	145469.11	7
山东	山东临沂	221480952	3/23/71	女	2008/5/5	大专	145455.6	10
四川	四川绵阳	277876892	1/1/83	女	2009/1/20	大专	145422.99	12
黑龙江	黑龙江哈尔滨	117159202	7/2/80	女	2007/1/24	本科	145022.88	15
青岛	青岛一支	720521392	4/9/79	男	2015/3/24	本科	144950	9
山东	山东枣庄	196519852	4/2/76	女	2008/1/1	中专	144824.02	7
辽宁	辽宁铁岭	5077234782	11/12/74	女	2016/3/7	大专	144721.6	1
四川	四川青羊	108039802	9/5/77	女	2006/12/18	大专	144632.14	5
宁波	宁波城区	5183709972	7/28/79	女	2016/6/26	大专	144202.5	25
天津	天津市区一支	619230502	3/7/86	女	2014/3/27	本科	144086.05	14
陕西	陕西安康	486139662	2/19/72	女	2012/12/1	大专	144082.14	9
大连	大连开发区	134110612	3/15/76	女	2007/5/8	初中	143941.1	9
浙江	浙江嘉兴	5198625262	12/31/69	女	2016/8/1	高中	143936	15
四川	四川绵阳	181627632	8/30/75	女	2007/11/21	中专	143802.84	8
河北	河北保定	379605332	3/16/65	女	2010/6/8	高中	143730.32	15
四川	四川青羊	144197912	7/3/78	女	2007/6/25	大专	143687.05	1
广东	广东中山	600144392	2/20/75	女	2013/12/30	大专	143675.01	11
天津	天津市区二支	312391172	9/9/71	女	2009/7/1	大专	143540.13	4
河南	河南周口	214669462	9/4/70	女	2008/4/4	高中	143481.58	6
深圳	深圳罗湖	556976322	10/21/77	女	2013/6/27	本科	143394.42	19
青岛	青岛一支	191408582	6/10/73	男	2007/12/19	高中	143150.31	15
黑龙江	黑龙江哈尔滨	5157795532	9/30/74	女	2016/6/1	高中	143115.7	4
四川	四川内江	87053332	9/5/71	女	2006/6/13	中专	143038.56	24
苏州	苏州常熟	5165157562	9/10/94	女	2016/6/7	大专	142917	9
深圳	深圳南山	399999892	3/13/76	女	2010/10/12	本科	142681.65	1
广西	广西南宁	230399512	10/4/73	女	2008/6/11	高中	142677.54	7
广西	广西南宁	227187902	8/20/61	女	2008/5/27	大专	142512.78	10
云南	云南玉溪	817816162	11/15/80	女	2015/7/31	大专	142483.1	10
黑龙江	黑龙江牡丹江	45034882	4/7/68	女	2005/6/1	高中	142124.86	7
山东	山东济南	747900652	5/13/78	女	2015/4/22	大专	142071.3	4
云南	云南昆明	304332632	8/20/83	女	2009/6/1	高中	141997.93	7
湖北	湖北荆州	5142796812	7/29/68	女	2016/5/19	大专	141830.5	11
北京	北京海淀	507629282	9/25/85	女	2013/3/19	大专	141807.43	13
广东	广东惠州	7197072	3/8/71	女	2003/9/5	大专	141792.03	11
广东	广东惠州	7183392	9/7/70	女	2003/8/31	高中	141673.06	1
陕西	陕西西安	244200172	4/12/80	女	2008/8/1	高中	141616.15	4
北京	北京东城	328776632	10/23/73	女	2009/9/1	本科	141591.95	4
浙江	浙江杭州	505016422	12/4/80	女	2013/2/28	硕士	141526.39	10
云南	云南昆明	583595632	2/22/74	女	2013/11/11	本科	141448.73	6
吉林	吉林吉林	240843412	7/20/74	女	2008/7/18	本科	141447.22	11
辽宁	辽宁营口	90755382	11/11/59	男	2006/7/7	高中	141393.15	4
山东	山东烟台	446548312	11/15/74	女	2011/12/9	初中	141237.47	8
青岛	青岛一支	877183232	2/3/76	男	2015/9/24	大专	141000	0
江西	江西南昌	5173171382	8/16/69	女	2016/6/16	高中	140835.5	3
河南	河南郑州	187494112	8/15/68	女	2007/12/5	大专	140772.22	21
湖北	湖北武汉	132338652	1/9/74	男	2007/4/25	中专	140757.2	5
浙江	浙江杭州	12270102	3/16/69	女	2004/4/9	本科	140699.39	7
河南	河南驻马店	12336672	8/3/62	女	2004/4/11	大专	140524.12	19
苏州	苏州张家港	66952142	1/11/63	女	2005/11/28	高中	140427.27	3
四川	四川青羊	702920982	5/28/72	女	2015/1/7	本科	140000	0
苏州	苏州新区	882704692	9/3/74	男	2015/10/10	中专	140000	0
江西	江西宜春	805145032	2/23/72	女	2015/7/27	大专	140000	0
苏州	苏州昆山	731127192	6/1/77	女	2015/3/31	高中	139916	8
福建	福建龙岩	5193718142	11/26/77	男	2016/7/27	高中	139817.5	30
四川	四川巴中	331383352	2/18/63	女	2009/9/22	高中	139602.43	7
宁波	宁波宁海	192653532	2/11/79	女	2007/12/23	初中	139559.86	11
四川	四川成都	482626172	4/13/83	女	2012/11/9	大专	139494.05	22
辽宁	辽宁沈阳	392867672	4/27/75	女	2010/8/25	高中	139408.32	11
辽宁	辽宁沈阳	433451912	7/4/80	女	2011/8/9	本科	139011.95	5
浙江	浙江金华	188412192	4/26/75	女	2007/12/10	高中	138952.4	8
陕西	陕西西安	579971462	1/26/83	女	2013/9/29	中专	138937.6	6
江西	江西赣州	658108382	11/15/75	女	2014/8/18	本科	138880.49	8
山西	山西太原	5067538382	1/16/78	女	2016/2/23	本科	138863.9	4
四川	四川青羊	272792282	10/28/79	男	2008/12/25	本科	138860.3	17
佛山	佛山禅城	508611562	8/15/84	男	2013/3/22	本科	138688	3
湖北	湖北襄阳	532456812	3/29/67	女	2013/6/10	高中	138422.58	11
江苏	江苏泰州	451562582	3/30/72	女	2012/1/17	初中	138395.61	12
吉林	吉林长春	451206682	7/31/73	女	2012/1/11	本科	138229.73	13
广西	广西南宁	149123422	12/30/74	女	2007/7/11	大专	138222.15	13
四川	四川泸州	689908332	11/19/75	女	2014/12/10	中专	138010	3
浙江	浙江金华	686858622	5/24/79	女	2014/11/24	高中	137916.8	9
江西	江西南昌	19112022	12/8/68	女	2004/9/19	高中	137874.12	7
黑龙江	黑龙江哈尔滨	300794852	4/17/68	女	2009/5/19	高中	137807	13
四川	四川高新	67222	8/20/70	男	2001/12/30	大专	137763.32	5
陕西	陕西西安	146237982	6/23/64	女	2007/6/25	高中	137687.66	4
山东	山东东营	134300842	10/18/75	女	2007/5/8	初中	137409.3	8
湖北	湖北十堰	5069710462	2/9/68	男	2016/2/29	高中	137406	6
山东	山东烟台	729135272	2/13/84	女	2015/3/27	本科	137273	12
河北	河北唐山	414015072	10/19/71	女	2011/1/13	大专	137207.46	13
福建	福建福州	653135422	2/6/78	女	2014/8/7	本科	137062.11	7
浙江	浙江金华	5108741642	8/23/65	女	2016/3/24	高中	136935	12
陕西	陕西西安	325350232	9/23/70	女	2009/8/21	中技	136827.68	5
吉林	吉林长春	153754162	5/29/78	女	2007/8/1	大专	136775.6	8
河南	河南郑州	4994402	6/13/74	女	2003/6/27	大专	136700.11	18
山东	山东烟台	773128192	10/11/81	女	2015/6/25	高中	136605	5
广西	广西玉林	284999902	7/14/71	女	2009/3/16	大专	136532.59	13
安徽	安徽滁州	169505702	2/10/71	女	2007/10/1	高中	136163	10
辽宁	辽宁辽阳	5137963082	2/15/63	女	2016/5/12	本科	136162.8	1
北京	北京海淀	488328652	11/28/87	女	2012/12/10	本科	136089.2	16
山东	山东烟台	438482932	1/1/82	女	2011/9/20	本科	136075.28	20
江西	江西抚州	613783152	4/6/62	女	2014/3/15	高中	136044.44	12
山东	山东威海	182661832	10/2/73	女	2007/11/23	初中	136001.02	18
深圳	深圳福田第二	433007252	10/10/80	男	2011/8/4	大专	135966	11
佛山	佛山禅城	5814782	11/26/71	男	2003/7/29	本科	135893.53	2
黑龙江	黑龙江哈尔滨	5028774662	5/19/73	女	2015/11/24	高中	135785	4
天津	天津市区二支	830683662	1/17/83	女	2015/8/5	本科	135743.5	12
浙江	浙江杭州	5057025202	5/7/80	女	2016/1/5	大专	135542	16
湖北	湖北十堰	853700022	9/27/86	男	2015/8/10	高中	135516	7
重庆	重庆永川	516259212	10/31/69	女	2013/4/23	大专	135496.5	4
四川	四川青羊	5171835122	2/10/81	女	2016/6/15	大专	135466	16
广东	广东中山	5096136142	7/13/81	女	2016/3/16	大专	135450	4
辽宁	辽宁锦州	741457342	7/25/82	男	2015/4/10	大专	135225.4	4
上海	上海黄浦	452252192	11/15/76	女	2012/2/9	大专	135208.5	16
河南	河南开封	872369102	2/12/84	女	2015/9/16	中专	135000	0
浙江	浙江杭州	34030202	9/9/72	女	2005/3/15	大专	134973.14	11
河南	河南郑州	342275682	12/3/72	女	2009/11/11	大专	134608.38	14
苏州	苏州姑苏	5192552342	8/28/74	女	2016/7/26	高中	134546.7	10
四川	四川高新	87158842	9/29/81	女	2006/6/14	本科	134519.1	35
四川	四川青羊	197968712	11/2/71	女	2008/1/4	大专	134220	7
黑龙江	黑龙江哈尔滨	32701232	9/24/71	男	2005/3/1	大专	134129.16	13
苏州	苏州张家港	414506212	10/6/68	女	2011/1/21	高中	134123.53	3
山东	山东烟台	92899292	10/15/68	女	2006/8/4	高中	134101.5	10
湖南	湖南长沙	685345562	10/28/77	男	2014/11/20	大专	134061.8	39
青岛	青岛一支	26287922	11/24/55	女	2004/12/17	本科	134047.38	7
湖北	湖北武汉	101238222	5/2/77	女	2006/10/20	高中	134008.3	30
深圳	深圳宝安	633335142	9/14/73	男	2014/6/5	硕士	133861.56	9
青岛	青岛一支	240004882	9/10/71	女	2008/7/14	大专	133804.42	12
云南	云南昆明	566755872	5/17/79	女	2013/6/30	大专	133761.36	6
黑龙江	黑龙江牡丹江	468002062	2/10/63	男	2012/6/14	大专	133693	7
青岛	青岛一支	5050606932	3/25/77	女	2015/12/22	本科	133623.31	6
佛山	佛山顺德龙江	193004962	8/11/66	女	2007/12/24	高中	133616.4	14
四川	四川高新	756013482	7/20/91	男	2015/5/22	本科	133597	13
云南	云南昆明	269327912	12/4/83	女	2008/12/10	高中	133492.69	5
河北	河北邢台	802569712	12/19/85	男	2015/7/25	大专	133319.6	3
山西	山西长治	5078663472	5/15/75	女	2016/3/7	高中	133159	2
北京	北京东城	77360172	4/9/72	女	2006/3/6	本科	133137.4	3
山东	山东日照	593677492	1/19/66	女	2013/12/20	中专	132920	8
安徽	安徽合肥	37062462	9/23/61	女	2005/3/1	大专	132895.08	10
湖北	湖北武汉	838941642	8/11/66	女	2015/8/8	本科	132872	26
重庆	重庆渝中	163551162	7/14/84	男	2007/9/17	本科	132841.49	18
四川	四川青羊	615906542	1/5/77	女	2014/3/20	大专	132670	8
吉林	吉林白山	531229312	3/17/74	女	2013/6/9	本科	132513	12
深圳	深圳福田第二	492552272	2/29/72	女	2012/12/21	本科	132212.65	10
江西	江西抚州	807611342	8/12/67	女	2015/7/28	高中	132020	3
北京	北京良乡	758812802	11/12/71	女	2015/5/29	大专	131933	9
四川	四川德阳	740746242	3/8/76	男	2015/4/10	高中	131918	8
山东	山东烟台	13165292	5/14/70	女	2004/5/1	本科	131728.6	14
四川	四川高新	260541252	8/28/70	女	2008/11/3	高中	131618.43	13
湖南	湖南常德	527683022	8/28/77	女	2013/6/5	大专	131494.53	2
安徽	安徽合肥	503742442	8/19/72	女	2013/2/20	大专	131403.75	0
江苏	江苏南京	5027403022	5/7/78	女	2015/11/23	大专	131399	4
四川	四川绵阳	637289252	6/25/67	女	2014/6/16	本科	131301.65	4
辽宁	辽宁营口	734978162	7/9/54	男	2015/4/3	大专	131287.83	5
重庆	重庆渝中	270849212	4/25/64	女	2008/12/18	中专	131244.18	14
四川	四川青羊	663038632	12/31/80	女	2014/8/27	大专	130862.96	4
浙江	浙江嘉兴	292061782	8/27/70	女	2009/4/10	高中	130748.1	16
湖北	湖北十堰	361695502	11/6/64	女	2010/2/24	高中	130676	3
安徽	安徽亳州	363068852	2/10/70	女	2010/3/1	大专	130661.09	31
黑龙江	黑龙江哈尔滨	429921672	8/2/77	女	2011/7/1	大专	130577.3	4
甘肃	甘肃酒泉	5086736212	7/30/69	女	2016/3/10	高中	130419	6
河南	河南郑州	5185456872	5/19/80	女	2016/6/29	大专	130343.5	9
山东	山东潍坊	831205232	7/19/82	女	2015/8/6	本科	130274.4	0
青岛	青岛一支	252241262	6/18/85	女	2008/9/19	本科	130257.5	31
四川	四川高新	73362	5/23/76	男	2001/12/30	大专	130125.14	11
山东	山东德州	858349182	10/8/74	男	2015/8/12	高中	130119.8	43
辽宁	辽宁沈阳	746569312	2/8/78	女	2015/4/17	大专	130000	0
山东	山东东营	417197922	11/6/69	女	2011/3/8	小学	129955.68	3
四川	四川成都	602178322	3/14/73	女	2013/12/31	高中	129840	3
四川	四川绵阳	294856362	3/8/85	女	2009/4/24	大专	129759.58	5
广西	广西梧州	350670302	12/24/71	女	2009/12/20	大专	129639.41	11
四川	四川乐山	5159255032	1/12/88	女	2016/6/2	高中	129611	16
青岛	青岛一支	5037056842	2/10/81	男	2015/11/30	本科	129430	7
山东	山东烟台	366840272	12/22/74	女	2010/3/25	初中	129323.9	4
深圳	深圳南山	5215022832	4/19/68	女	2016/8/13	大专	129299	5
湖北	湖北十堰	699989492	11/18/68	男	2014/12/30	高中	129191	21
山东	山东威海	200506762	4/1/78	女	2008/1/15	大专	129061.43	12
宁波	宁波城区	829307242	7/27/79	女	2015/8/5	本科	128939	9
山东	山东淄博	198284972	3/15/70	男	2008/1/5	大专	128918.8	14
湖北	湖北十堰	427018512	4/13/56	女	2011/6/15	高中	128810.5	7
山西	山西太原	195927942	10/4/72	女	2008/1/1	中专	128573.57	7
四川	四川高新	1595632	4/28/80	女	2002/8/6	本科	128559.44	4
宁波	宁波城区	375864342	4/6/78	女	2010/5/19	高中	128508	7
深圳	深圳福田第二	49341822	12/1/75	女	2005/6/29	本科	128471.44	6
云南	云南昆明	148598782	1/3/78	女	2007/7/1	大专	128355.52	10
新疆	新疆乌鲁木齐	621087182	9/27/78	女	2014/3/31	高中	128257.4	14
河南	河南南阳	370225362	12/18/69	女	2010/4/16	本科	128168.02	10
山东	山东滨州	407501692	10/28/75	女	2010/12/3	大专	128154.05	9
河北	河北石家庄	5144121232	7/16/79	女	2016/5/23	本科	128056.2	1
陕西	陕西西安	544425082	6/25/84	女	2013/6/21	高中	127970.05	1
湖北	湖北宜昌	810141852	11/13/75	女	2015/7/29	高中	127923	7
山东	山东淄博	12915602	3/1/74	女	2004/4/26	大专	127639.25	22
山东	山东济南	5199090322	5/26/84	女	2016/8/1	大专	127609.5	5
浙江	浙江杭州	4748482	6/15/71	女	2003/6/20	本科	127606.6	7
四川	四川高新	271617672	10/24/74	女	2008/12/23	大专	127536.68	9
四川	四川高新	5217597382	11/4/76	男	2016/8/17	硕士	127488.5	16
重庆	重庆永川	759031092	11/9/77	女	2015/5/29	高中	127486	8
北京	北京海淀	507236812	12/10/90	女	2013/3/18	本科	127485.11	1
青岛	青岛开发区	397596112	5/14/80	女	2010/9/25	大专	127442.96	5
浙江	浙江金华	425466512	4/19/80	女	2011/5/30	高中	127441.17	13
湖南	湖南益阳	843975732	1/9/93	女	2015/8/10	大专	127282.24	1
四川	四川绵阳	168378272	5/9/75	女	2007/10/8	高中	127233.59	14
辽宁	辽宁营口	698204552	7/19/67	女	2014/12/25	大专	127209.84	1
黑龙江	黑龙江佳木斯	216967732	10/1/77	女	2008/4/18	大专	127183.64	6
广西	广西玉林	606489032	5/17/85	女	2014/1/20	大专	127070.4	12
福建	福建泉州	229916882	8/12/66	女	2008/6/1	高中	127016.8	5
山东	山东临沂	5154395062	12/13/79	男	2016/5/31	高中	126964	30
辽宁	辽宁铁岭	5009232312	2/9/82	女	2015/11/4	高中	126900	1
江西	江西南昌	336650382	5/12/63	女	2009/10/14	本科	126881.31	11
青岛	青岛一支	9461842	5/3/70	女	2003/12/22	大专	126794.29	3
深圳	深圳龙岗	537312952	1/9/94	女	2013/6/17	高中	126739.87	0
吉林	吉林四平	663159222	6/19/72	女	2014/8/27	大专	126722.05	8
北京	北京海淀	422520642	1/8/84	男	2011/5/1	大专	126650.86	6
湖北	湖北襄阳	58097782	2/2/68	女	2005/9/12	大专	126569.42	14
重庆	重庆渝中	5159506152	2/12/95	男	2016/6/2	大专	126453	6
湖北	湖北荆州	5176370582	9/30/79	女	2016/6/20	本科	126435	20
山东	山东济南	756989622	3/2/88	女	2015/5/25	中专	126408	12
浙江	浙江金华	380200312	8/11/80	男	2010/6/12	大专	126323.22	13
辽宁	辽宁铁岭	5076841312	4/22/79	女	2016/3/6	本科	126210	2
辽宁	辽宁沈阳	398146982	11/24/68	女	2010/9/26	本科	126198.49	10
四川	四川青羊	661322362	7/16/77	女	2014/8/25	高中	126106.2	9
山东	山东济南	617663152	1/3/70	女	2014/3/25	本科	125931.8	6
北京	北京海淀	492426242	12/4/80	男	2012/12/21	本科	125921.27	0
辽宁	辽宁沈阳	370805962	5/21/78	女	2010/4/21	大专	125911.16	7
福建	福建泉州	421138082	11/2/71	男	2011/4/12	高中	125898.3	4
深圳	深圳福田	495732752	9/15/77	女	2012/12/28	本科	125808.32	2
黑龙江	黑龙江哈尔滨	444201192	5/12/68	女	2011/11/18	大专	125787.6	8
江苏	江苏常州	5206244782	7/17/81	女	2016/8/5	高中	125723.4	7
四川	四川青羊	579690372	9/21/76	女	2013/9/26	本科	125669.32	4
浙江	浙江温州	5049657772	9/17/67	男	2015/12/22	高中	125631.5	12
山东	山东烟台	594893412	3/13/71	女	2013/12/23	本科	125454	2
佛山	佛山顺德龙江	8076212	11/7/74	女	2003/9/25	大专	125405.39	14
浙江	浙江杭州	865168212	9/1/87	女	2015/8/31	大专	125276	11
贵州	贵州贵阳	5084058292	1/21/78	女	2016/3/10	本科	125212	18
四川	四川绵阳	506895292	11/28/85	男	2013/3/15	高中	125058.66	0
广东	广东东莞	117754182	9/6/72	女	2007/1/25	大专	124828.43	6
青岛	青岛一支	24708292	7/23/72	女	2004/11/30	大专	124810.67	9
湖北	湖北黄石	246838292	6/7/75	女	2008/8/20	高中	124713.9	23
吉林	吉林延边	440471782	4/20/76	女	2011/9/29	高中	124648.65	7
福建	福建福州	617733492	4/18/79	女	2014/3/25	中专	124590	1
陕西	陕西咸阳	5075772512	2/6/76	女	2016/3/5	高中	124552	2
山东	山东烟台	508782672	3/7/82	女	2013/3/22	大专	124531.25	17
北京	北京海淀	808836472	9/11/81	女	2015/7/28	本科	124510	1
江西	江西赣州	614782182	10/8/64	男	2014/3/17	大专	124505.59	2
苏州	苏州太仓	368455772	10/12/66	女	2010/4/1	高中	124468.43	2
四川	四川高新	835161712	3/10/86	女	2015/8/7	高中	124394	2
河北	河北保定	449890692	2/19/73	女	2012/1/1	大专	124389.86	2
陕西	陕西安康	663410582	12/22/78	女	2014/8/27	大专	124328.79	4
天津	天津滨海新区	335814922	1/20/70	女	2009/10/9	高中	124220.82	7
大连	大连明锐	242941542	10/10/83	男	2008/7/25	大专	124210.14	13
四川	四川高新	638771882	11/29/77	男	2014/6/20	大专	124207.88	8
辽宁	辽宁鞍山	545958932	3/6/87	女	2013/6/24	大专	124094	2
河南	河南南阳	277866982	10/17/60	女	2009/1/20	大专	124000	0
湖北	湖北武汉	68814262	10/28/78	女	2005/12/7	大专	123963.3	5
黑龙江	黑龙江伊春	430755732	3/4/69	女	2011/7/18	中专	123949	3
四川	四川青羊	5204856872	1/20/87	女	2016/8/4	大专	123948	22
四川	四川青羊	98622	11/3/63	女	2001/12/30	大专	123731.22	10
云南	云南昆明	147469952	11/23/67	女	2007/7/1	本科	123724.02	4
四川	四川青羊	338606432	12/8/85	女	2009/10/25	高中	123703.85	12
四川	四川青羊	151110872	7/9/79	女	2007/7/20	大专	123698.3	9
黑龙江	黑龙江牡丹江	537686302	3/22/87	男	2013/6/17	初中	123616.03	0
湖南	湖南长沙	141246602	11/28/67	女	2007/7/1	大专	123614.78	6
辽宁	辽宁营口	585925032	10/5/75	女	2013/11/25	大专	123583.9	9
江西	江西南昌	275395232	9/23/60	女	2009/1/3	高中	123536.48	5
新疆	新疆石河子	568929512	12/26/62	女	2013/7/5	大专	123516.43	9
北京	北京海淀	129431722	2/22/85	女	2007/7/1	本科	123514.61	11
黑龙江	黑龙江哈尔滨	313953082	5/27/80	女	2009/7/10	大专	123482	15
佛山	佛山禅城	6438792	10/18/74	女	2003/8/11	中专	123457.67	11
河北	河北秦皇岛	564170372	5/10/72	女	2013/6/29	大专	123404.92	2
青岛	青岛开发区	46686952	3/26/76	女	2005/6/8	高中	123387.65	6
佛山	佛山顺德龙江	86880822	12/3/79	女	2006/6/9	本科	123296.67	10
四川	四川青羊	917332	8/14/70	女	2002/4/2	大专	123129.59	6
江西	江西新余	807811482	6/27/64	女	2015/7/28	高中	123082.19	1
山西	山西太原	155330722	12/8/75	女	2007/8/1	大专	123059.55	9
大连	大连银洲	629864632	4/20/74	女	2014/5/22	大专	123046.05	12
辽宁	辽宁本溪	701961592	7/26/72	女	2014/12/31	中专	122871.03	5
四川	四川青羊	593491032	9/9/75	女	2013/12/19	大专	122756.66	1
辽宁	辽宁沈阳	468874362	5/16/70	女	2012/6/21	本科	122744	6
浙江	浙江杭州	249375642	7/2/63	女	2008/9/1	本科	122679.14	6
苏州	苏州新区	628306492	1/22/85	女	2014/5/13	高中	122660	7
陕西	陕西汉中	5175896452	2/5/85	女	2016/6/20	本科	122601.5	17
河北	河北沧州	516546442	3/1/62	男	2013/4/24	高中	122567.2	13
大连	大连海川	5376262	2/9/63	男	2003/7/1	本科	122396.61	3
青岛	青岛城阳	838170112	11/5/85	女	2015/8/8	大专	122350	1
山东	山东济南	154026042	12/2/79	女	2007/7/25	中专	122255.61	15
四川	四川乐山	701436952	2/27/78	女	2014/12/31	大专	122195.8	1
北京	北京东城	96592132	10/2/66	女	2006/9/8	大专	122187.12	11
深圳	深圳福田第二	314128932	3/8/81	男	2009/7/10	大专	122153.9	8
四川	四川高新	98609312	6/17/73	女	2006/9/25	本科	122053.93	13
辽宁	辽宁葫芦岛	5077622782	10/12/83	男	2016/3/7	大专	121996.8	3
浙江	浙江绍兴	5199166482	10/23/76	男	2016/8/1	高中	121841.5	21
广东	广东广州	101004582	8/21/80	男	2006/10/18	中专	121672.97	28
四川	四川青羊	500302	5/22/63	女	2001/12/31	高中	121583.04	6
广西	广西北海	378004482	8/11/78	女	2010/5/28	大专	121578.93	17
广东	广东广州	468368842	4/20/82	女	2012/6/18	高中	121397.91	9
浙江	浙江温州	759932422	4/7/75	女	2015/6/2	高中	121387.29	7
天津	天津市区一支	554003792	8/28/85	女	2013/6/26	大专	121370.9	3
山东	山东济南	438908202	8/28/76	女	2011/9/22	中专	121307.38	3
苏州	苏州张家港	5029172632	12/14/79	女	2015/11/24	中专	121264	2
浙江	浙江金华	321644412	4/4/73	女	2009/8/3	高中	121204.04	7
山西	山西阳泉	322728162	10/18/73	女	2009/8/6	中专	121194.95	4
福建	福建漳州	5178546262	2/27/67	女	2016/6/21	中专	121112.1	5
河南	河南洛阳	378470362	8/29/73	女	2010/5/31	高中	121105.68	5
河南	河南南阳	5209635212	6/16/86	女	2016/8/8	本科	120969.1	5
山东	山东淄博	15830072	3/11/72	男	2004/6/1	中专	120942.43	6
四川	四川高新	5205980022	8/13/77	女	2016/8/5	硕士	120914	11
吉林	吉林延边	5106746322	10/13/68	男	2016/3/23	本科	120747.5	6
河北	河北秦皇岛	5060898902	8/17/78	男	2016/1/14	大专	120610	1
辽宁	辽宁阜新	5079832942	7/22/75	男	2016/3/7	大专	120540	2
深圳	深圳罗湖	5046280912	10/31/76	女	2015/12/14	本科	120530	5
四川	四川青羊	264292	3/17/65	女	2001/12/31	硕士	120512.1	9
河南	河南驻马店	5144515602	7/9/78	女	2016/5/23	大专	120506	17
辽宁	辽宁沈阳	824900232	4/3/73	女	2015/8/3	大专	120471	6
苏州	苏州昆山	586992032	8/3/87	女	2013/11/29	大专	120443.76	7
浙江	浙江杭州	522446692	4/16/72	女	2013/5/24	高中	120436.22	3
上海	上海黄浦	515279302	2/27/89	女	2013/4/16	大专	120405.5	13
广东	广东江门	239711512	4/12/66	女	2008/7/10	初中	120285.23	3
湖南	湖南岳阳	412580862	3/21/78	女	2011/1/1	大专	120155.75	19
浙江	浙江嘉兴	269895262	6/14/74	女	2008/12/12	大专	120026.35	7
吉林	吉林吉林	830121482	4/3/70	男	2015/8/5	大专	120017.5	6
辽宁	辽宁盘锦	449985322	4/9/79	女	2012/1/1	大专	120000	0
北京	北京海淀	5171876872	9/6/86	女	2016/6/15	本科	119975	7
苏州	苏州张家港	181629032	7/11/75	女	2007/11/21	高中	119915.16	9
北京	北京东城	710327182	4/11/71	女	2015/2/27	本科	119901	19
河北	河北廊坊	304996282	3/20/64	女	2009/6/1	高中	119676.62	6
山东	山东潍坊	24927552	1/19/70	男	2004/11/30	大专	119650.8	37
青岛	青岛一支	756701692	4/17/75	女	2015/5/25	大专	119589	7
浙江	浙江杭州	5123665102	1/5/77	女	2016/4/18	大专	119530.5	7
辽宁	辽宁锦州	5013000842	6/5/81	男	2015/11/8	中专	119487.1	3
江西	江西景德镇	400221252	9/14/71	女	2010/10/12	高中	119461.75	3
四川	四川高新	192289122	7/27/80	女	2007/12/21	本科	119399.47	10
黑龙江	黑龙江哈尔滨	224188442	3/28/74	女	2008/5/19	高中	119263.24	4
贵州	贵州六盘水	503203152	2/21/79	女	2013/2/6	高中	119142.06	16
北京	北京海淀	97662202	11/24/82	男	2006/10/1	本科	119101.82	10
山东	山东东营	5100195672	7/2/78	女	2016/3/19	中专	119052.9	29
大连	大连开发区	578664682	9/15/62	女	2013/9/23	高中	119000	0
福建	福建厦门	323552312	9/16/74	女	2009/8/11	本科	118960.7	33
安徽	安徽阜阳	660929892	12/23/93	女	2014/8/25	高中	118874	26
四川	四川高新	11717132	2/9/63	女	2004/3/31	本科	118839.4	5
陕西	陕西西安	448403592	12/27/88	女	2011/12/23	本科	118782.78	3
佛山	佛山南海	626802642	6/25/82	女	2014/5/5	大专	118736.49	6
辽宁	辽宁营口	5059305152	5/20/80	女	2016/1/8	本科	118704	3
四川	四川高新	279445642	12/10/83	女	2009/2/17	大专	118554.94	23
辽宁	辽宁铁岭	725052062	9/20/69	女	2015/3/25	大专	118497.65	18
四川	四川青羊	75431752	7/13/67	女	2006/2/22	中专	118433.89	9
山东	山东烟台	13177572	12/6/64	女	2004/5/1	大专	118417.28	14
山东	山东威海	756037072	11/29/82	女	2015/5/22	中专	118365.9	7
四川	四川高新	416925072	5/30/90	女	2011/3/5	高中	118292.77	3
山东	山东济南	302534712	8/13/77	女	2009/5/21	高中	118061.3	1
河南	河南安阳	129017762	7/2/66	女	2007/4/5	高中	117971.74	3
河北	河北唐山	432345972	3/16/75	女	2011/8/1	初中	117921.67	6
辽宁	辽宁营口	695117302	9/15/75	女	2014/12/22	大专	117907	8
黑龙江	黑龙江哈尔滨	499223082	3/14/68	女	2013/1/11	中专	117898.3	9
北京	北京东城	5117783482	11/2/72	男	2016/4/7	本科	117897.4	14
四川	四川高新	121481492	6/30/70	女	2007/3/6	大专	117884.52	10
四川	四川内江	7652342	3/5/63	女	2003/9/17	大专	117865.42	3
陕西	陕西渭南	555204362	3/27/58	女	2013/6/27	中专	117861.36	0
青岛	青岛一支	548554072	10/9/81	女	2013/6/25	本科	117825.5	12
湖北	湖北宜昌	5019721952	11/10/62	女	2015/11/16	高中	117752	5
深圳	深圳福田第二	353482602	6/21/70	女	2009/12/30	大专	117697.48	5
河南	河南驻马店	15007592	3/18/72	女	2004/6/28	大专	117693.12	18
天津	天津市区二支	5172990052	1/31/74	女	2016/6/15	大专	117693	15
北京	北京东城	5113247122	6/18/79	男	2016/3/28	大专	117674	12
河南	河南郑州	252926402	9/29/74	女	2008/9/23	大专	117591.5	12
河北	河北石家庄	258247722	9/5/77	男	2008/10/22	大专	117576.56	16
苏州	苏州新区	20446432	8/10/68	女	2004/10/8	高中	117321.4	2
山东	山东枣庄	362968782	6/20/73	女	2010/3/8	中专	117176.97	14
广东	广东广州	617810672	7/28/75	女	2014/3/25	大专	117142.8	20
四川	四川绵阳	513333162	12/4/73	女	2013/4/1	高中	117104.3	7
天津	天津市区二支	275442682	4/22/84	女	2009/1/4	本科	116975.14	10
深圳	深圳福田第二	613156072	10/14/80	女	2014/3/13	大专	116880.38	12
山东	山东淄博	184641472	6/5/73	女	2007/11/25	高中	116832.24	13
广西	广西南宁	393798732	8/23/77	女	2010/9/1	本科	116821.08	5
北京	北京海淀	390076862	9/17/79	男	2010/8/10	本科	116820.59	10
苏州	苏州张家港	776940222	12/18/68	女	2015/6/29	高中	116686	2
河北	河北唐山	475549912	4/7/77	男	2012/8/24	高中	116665.97	11
广东	广东珠海	115948722	10/5/69	女	2007/1/17	大专	116649.01	3
苏州	苏州昆山	5206229342	8/5/81	女	2016/8/5	本科	116429.5	12
福建	福建宁德	426054652	4/17/64	女	2011/6/3	大专	116350.2	18
山东	山东威海	467064162	1/12/66	女	2012/6/4	高中	116261.97	3
青岛	青岛一支	163237272	2/3/73	女	2007/9/17	大专	116159.72	1
北京	北京平谷	7539292	10/20/57	女	2003/9/11	大专	116105.54	3
吉林	吉林吉林	416110132	11/12/76	男	2011/2/25	大专	116067.88	9
北京	北京海淀	250532802	1/2/87	女	2008/9/9	大专	115996.82	25
广西	广西南宁	711303322	4/5/82	女	2015/3/6	大专	115990	3
河北	河北沧州	327033702	4/5/89	男	2009/8/25	高中	115879.4	18
黑龙江	黑龙江哈尔滨	299973772	10/1/73	女	2009/5/15	高中	115869.13	11
青岛	青岛一支	27345712	2/8/63	女	2004/12/29	大专	115847.93	6
四川	四川高新	755757652	10/25/69	女	2015/5/21	大专	115785.52	15
青岛	青岛一支	357117382	10/23/80	女	2010/1/13	本科	115770.27	19
苏州	苏州张家港	70478612	9/19/64	女	2005/12/23	高中	115629.53	3
黑龙江	黑龙江鹤岗	118691382	9/27/59	女	2007/2/1	大专	115588.44	7
山东	山东烟台	851288972	11/7/75	女	2015/8/10	大专	115525	4
苏州	苏州新区	464456742	5/25/71	女	2012/5/11	本科	115413.03	6
湖北	湖北荆州	251653122	5/15/77	女	2008/9/17	中专	115386.93	10
江苏	江苏泰州	162457502	11/1/58	女	2007/9/10	高中	115315.54	4
河北	河北唐山	304664942	3/19/60	男	2009/5/31	高中	115231.25	3
辽宁	辽宁沈阳	5013738762	10/10/77	女	2015/11/9	大专	115221	4
湖北	湖北襄阳	242083892	12/23/79	女	2008/7/24	大专	115149.03	16
青岛	青岛一支	322679312	8/6/75	女	2009/8/6	本科	115134.99	9
河南	河南南阳	12555932	5/27/71	女	2004/4/15	中专	115101.6	2
山东	山东济南	5088630932	9/14/79	男	2016/3/11	本科	115000	19
四川	四川高新	116611672	10/5/70	男	2007/1/22	大专	114965.9	11
湖北	湖北十堰	5194118882	1/21/88	女	2016/7/27	高中	114960.9	24
湖北	湖北襄阳	5126069262	2/29/84	女	2016/4/21	高中	114871.5	4
辽宁	辽宁盘锦	5079899062	9/16/69	女	2016/3/7	大专	114866.9	18
佛山	佛山顺德龙江	185701632	11/7/69	女	2007/12/1	高中	114819.34	7
江苏	江苏泰州	65132032	5/10/58	女	2005/11/11	高中	114800.52	8
湖北	湖北武汉	334930342	4/16/76	女	2009/10/1	大专	114734.55	10
浙江	浙江绍兴	5143414122	6/17/88	女	2016/5/20	本科	114734.5	2
山东	山东烟台	5160400962	5/3/81	女	2016/6/3	中专	114561	9
上海	上海陆家嘴	504625352	3/23/80	女	2013/2/26	大专	114420.01	11
山东	山东济宁	5163305492	4/20/82	男	2016/6/6	高中	114406.1	2
深圳	深圳南山	713445832	10/18/74	女	2015/3/18	中专	114025	11
黑龙江	黑龙江哈尔滨	264689362	12/5/71	男	2008/11/21	本科	114019.9	11
江苏	江苏南京	18620182	6/14/64	女	2004/9/9	高中	113860.63	3
广西	广西南宁	5160062812	9/10/79	女	2016/6/2	本科	113717	10
四川	四川南充	285563482	10/22/81	男	2009/3/18	本科	113700	2
山东	山东烟台	43331592	12/13/73	女	2005/5/17	中专	113669.32	19
四川	四川高新	663763412	8/19/81	女	2014/8/28	本科	113621.66	8
北京	北京平谷	703275212	6/27/75	女	2015/1/8	中专	113608	4
山东	山东潍坊	336470062	8/7/72	女	2009/10/13	高中	113558.83	27
江西	江西赣州	810862862	4/17/84	女	2015/7/29	大专	113505.13	23
深圳	深圳宝安	93040672	7/2/68	女	2006/8/8	大专	113463.16	4
浙江	浙江杭州	6751982	11/27/70	女	2003/8/22	大专	113447.63	6
广西	广西桂林	477409662	8/26/63	女	2012/9/6	大专	113420.3	15
广东	广东广州	505128772	6/30/69	女	2013/3/1	高中	113305.92	15
江苏	江苏无锡	659387802	8/7/72	女	2014/8/21	大专	113259.42	1
宁波	宁波城区	5157707602	9/15/70	女	2016/6/1	大专	113243	8
北京	北京良乡	251858702	3/28/86	男	2008/10/1	本科	113241.55	4
山东	山东枣庄	250488422	8/29/67	女	2008/9/10	大专	113076.61	8
湖北	湖北武汉	470917502	4/6/82	女	2012/6/28	本科	113040.08	10
青岛	青岛即墨	583620892	5/15/78	男	2013/11/11	高中	113013.05	0
辽宁	辽宁铁岭	5058818352	12/8/71	女	2016/1/8	高中	112759	4
福建	福建福州	5002276422	11/4/71	女	2015/10/17	高中	112754.3	25
吉林	吉林吉林	5137617732	4/15/72	女	2016/5/12	高中	112750	3
黑龙江	黑龙江七台河	221284582	5/26/69	女	2008/5/5	大专	112708.37	3
四川	四川青羊	370385862	1/29/76	女	2010/4/19	本科	112708.06	13
河南	河南南阳	533556612	7/24/82	女	2013/6/13	高中	112702.96	0
四川	四川青羊	16892602	10/10/75	女	2004/8/4	高中	112692.04	3
四川	四川高新	157076722	4/15/75	女	2007/8/10	大专	112680.56	13
北京	北京良乡	37858282	7/25/76	女	2005/4/4	大专	112668	2
湖北	湖北武汉	838152662	12/19/74	女	2015/8/8	大专	112570	19
上海	上海陆家嘴	5132689772	8/19/82	女	2016/5/4	本科	112449	16
福建	福建厦门	218254692	4/13/63	女	2008/4/23	高中	112385.02	11
四川	四川高新	5027434802	10/23/82	女	2015/11/23	大专	112320	7
安徽	安徽合肥	206267032	3/2/62	女	2008/3/1	本科	112250.77	10
福建	福建福州	92683102	11/15/69	女	2006/8/2	大专	112187.01	29
湖北	湖北十堰	590788982	11/24/76	女	2013/12/12	高中	112143	6
山西	山西晋中	669865202	11/7/66	女	2014/9/17	高中	112100	2
青岛	青岛一支	875811552	10/27/76	女	2015/9/22	大专	112075.9	5
福建	福建福州	631175182	8/3/76	女	2014/5/26	中专	112058.88	9
辽宁	辽宁沈阳	482442082	3/26/81	女	2012/11/7	本科	112043	10
江西	江西南昌	816538412	12/8/90	女	2015/7/30	大专	112025	16
山东	山东枣庄	302881402	2/22/62	女	2009/5/24	中专	111838.58	1
苏州	苏州园区	788728442	6/8/80	女	2015/7/14	大专	111832	12
苏州	苏州吴江	434891562	7/13/62	女	2011/8/22	大专	111826.05	11
山西	山西吕梁	5043858612	11/4/86	女	2015/12/10	中专	111783.6	3
重庆	重庆渝中	5172203182	11/15/83	女	2016/6/15	本科	111744.8	17
山东	山东烟台	750405902	4/10/83	女	2015/4/29	大专	111627.3	6
山西	山西朔州	850148562	11/24/73	女	2015/8/10	本科	111607	4
山东	山东东营	145756752	11/19/68	女	2007/6/25	本科	111504.32	15
山东	山东威海	207748962	1/5/60	女	2008/3/17	中专	111388	10
湖北	湖北武汉	5217393412	8/31/75	女	2016/8/16	本科	111355.4	15
四川	四川青羊	77035672	1/19/77	女	2006/3/1	大专	111340.62	1
云南	云南昆明	346055612	5/21/57	女	2009/11/26	大专	111324.62	13
吉林	吉林辽源	548145552	1/1/63	女	2013/6/24	大专	111301.19	2
四川	四川高新	153316612	9/2/71	女	2007/7/25	大专	111286.1	10
陕西	陕西宝鸡	500675982	12/23/68	女	2013/1/23	大专	111272.71	9
四川	四川青羊	1511612	2/26/63	女	2002/7/23	大专	111137.63	3
江苏	江苏泰州	549008642	12/23/78	女	2013/6/25	中专	111114.04	4
吉林	吉林长春	572624722	12/5/76	女	2013/8/7	大专	111072.88	1
北京	北京东城	11565142	5/4/72	女	2004/4/1	大专	111005.53	15
江苏	江苏泰州	757105742	4/7/79	女	2015/5/25	高中	111000	2
湖南	湖南永州	499297192	4/9/66	女	2013/1/14	高中	110996.95	8
福建	福建漳州	195533802	3/31/79	女	2008/1/1	本科	110918.48	38
新疆	新疆库尔勒	407267082	2/25/65	女	2010/12/2	大专	110898.78	16
北京	北京海淀	1902682	4/11/71	女	2002/9/6	硕士	110861.86	1
上海	上海黄浦	430923772	12/8/71	女	2011/7/20	本科	110840.03	1
北京	北京海淀	216382662	10/3/85	女	2008/4/14	高中	110774.87	16
四川	四川高新	754930432	3/2/74	女	2015/5/19	本科	110691.7	11
北京	北京海淀	587594822	10/3/83	女	2013/12/2	硕士	110614	1
深圳	深圳福田第二	590897292	11/3/84	女	2013/12/12	本科	110611	9
深圳	深圳福田第二	446183202	1/30/77	女	2011/12/6	大专	110532.92	8
浙江	浙江衢州	806829202	7/23/81	女	2015/7/27	高中	110520	1
河北	河北保定	5073952152	9/11/80	女	2016/3/4	中专	110476.2	3
四川	四川青羊	839452	6/28/73	女	2002/3/21	中专	110409.58	11
河南	河南三门峡	203772662	3/28/66	女	2008/2/10	高中	110374.33	3
四川	四川青羊	5028789132	7/30/81	女	2015/11/24	大专	110367	20
新疆	新疆乌鲁木齐	251991572	2/10/71	女	2008/9/18	大专	110280.5	32
湖北	湖北荆州	216740932	8/29/71	女	2008/4/17	大专	110229.4	7
四川	四川青羊	346132792	6/12/66	女	2009/11/26	中专	110126.02	12
青岛	青岛一支	881393172	9/4/73	女	2015/10/4	大专	110072.8	3
黑龙江	黑龙江牡丹江	45725192	8/24/79	男	2005/6/1	大专	110058	4
佛山	佛山顺德龙江	818578482	1/31/91	男	2015/7/31	大专	110040	3
广东	广东中山	7239082	8/14/73	女	2003/8/31	中专	110014.67	11
河南	河南南阳	260606152	9/13/66	男	2008/11/3	高中	110002.13	15
天津	天津市区二支	5074564062	8/4/66	女	2016/3/4	大专	110000	0
青岛	青岛即墨	200769432	10/9/71	女	2008/1/16	大专	109956.1	6
天津	天津市区一支	24772492	9/9/75	男	2004/11/30	大专	109888.86	13
山东	山东枣庄	858116242	11/8/83	女	2015/8/11	中专	109822	2
重庆	重庆渝中	766677342	11/12/78	男	2015/6/19	中专	109782.9	2
吉林	吉林长春	763437402	11/10/70	女	2015/6/11	本科	109712	3
新疆	新疆库尔勒	5198654272	8/22/64	女	2016/8/1	大专	109697	20
山东	山东烟台	723457352	7/7/82	女	2015/3/25	大专	109687.5	6
山东	山东烟台	829712962	3/11/71	女	2015/8/5	中专	109665	16
辽宁	辽宁鞍山	585733402	10/14/77	女	2013/11/25	大专	109620.09	13
青岛	青岛开发区	202435182	8/19/68	女	2008/1/25	中专	109573.74	5
上海	上海黄浦	484499872	9/30/66	女	2009/1/12	高中	109560.2	10
重庆	重庆渝中	5079032432	1/14/86	女	2016/3/7	本科	109559.5	8
辽宁	辽宁沈阳	450152662	4/4/79	女	2012/1/1	本科	109498.7	14
黑龙江	黑龙江双鸭山	377039922	12/25/57	男	2010/5/25	高中	109477.96	0
黑龙江	黑龙江牡丹江	5064313842	8/18/71	男	2016/2/1	高中	109419	4
浙江	浙江温州	208714222	3/15/82	女	2008/3/20	大专	109410	5
辽宁	辽宁营口	754398952	12/4/86	女	2015/5/15	中专	109353.22	0
青岛	青岛即墨	26359662	10/20/76	女	2004/12/17	高中	109351.51	5
辽宁	辽宁沈阳	9165402	7/13/66	女	2003/11/28	大专	109349.33	13
四川	四川成都	3289712	10/10/71	女	2003/3/25	本科	109300.68	5
河南	河南南阳	627531192	7/4/78	女	2014/5/9	高中	109300	0
广西	广西钦州	871876562	1/1/85	女	2015/9/15	中专	109270	1
宁波	宁波城区	5115497162	2/17/77	女	2016/4/1	大专	109141	8
吉林	吉林吉林	669111392	2/22/81	男	2014/9/15	高中	109060	1
佛山	佛山禅城	658256602	2/23/69	女	2014/8/19	大专	109039.27	0
北京	北京东城	868741162	1/17/79	女	2015/9/9	大专	109000	0
山东	山东济南	713605362	2/6/82	女	2015/3/18	硕士	108910	2
深圳	深圳宝安	350445872	8/13/72	女	2009/12/18	高中	108881.11	15
深圳	深圳南山	5718068432	1/27/74	男	2018/5/15	高中	108840.07	1
深圳	深圳宝安	830091752	1/17/87	女	2015/8/5	高中	108831.3	3
青岛	青岛一支	5214180642	10/10/92	女	2016/8/12	本科	108696.5	20
苏州	苏州张家港	5203446252	12/8/65	女	2016/8/3	中专	108606	15
辽宁	辽宁阜新	472929242	12/27/72	女	2012/7/27	大专	108570.14	2
福建	福建泉州	180284982	2/10/69	女	2007/11/17	中专	108564.6	14
辽宁	辽宁葫芦岛	72833272	11/12/70	女	2005/12/31	大专	108427.75	19
河南	河南三门峡	170579542	2/14/76	女	2007/10/22	大专	108416.25	6
四川	四川乐山	7654712	3/16/69	女	2003/9/17	大专	108406.78	3
四川	四川内江	7783812	4/10/72	女	2003/9/17	大专	108391.73	3
浙江	浙江杭州	273777632	3/25/75	女	2008/12/30	本科	108366.45	7
北京	北京良乡	708159682	12/21/69	女	2015/2/4	大专	108315.94	2
浙江	浙江嘉兴	547521542	3/7/72	女	2013/6/24	初中	108284.44	1
上海	上海黄浦	486035822	2/20/78	男	2012/11/27	本科	108261.46	2
湖南	湖南长沙	5113140152	4/4/75	男	2016/3/28	大专	108236	3
浙江	浙江温州	498758872	8/10/81	女	2013/1/7	高中	108232.64	7
黑龙江	黑龙江哈尔滨	655825462	2/3/79	女	2014/8/13	大专	108207.02	2
湖北	湖北武汉	81850502	4/13/76	女	2006/4/14	高中	108122.68	18
青海	西宁	5193011032	5/17/77	女	2016/7/26	高中	108078.7	7
安徽	安徽芜湖	391489852	11/30/81	女	2010/8/1	大专	108043.63	7
辽宁	辽宁盘锦	335956032	3/15/61	女	2009/10/5	本科	107825.86	4
重庆	重庆渝中	481706692	7/31/79	女	2012/10/30	大专	107787.04	9
宁波	宁波城区	643443932	5/12/79	女	2014/6/27	高中	107712.5	17
河北	河北沧州	149788742	10/1/58	女	2007/7/16	高中	107667.85	4
辽宁	辽宁阜新	98866812	11/22/71	女	2006/9/25	本科	107657.28	15
山东	山东烟台	270959192	4/24/67	女	2008/12/18	高中	107644.39	14
山西	山西长治	622943162	3/6/90	女	2014/4/10	高中	107622.7	2
浙江	浙江温州	511523932	10/23/78	女	2013/3/28	大专	107585.15	2
深圳	深圳福田	276043802	6/5/68	男	2009/1/6	硕士	107545.11	4
辽宁	辽宁本溪	708549082	11/18/78	女	2015/2/7	大专	107470.9	4
河南	河南许昌	238263352	10/24/64	男	2008/7/2	大专	107394.5	16
深圳	深圳南山	508111212	1/12/73	男	2013/3/20	本科	107365.48	6
福建	福建福州	83177102	12/7/74	女	2006/4/27	本科	107363.85	17
浙江	浙江杭州	142648982	11/19/75	女	2007/6/22	高中	107316.42	10
黑龙江	黑龙江佳木斯	63367882	9/5/70	女	2005/11/1	本科	107265.38	15
河南	河南周口	218263902	9/13/77	女	2008/4/23	大专	107235.7	14
重庆	重庆渝中	879381432	1/17/82	男	2015/9/28	中专	107231.5	19
辽宁	辽宁沈阳	692160822	12/26/80	女	2014/12/18	大专	107179.53	4
四川	四川乐山	5051446352	5/16/70	女	2015/12/23	高中	107173	1
广东	广东肇庆	643590482	10/15/82	女	2014/6/27	大专	107152.09	2
四川	四川青羊	203164702	8/11/66	女	2008/2/1	大专	107109.82	7
山西	山西阳泉	327465112	11/26/73	女	2009/8/26	中专	107103.43	3
苏州	苏州新区	255808072	8/21/79	男	2008/10/8	大专	107054.16	10
山东	山东日照	540434402	3/1/76	男	2013/6/19	中专	107000.73	1
吉林	吉林长春	497471912	12/17/64	女	2012/12/31	高中	106971.05	6
河南	河南南阳	803343342	10/6/81	女	2015/7/26	高中	106961	2
辽宁	辽宁盘锦	864202952	9/20/69	男	2015/8/27	高中	106914.43	1
大连	大连明锐	321558022	9/15/71	女	2009/8/3	初中	106874.2	4
辽宁	辽宁朝阳	5203055552	7/2/75	男	2016/8/2	高中	106841.1	8
吉林	吉林延边	412054552	7/26/77	女	2010/12/29	高中	106742.05	2
山东	山东烟台	460199352	1/7/71	女	2012/3/28	高中	106658.7	3
山西	山西临汾	736348442	2/9/65	女	2015/4/7	高中	106650.5	5
山东	山东烟台	5112361452	4/8/64	女	2016/3/25	高中	106644	3
大连	大连开发区	167488522	3/3/74	女	2007/10/5	大专	106597.71	10
青岛	青岛一支	826919782	2/16/75	女	2015/8/4	博士	106475	8
山东	山东烟台	408320402	3/4/81	女	2010/12/9	中专	106386	13
深圳	深圳宝安	46164412	2/4/65	女	2005/6/3	本科	106347.87	6
山东	山东淄博	198264182	10/22/61	女	2008/1/5	中专	106309.21	18
辽宁	辽宁沈阳	5008248742	11/12/93	男	2015/11/2	本科	106306.4	16
重庆	重庆永川	498960412	5/22/72	女	2013/1/9	本科	106254.92	30
四川	四川高新	741251062	2/16/81	女	2015/4/10	本科	106222.1	19
辽宁	辽宁丹东	831637612	9/28/58	女	2015/8/6	大专	106200	6
四川	四川青羊	2265422	2/28/80	女	2002/11/27	中专	106159.62	4
辽宁	辽宁铁岭	104004742	12/15/69	女	2006/11/20	大专	106136.2	10
上海	上海陆家嘴	622541482	1/6/85	男	2014/4/8	高中	106116.39	14
辽宁	辽宁盘锦	877177092	10/25/71	女	2015/9/24	大专	106101.8	13
重庆	重庆永川	815980972	12/13/65	女	2015/7/30	高中	106075.54	30
贵州	贵州贵阳	5193953892	10/9/65	女	2016/7/27	大专	106001	13
北京	北京海淀	363176462	4/9/79	女	2010/3/10	大专	105918.9	9
云南	云南昆明	506605962	4/8/57	女	2013/3/13	大专	105902.86	2
河南	河南郑州	104812842	5/30/69	女	2006/11/24	中专	105894	2
江苏	江苏镇江	854093462	9/18/72	女	2015/8/10	高中	105835	1
福建	福建福州	746320322	5/1/65	女	2015/4/16	高中	105780	1
湖北	湖北武汉	61245192	5/4/70	女	2005/10/7	高中	105749.43	9
四川	四川青羊	5113250792	7/21/91	女	2016/3/28	本科	105746	1
河北	河北唐山	532940142	4/10/83	男	2013/6/12	大专	105714.17	1
天津	天津市区二支	79390332	9/30/84	女	2006/3/24	大专	105586.41	15
山东	山东泰安	652192082	5/4/74	女	2014/8/6	大专	105564.8	12
北京	北京良乡	394605162	9/16/76	女	2010/9/7	初中	105491.46	5
黑龙江	黑龙江哈尔滨	363810382	11/15/76	男	2010/3/15	本科	105452.94	3
重庆	重庆永川	500052672	5/20/71	女	2013/1/18	高中	105449.28	22
安徽	安徽合肥	5121413262	2/21/75	女	2016/4/14	大专	105393.1	15
浙江	浙江嘉兴	137803452	3/25/72	女	2007/6/1	大专	105353.73	4
浙江	浙江杭州	258732722	2/17/70	女	2008/10/24	高中	105346.07	8
云南	云南昆明	212685082	12/26/68	女	2008/4/1	高中	105309.19	11
苏州	苏州新区	20683142	6/23/61	女	2004/10/12	大专	105246.84	1
江苏	江苏南京	701351262	5/15/82	男	2014/12/31	硕士	105241	9
青岛	青岛一支	4659022	4/22/48	女	2003/6/18	大专	105220.91	14
新疆	新疆乌鲁木齐	5088289112	3/21/84	女	2016/3/11	本科	105211	17
四川	四川高新	230457572	8/19/75	女	2008/6/11	大专	105194.26	21
云南	云南昆明	680336732	10/27/81	女	2014/11/5	大专	105157	1
青岛	青岛一支	752502362	7/18/81	女	2015/5/7	大专	105075.5	11
青岛	青岛一支	5097001942	2/26/84	女	2016/3/16	本科	105000.5	3
浙江	浙江温州	759647562	10/8/80	女	2015/6/1	高中	104868.51	5
宁波	宁波宁海	5072071072	11/25/81	女	2016/3/2	中专	104866	3
湖北	湖北黄冈	457593332	1/13/72	女	2012/3/20	高中	104863.51	12
湖南	湖南郴州	5092193622	3/11/97	男	2016/3/14	高中	104850	2
黑龙江	黑龙江哈尔滨	5178792882	6/7/68	女	2016/6/21	高中	104758	3
山东	山东威海	181515282	1/28/70	男	2007/11/21	本科	104737.02	4
广西	广西南宁	293880222	7/14/78	女	2009/4/21	中专	104712.77	1
四川	四川高新	330413352	3/4/80	女	2009/9/14	大专	104677.75	10
北京	北京东城	804417182	2/2/81	女	2015/7/26	本科	104614	1
四川	四川青羊	336743612	6/16/54	女	2009/10/15	本科	104488.77	2
吉林	吉林辽源	5010357332	7/11/83	女	2015/11/5	大专	104486.5	2
北京	北京海淀	547132842	11/24/91	女	2013/6/30	本科	104479.48	7
浙江	浙江金华	379169182	2/24/73	女	2010/6/5	高中	104398.05	7
大连	大连海川	5154270922	2/14/68	女	2016/5/31	本科	104277	2
深圳	深圳福田	613855862	5/17/86	女	2014/3/15	本科	104268.05	5
安徽	安徽滁州	5139672872	8/4/68	女	2016/5/16	大专	104208.8	10
山西	山西长治	273416292	8/11/74	女	2009/1/1	大专	104203.6	10
青岛	青岛一支	681109662	2/8/67	女	2014/11/10	本科	104190	2
苏州	苏州昆山	61580572	12/3/71	女	2005/10/10	高中	104153.1	3
辽宁	辽宁阜新	71510442	10/28/69	女	2005/12/30	大专	104151.32	8
北京	北京海淀	394760222	10/23/87	男	2010/9/8	本科	104140.11	13
山东	山东菏泽	491738032	10/20/77	女	2012/12/20	本科	104121.5	6
北京	北京良乡	460874582	12/4/84	女	2012/4/1	中专	104092.34	15
河南	河南南阳	574691452	3/19/80	女	2013/8/26	中专	104000	0
江苏	江苏泰州	5143823932	11/16/78	女	2016/5/21	高中	103993	13
苏州	苏州新区	485193252	8/14/69	女	2012/11/25	大专	103775.9	4
四川	四川高新	206082242	12/19/78	女	2008/3/6	大专	103703.6	7
宁波	宁波慈溪	634496072	2/20/68	女	2014/6/9	高中	103669.22	4
河北	河北唐山	61391042	3/28/75	女	2005/10/8	大专	103642	19
河南	河南郑州	540946062	2/8/71	女	2013/6/19	大专	103639.52	2
河南	河南洛阳	696591422	2/7/70	女	2014/12/23	高中	103591	1
山东	山东临沂	18346902	4/8/63	男	2004/9/1	大专	103589.75	12
山东	山东济宁	442349952	10/22/63	女	2011/10/21	大专	103569.35	10
重庆	重庆渝中	5024636272	2/12/80	女	2015/11/20	大专	103555	3
安徽	安徽合肥	670525082	6/1/81	女	2014/9/19	大专	103541.5	7
大连	大连明锐	708995142	6/1/81	女	2015/2/11	大专	103522	9
山东	山东济南	5195594722	2/28/81	女	2016/7/28	博士	103514	9
吉林	吉林辽源	739348602	8/8/55	男	2015/4/9	中专	103498.5	1
重庆	重庆渝中	451314292	7/9/75	女	2012/1/13	大专	103428	15
山西	山西太原	325457142	7/27/77	女	2009/8/24	本科	103363.06	7
青岛	青岛一支	742065302	9/20/82	男	2015/4/10	高中	103276.5	6
广西	广西柳州	348891502	1/16/71	女	2009/12/10	高中	103214	3
四川	四川雅安	5141825912	12/31/73	女	2016/5/18	大专	103152.7	6
山东	山东枣庄	208136962	11/30/74	女	2008/3/17	大专	103128.63	8
北京	北京平谷	5032257592	3/27/86	女	2015/11/26	大专	103114	5
青岛	青岛一支	881385632	4/7/69	男	2015/10/4	大专	103095	2
青岛	青岛即墨	586063342	3/30/71	女	2013/11/25	高中	103013.11	0
河南	河南濮阳	600220872	11/8/79	女	2013/12/30	本科	103000	0
广东	广东东莞	814186822	10/25/81	女	2015/7/30	高中	103000	0
山东	山东临沂	378277062	3/10/68	女	2010/5/31	大专	102994.68	7
湖北	湖北武汉	101938712	12/3/69	女	2006/10/25	大专	102966.96	6
四川	四川高新	5177226342	8/5/75	女	2016/6/21	中专	102928	27
山东	山东济南	5107083572	10/29/81	女	2016/3/23	本科	102874	3
四川	四川宜宾	5081232622	11/16/87	男	2016/3/8	大专	102860	2
山东	山东烟台	285215122	11/5/71	女	2009/3/17	高中	102856.19	9
浙江	浙江嘉兴	870665112	6/16/85	女	2015/9/10	高中	102816.5	8
辽宁	辽宁葫芦岛	564557672	9/13/79	女	2013/6/29	大专	102782.4	17
广西	广西桂林	665997482	10/4/86	女	2014/9/2	中专	102778	12
四川	四川青羊	5124936072	8/18/83	女	2016/4/19	大专	102700	2
山西	山西太原	861276902	9/21/76	女	2015/8/19	大专	102627	7
江苏	江苏南京	13842892	9/18/74	男	2004/5/25	本科	102472.67	2
辽宁	辽宁沈阳	44110662	11/6/73	女	2005/5/25	大专	102472.01	15
四川	四川乐山	5047811102	10/24/81	女	2015/12/17	高中	102433.59	0
四川	四川高新	414961482	8/6/85	女	2011/1/26	大专	102410.4	22
福建	福建福州	5063372852	3/19/84	女	2016/1/28	大专	102368.13	22
山东	山东威海	199065442	4/13/81	女	2008/1/8	高中	102350.5	25
四川	四川内江	158450772	9/8/69	女	2007/8/21	大专	102329	21
青岛	青岛一支	583701842	9/24/76	女	2013/11/11	大专	102270.25	12
江西	江西南昌	456539312	3/2/78	女	2012/3/14	本科	102270	4
上海	上海黄浦	680202192	8/29/89	女	2014/11/4	本科	102256.2	24
湖北	湖北十堰	5017890722	10/29/84	男	2015/11/13	高中	102186	1
湖北	湖北荆州	782853452	10/13/74	女	2015/7/7	高中	102182	18
山东	山东枣庄	250193652	9/28/68	女	2008/9/8	中专	102093.77	10
湖北	湖北黄冈	509003332	11/6/68	女	2013/3/22	中专	102077.67	12
山东	山东临沂	531058202	3/1/78	女	2013/6/9	高中	101984.67	7
山东	山东济宁	271482432	10/20/62	女	2008/12/22	大专	101974.47	7
浙江	浙江湖州	5014913632	10/25/89	男	2015/11/10	本科	101954.6	12
湖北	湖北十堰	716066232	8/26/83	女	2015/3/21	中专	101909	1
大连	大连开发区	42111902	3/20/65	女	2005/5/6	本科	101907.69	6
福建	福建泉州	480989452	8/4/66	女	2012/10/23	高中	101897.41	7
辽宁	辽宁沈阳	535180352	10/27/78	女	2013/6/14	本科	101881.79	8
云南	云南昆明	527623562	9/2/88	女	2013/6/5	大专	101856.27	1
湖北	湖北武汉	32686852	10/11/77	女	2005/3/1	大专	101776.39	14
河南	河南南阳	12554262	1/16/75	女	2004/4/15	高中	101763.71	14
黑龙江	黑龙江佳木斯	314869762	6/9/77	女	2009/7/15	中专	101749.3	13
吉林	吉林四平	439640792	6/25/65	女	2011/9/26	大专	101710.29	15
江西	江西赣州	816605682	3/14/81	女	2015/7/30	中专	101702.57	0
山东	山东德州	238018132	9/2/71	女	2008/7/2	高中	101694.09	7
山东	山东菏泽	421201582	7/6/64	男	2011/4/12	高中	101688.28	6
黑龙江	黑龙江鹤岗	273967862	6/18/60	女	2009/1/1	大专	101685.1	9
湖北	湖北荆州	378425282	11/3/62	男	2010/6/1	大专	101668.49	12
广东	广东江门	51680432	4/9/65	女	2005/7/15	高中	101598.03	10
黑龙江	黑龙江黑河	5031848752	4/29/65	女	2015/11/26	中专	101558	1
广西	广西桂林	377728832	4/2/72	男	2010/5/26	大专	101546.84	10
浙江	浙江台州	412730482	6/22/73	女	2011/1/1	高中	101522.84	14
浙江	浙江杭州	235763542	5/23/62	女	2008/6/25	高中	101499.73	4
佛山	佛山顺德龙江	683312332	12/30/88	女	2014/11/17	高中	101485.42	9
河南	河南南阳	730605622	6/24/72	女	2015/3/30	高中	101350	1
河南	河南开封	5008158042	12/27/91	男	2015/11/2	本科	101350	1
四川	四川高新	687482632	4/16/78	女	2014/11/26	大专	101350	1
四川	四川高新	449170382	10/25/76	女	2011/12/26	大专	101328.73	9
山东	山东滨州	5197310812	12/2/68	女	2016/7/30	大专	101300.9	13
山东	山东淄博	5158733822	12/27/79	男	2016/6/1	中专	101239.5	7
黑龙江	黑龙江牡丹江	272340082	12/10/52	女	2009/1/1	高中	101189	1
云南	云南昆明	410700322	1/25/74	女	2010/12/23	大专	101182.96	1
青岛	青岛一支	11510152	9/27/73	男	2004/3/27	大专	101132	6
深圳	深圳宝安	5175297212	9/10/79	女	2016/6/18	中专	101110	7
广东	广东广州	605816172	11/11/74	女	2014/1/16	中专	101078	1
浙江	浙江杭州	390844352	10/19/81	女	2010/8/16	高中	101064.28	11
山东	山东济南	9966662	8/27/69	女	2004/2/1	大专	101050.02	4
浙江	浙江杭州	881510962	5/6/82	女	2015/10/6	高中	101027.6	6
四川	四川高新	5077567462	4/15/75	女	2016/3/7	本科	101000	0
山东	山东济南	5203463492	10/17/78	男	2016/8/3	本科	100972	11
苏州	苏州新区	716769792	12/1/66	女	2015/3/23	本科	100947.16	11
四川	四川高新	4153502	1/8/78	女	2003/5/20	大专	100845.99	12
山东	山东威海	516520482	3/2/71	女	2013/4/24	高中	100761.89	25
重庆	重庆渝中	447542872	9/28/78	女	2011/12/19	大专	100758.57	15
辽宁	辽宁丹东	36292602	12/27/67	男	2005/3/30	本科	100738.55	4
福建	福建福州	5150163342	2/20/76	女	2016/5/27	高中	100704	10
山东	山东临沂	5158820852	11/12/78	女	2016/6/2	中专	100610.4	11
浙江	浙江杭州	128256142	5/9/72	男	2007/4/2	高中	100598.36	4
北京	北京良乡	105363442	1/8/64	女	2006/12/1	大专	100598.31	1
浙江	浙江嘉兴	771765722	1/3/76	女	2015/6/24	高中	100508.5	2
陕西	陕西西安	627520582	4/19/75	男	2014/5/9	大专	100466.61	19
四川	四川乐山	104357572	1/23/69	女	2006/11/23	大专	100438.38	10
大连	大连明锐	735132522	6/25/79	男	2015/4/3	本科	100378.1	7
福建	福建福州	424286462	4/4/72	女	2011/5/17	高中	100368.25	9
广西	广西南宁	766007552	2/18/86	男	2015/6/18	大专	100332.5	1
山东	山东烟台	486451182	7/22/65	女	2012/11/29	大专	100302.02	12
辽宁	辽宁鞍山	750884762	7/16/77	男	2015/4/30	高中	100204	1
北京	北京海淀	667183672	10/7/71	男	2014/9/5	大专	100188.17	3
河北	河北廊坊	80743862	8/25/53	女	2006/3/30	中专	100130.25	4
黑龙江	黑龙江齐齐哈尔	5079040602	5/12/64	女	2016/3/7	本科	100060	6
浙江	浙江杭州	17451712	3/21/77	男	2004/8/18	大专	100028.49	4
山东	山东烟台	272740362	6/26/65	女	2008/12/25	大专	100013	5
辽宁	辽宁鞍山	5012221172	5/22/66	女	2015/11/7	高中	100000	0
江西	江西上饶	5084345692	11/15/92	男	2016/3/10	高中	100000	0
山东	山东烟台	711815952	7/29/74	女	2015/3/11	中专	100000	0
苏州	苏州张家港	454346552	2/21/77	女	2012/3/5	初中	100000	0
湖南	湖南长沙	824684042	6/8/81	女	2015/8/3	高中	100000	0
苏州	苏州张家港	788590132	11/13/84	男	2015/7/14	硕士	100000	0
广东	广东广州	739385172	9/19/88	女	2015/4/9	高中	100000	0
广东	广东广州	825439522	7/10/76	女	2015/8/3	大专	100000	0
天津	天津滨海新区	5120805922	10/8/85	女	2016/4/13	中专	100000	0
黑龙江	黑龙江哈尔滨	677205102	10/5/84	女	2014/10/16	中专	100000	0
苏州	苏州张家港	5130221222	9/5/70	女	2016/4/29	高中	100000	0
青岛	青岛一支	5084474402	10/31/81	女	2016/3/10	本科	100000	0
广西	广西百色	5157119232	8/13/84	女	2016/6/1	高中	100000	0
山西	山西运城	5068218282	9/5/93	男	2016/2/25	高中	100000	0
福建	福建龙岩	5007522592	2/11/94	男	2015/10/30	本科	100000	0
湖南	湖南益阳	5068602682	4/16/82	女	2016/2/25	中专	100000	0
山西	山西朔州	5005257112	8/30/81	女	2015/10/25	高中	100000	0
江苏	江苏镇江	790091882	10/28/59	女	2015/7/15	大专	100000	0
广东	广东珠海	787721872	1/4/80	女	2015/7/13	高中	100000	0
河北	河北秦皇岛	477007982	2/5/83	男	2012/9/3	大专	100000	0
广西	广西南宁	619091492	7/13/86	女	2014/3/27	本科	100000	0
四川	四川高新	544863602	1/11/81	女	2013/6/22	本科	100000	0
山东	山东日照	870103902	9/27/79	男	2015/9/10	大专	100000	0
黑龙江	黑龙江鸡西	741606262	11/11/72	女	2015/4/10	大专	100000	0
苏州	苏州张家港	5066721602	11/4/75	女	2016/2/18	大专	100000	0
江西	江西新余	5150028262	11/26/72	女	2016/5/27	本科	100000	0
湖北	湖北鄂州	738425082	7/12/86	女	2015/4/9	本科	100000	0
辽宁	辽宁本溪	5137243302	1/23/78	女	2016/5/12	高中	100000	0
广西	广西南宁	760167732	9/2/76	女	2015/6/3	中专	100000	0
辽宁	辽宁本溪	695719122	11/10/63	男	2014/12/22	高中	100000	0
广西	广西北海	873819632	4/22/89	女	2015/9/18	中专	100000	0
四川	四川乐山	682619652	2/1/77	女	2014/11/14	中专	100000	0
陕西	陕西西安	237249942	1/19/64	女	2008/7/1	高中	99804.59	5
河北	河北保定	5207285472	5/6/70	女	2016/8/6	大专	99781	19
四川	四川青羊	3623422	9/21/79	女	2003/4/14	本科	99673.06	11
黑龙江	黑龙江牡丹江	342895922	6/5/71	男	2009/11/14	中专	99611.7	1
重庆	重庆渝中	5179357642	2/3/83	女	2016/6/21	大专	99584.1	15
四川	四川高新	4860832	3/22/78	女	2003/6/23	大专	99566.68	13
深圳	深圳宝安	554190952	1/27/82	女	2013/6/26	大专	99530.08	9
四川	四川高新	517031442	11/28/74	女	2013/4/25	大专	99529.19	17
内蒙古	内蒙古包头	593070232	11/3/77	男	2013/12/19	高中	99444	3
广东	广东广州	415488492	2/9/87	女	2011/2/14	大专	99409.37	10
浙江	浙江杭州	4808912	12/11/70	女	2003/6/20	大专	99306.36	4
山东	山东威海	26398602	7/9/69	女	2004/12/18	高中	99204.15	12
山东	山东潍坊	132806902	10/26/70	女	2007/4/25	高中	99174.8	23
浙江	浙江杭州	506074212	9/19/69	女	2013/3/11	高中	99143.5	7
黑龙江	黑龙江哈尔滨	71578412	1/27/68	女	2005/12/30	大专	99125.38	13
深圳	深圳宝安	5005955152	5/29/82	女	2015/10/26	本科	99069.76	10
辽宁	辽宁铁岭	517219302	10/17/80	女	2013/4/25	中专	99003.41	7
四川	四川内江	290108802	3/5/72	女	2009/4/1	高中	98999.2	9
新疆	新疆库尔勒	516093542	11/1/80	女	2013/4/22	本科	98972.98	12
四川	四川高新	447248802	5/24/80	女	2011/12/16	高中	98965.05	14
北京	北京海淀	819587422	1/19/78	男	2015/7/31	硕士	98889	17
江苏	江苏常州	484964082	2/29/64	女	2012/11/24	高中	98876.2	6
陕西	陕西咸阳	548466282	1/15/81	男	2013/6/25	高中	98771	10
深圳	深圳宝安	5004716862	11/28/75	女	2015/10/24	高中	98721	7
浙江	浙江杭州	795940912	4/8/70	女	2015/7/20	高中	98705	17
上海	上海黄浦	499778422	2/12/80	女	2013/1/15	本科	98599	7
大连	大连明锐	135751102	3/29/83	女	2007/5/21	大专	98538.68	3
四川	四川青羊	144947952	12/8/65	女	2007/6/25	初中	98499.63	10
北京	北京海淀	390068352	2/22/83	女	2010/8/10	大专	98481.86	7
黑龙江	黑龙江哈尔滨	135527372	9/17/77	女	2007/5/18	本科	98420.85	19
四川	四川青羊	36107812	11/23/78	女	2005/3/29	大专	98401.8	9
山东	山东济南	87583412	10/27/78	女	2006/6/21	大专	98305.83	13
黑龙江	黑龙江牡丹江	443346612	12/25/72	女	2011/11/4	中专	98225	4
山东	山东济南	153760302	10/20/78	女	2007/7/25	本科	98218.69	9
北京	北京东城	319840352	9/10/89	男	2009/8/1	中专	98169.1	2
广东	广东中山	223070222	8/24/74	女	2008/5/13	中专	98114.68	13
佛山	佛山顺德龙江	5191142692	2/20/74	男	2016/7/22	高中	98081	6
安徽	安徽合肥	638251712	12/19/80	女	2014/6/18	高中	98047.05	12
浙江	浙江温州	5029416482	10/20/70	女	2015/11/24	高中	97976.7	8
山东	山东烟台	147737092	10/16/64	女	2007/7/4	高中	97890	6
四川	四川成都	588863362	12/18/76	女	2013/12/5	高中	97870.67	12
重庆	重庆渝中	163286122	12/30/75	女	2007/9/17	本科	97837.06	9
山东	山东德州	352413232	5/27/79	女	2009/12/25	初中	97730.62	18
北京	北京东城	5033551202	2/15/74	男	2015/11/27	本科	97657	5
山东	山东烟台	78641962	1/26/73	女	2006/3/22	中专	97638.85	24
湖北	湖北十堰	5227545802	11/8/88	女	2016/8/31	大专	97633	9
浙江	浙江金华	96543282	8/16/77	女	2006/9/7	高中	97604.35	18
河南	河南洛阳	16789462	9/18/68	女	2004/8/2	高中	97576.98	4
贵州	贵州贵阳	276847162	11/1/73	女	2009/1/13	本科	97530.44	11
江西	江西南昌	507063332	3/27/76	女	2013/3/15	大专	97526.61	9
青岛	青岛即墨	151794342	6/25/70	女	2007/7/23	中专	97495.91	1
山东	山东东营	64554772	9/13/76	女	2005/11/3	大专	97494.9	3
青岛	青岛一支	5142622	5/22/70	男	2003/6/28	大专	97432	5
山东	山东济南	4971512	4/23/70	女	2003/7/1	大专	97312.81	6
深圳	深圳南山	726207822	6/2/65	女	2015/3/25	高中	97291.96	12
宁波	宁波城区	243628082	5/6/71	女	2008/8/1	大专	97267.04	6
辽宁	辽宁沈阳	5183067182	10/2/82	男	2016/6/25	硕士	97249	15
湖南	湖南永州	431968582	6/28/68	女	2011/7/28	高中	97209.62	8
湖南	湖南长沙	5032996412	7/23/76	女	2015/11/27	高中	97151.6	12
四川	四川成都	3338562	4/27/69	女	2003/3/27	高中	97122.65	5
海南	海南海口	446654522	1/18/83	女	2011/12/12	高中	97074.89	0
四川	四川高新	477749512	8/15/72	女	2012/9/11	大专	97048	6
广西	广西玉林	5168807282	9/1/83	女	2016/6/12	大专	97015.4	15
黑龙江	黑龙江哈尔滨	338913482	5/26/72	女	2009/10/26	大专	96932.4	9
湖南	湖南株洲	845298562	10/12/80	女	2015/8/10	中专	96879.5	3
深圳	深圳福田	548682472	9/29/86	女	2013/6/25	大专	96814.14	10
山东	山东潍坊	20426612	1/24/58	女	2004/10/8	高中	96805.89	18
山东	山东威海	5201885182	7/16/85	女	2016/8/2	中专	96581.5	12
四川	四川高新	5203846022	7/27/75	男	2016/8/3	硕士	96358	11
江苏	江苏泰州	329168402	4/30/64	女	2009/9/4	高中	96353.12	9
湖北	湖北襄阳	5200646892	10/24/81	男	2016/8/2	高中	96219.9	18
黑龙江	黑龙江哈尔滨	559981922	1/27/60	女	2013/6/28	大专	96186.63	11
湖北	湖北襄阳	639633302	9/23/68	女	2014/6/22	高中	96175.83	15
上海	上海黄浦	681514412	10/19/76	女	2014/11/11	高中	96160.3	17
苏州	苏州新区	497412182	2/28/70	女	2012/12/31	本科	96150.58	5
山东	山东烟台	475028072	5/2/65	男	2012/8/18	本科	96148.09	5
浙江	浙江杭州	269198812	8/10/71	女	2008/12/10	高中	96053.47	4
新疆	新疆石河子	351640302	3/14/80	女	2009/12/24	大专	96046.52	11
大连	大连明锐	5209345112	4/18/61	女	2016/8/8	大专	96021	12
上海	上海黄浦	655760562	2/5/92	男	2014/8/13	高中	95894.04	3
四川	四川青羊	650428632	11/14/72	女	2014/8/1	大专	95818.6	15
辽宁	辽宁营口	596035222	6/24/67	女	2013/12/24	中专	95817	4
苏州	苏州昆山	473952832	12/15/79	女	2012/8/7	高中	95747.6	6
广东	广东广州	638737412	10/24/82	女	2014/6/20	大专	95740.23	7
吉林	吉林四平	487337162	10/31/67	女	2012/12/4	大专	95630.1	4
河北	河北衡水	375831542	9/26/68	女	2010/5/19	大专	95592.7	9
四川	四川高新	5096543182	1/9/82	女	2016/3/16	高中	95569	7
山东	山东菏泽	364142692	4/17/70	女	2010/3/15	大专	95557.77	13
深圳	深圳宝安	5231282552	3/1/77	女	2016/9/8	高中	95526.5	8
江苏	江苏南京	455447052	6/8/79	女	2012/3/8	本科	95469.9	12
浙江	浙江绍兴	736120942	8/25/86	女	2015/4/7	本科	95447.5	8
山西	山西运城	481106272	8/16/78	女	2012/10/24	大专	95426.29	1
河北	河北唐山	51922582	10/5/54	女	2005/7/18	高中	95421.73	9
云南	云南昆明	378657522	2/1/82	男	2010/6/1	大专	95393.7	7
浙江	浙江金华	650882232	3/27/72	女	2014/8/4	大专	95374.55	9
青岛	青岛一支	103518072	5/12/60	女	2006/11/15	本科	95373.96	9
辽宁	辽宁鞍山	600708942	2/11/75	女	2013/12/30	高中	95342.9	12
大连	大连海川	551412152	10/21/55	女	2013/6/25	本科	95321.3	3
青岛	青岛一支	5125018602	11/12/78	男	2016/4/19	硕士	95319.7	16
湖南	湖南衡阳	5205793292	3/18/68	男	2016/8/5	高中	95287.3	16
河南	河南焦作	362609812	5/19/71	女	2010/3/2	高中	95257.82	4
云南	云南昆明	469996352	6/20/70	女	2012/6/25	高中	95256.42	11
四川	四川青羊	753338792	11/26/71	女	2015/5/11	大专	95106.83	13
北京	北京海淀	456621932	5/23/88	女	2012/3/14	本科	95096.47	5
四川	四川高新	459468432	8/22/85	女	2012/3/25	高中	95085.5	9
山东	山东烟台	5220037822	11/8/85	女	2016/8/19	中专	95057.5	16
上海	上海陆家嘴	5203570462	12/10/87	男	2016/8/3	高中	95023.5	13
四川	四川青羊	4844782	12/19/73	女	2003/6/23	大专	95019.67	5
浙江	浙江杭州	25036832	1/23/75	女	2004/11/30	高中	94987.05	13
四川	四川高新	8675932	12/8/70	女	2003/10/29	大专	94928.42	2
河南	河南南阳	507040442	4/25/70	女	2013/3/15	大专	94910.29	5
黑龙江	黑龙江哈尔滨	763221212	6/3/75	女	2015/6/10	本科	94887.41	11
山东	山东烟台	506767862	1/16/79	女	2013/3/14	大专	94880.2	9
山东	山东淄博	464700292	4/26/81	女	2012/5/14	大专	94785.91	17
四川	四川青羊	5218597292	5/20/88	男	2016/8/18	本科	94734	14
浙江	浙江绍兴	5154556452	11/10/60	女	2016/5/31	大专	94732.6	16
四川	四川高新	674932092	5/24/73	女	2014/9/29	本科	94690.5	15
湖南	湖南长沙	623500872	7/13/76	女	2014/4/15	本科	94663.45	8
江西	江西上饶	803194422	6/30/73	女	2015/7/26	大专	94621	2
山东	山东威海	5097559432	8/5/80	女	2016/3/17	大专	94593.8	14
山东	山东烟台	64658612	4/17/74	女	2005/11/4	中专	94590.65	19
山东	山东烟台	345866082	3/23/71	男	2009/11/25	大专	94577.62	14
广东	广东珠海	87243562	1/10/73	女	2006/6/16	大专	94572.05	10
四川	四川青羊	657561552	7/8/78	女	2014/8/18	本科	94520.96	22
浙江	浙江绍兴	464707132	9/26/67	女	2012/5/15	初中	94505.57	11
河北	河北沧州	5153855712	10/23/63	女	2016/5/30	高中	94428.7	21
山东	山东烟台	675805092	9/19/82	女	2014/10/5	大专	94387	13
湖南	湖南长沙	427070432	4/19/72	男	2011/6/16	本科	94323.82	5
上海	上海黄浦	5117294882	4/21/79	女	2016/4/6	本科	94269	10
北京	北京海淀	324269552	5/15/85	女	2009/8/17	本科	94185.97	16
湖北	湖北武汉	20867232	8/31/68	女	2004/10/15	本科	94160.08	11
湖北	湖北武汉	85274532	2/19/75	女	2006/5/24	中专	94149.9	5
山东	山东威海	344538782	9/13/74	女	2009/11/23	中专	94133.9	11
苏州	苏州昆山	659999532	7/23/73	女	2014/8/22	高中	94105.9	7
青岛	青岛一支	5032034582	3/21/79	女	2015/11/26	大专	94064.5	5
佛山	佛山顺德龙江	476289072	12/23/76	女	2012/8/30	初中	94059.37	9
辽宁	辽宁阜新	472876622	5/20/71	女	2012/7/26	本科	94058.64	6
四川	四川高新	131792522	12/27/84	女	2007/4/24	大专	94040.36	17
广东	广东湛江	702191462	6/29/88	女	2015/1/2	本科	93992.8	4
北京	北京海淀	527336332	6/25/90	女	2013/6/3	大专	93953.05	9
河北	河北保定	144027502	2/4/70	男	2007/6/25	高中	93945.7	7
山西	山西临汾	5191599582	7/1/68	女	2016/7/24	大专	93939.2	23
山东	山东泰安	281850822	4/11/75	女	2009/3/2	大专	93930.28	14
浙江	浙江杭州	12249582	11/18/80	男	2004/4/8	大专	93923.35	10
湖北	湖北武汉	488880922	2/11/91	男	2012/12/10	本科	93919.13	0
辽宁	辽宁葫芦岛	544102952	6/2/61	女	2013/6/21	大专	93886.8	0
北京	北京东城	557197952	10/8/86	男	2013/6/27	本科	93875.02	0
佛山	佛山禅城	5080375962	5/24/81	女	2016/3/8	高中	93802	18
河北	河北唐山	334361322	3/5/67	女	2009/9/26	大专	93751.23	5
广东	广东东莞	735061482	12/10/80	女	2015/4/3	大专	93732.4	16
云南	云南昆明	228354972	12/15/70	女	2008/6/1	中专	93700.53	6
深圳	深圳南山	5188369572	12/7/79	男	2016/7/12	高中	93605.6	17
浙江	浙江衢州	5199211832	5/29/74	女	2016/8/1	高中	93562.3	7
北京	北京东城	74730562	3/10/79	女	2006/2/5	大专	93546.29	11
河南	河南郑州	5160008392	8/1/80	男	2016/6/2	大专	93545.5	17
福建	福建厦门	141435162	8/4/75	女	2007/6/19	高中	93486.36	24
湖北	湖北宜昌	768699962	7/19/76	女	2015/6/23	本科	93477	13
北京	北京良乡	5207101442	2/11/85	女	2016/8/6	大专	93463	16
山西	山西阳泉	322746582	4/18/62	女	2009/8/6	大专	93452.88	4
江苏	江苏徐州	767181462	3/5/77	女	2015/6/19	高中	93427	2
福建	福建泉州	91697052	7/12/72	女	2006/7/24	高中	93392.69	8
北京	北京东城	569442842	9/13/85	女	2013/7/15	本科	93330.44	19
大连	大连海川	5367052	4/8/63	女	2003/7/1	大专	93323.98	4
浙江	浙江杭州	398293532	11/20/89	男	2010/9/28	大专	93234.42	5
福建	福建漳州	5032428952	1/4/60	男	2015/11/26	高中	93171	11
大连	大连明锐	5111716932	12/22/87	女	2016/3/25	本科	93131.5	20
四川	四川高新	655901942	9/24/74	女	2014/8/13	本科	93123.11	5
佛山	佛山顺德龙江	473792332	1/5/83	女	2012/8/6	高中	93121.24	6
江西	江西上饶	5148744622	11/8/75	女	2016/5/26	高中	93120	1
甘肃	甘肃兰州	243823752	7/11/74	女	2008/8/1	本科	93108.1	8
新疆	新疆库尔勒	629744742	8/12/69	女	2014/5/21	中专	93094.09	12
黑龙江	黑龙江佳木斯	501825332	9/21/61	女	2013/1/29	高中	93056	6
云南	云南昆明	5188833732	5/26/78	女	2016/7/14	高中	93053	10
湖北	湖北武汉	5030019322	11/5/83	女	2015/11/25	大专	92982	10
辽宁	辽宁丹东	465895692	11/30/72	女	2012/5/25	大专	92973	5
福建	福建厦门	869942702	6/1/77	男	2015/9/10	高中	92946	12
浙江	浙江杭州	391283572	11/11/67	女	2010/8/18	大专	92923.22	2
广东	广东珠海	7107882	3/17/66	女	2003/9/5	大专	92852.91	9
佛山	佛山顺德龙江	637009832	10/2/86	女	2014/6/16	高中	92844.5	4
青岛	青岛开发区	508306882	8/3/76	女	2013/3/21	大专	92837.66	14
湖北	湖北十堰	309086332	12/14/81	女	2009/6/25	本科	92752.03	13
辽宁	辽宁丹东	432215202	10/3/65	女	2011/8/1	高中	92698.99	3
山东	山东日照	172617242	12/2/79	女	2007/10/25	本科	92656.6	14
重庆	重庆渝中	5201038492	2/27/75	男	2016/8/2	大专	92654	21
山东	山东淄博	629855422	12/15/70	女	2014/5/22	高中	92610.18	7
四川	四川青羊	292768142	9/23/83	女	2009/4/15	本科	92589.49	17
福建	福建泉州	91538222	8/15/74	女	2006/7/24	大专	92581.29	6
黑龙江	黑龙江牡丹江	120111212	1/16/79	男	2007/2/14	中专	92569.53	2
浙江	浙江温州	637962112	7/5/80	女	2014/6/18	本科	92483.88	2
重庆	重庆渝中	426365472	8/18/81	女	2011/6/8	本科	92441	14
福建	福建龙岩	589269782	5/24/79	女	2013/12/7	大专	92347.97	18
陕西	陕西西安	669299252	3/22/81	女	2014/9/16	本科	92346.5	10
重庆	重庆渝中	227137382	12/21/72	男	2008/6/1	大专	92309.94	17
北京	北京海淀	1918732	6/27/66	男	2002/9/17	大专	92247.46	2
四川	四川青羊	599340062	3/7/83	女	2013/12/27	大专	92242.4	6
宁波	宁波象山	5196663522	3/27/86	女	2016/7/29	本科	92237	14
湖北	湖北宜昌	5031497932	11/19/79	女	2015/11/25	中专	92225.5	17
辽宁	辽宁沈阳	5177257152	9/10/79	女	2016/6/21	中技	92217	11
四川	四川绵阳	82790772	9/11/74	女	2006/4/25	大专	92183.97	4
深圳	深圳福田第二	5228670822	3/3/74	女	2016/9/2	本科	92176	8
浙江	浙江杭州	727719212	10/30/79	男	2015/3/25	本科	92102.5	9
青岛	青岛一支	265418882	6/16/80	女	2008/11/24	大专	92100.37	10
天津	天津市区一支	727509162	11/1/92	女	2015/3/25	本科	92000	0
四川	四川青羊	9289062	8/16/67	女	2003/12/5	大专	91984.11	11
辽宁	辽宁本溪	625705912	2/9/72	女	2014/4/28	大专	91872.03	11
浙江	浙江金华	50410222	7/17/72	女	2005/6/30	大专	91862.85	6
重庆	重庆永川	5098238432	1/20/69	女	2016/3/17	高中	91857.5	25
湖北	湖北黄冈	545442532	9/12/77	女	2013/6/23	高中	91831.11	7
湖北	湖北武汉	633934862	7/24/79	女	2014/6/6	本科	91829	14
山东	山东济南	465902532	2/1/86	女	2012/5/25	本科	91752.3	25
黑龙江	黑龙江哈尔滨	523551932	1/2/74	女	2013/5/27	本科	91710.01	0
青岛	青岛一支	718702952	1/5/77	男	2015/3/24	大专	91691.02	13
四川	四川高新	359755502	2/28/89	男	2010/1/29	高中	91580.37	3
北京	北京东城	334473672	6/3/82	女	2009/10/1	本科	91525.1	4
山东	山东淄博	235809322	10/19/71	女	2008/6/25	高中	91504.74	10
陕西	陕西汉中	778112732	11/29/85	女	2015/6/30	大专	91494	7
佛山	佛山顺德龙江	653737242	3/9/78	女	2014/8/8	中专	91478.55	6
河北	河北唐山	519962932	11/7/79	女	2013/5/13	中专	91458.27	2
辽宁	辽宁营口	70415112	12/2/78	女	2005/12/23	初中	91450.32	9
山东	山东济南	811464682	12/16/87	女	2015/7/29	本科	91415.2	18
江西	江西南昌	514131352	4/4/63	女	2013/4/3	本科	91406.55	2
四川	四川青羊	15583452	7/2/69	女	2004/7/1	大专	91406.5	20
深圳	深圳南山	572200852	4/10/89	女	2013/8/4	中专	91278.09	6
海南	海南海口	498577852	9/21/81	女	2013/1/6	本科	91277.63	14
山东	山东烟台	5129839592	1/22/75	女	2016/4/28	中专	91233.9	9
四川	四川高新	5196345312	2/10/82	女	2016/7/29	本科	91187	16
山西	山西长治	429633742	12/5/82	女	2011/6/29	本科	91177.2	4
四川	四川青羊	584077562	11/1/90	男	2013/11/14	大专	91159.45	6
江苏	江苏连云港	241982152	6/2/72	女	2008/7/24	高中	91114.57	1
深圳	深圳福田第二	790523292	7/14/81	女	2015/7/15	大专	91091	8
辽宁	辽宁沈阳	374739282	8/20/71	女	2010/5/12	本科	91089.16	7
宁波	宁波宁海	707703712	4/3/77	女	2015/2/2	高中	91000	0
福建	福建莆田	383636622	4/14/80	女	2010/6/25	高中	90949.85	5
四川	四川绵阳	5211370342	5/30/71	女	2016/8/9	高中	90934.3	10
江西	江西赣州	5171226882	3/6/73	女	2016/6/15	大专	90916.7	14
福建	福建福州	730666752	8/10/82	女	2015/3/30	中专	90854.4	13
宁波	宁波城区	5069851912	1/15/93	女	2016/3/1	大专	90836	9
河南	河南郑州	540622262	10/21/83	女	2013/6/19	本科	90831.26	18
辽宁	辽宁沈阳	6736632	10/14/58	女	2003/8/21	大专	90811.91	12
北京	北京东城	54242	7/20/68	男	2001/12/20	大专	90774.4	1
青海	西宁	652403802	9/1/78	女	2014/8/6	中专	90746.99	6
黑龙江	黑龙江佳木斯	699047822	3/29/62	女	2014/12/26	大专	90729.7	4
四川	四川青羊	3528792	3/18/71	女	2003/4/10	大专	90724.54	2
湖北	湖北武汉	5734793212	5/12/78	女	2018/5/28	大专	90678.24	4
山东	山东日照	5205520432	11/13/80	女	2016/8/4	中专	90666	10
深圳	深圳福田	4342062	7/7/66	男	2003/5/22	本科	90640.14	2
辽宁	辽宁营口	710040922	5/12/83	女	2015/2/25	本科	90577.25	5
四川	四川青羊	569343472	5/25/85	男	2013/7/12	大专	90514.58	0
四川	四川青羊	626756862	7/25/83	女	2014/5/5	大专	90506.31	8
江苏	江苏无锡	736483682	9/17/79	女	2015/4/7	高中	90502.5	9
苏州	苏州张家港	482494972	4/13/62	女	2012/11/8	高中	90490.08	25
河北	河北邯郸	5172713592	2/18/67	女	2016/6/15	中专	90490	4
辽宁	辽宁沈阳	96343142	3/18/63	女	2006/9/6	大专	90489.07	7
山东	山东菏泽	679873922	10/1/83	女	2014/11/3	大专	90476.5	21
浙江	浙江杭州	5027552642	6/29/75	女	2015/11/23	高中	90465.6	13
河南	河南郑州	150147712	8/18/78	男	2007/7/17	大专	90416.36	8
河南	河南洛阳	316101732	3/16/65	女	2009/7/20	高中	90398.7	0
四川	四川青羊	416859472	5/1/74	女	2011/3/4	大专	90393.3	0
山西	山西长治	273291932	7/14/66	女	2009/1/1	本科	90356.34	4
安徽	安徽合肥	418718522	9/18/63	女	2011/3/1	高中	90245.28	5
浙江	浙江杭州	311507562	9/3/81	男	2009/6/29	大专	90180.7	10
辽宁	辽宁盘锦	607784502	4/5/69	女	2014/1/26	本科	90143.62	14
山东	山东烟台	543396322	11/14/81	女	2013/6/21	初中	90140	14
黑龙江	黑龙江七台河	5035937222	8/31/72	女	2015/11/30	高中	90139.9	3
黑龙江	黑龙江哈尔滨	805420952	5/5/81	男	2015/7/27	本科	90139	1
广东	广东东莞	260774192	10/29/76	女	2008/11/3	大专	90022.24	9
山东	山东潍坊	524510352	2/5/82	女	2013/5/29	大专	90000	0
黑龙江	黑龙江佳木斯	5066351742	3/3/65	男	2016/2/16	大专	90000	0
辽宁	辽宁沈阳	880929662	6/9/76	女	2015/9/30	本科	90000	0
湖南	湖南衡阳	833682152	10/4/68	女	2015/8/7	中专	89993	14
河南	河南三门峡	462401322	10/13/68	女	2012/4/21	高中	89985.27	5
四川	四川青羊	5219831082	12/11/88	女	2016/8/19	大专	89938	13
宁波	宁波宁海	731220422	11/30/75	女	2015/3/31	高中	89936	2
北京	北京东城	569286112	9/9/82	女	2013/7/11	大专	89890	10
辽宁	辽宁盘锦	295230682	6/26/76	女	2009/4/25	大专	89862.82	11
四川	四川高新	14944092	4/29/68	女	2004/6/25	本科	89859.71	4
河南	河南南阳	213697092	12/27/82	女	2008/4/1	高中	89813.6	14
山东	山东德州	264780222	2/25/64	女	2008/11/21	中专	89776.79	11
广东	广东中山	691604782	12/4/73	女	2014/12/17	大专	89654.7	10
重庆	重庆渝中	879069912	7/23/87	男	2015/9/26	本科	89649	13
四川	四川高新	573224172	3/16/82	女	2013/8/12	大专	89647.08	11
天津	天津市区一支	5206507672	9/8/81	男	2016/8/5	本科	89635	7
苏州	苏州张家港	417483482	1/27/65	女	2011/3/11	初中	89628.61	4
重庆	重庆江津	497202132	8/10/76	女	2012/12/31	高中	89617	19
山东	山东莱芜	433655822	5/27/80	男	2011/8/10	大专	89579.7	11
河南	河南南阳	513928142	10/31/69	女	2013/4/3	本科	89550.66	2
四川	四川高新	216688042	9/14/78	女	2008/4/17	大专	89548.56	10
山东	山东东营	422305152	8/8/83	女	2011/4/25	初中	89539.9	8
吉林	吉林长春	720964382	1/9/86	女	2015/3/24	本科	89489.5	7
黑龙江	黑龙江佳木斯	343933892	5/16/78	女	2009/11/19	高中	89464.95	21
浙江	浙江金华	379127172	4/2/68	男	2010/6/4	高中	89463.38	6
山东	山东临沂	90727052	11/13/77	女	2006/7/6	中专	89454.62	13
新疆	新疆昌吉	584126412	9/3/70	男	2013/11/14	高中	89377.99	13
黑龙江	黑龙江哈尔滨	174668892	5/29/55	女	2007/11/2	本科	89343.96	2
河北	河北唐山	5008806232	11/8/87	女	2015/11/3	大专	89330	8
北京	北京东城	511351152	8/7/78	女	2013/4/1	大专	89322.5	4
广东	广东汕头	148474152	11/7/78	女	2007/7/6	高中	89301.56	8
辽宁	辽宁丹东	543759062	3/21/65	女	2013/6/21	本科	89270	2
北京	北京东城	12510852	5/31/69	男	2004/4/15	大专	89269.13	3
广东	广东广州	583091512	8/25/73	女	2013/11/5	大专	89261.33	5
云南	云南昆明	282365552	9/24/81	女	2009/3/4	大专	89160.81	8
辽宁	辽宁铁岭	468277982	2/27/69	女	2012/6/18	高中	89102.08	8
浙江	浙江台州	47387172	12/2/58	女	2005/6/18	大专	89094.92	1
大连	大连明锐	637143402	6/28/80	女	2014/6/16	本科	89082.61	5
广西	广西贵港	483375512	12/15/84	女	2012/11/17	本科	89073	8
湖北	湖北荆州	694713252	10/8/76	女	2014/12/22	大专	89012.5	15
四川	四川雅安	5107785212	1/9/82	女	2016/3/23	高中	89000	0
广西	广西柳州	5205188652	10/18/81	女	2016/8/4	中专	88986.2	25
北京	北京平谷	22262772	9/4/68	女	2004/11/4	大专	88974.85	18
上海	上海陆家嘴	185781882	7/22/70	女	2007/12/1	高中	88941.5	5
山东	山东滨州	5066430602	9/10/86	女	2016/2/16	大专	88921.1	4
四川	四川青羊	21408892	8/1/75	女	2004/10/25	高中	88870.9	3
山西	山西吕梁	496661442	12/26/72	女	2012/12/31	大专	88829.36	9
四川	四川成都	5182542302	10/11/77	女	2016/6/24	大专	88799.6	3
青岛	青岛一支	279633502	5/23/78	女	2009/2/18	大专	88799.1	13
四川	四川泸州	5187898142	3/6/79	女	2016/7/8	高中	88783.2	53
福建	福建福州	5148003032	10/16/75	女	2016/5/26	本科	88743.9	15
山东	山东济南	249557362	3/26/81	女	2008/9/3	中专	88731.69	15
苏州	苏州常熟	426936862	10/14/65	女	2011/6/15	高中	88715.48	4
黑龙江	黑龙江佳木斯	136670852	3/12/72	女	2007/5/24	大专	88672.07	8
福建	福建福州	113741312	4/30/68	女	2007/1/8	高中	88612.49	13
山东	山东淄博	5187942592	3/16/81	女	2016/7/8	大专	88541	12
北京	北京海淀	515395422	9/8/89	男	2013/4/16	本科	88530	5
山东	山东烟台	648559672	8/4/78	女	2014/7/29	大专	88505	12
辽宁	辽宁丹东	5124698592	7/14/73	男	2016/4/19	高中	88486.5	20
北京	北京东城	375025812	2/7/73	女	2010/5/14	大专	88470.1	5
湖北	湖北襄阳	286999362	10/30/79	女	2009/3/23	高中	88450.99	24
佛山	佛山禅城	706912362	11/23/87	女	2015/1/27	大专	88348.28	4
山东	山东烟台	162600282	10/29/62	女	2007/9/11	高中	88232.16	6
陕西	陕西西安	381075682	4/9/80	女	2010/6/21	大专	88203.27	5
河南	河南三门峡	686045082	10/24/65	女	2014/11/20	大专	88202	12
山东	山东济南	651870652	7/17/76	女	2014/8/5	高中	88180.69	13
山东	山东烟台	5076462382	5/28/79	女	2016/3/5	大专	88175.4	17
山东	山东泰安	140462092	9/2/73	女	2007/6/17	大专	88135.98	8
黑龙江	黑龙江鹤岗	118314962	12/16/70	女	2007/2/1	大专	88129.38	8
广东	广东中山	454891982	9/4/72	女	2012/3/7	高中	88088.82	12
苏州	苏州张家港	346991142	5/20/65	女	2009/12/1	高中	87923.45	5
北京	北京海淀	130507932	1/19/85	男	2007/7/1	本科	87921.47	7
北京	北京平谷	391607372	10/20/74	女	2010/9/1	本科	87918	-1
四川	四川高新	708050402	4/12/77	男	2015/2/4	大专	87859.4	14
广东	广东广州	467456902	8/5/79	女	2012/6/7	本科	87853.79	2
山西	山西长治	5128024702	4/28/90	男	2016/4/25	高中	87804.4	19
北京	北京海淀	335678982	12/26/80	女	2009/10/7	大专	87650.39	6
福建	福建厦门	116382502	8/17/69	女	2007/1/20	中专	87592.12	11
陕西	陕西西安	220180042	6/5/77	女	2008/5/1	本科	87572.31	5
湖北	湖北武汉	21193402	5/3/70	女	2004/10/20	大专	87540.92	7
福建	福建漳州	404537402	9/20/76	女	2010/11/17	高中	87539.24	14
浙江	浙江杭州	27471742	9/15/77	女	2004/12/30	本科	87536.06	10
深圳	深圳宝安	5163641842	2/14/63	女	2016/6/6	中专	87480	11
河北	河北廊坊	5165678842	10/30/69	男	2016/6/8	中专	87476	2
山东	山东威海	5061706612	8/18/81	女	2016/1/18	中专	87460	13
福建	福建福州	434981722	2/17/73	女	2011/8/22	高中	87441.29	2
江西	江西南昌	337947252	8/23/83	女	2009/10/23	大专	87432.85	8
浙江	浙江温州	377105522	2/28/76	女	2010/5/25	中专	87376.14	9
新疆	新疆库尔勒	374423022	10/8/70	女	2010/5/10	本科	87360.09	8
云南	云南昆明	5228155912	2/23/79	女	2016/9/1	大专	87353.5	19
四川	四川青羊	318466302	3/30/86	女	2009/7/25	高中	87323.57	6
北京	北京海淀	840927422	5/22/93	男	2015/8/9	本科	87212	8
黑龙江	黑龙江大庆	173224502	5/12/72	女	2007/10/25	大专	87144.82	8
福建	福建泉州	169103052	1/30/77	女	2007/10/11	高中	87095.7	10
江苏	江苏常州	457879862	8/2/62	女	2012/3/21	高中	87043.32	13
深圳	深圳福田	158597322	1/17/62	男	2007/8/21	大专	87011	10
山东	山东烟台	684344162	7/11/73	女	2014/11/19	中专	86986.4	8
山东	山东威海	708066452	12/21/77	女	2015/2/4	高中	86969.2	4
江西	江西南昌	18919692	12/28/71	男	2004/9/16	中专	86915.78	10
江苏	江苏无锡	62851482	5/8/74	女	2005/10/26	大专	86912	9
陕西	陕西西安	146375322	1/12/70	女	2007/6/26	中专	86819.42	6
黑龙江	黑龙江黑河	482949272	4/14/72	女	2012/11/13	高中	86803.26	6
天津	天津市区二支	323737102	12/8/84	女	2009/8/12	大专	86770.04	11
山西	山西临汾	233200232	4/24/69	女	2008/6/23	高中	86763.74	9
上海	上海黄浦	379537362	11/23/70	女	2010/6/8	本科	86759.52	7
浙江	浙江温州	5210890812	8/1/88	男	2016/8/9	本科	86702.9	13
湖北	湖北武汉	370432342	3/15/80	女	2010/4/19	大专	86692.59	22
大连	大连海川	481491202	10/28/77	女	2012/10/26	本科	86661.35	9
山东	山东济南	452638792	1/14/76	女	2012/2/17	大专	86602.92	13
山东	山东德州	5206589232	10/9/66	男	2016/8/5	中专	86586.3	16
山东	山东临沂	536370312	7/21/74	女	2013/6/15	初中	86551.35	11
四川	四川自贡	5196632712	1/26/76	男	2016/7/29	高中	86516	18
深圳	深圳罗湖	284942542	3/19/75	女	2009/3/16	大专	86497.53	3
辽宁	辽宁沈阳	5048954262	6/16/66	女	2015/12/21	大专	86495	10
山东	山东威海	79360602	7/6/82	男	2006/3/24	大专	86488.19	8
甘肃	甘肃金昌	801294062	12/24/78	男	2015/7/24	大专	86481.5	2
黑龙江	黑龙江哈尔滨	253105052	12/30/69	女	2008/9/24	大专	86470.8	6
吉林	吉林白城	5032130682	3/8/64	女	2015/11/26	大专	86458	19
湖北	湖北荆州	237624962	8/9/75	女	2008/7/1	高中	86448.48	10
北京	北京良乡	118334782	4/8/66	女	2007/2/1	大专	86413.17	2
浙江	浙江杭州	364349672	10/4/87	男	2010/3/16	大专	86403	12
四川	四川青羊	38528072	7/5/75	女	2005/4/14	大专	86395.8	7
北京	北京良乡	115745512	1/15/78	女	2007/1/12	大专	86376.36	3
黑龙江	黑龙江黑河	5149999252	4/17/70	女	2016/5/27	本科	86346	16
北京	北京海淀	528953232	3/10/90	女	2013/6/6	本科	86309.65	3
山东	山东济宁	476947552	9/27/80	女	2012/9/1	中专	86302.77	7
山东	山东威海	424795052	9/29/73	女	2011/5/24	中专	86300.08	15
河南	河南郑州	138741352	12/16/71	女	2007/6/6	大专	86297.52	18
四川	四川乐山	7641732	8/11/70	女	2003/9/17	本科	86224.09	5
福建	福建莆田	196114132	4/11/77	女	2008/1/1	大专	86181.49	13
浙江	浙江杭州	650854872	7/9/74	女	2014/8/4	中专	86179.51	4
四川	四川高新	5211046732	12/20/77	男	2016/8/9	本科	86159	11
黑龙江	黑龙江佳木斯	81620632	4/12/55	女	2006/4/11	大专	86133.31	3
四川	四川高新	5066380752	11/28/67	女	2016/2/16	大专	86115	10
广西	广西桂林	291372872	12/4/63	女	2009/4/7	大专	86036.36	12
山东	山东淄博	96054512	9/30/66	女	2006/9/4	中专	85960.37	7
广东	广东广州	500354282	11/25/64	女	2013/1/21	大专	85902.48	8
四川	四川绵阳	5208730502	9/23/78	男	2016/8/8	高中	85879.5	14
苏州	苏州张家港	598507672	12/17/75	男	2013/12/26	高中	85773.2	12
四川	四川青羊	104413262	6/4/78	女	2006/11/23	大专	85758.14	2
四川	四川高新	290605112	7/22/80	女	2009/4/2	大专	85729.5	33
辽宁	辽宁营口	485573712	7/9/68	女	2012/11/25	中专	85710.19	24
河南	河南郑州	762636142	3/29/80	女	2015/6/9	高中	85647.9	19
四川	四川高新	5125753822	2/28/86	女	2016/4/21	大专	85642	7
陕西	陕西西安	398561642	11/2/76	男	2010/10/1	本科	85623.03	5
辽宁	辽宁营口	792054772	5/29/82	女	2015/7/16	大专	85579.45	16
四川	四川成都	105791082	9/5/75	女	2006/12/1	中专	85542.37	7
四川	四川成都	3297252	4/22/66	女	2003/3/25	高中	85528.31	5
湖北	湖北武汉	115294982	11/14/70	女	2007/1/12	大专	85514.95	18
山东	山东临沂	5165026082	3/3/74	女	2016/6/7	中专	85494.5	16
安徽	安徽合肥	517726492	7/27/83	女	2013/4/26	大专	85490.64	16
福建	福建厦门	609176972	6/23/79	女	2014/2/13	大专	85452.53	18
山东	山东济南	5329782	1/18/57	女	2003/7/1	高中	85448.44	9
青岛	青岛一支	292486352	4/1/78	女	2009/4/14	大专	85375.66	13
山东	山东济南	5017564342	2/13/71	男	2015/11/13	大专	85361.16	8
辽宁	辽宁沈阳	608428332	3/19/77	女	2014/1/28	高中	85349	7
辽宁	辽宁沈阳	516576172	5/9/70	女	2013/4/24	大专	85270	8
浙江	浙江金华	506542462	1/31/80	男	2013/3/13	中专	85235.92	5
苏州	苏州张家港	76463582	9/29/62	女	2006/2/27	高中	85135.19	3
河南	河南洛阳	226160542	8/27/70	女	2008/5/24	高中	85117.77	11
山东	山东烟台	581207202	5/11/82	女	2013/10/21	中专	85051.14	17
内蒙古	内蒙古通辽	696660092	7/30/76	女	2014/12/23	大专	85018	6
江苏	江苏南京	515550482	7/7/67	女	2013/4/18	大专	85010	8
青岛	青岛一支	5214177942	2/28/68	男	2016/8/12	本科	84981.5	11
山东	山东淄博	73488682	3/8/64	女	2006/1/13	高中	84967.11	16
湖北	湖北恩施	5215824172	2/9/71	女	2016/8/15	高中	84945.8	17
山东	山东淄博	651863812	2/19/76	女	2014/8/5	中专	84910.11	4
福建	福建福州	138947632	10/31/78	女	2007/6/6	本科	84891.72	6
广西	广西桂林	526287752	1/29/68	女	2013/6/3	大专	84872.29	9
广东	广东东莞	5204225922	4/9/79	男	2016/8/3	中专	84859.7	16
浙江	浙江杭州	18588082	2/21/70	女	2004/9/8	高中	84801.45	9
湖南	湖南邵阳	260130362	5/5/61	女	2008/11/1	高中	84780.93	3
河北	河北保定	497959982	8/12/57	女	2013/1/1	大专	84773.41	20
云南	云南昆明	213530722	6/20/68	女	2008/4/1	高中	84765.29	7
福建	福建龙岩	758061092	1/22/85	男	2015/5/27	中专	84764.5	18
黑龙江	黑龙江哈尔滨	370518732	10/5/76	男	2010/4/19	高中	84725.56	2
辽宁	辽宁朝阳	385081712	11/27/56	女	2010/7/1	大专	84719.86	12
安徽	安徽合肥	5194875842	9/20/78	男	2016/7/28	高中	84638.3	6
山东	山东淄博	143307192	2/13/60	女	2007/6/23	本科	84594.36	9
河南	河南周口	293069052	8/14/66	男	2009/4/17	高中	84590	16
福建	福建福州	83636142	12/21/77	女	2006/4/30	大专	84577.48	9
苏州	苏州昆山	5204654702	10/4/71	女	2016/8/4	大专	84539.5	17
广西	广西梧州	599970212	12/10/76	女	2013/12/29	中专	84501.1	3
苏州	苏州昆山	159223702	1/6/75	女	2007/8/24	高中	84477.69	10
山东	山东临沂	5198984252	7/3/74	男	2016/8/1	高中	84430	15
深圳	深圳南山	5205017292	7/30/87	男	2016/8/4	大专	84339.4	11
山东	山东烟台	5205215862	12/20/81	女	2016/8/4	中专	84323.6	12
佛山	佛山顺德龙江	8566652	3/30/74	女	2003/10/1	中专	84309.26	13
苏州	苏州常熟	425260232	8/15/62	女	2011/5/26	高中	84122.89	6
黑龙江	黑龙江七台河	586579742	2/24/75	男	2013/11/27	本科	84099.2	1
湖北	湖北武汉	5072203452	1/15/80	女	2016/3/2	本科	84094	8
四川	四川青羊	533201412	5/9/83	女	2013/6/13	大专	84048.15	16
北京	北京海淀	317176272	6/29/86	男	2009/8/1	本科	84041.51	5
山东	山东威海	5145992342	6/25/78	女	2016/5/25	中专	84040.1	12
佛山	佛山顺德龙江	179787702	12/26/74	女	2007/11/14	高中	84004.48	10
重庆	重庆江津	440587902	10/10/68	女	2011/9/30	大专	84001.64	11
大连	大连开发区	236359222	8/25/71	女	2008/6/25	大专	83965.77	0
辽宁	辽宁葫芦岛	739928232	8/22/84	男	2015/4/9	本科	83930.64	1
湖北	湖北武汉	5110850232	7/2/79	女	2016/3/25	大专	83904	9
四川	四川青羊	14720362	1/14/82	女	2004/6/21	本科	83880.52	9
苏州	苏州张家港	5095297622	8/3/74	女	2016/3/15	高中	83831	9
浙江	浙江杭州	430301162	6/2/76	女	2011/7/8	高中	83822.97	4
辽宁	辽宁本溪	5064674632	8/9/77	女	2016/2/2	中专	83800	1
四川	四川青羊	660802	12/16/64	女	2002/2/25	本科	83790.82	5
北京	北京东城	3933542	7/15/68	女	2003/5/1	本科	83768.08	1
天津	天津滨海新区	576804932	1/25/71	女	2013/9/5	大专	83749	5
北京	北京海淀	291572042	6/15/84	女	2009/4/8	本科	83698.19	1
山东	山东临沂	18819622	3/27/72	女	2004/9/1	高中	83689.26	7
河南	河南周口	5198026022	10/17/80	女	2016/8/1	大专	83673.4	14
广东	广东珠海	296632092	7/4/67	女	2009/5/4	大专	83666.46	12
云南	云南昆明	753989462	10/30/71	女	2015/5/14	本科	83663	15
湖北	湖北武汉	876387412	9/8/84	男	2015/9/22	大专	83653	8
云南	云南昆明	5151106132	4/26/84	女	2016/5/28	大专	83600	14
苏州	苏州张家港	134709362	9/30/75	女	2007/5/11	大专	83565.76	2
北京	北京良乡	286171442	9/7/59	女	2009/4/1	高中	83512.32	9
苏州	苏州昆山	658261072	9/26/79	女	2014/8/19	中专	83476.05	2
辽宁	辽宁沈阳	761351552	2/5/80	女	2015/6/5	大专	83451.46	10
浙江	浙江金华	638446412	9/23/69	女	2014/6/19	高中	83413.9	6
青岛	青岛开发区	349898072	10/3/63	女	2009/12/15	大专	83394.27	11
山东	山东滨州	328005102	3/17/76	女	2009/8/29	大专	83375.3	13
江西	江西南昌	5032389972	7/1/83	女	2015/11/26	高中	83367.5	11
安徽	安徽淮南	51103872	1/1/59	女	2005/6/1	大专	83358.5	9
辽宁	辽宁沈阳	5008957652	2/11/79	女	2015/11/3	本科	83344.5	13
福建	福建福州	71069822	11/14/62	女	2005/12/29	大专	83313.13	5
青岛	青岛一支	58947892	9/4/75	女	2005/9/19	本科	83303.66	17
北京	北京海淀	526893342	1/13/90	女	2013/6/3	本科	83290	8
江西	江西南昌	425134202	12/19/58	女	2011/5/25	高中	83289.3	5
湖北	湖北武汉	207326492	10/29/81	女	2008/3/14	中专	83244.85	6
福建	福建福州	362559292	6/19/82	女	2010/3/2	大专	83233.44	6
河南	河南三门峡	449008482	9/29/73	女	2011/12/25	高中	83222.07	7
辽宁	辽宁沈阳	5449672	6/20/54	女	2003/7/1	大专	83151	7
江西	江西南昌	532971542	8/9/67	女	2013/6/13	大专	83146.75	4
黑龙江	黑龙江哈尔滨	210968112	7/23/70	女	2008/3/25	大专	83097.83	4
青岛	青岛一支	807662562	8/1/72	女	2015/7/28	硕士	83063.2	20
安徽	安徽合肥	77666522	10/8/71	女	2006/3/1	大专	83055.39	10
陕西	陕西汉中	486527662	9/22/75	女	2012/12/1	本科	83037.87	10
北京	北京海淀	571932742	8/21/90	男	2013/8/1	大专	82990	1
湖北	湖北十堰	611285712	9/5/79	女	2014/3/5	本科	82890.38	13
四川	四川巴中	758929622	3/9/78	女	2015/5/29	中专	82837	16
苏州	苏州常熟	495202672	6/18/78	男	2012/12/26	初中	82824.91	3
江西	江西赣州	812711032	4/1/77	女	2015/7/29	高中	82809	14
四川	四川高新	141660562	10/24/80	男	2007/6/20	本科	82778.3	19
北京	北京平谷	373519592	11/7/68	女	2010/5/5	高中	82752	12
河南	河南平顶山	16158612	3/21/56	男	2004/7/20	大专	82723.52	9
湖北	湖北荆州	588067542	7/8/64	男	2013/12/3	高中	82719.4	23
四川	四川青羊	672201712	7/11/76	女	2014/9/24	高中	82687	14
山西	山西太原	731760412	11/20/76	女	2015/3/31	中专	82649.5	5
四川	四川青羊	553333032	10/10/86	女	2013/6/26	高中	82618.1	8
宁波	宁波象山	226053632	1/15/70	女	2008/5/23	中专	82501.64	7
苏州	苏州姑苏	29084872	2/21/78	女	2005/1/14	中专	82447.02	8
四川	四川高新	432285542	8/26/80	女	2011/8/1	大专	82416.36	16
苏州	苏州昆山	5217735162	12/14/87	女	2016/8/17	大专	82354.3	15
辽宁	辽宁丹东	634685602	6/23/72	女	2014/6/10	大专	82351.58	8
浙江	浙江杭州	313628582	1/12/83	女	2009/7/8	大专	82325.45	7
四川	四川高新	833939652	10/30/87	男	2015/8/7	硕士	82240.5	9
四川	四川高新	28950332	7/9/79	女	2005/1/12	大专	82220.09	7
四川	四川高新	312375122	12/6/84	男	2009/7/1	本科	82218.93	23
苏州	苏州吴江	714683942	4/5/82	女	2015/3/20	中专	82217	5
上海	上海陆家嘴	710167652	2/9/77	女	2015/2/26	本科	82206	9
青岛	青岛一支	684892662	2/12/86	女	2014/11/19	本科	82160	8
山东	山东日照	518810242	8/15/85	女	2013/5/3	中专	82126.5	16
安徽	安徽合肥	260756742	1/28/81	女	2008/11/1	大专	82080.25	2
山东	山东烟台	71284342	1/13/80	女	2005/12/29	大专	82056.32	4
安徽	安徽池州	860540542	6/15/76	女	2015/8/17	高中	82046.9	10
重庆	重庆永川	5154484862	12/20/85	女	2016/5/31	中专	81998	21
湖北	湖北武汉	5198642502	5/6/85	男	2016/8/1	高中	81981	19
苏州	苏州昆山	5098822232	4/24/75	女	2016/3/18	高中	81951	19
山东	山东济南	679256752	9/12/82	女	2014/10/29	大专	81941	15
青岛	青岛城阳	5171303942	6/23/64	女	2016/6/15	大专	81940.5	10
河北	河北廊坊	646585202	5/20/64	女	2014/7/18	高中	81936	2
河北	河北廊坊	47145022	12/24/61	女	2005/6/13	高中	81835.1	10
广西	广西南宁	506982382	4/2/77	女	2013/3/15	大专	81820.68	6
广东	广东中山	251226182	7/17/73	女	2008/9/12	大专	81808.69	14
辽宁	辽宁辽阳	5042009242	12/29/57	女	2015/12/5	中专	81798.33	4
浙江	浙江杭州	556349242	12/16/77	女	2013/6/27	高中	81786.64	16
天津	天津蓟州	124511382	6/6/73	女	2007/3/21	高中	81785.41	15
江苏	江苏镇江	85113332	6/14/70	女	2006/5/23	初中	81784.85	10
青岛	青岛一支	5218728772	12/26/69	女	2016/8/18	大专	81722.5	8
福建	福建福州	401724402	11/16/68	女	2010/10/25	高中	81710	13
贵州	贵州贵阳	5159069202	10/31/77	女	2016/6/2	大专	81663.13	11
苏州	苏州张家港	5164847522	10/7/83	女	2016/6/7	高中	81651.5	18
四川	四川青羊	119985182	4/16/79	女	2007/2/14	本科	81627.71	13
深圳	深圳宝安	107488232	2/27/71	女	2006/12/14	大专	81609.11	2
湖北	湖北武汉	35567822	8/2/74	男	2005/3/25	大专	81557.21	16
辽宁	辽宁本溪	625710382	9/28/69	男	2014/4/28	大专	81549	2
山东	山东临沂	831941592	12/25/75	女	2015/8/6	中专	81536.98	13
苏州	苏州姑苏	256264042	12/21/79	女	2008/10/10	初中	81504.95	2
天津	天津市区一支	23964392	9/25/66	女	2004/11/26	大专	81499.53	9
山东	山东泰安	431115402	5/4/81	女	2011/7/22	高中	81414.09	9
重庆	重庆渝中	5207523922	5/18/76	女	2016/8/6	高中	81403	22
天津	天津市区二支	22560612	5/29/57	男	2004/11/10	大专	81402.98	7
山东	山东济南	5164319942	3/16/76	男	2016/6/7	本科	81401	6
深圳	深圳宝安	729246922	6/22/82	女	2015/3/27	高中	81395.04	9
河北	河北石家庄	5162798752	8/21/68	男	2016/6/6	大专	81366	8
湖北	湖北襄阳	5018123702	4/2/72	女	2015/11/13	高中	81350	1
黑龙江	黑龙江牡丹江	684299082	11/7/79	女	2014/11/19	大专	81341	1
重庆	重庆渝中	498771852	10/11/83	女	2013/1/7	本科	81337.77	9
山东	山东泰安	5168318682	11/19/77	女	2016/6/12	高中	81336	10
黑龙江	黑龙江大庆	458210502	5/27/82	女	2012/3/22	大专	81325	8
深圳	深圳南山	321757462	4/12/81	女	2009/8/3	本科	81303.63	7
北京	北京东城	572473432	9/16/89	男	2013/8/6	本科	81299.83	0
四川	四川高新	5144102192	12/11/76	女	2016/5/23	大专	81270	6
安徽	安徽合肥	23242682	1/4/64	女	2004/11/1	高中	81193.59	16
青岛	青岛一支	558812482	10/8/77	女	2013/6/28	中专	81137.73	8
上海	上海黄浦	5063362882	10/21/84	女	2016/1/28	本科	81094	8
四川	四川青羊	5113053122	10/17/72	女	2016/3/28	大专	81092	6
宁波	宁波象山	5199675022	11/18/75	男	2016/8/1	高中	81071.5	12
湖北	湖北武汉	262519492	8/7/79	女	2008/11/11	大专	81049.14	9
河南	河南郑州	491457912	10/28/69	女	2012/12/19	本科	81044.06	14
广西	广西南宁	281158842	8/3/75	男	2009/2/25	大专	81010.43	13
四川	四川内江	65945572	10/7/76	女	2005/11/21	中专	81001.1	13
云南	云南昆明	380083492	10/23/68	女	2010/6/11	大专	80975.45	12
山东	山东枣庄	285233542	5/10/73	女	2009/3/17	大专	80925.91	14
山东	山东淄博	5209643382	7/19/70	女	2016/8/8	高中	80913	10
广东	广东广州	5187777602	12/20/86	女	2016/7/7	中专	80883.9	12
吉林	吉林长春	164004062	10/3/66	女	2007/9/19	大专	80874.5	12
黑龙江	黑龙江齐齐哈尔	45508302	10/16/57	女	2005/6/1	大专	80869.8	7
河南	河南开封	290177472	5/27/66	女	2009/4/1	大专	80851.12	10
黑龙江	黑龙江哈尔滨	625665302	5/15/85	女	2014/4/28	本科	80845.83	1
云南	云南昆明	148656842	8/8/76	女	2007/7/1	本科	80840.18	2
福建	福建龙岩	5003223782	12/13/77	女	2015/10/21	大专	80833.5	9
湖北	湖北十堰	5172695452	8/14/82	女	2016/6/15	大专	80823	4
四川	四川绵阳	5180735582	5/12/86	女	2016/6/23	高中	80796.5	17
山西	山西长治	273298772	9/29/54	男	2009/1/1	大专	80779	7
重庆	重庆渝中	749833812	11/21/78	女	2015/4/28	本科	80763.8	10
深圳	深圳福田	17716752	4/26/67	女	2004/8/24	本科	80729.13	5
安徽	安徽芜湖	337625822	10/3/66	女	2009/10/1	高中	80701.61	7
云南	云南昆明	5234543372	11/5/81	女	2016/9/18	本科	80701	8
河南	河南商丘	717922212	7/8/75	男	2015/3/23	高中	80693.8	9
四川	四川南充	76362812	12/29/70	女	2006/2/25	高中	80646.84	5
湖南	湖南长沙	5154642582	8/3/63	男	2016/5/31	本科	80627	7
福建	福建泉州	239149332	5/19/76	女	2008/7/6	高中	80621.11	13
浙江	浙江温州	703644092	4/6/82	男	2015/1/10	高中	80606.1	5
青岛	青岛一支	5031736312	12/11/78	女	2015/11/26	本科	80539	11
深圳	深圳宝安	72851692	11/22/80	女	2006/1/4	高中	80497.39	11
江苏	江苏泰州	99538972	1/17/65	女	2006/10/1	大专	80478.01	5
山西	山西临汾	255753082	11/20/68	女	2008/10/7	高中	80462.3	8
山东	山东烟台	538978702	4/21/83	女	2013/6/18	中专	80432.53	7
辽宁	辽宁本溪	187279592	8/4/56	女	2007/12/5	大专	80379.41	3
江西	江西南昌	451034872	1/6/80	女	2012/1/8	高中	80349.61	4
山东	山东菏泽	5215158812	6/8/78	女	2016/8/14	高中	80326	5
江苏	江苏南京	664935922	12/10/75	男	2014/8/30	大专	80259	3
山东	山东济南	10792212	11/22/76	女	2004/3/1	中专	80226.59	10
山东	山东济宁	183896872	9/16/77	女	2007/11/24	中专	80210.45	12
深圳	深圳宝安	5115086522	2/20/78	女	2016/3/31	大专	80168	6
江西	江西南昌	103656382	8/4/65	男	2006/11/1	大专	80167.22	5
黑龙江	黑龙江牡丹江	427167432	10/5/73	女	2011/6/17	大专	80085.18	9
北京	北京东城	5113955132	4/15/77	女	2016/3/29	本科	80043.91	10
重庆	重庆巴南	580140202	1/15/72	女	2013/9/30	高中	80011	8
广东	广东广州	5210926122	10/17/83	女	2016/8/9	高中	80008	5
湖南	湖南郴州	829705152	3/16/89	女	2015/8/5	高中	80000	0
北京	北京良乡	5099597332	8/24/63	女	2016/3/18	大专	80000	0
辽宁	辽宁本溪	5069695022	4/16/70	女	2016/2/29	大专	80000	0
四川	四川高新	751202422	3/2/92	女	2015/4/30	本科	80000	0
河南	河南濮阳	546681342	1/3/87	女	2013/6/24	高中	80000	0
天津	天津蓟州	5096151582	3/10/80	女	2016/3/16	大专	79950	1
广西	广西南宁	346020442	4/12/81	女	2009/11/26	大专	79802.11	7
山东	山东烟台	597047232	10/18/63	女	2013/12/25	大专	79798.83	3
山东	山东德州	627042422	4/20/67	女	2014/5/6	高中	79795	4
山西	山西运城	5110141322	3/1/77	女	2016/3/24	高中	79778.5	7
大连	大连明锐	550371112	5/6/53	男	2013/6/25	大专	79765.5	1
福建	福建泉州	5007210752	3/22/78	女	2015/10/30	高中	79762	10
山东	山东临沂	831544382	4/2/88	女	2015/8/6	中专	79758.5	26
四川	四川青羊	116746912	1/12/82	男	2007/1/22	本科	79747.55	9
上海	上海陆家嘴	249123582	5/15/78	女	2008/9/1	高中	79729.2	4
广东	广东中山	696141592	8/18/79	女	2014/12/23	大专	79727.3	7
北京	北京海淀	571230852	8/8/91	女	2013/7/31	高中	79701.6	2
天津	天津市区二支	848338362	4/12/80	女	2015/8/10	本科	79653.5	17
深圳	深圳福田第二	409943442	6/3/92	女	2010/12/20	中专	79621.43	12
广东	广东中山	681551952	1/30/82	女	2014/11/12	硕士	79610	4
佛山	佛山禅城	95383052	6/4/71	男	2006/8/30	大专	79594.74	6
山东	山东潍坊	671575332	3/29/87	女	2014/9/23	中专	79584	13
重庆	重庆渝中	522765052	12/22/89	女	2013/5/24	大专	79571.02	10
陕西	陕西西安	237233892	11/28/69	男	2008/7/1	高中	79547.85	1
青岛	青岛一支	550452062	6/28/81	女	2013/6/25	本科	79507.86	9
黑龙江	黑龙江哈尔滨	412034732	4/20/76	女	2010/12/29	大专	79479.73	11
湖北	湖北武汉	498487692	11/8/77	男	2013/1/5	本科	79471.05	5
山东	山东济南	5144038772	7/16/81	女	2016/5/23	中专	79463.3	10
山东	山东日照	769109182	3/2/76	女	2015/6/23	中专	79440	8
广东	广东惠州	9361772	8/10/70	女	2003/12/12	大专	79398.95	4
四川	四川内江	262475112	7/15/68	女	2008/11/10	中专	79349.88	8
四川	四川青羊	151147442	3/1/70	女	2007/7/20	本科	79300.94	8
北京	北京海淀	689035332	11/30/77	女	2014/12/5	大专	79290.5	19
重庆	重庆永川	755094702	12/15/76	女	2015/5/19	大专	79286	17
黑龙江	黑龙江哈尔滨	492165942	9/26/66	女	2012/12/20	大专	79273	4
湖北	湖北武汉	5015286192	10/10/73	女	2015/11/10	高中	79272.8	20
云南	云南昆明	380085862	12/17/66	女	2010/6/11	本科	79240.57	6
北京	北京平谷	5205904832	8/31/86	男	2016/8/5	本科	79221	21
上海	上海陆家嘴	5120820392	6/8/76	男	2016/4/13	高中	79215.3	10
山东	山东淄博	5177680532	3/22/86	男	2016/6/21	大专	79182.3	10
广东	广东惠州	5203392802	2/1/57	女	2016/8/3	大专	79168.5	6
安徽	安徽合肥	483169232	8/17/81	男	2012/11/15	大专	79153.55	25
河南	河南郑州	866071912	7/16/77	女	2015/9/2	本科	79145.6	11
辽宁	辽宁沈阳	605350292	7/21/73	女	2014/1/11	高中	79144.14	3
浙江	浙江绍兴	5146242562	4/12/62	女	2016/5/25	大专	79117	5
云南	云南昆明	5002202062	5/20/78	女	2015/10/16	大专	79090	1
四川	四川德阳	26726172	11/12/73	女	2004/12/22	高中	79060.53	8
四川	四川高新	5044923812	2/5/86	女	2015/12/12	大专	79050.3	7
山东	山东淄博	272907702	11/8/72	女	2008/12/25	高中	79041.29	6
四川	四川雅安	5189152842	6/7/77	男	2016/7/15	大专	79024.2	13
大连	大连海川	496005332	1/7/79	男	2012/12/28	本科	79020	2
广西	广西南宁	5114339532	11/6/80	女	2016/3/30	中专	79009.4	6
陕西	陕西汉中	818778622	4/24/86	女	2015/7/31	大专	79000	0
湖北	湖北孝感	556773112	7/8/76	男	2013/6/27	大专	78910	7
山东	山东临沂	268142422	5/7/63	女	2008/12/3	大专	78870.65	9
山东	山东枣庄	395903702	5/4/66	男	2010/9/16	高中	78869.44	7
湖北	湖北襄阳	5078972612	12/3/76	女	2016/3/7	高中	78867	2
福建	福建龙岩	257358672	6/2/76	女	2008/10/17	高中	78837.38	9
辽宁	辽宁沈阳	5008257812	8/22/80	男	2015/11/2	本科	78833	21
江西	江西萍乡	708229052	10/1/74	女	2015/2/5	本科	78829.5	8
浙江	浙江台州	251086472	3/13/68	女	2008/9/11	高中	78828.48	9
四川	四川青羊	483775792	9/13/79	女	2012/11/20	中专	78815.79	6
四川	四川青羊	177734652	9/13/69	女	2007/11/8	大专	78803.29	8
江苏	江苏常州	18111592	10/13/67	女	2004/8/31	高中	78794.07	8
湖北	湖北黄冈	836632762	5/10/69	女	2015/8/8	高中	78770	3
四川	四川青羊	5196424172	5/29/80	女	2016/7/29	大专	78754	9
湖南	湖南长沙	5174719782	5/22/64	男	2016/6/17	本科	78747.7	15
四川	四川高新	192911732	2/14/79	女	2007/12/24	高中	78707.57	1
北京	北京良乡	5205790592	5/6/88	男	2016/8/5	大专	78698	6
深圳	深圳福田	4510102	3/22/69	女	2003/6/3	本科	78689.77	5
湖北	湖北十堰	5019741892	3/20/73	女	2015/11/16	高中	78619.5	15
黑龙江	黑龙江绥化	432504802	1/9/74	女	2011/8/2	高中	78612.93	3
山东	山东烟台	5216530382	9/22/88	男	2016/8/15	大专	78612	8
湖北	湖北黄石	93041372	4/27/79	女	2006/8/8	高中	78504.4	17
黑龙江	黑龙江大庆	593889912	2/25/78	女	2013/12/20	本科	78504	9
浙江	浙江杭州	790390422	10/27/94	男	2015/7/15	大专	78485	3
重庆	重庆渝中	225213432	1/6/77	女	2008/5/22	本科	78460.41	18
福建	福建厦门	5103836322	9/30/90	女	2016/3/22	本科	78448	6
江西	江西赣州	363688122	5/1/71	女	2010/3/15	初中	78442.91	6
辽宁	辽宁沈阳	668446072	3/3/83	女	2014/9/11	大专	78436.4	11
辽宁	辽宁丹东	21817412	10/31/58	女	2004/10/29	中专	78405.33	4
湖北	湖北宜昌	467908132	2/5/80	女	2012/6/13	大专	78402.69	14
云南	云南昆明	864513772	10/6/83	女	2015/8/28	高中	78384.1	2
大连	大连银洲	623450352	6/3/87	男	2014/4/15	本科	78339.3	0
浙江	浙江湖州	327233842	10/30/70	女	2009/8/27	初中	78316.71	2
黑龙江	黑龙江黑河	629790522	11/1/78	女	2014/5/22	大专	78309.45	0
四川	四川青羊	380722852	6/9/84	女	2010/6/18	本科	78264.6	12
辽宁	辽宁沈阳	311844342	12/12/70	女	2009/6/30	大专	78262.86	3
佛山	佛山顺德龙江	443345912	5/1/76	女	2011/11/4	大专	78254.59	6
河南	河南郑州	5207142222	6/2/78	男	2016/8/6	大专	78250.5	9
山东	山东临沂	709363322	9/20/92	男	2015/2/13	大专	78205.1	28
黑龙江	黑龙江齐齐哈尔	353766762	10/25/60	女	2010/1/1	大专	78183.36	4
湖北	湖北襄阳	625553922	7/10/86	女	2014/4/28	中专	78170.2	23
黑龙江	黑龙江齐齐哈尔	712706402	6/23/72	女	2015/3/17	高中	78153	2
重庆	重庆渝中	5161288432	9/10/74	女	2016/6/3	高中	78149.5	23
深圳	深圳宝安	588087362	5/5/74	女	2013/12/3	大专	78072.75	14
山东	山东济南	5074011072	4/29/82	女	2016/3/4	大专	78063.1	7
浙江	浙江杭州	710678612	8/31/81	女	2015/2/28	大专	78063	8
重庆	重庆江津	5092333202	9/14/72	女	2016/3/14	高中	78047	8
深圳	深圳福田	5005515502	8/29/78	女	2015/10/26	大专	78034.29	4
上海	上海黄浦	4176392	6/26/60	女	2003/5/31	大专	78020.21	7
山东	山东潍坊	651018172	2/18/70	女	2014/8/4	中专	78013.17	11
深圳	深圳福田	5153977152	10/12/69	女	2016/5/30	高中	78000	0
深圳	深圳福田第二	299224432	4/17/82	女	2009/5/13	本科	77896	10
天津	天津滨海新区	580086882	7/13/80	女	2013/9/30	大专	77850.62	7
河南	河南郑州	28916832	11/17/72	女	2005/1/11	大专	77840.2	13
北京	北京良乡	462612072	6/7/77	女	2012/5/1	高中	77829.74	0
山东	山东潍坊	5228488592	6/10/64	女	2016/9/2	高中	77820	2
湖北	湖北武汉	417178802	6/12/86	女	2011/3/8	高中	77794.28	16
安徽	安徽合肥	392992032	3/12/77	女	2010/9/1	中专	77767.5	5
山东	山东威海	722385882	6/26/78	男	2015/3/25	大专	77767	11
青岛	青岛一支	421885052	10/26/69	女	2011/4/22	大专	77754.95	18
河南	河南南阳	201170412	10/25/76	女	2008/1/18	大专	77749.61	9
北京	北京良乡	655715482	11/11/82	女	2014/8/13	大专	77744.05	0
云南	云南昆明	149885742	4/12/76	女	2007/7/1	大专	77736.87	4
浙江	浙江绍兴	5198578112	11/8/71	女	2016/8/1	大专	77678	13
吉林	吉林吉林	527713722	1/8/69	男	2013/6/5	大专	77674.84	5
山东	山东淄博	263262692	6/14/81	女	2008/11/14	中专	77672.07	4
广东	广东广州	697727092	10/8/70	女	2014/12/24	本科	77648.69	8
广东	广东惠州	373448552	10/11/80	女	2010/5/5	大专	77628.02	14
山东	山东济南	5227989052	12/24/75	女	2016/9/1	大专	77614.5	9
山东	山东滨州	273892082	12/3/69	女	2009/1/1	中专	77609.02	9
江苏	江苏南京	8462812	3/22/71	女	2003/10/20	本科	77607.84	11
辽宁	辽宁铁岭	5192684722	3/18/84	女	2016/7/26	大专	77604.7	13
浙江	浙江杭州	158272822	10/23/70	女	2007/8/20	大专	77603.53	5
湖北	湖北十堰	137215312	3/15/63	女	2007/5/25	大专	77593.69	14
广东	广东广州	265672342	4/12/75	女	2008/11/24	大专	77593.61	9
天津	天津市区二支	396236712	1/5/69	女	2010/9/18	大专	77575.58	11
江西	江西南昌	5153987122	12/4/81	女	2016/5/30	高中	77545.5	5
四川	四川青羊	5222875262	11/23/84	女	2016/8/24	本科	77511	16
广东	广东广州	738630662	3/11/68	女	2015/4/9	高中	77503.33	5
北京	北京东城	769112	10/2/67	女	2002/3/8	本科	77502.12	2
北京	北京海淀	509067532	2/26/91	男	2013/4/1	本科	77500	2
山东	山东滨州	5194107042	5/11/77	女	2016/7/27	大专	77492	22
湖北	湖北十堰	472408372	6/5/90	女	2012/7/19	大专	77435	10
深圳	深圳罗湖	127551182	4/24/64	男	2007/4/1	本科	77428.78	5
北京	北京海淀	213232882	1/3/82	女	2008/4/1	高中	77343.2	6
山东	山东烟台	174004272	1/3/79	女	2007/11/1	大专	77316.89	15
河北	河北石家庄	608142772	5/12/67	女	2014/1/27	高中	77315	3
四川	四川高新	462062	11/27/73	女	2001/12/31	本科	77288.05	7
广东	广东阳江	5006028612	11/4/88	女	2015/10/27	高中	77224.9	11
四川	四川青羊	714263842	9/10/79	女	2015/3/19	大专	77212.7	8
河北	河北石家庄	680822432	5/1/84	女	2014/11/8	大专	77154.5	13
陕西	陕西西安	702202072	10/10/82	男	2015/1/3	大专	77131.5	4
北京	北京海淀	295953092	11/29/81	男	2009/5/1	硕士	77102.35	5
青岛	青岛一支	5187406842	4/24/68	女	2016/7/5	本科	77091.5	6
湖北	湖北襄阳	5126149022	7/2/77	女	2016/4/21	高中	77080	7
河北	河北石家庄	657527082	9/6/77	女	2014/8/18	本科	77063.67	12
浙江	浙江嘉兴	422774102	4/1/90	女	2011/4/29	大专	77054.2	9
河南	河南南阳	588108852	3/25/81	女	2013/12/3	大专	77012	11
重庆	重庆渝中	764761902	10/11/78	女	2015/6/15	中专	76926.5	10
青岛	青岛一支	386684932	3/4/73	女	2010/7/20	本科	76919.01	12
四川	四川高新	616208152	4/8/67	男	2014/3/21	硕士	76916.31	1
四川	四川高新	876729632	12/21/78	女	2015/9/23	本科	76890.5	17
黑龙江	黑龙江哈尔滨	292599402	10/25/73	女	2009/4/14	高中	76821.32	15
深圳	深圳福田第二	334723362	2/16/77	女	2009/9/29	大专	76793.36	10
四川	四川高新	572126742	5/17/75	女	2013/8/2	大专	76791.21	13
北京	北京平谷	192873492	6/10/75	男	2007/12/24	高中	76775.61	9
河南	河南开封	667325752	12/31/85	女	2014/9/5	大专	76732.46	15
湖北	湖北武汉	177157122	11/12/61	女	2007/11/7	中专	76723.82	7
青岛	青岛一支	265958872	11/30/72	女	2008/11/25	本科	76723.63	11
深圳	深圳宝安	691375612	7/23/73	女	2014/12/16	大专	76716.22	6
重庆	重庆渝中	760135632	5/26/91	男	2015/6/3	本科	76701	23
福建	福建泉州	5163578352	9/4/74	女	2016/6/6	中专	76693	15
广东	广东珠海	61682042	9/23/74	女	2005/10/12	大专	76669.24	15
大连	大连银洲	680191582	3/12/69	男	2014/11/4	本科	76650	7
贵州	贵州贵阳	694282542	2/10/68	女	2014/12/22	本科	76638.9	17
黑龙江	黑龙江哈尔滨	217392302	11/30/74	女	2008/4/21	大专	76633.97	11
重庆	重庆渝中	437078182	9/5/79	女	2011/9/8	大专	76615.83	12
广东	广东惠州	150916872	10/19/68	女	2007/7/19	中专	76586.03	3
四川	四川青羊	862666032	9/14/86	男	2015/8/24	本科	76537	7
河北	河北衡水	524330032	9/20/83	女	2013/5/29	中专	76536.57	3
宁波	宁波城区	397357032	12/1/74	女	2010/9/25	中专	76535.08	9
北京	北京海淀	52053082	10/31/59	女	2005/7/20	大专	76489.52	4
湖北	湖北武汉	5155231852	10/22/84	女	2016/5/31	大专	76473	3
辽宁	辽宁鞍山	590629182	3/24/70	男	2013/12/12	高中	76464.64	11
四川	四川高新	613109592	5/9/84	女	2014/3/13	大专	76430.77	10
四川	四川高新	757789212	5/10/83	女	2015/5/27	本科	76421.6	15
重庆	重庆渝中	5032044552	2/18/84	女	2015/11/26	本科	76316	15
贵州	贵州遵义	5196847552	3/2/82	女	2016/7/29	中专	76314.1	24
湖北	湖北十堰	5043997292	1/2/80	女	2015/12/10	高中	76306	3
四川	四川青羊	330740222	12/15/82	女	2009/9/17	大专	76301.1	3
北京	北京海淀	28205732	1/5/79	男	2005/1/4	本科	76291.49	3
上海	上海黄浦	452033902	8/21/86	女	2012/2/1	大专	76249.49	0
山东	山东济宁	718916772	8/9/83	女	2015/3/24	高中	76238.7	8
广东	广东惠州	203145582	4/8/81	男	2008/2/1	中专	76227.52	13
四川	四川高新	5084877842	8/12/80	男	2016/3/10	本科	76221.6	13
福建	福建福州	166761372	1/1/78	女	2007/9/25	高中	76217.3	10
福建	福建漳州	507146652	1/2/75	女	2013/3/16	本科	76212.84	17
四川	四川乐山	684496152	8/1/66	女	2014/11/19	高中	76193	3
黑龙江	黑龙江哈尔滨	307553452	9/28/73	女	2009/6/22	大专	76187.97	5
安徽	安徽亳州	5186739612	3/28/89	女	2016/7/1	高中	76155	18
湖北	湖北武汉	417022072	2/4/77	女	2011/3/7	大专	76145.22	12
江西	江西赣州	5190426512	5/2/70	男	2016/7/20	高中	76134	9
重庆	重庆永川	5186742312	10/20/84	女	2016/7/1	本科	76111.9	23
四川	四川高新	5101742202	6/14/82	女	2016/3/21	大专	76073	7
佛山	佛山顺德龙江	841630982	12/22/85	女	2015/8/9	高中	76000	0
湖北	湖北十堰	651677352	3/29/75	女	2014/8/5	大专	75970.17	8
深圳	深圳南山	5204928462	1/20/94	男	2016/8/4	高中	75960.5	9
山东	山东烟台	5023361702	5/5/80	男	2015/11/19	高中	75909.7	9
江西	江西南昌	5176593592	3/10/89	女	2016/6/20	高中	75902	9
四川	四川成都	713580102	8/19/72	女	2015/3/18	大专	75831.5	12
江苏	江苏扬州	295329082	1/13/70	女	2009/4/25	高中	75813.05	11
广西	广西南宁	5199600732	11/29/78	女	2016/8/1	本科	75796.9	12
江西	江西南昌	288502512	9/21/82	男	2009/3/25	本科	75774.47	5
青岛	青岛城阳	686352132	2/12/82	男	2014/11/20	大专	75759.5	6
河北	河北沧州	688071472	7/10/69	男	2014/11/30	高中	75752	7
陕西	陕西汉中	5063610332	12/10/76	女	2016/1/29	大专	75749.5	7
四川	四川青羊	249912	1/18/69	女	2001/12/31	大专	75743.48	11
辽宁	辽宁沈阳	5203189732	9/4/76	女	2016/8/3	大专	75742.5	17
河北	河北保定	5196158582	5/21/63	男	2016/7/29	中专	75740.2	19
山东	山东济南	582221582	11/4/80	女	2013/10/28	本科	75731.91	18
吉林	吉林长春	449531722	5/30/80	女	2011/12/31	大专	75694.13	1
湖南	湖南永州	429669612	9/5/73	女	2011/6/28	中专	75674.12	12
河北	河北石家庄	865017892	10/1/74	女	2015/8/31	高中	75670.5	21
福建	福建南平	5023928262	8/4/69	男	2015/11/19	中专	75668.6	7
四川	四川高新	24807662	1/21/69	男	2004/11/30	本科	75644.99	4
四川	四川高新	5047913572	7/7/87	女	2015/12/18	大专	75640.4	8
广东	广东珠海	7152962	9/16/70	女	2003/8/31	中专	75608.96	16
北京	北京海淀	322665632	9/27/86	男	2009/8/6	本科	75525.63	12
湖北	湖北荆州	228016522	9/15/73	男	2008/6/2	中专	75496.86	15
四川	四川青羊	659531282	4/14/87	男	2014/8/21	本科	75492.45	0
四川	四川青羊	5094589612	1/1/76	女	2016/3/15	大专	75457.3	14
山东	山东临沂	5110288172	1/28/76	女	2016/3/24	中专	75455	20
山东	山东济南	30299122	2/16/69	女	2005/1/28	高中	75446.77	6
陕西	陕西西安	146162202	2/26/70	女	2007/6/25	本科	75443.76	5
青岛	青岛一支	5213610412	3/21/84	女	2016/8/11	本科	75437.5	21
天津	天津市区一支	471565102	12/12/89	女	2012/7/2	本科	75422.26	7
山西	山西太原	828824612	1/17/73	女	2015/8/5	高中	75389.9	9
黑龙江	黑龙江鹤岗	844981602	4/22/65	男	2015/8/10	大专	75369	2
四川	四川青羊	663239472	1/4/82	女	2014/8/27	高中	75326.85	0
浙江	浙江嘉兴	280326182	2/6/84	女	2009/2/23	大专	75320.05	2
广东	广东汕头	492134542	2/7/74	男	2012/12/20	高中	75221.65	12
青岛	青岛一支	430777922	11/23/74	女	2011/7/18	本科	75174.96	7
广西	广西南宁	605023422	8/13/79	女	2014/1/9	本科	75171.97	4
河北	河北保定	5101469342	10/24/69	女	2016/3/21	大专	75163.1	14
广东	广东中山	96214042	10/9/79	女	2006/9/5	大专	75162.36	3
四川	四川青羊	521615702	5/30/88	男	2013/5/22	本科	75114.7	21
浙江	浙江温州	5192196052	7/28/76	女	2016/7/25	本科	75100.8	9
佛山	佛山顺德龙江	722833342	1/4/89	女	2015/3/25	本科	75098.74	0
四川	四川内江	292110632	11/3/81	男	2009/4/10	大专	75094.4	16
福建	福建厦门	116459682	11/1/73	女	2007/1/21	高中	75088.17	13
云南	云南昆明	609147942	6/9/68	女	2014/2/13	大专	75086.73	10
广西	广西南宁	312354602	1/16/75	女	2009/7/1	大专	75083.95	2
苏州	苏州新区	77324302	10/22/64	女	2006/3/6	高中	75080.85	2
四川	四川青羊	461115062	6/27/70	女	2012/3/31	中专	75078.72	0
苏州	苏州昆山	5185320892	4/11/82	女	2016/6/29	高中	75068.5	13
湖北	湖北十堰	279322682	2/15/67	女	2009/2/13	大专	75065	6
佛山	佛山顺德龙江	723209062	12/24/84	女	2015/3/25	高中	75001.4	12
四川	四川青羊	158684412	11/16/82	女	2007/8/22	本科	74976.85	8
广西	广西南宁	222291152	12/28/67	男	2008/5/9	大专	74965.56	15
河南	河南焦作	255860962	1/9/57	男	2008/4/25	高中	74936.22	3
山东	山东东营	75236082	8/17/57	女	2006/2/16	中专	74931.83	3
黑龙江	黑龙江牡丹江	200541932	7/15/62	女	2008/1/15	大专	74928.04	10
黑龙江	黑龙江哈尔滨	50344622	6/29/53	女	2005/6/30	中专	74919.55	1
四川	四川高新	660311052	6/20/90	女	2014/8/22	本科	74905.7	14
天津	天津蓟州	487806112	4/22/71	女	2012/12/6	高中	74890	5
广东	广东惠州	77030232	5/5/78	女	2006/3/1	大专	74887.3	11
四川	四川高新	27102862	4/1/73	女	2004/12/27	本科	74842.32	10
湖南	湖南长沙	5186713302	8/6/93	男	2016/7/1	本科	74842	7
四川	四川德阳	7825822	11/20/71	女	2003/9/18	大专	74833.52	3
浙江	浙江金华	340317262	7/3/75	女	2009/11/3	高中	74833.43	6
广东	广东韶关	486446712	11/3/78	女	2012/11/29	大专	74792.04	18
黑龙江	黑龙江哈尔滨	54803552	8/1/66	女	2005/8/15	中专	74784.8	10
四川	四川雅安	5138760892	10/28/81	女	2016/5/13	本科	74767.9	22
四川	四川高新	5199170082	10/3/85	女	2016/8/1	大专	74759.5	13
北京	北京海淀	509954212	1/7/88	女	2013/4/1	本科	74756.58	3
青岛	青岛一支	639451582	11/2/78	女	2014/6/21	大专	74751.46	4
山东	山东临沂	651969052	12/30/71	女	2014/8/5	高中	74737	8
四川	四川成都	592167502	3/5/68	女	2013/12/16	高中	74728.39	9
青岛	青岛一支	288425332	1/16/72	女	2009/3/25	大专	74719.73	10
辽宁	辽宁本溪	5058855532	11/20/70	女	2016/1/8	本科	74700	1
深圳	深圳福田	158696692	10/17/80	女	2007/8/22	本科	74698.27	9
浙江	浙江温州	765883892	11/19/74	女	2015/6/18	高中	74690	5
浙江	浙江绍兴	5148240582	10/11/68	女	2016/5/26	中专	74629.5	9
云南	云南昆明	5117669242	8/14/82	女	2016/4/7	大专	74598.3	6
山东	山东济南	5145858162	3/19/88	男	2016/5/25	大专	74580	2
广东	广东广州	342446792	1/5/75	女	2009/11/12	高中	74557.13	3
湖北	湖北武汉	465228972	1/14/71	女	2012/5/21	大专	74523	15
山东	山东济宁	505457042	11/21/67	男	2013/3/5	大专	74492.34	10
重庆	重庆永川	869698182	3/8/88	女	2015/9/10	中专	74471	21
四川	四川青羊	354664052	2/17/90	女	2009/12/31	本科	74437.13	8
湖北	湖北襄阳	112569772	4/8/66	女	2007/1/1	中专	74418	12
山东	山东烟台	334927272	11/3/71	女	2009/9/30	高中	74407.56	6
河北	河北石家庄	520404252	3/9/63	女	2013/5/15	高中	74405.74	5
四川	四川高新	611177132	3/16/86	女	2014/3/5	大专	74361.8	13
浙江	浙江金华	702526842	2/19/79	女	2015/1/5	高中	74340	8
广东	广东广州	756326672	8/9/65	女	2015/5/23	高中	74330	6
辽宁	辽宁铁岭	5135829082	10/10/77	女	2016/5/9	本科	74256.3	16
深圳	深圳罗湖	128754122	6/25/72	女	2007/4/3	本科	74208.36	3
江苏	江苏连云港	302280282	1/17/85	女	2009/5/20	高中	74207.2	8
北京	北京海淀	5221414862	5/5/71	女	2016/8/22	本科	74200	7
青岛	青岛一支	93347722	7/6/70	女	2006/8/14	高中	74176.61	9
浙江	浙江杭州	5191682042	11/10/70	女	2016/7/25	高中	74153	9
四川	四川高新	5001065272	10/11/79	男	2015/10/13	本科	74130	8
山东	山东淄博	5048982372	5/29/81	女	2015/12/21	中专	74120.3	12
吉林	吉林延边	866695922	8/25/73	男	2015/9/5	高中	74118	1
浙江	浙江金华	276515822	12/5/69	女	2009/1/9	大专	74097.16	3
山东	山东威海	5100321682	8/14/72	女	2016/3/19	大专	74092	4
山西	山西临汾	232364772	6/13/70	女	2008/6/19	大专	74089.9	2
山东	山东聊城	164707622	12/16/67	女	2007/9/22	中专	74082.73	9
河北	河北唐山	435021362	10/27/72	男	2011/8/23	本科	74078.94	13
青岛	青岛一支	183466862	7/14/77	女	2007/11/24	大专	74074.24	17
湖北	湖北武汉	418551182	8/4/76	女	2011/3/22	大专	74070.32	13
福建	福建泉州	91658112	9/8/69	女	2006/7/24	高中	74057.81	9
北京	北京良乡	5114446502	1/26/84	男	2016/3/30	大专	74055.6	15
重庆	重庆江津	433111092	3/18/70	女	2011/8/8	大专	74053.3	20
江西	江西上饶	606456232	1/9/71	女	2014/1/20	大专	74046	2
湖北	湖北随州	483588632	6/18/69	女	2012/11/19	高中	74033.57	11
山东	山东威海	531011722	9/15/83	女	2013/6/9	中专	74017.05	14
浙江	浙江杭州	5191115482	1/28/71	女	2016/7/22	高中	74016.5	9
四川	四川高新	5199779292	7/8/80	女	2016/8/1	大专	74014.6	10
青岛	青岛一支	675624072	8/13/82	女	2014/10/2	大专	74010	3
四川	四川青羊	271147052	8/26/79	女	2008/12/19	大专	74007.8	7
四川	四川乐山	640032882	5/1/74	女	2014/6/23	大专	73999.15	3
山东	山东德州	238108292	11/5/70	女	2008/7/2	高中	73974.82	1
陕西	陕西汉中	625333962	3/21/70	女	2014/4/25	高中	73955.84	10
云南	云南昆明	482194762	5/18/68	女	2012/11/5	大专	73950.69	5
辽宁	辽宁铁岭	5066898362	12/19/80	女	2016/2/19	本科	73935.2	12
四川	四川青羊	5020692852	11/24/72	女	2015/11/17	大专	73924	8
山东	山东威海	62849112	11/11/65	女	2005/10/26	高中	73893.47	13
江苏	江苏无锡	592493672	7/16/75	女	2013/12/17	大专	73881.35	7
湖北	湖北武汉	20884952	10/27/63	女	2004/10/16	大专	73873.55	9
北京	北京海淀	389295422	11/11/86	男	2010/8/4	本科	73839.85	0
北京	北京平谷	372381552	7/20/71	女	2010/5/1	高中	73838.88	11
四川	四川高新	189942972	11/7/78	女	2007/12/14	大专	73832.08	9
山东	山东淄博	592581462	2/13/78	女	2013/12/17	中专	73830.28	6
辽宁	辽宁沈阳	665623162	3/20/56	女	2014/9/1	本科	73789.09	6
天津	天津滨海新区	668492822	7/22/57	女	2014/9/11	大专	73775.18	5
大连	大连开发区	19415302	8/16/72	男	2004/9/23	大专	73774.87	10
苏州	苏州昆山	440608422	12/2/79	女	2011/9/30	中专	73773.06	6
四川	四川青羊	5178399412	8/3/74	女	2016/6/21	大专	73771	9
河南	河南洛阳	253246432	12/6/69	女	2008/9/24	高中	73767.16	11
安徽	安徽合肥	449872272	4/3/82	女	2012/1/1	大专	73747.17	10
吉林	吉林长春	166875122	10/24/78	女	2007/9/25	大专	73743	6
山东	山东枣庄	395943342	10/12/63	女	2010/9/16	大专	73732.49	4
广东	广东广州	369477692	1/2/79	女	2010/4/11	大专	73724.73	3
青岛	青岛开发区	831956942	5/3/78	男	2015/8/6	高中	73703	6
广西	广西桂林	582207902	9/29/76	女	2013/10/28	大专	73670.73	4
江苏	江苏南京	5149197912	12/5/80	女	2016/5/27	本科	73645.1	8
四川	四川青羊	5204299312	4/27/74	女	2016/8/3	大专	73628	3
广东	广东广州	5208876452	3/30/73	女	2016/8/8	大专	73612	8
广东	广东广州	477412	4/18/70	女	2002/1/23	本科	73599.91	7
湖北	湖北武汉	126018302	12/14/78	女	2007/3/24	中专	73570.19	11
云南	云南昆明	407073082	8/13/72	女	2010/12/1	大专	73564.51	7
浙江	浙江杭州	640821862	6/23/88	女	2014/6/24	本科	73561.55	11
广东	广东广州	5161354622	12/13/87	女	2016/6/3	本科	73559.3	1
陕西	陕西安康	486176232	10/18/75	女	2012/12/1	高中	73521.5	9
黑龙江	黑龙江哈尔滨	424571322	10/16/81	女	2011/5/20	本科	73506.95	2
四川	四川德阳	7883882	12/7/68	女	2003/9/18	大专	73490.66	2
重庆	重庆渝中	474282772	5/31/82	女	2012/8/10	大专	73489.76	7
苏州	苏州新区	189979542	10/25/83	女	2007/12/14	大专	73480.98	8
广东	广东江门	298691282	12/15/80	女	2009/5/11	中专	73438.36	16
吉林	吉林吉林	265051402	12/13/65	女	2008/11/23	高中	73425.11	8
甘肃	甘肃金昌	574527182	11/15/66	女	2013/8/25	大专	73417.6	6
天津	天津市区二支	5204312952	7/7/87	女	2016/8/3	本科	73416.5	16
天津	天津市区一支	580386122	12/29/69	女	2013/10/8	高中	73398.11	14
浙江	浙江杭州	364480172	3/9/67	女	2010/3/17	高中	73367.76	7
四川	四川乐山	5007835332	7/29/65	男	2015/10/31	中专	73336	11
广东	广东中山	5167511872	1/26/77	女	2016/6/9	中专	73323.8	9
北京	北京海淀	568982132	4/11/90	女	2013/7/8	大专	73323	9
江苏	江苏南京	513740282	10/13/67	女	2013/4/2	大专	73302.86	6
黑龙江	黑龙江哈尔滨	5143200182	5/20/71	女	2016/5/20	本科	73293.5	13
北京	北京海淀	46131612	11/3/62	男	2005/6/3	大专	73231.57	5
陕西	陕西渭南	413918072	9/10/62	男	2011/1/11	高中	73216	1
吉林	吉林吉林	243586072	2/27/55	女	2008/8/1	大专	73179.79	6
山东	山东威海	405048362	10/27/73	女	2010/11/22	初中	73172.52	12
广东	广东韶关	507858452	4/6/82	女	2013/3/19	大专	73172.19	16
浙江	浙江杭州	5137402892	2/6/78	女	2016/5/12	大专	73157	5
浙江	浙江金华	591395272	4/10/79	女	2013/12/13	高中	73124	7
山东	山东东营	460556922	12/25/72	女	2012/3/30	大专	73122.63	3
贵州	贵州遵义	625940252	10/30/73	女	2014/4/29	高中	73120.93	11
河南	河南郑州	75473762	10/2/78	男	2006/2/22	大专	73100.67	12
江苏	江苏无锡	5202221532	12/13/76	女	2016/8/2	高中	73095.4	11
湖北	湖北荆州	344220152	7/25/72	女	2009/11/20	高中	73058.84	15
大连	大连明锐	729626412	1/22/61	女	2015/3/27	高中	73050	4
山东	山东潍坊	281018162	6/27/80	女	2009/2/25	中专	73030.52	13
山东	山东临沂	715147452	11/14/77	女	2015/3/20	高中	73018.6	16
四川	四川青羊	619335042	9/27/76	女	2014/3/27	大专	72982.51	14
山东	山东烟台	849218202	4/28/68	女	2015/8/10	中专	72911.5	5
湖北	湖北襄阳	75595052	9/22/73	女	2006/2/23	中技	72910.5	18
安徽	安徽合肥	5159959442	3/18/76	女	2016/6/2	本科	72904	10
四川	四川绵阳	733754702	3/22/84	女	2015/4/1	大专	72900	5
四川	四川高新	5160390022	6/16/78	女	2016/6/3	中专	72880	4
四川	四川青羊	309372	4/27/69	女	2001/12/31	大专	72875.84	6
北京	北京海淀	368922352	5/10/88	女	2010/4/6	本科	72868.05	7
湖北	湖北武汉	251844052	11/23/72	女	2008/9/17	本科	72852.74	9
浙江	浙江衢州	5144745882	5/1/90	男	2016/5/24	高中	72843	9
江苏	江苏镇江	70634372	5/7/69	女	2005/12/26	高中	72836.78	10
湖北	湖北十堰	167995712	12/11/78	女	2007/10/5	中专	72833.03	7
安徽	安徽亳州	338405592	8/15/57	男	2009/10/1	高中	72763.55	5
浙江	浙江衢州	625846322	9/24/81	女	2014/4/29	高中	72763.11	4
湖南	湖南长沙	783959392	2/1/64	女	2015/7/8	高中	72762.5	2
四川	四川高新	877428452	5/19/77	男	2015/9/24	大专	72740	33
广东	广东珠海	110037862	7/28/71	女	2006/12/24	大专	72730.2	6
四川	四川绵阳	609763442	7/1/85	女	2014/2/24	本科	72712.11	4
四川	四川青羊	5096767092	8/9/85	女	2016/3/16	本科	72708.7	15
湖北	湖北武汉	5176050572	1/10/84	女	2016/6/20	中专	72703	9
山东	山东淄博	823567492	10/28/81	男	2015/8/1	大专	72699.5	7
深圳	深圳南山	5185845772	10/6/95	女	2016/6/30	高中	72686	7
河北	河北承德	5166232732	11/22/76	女	2016/6/8	高中	72672	8
黑龙江	黑龙江哈尔滨	145229742	1/6/77	女	2007/6/25	本科	72633.52	9
山东	山东日照	536033532	7/22/75	女	2013/6/15	大专	72598.91	9
贵州	贵州遵义	497220552	3/13/79	女	2012/12/31	大专	72575	5
广东	广东东莞	384700552	3/10/73	女	2010/6/29	大专	72561.8	3
辽宁	辽宁营口	855905062	10/17/83	女	2015/8/10	大专	72554.79	5
佛山	佛山顺德龙江	673233542	11/18/84	女	2014/9/25	高中	72553	4
陕西	陕西渭南	402156782	11/29/73	女	2010/10/25	高中	72544.36	4
辽宁	辽宁辽阳	618815842	5/28/58	女	2014/3/26	大专	72520.07	6
重庆	重庆渝中	5002778662	10/1/79	女	2015/10/20	本科	72511.7	7
辽宁	辽宁抚顺	5077533952	1/6/60	男	2016/3/7	大专	72503.4	3
河南	河南郑州	6761892	9/21/71	男	2003/8/25	大专	72498.95	13
四川	四川高新	5080197332	1/6/81	女	2016/3/8	大专	72493	23
浙江	浙江杭州	240654852	1/18/76	女	2008/7/16	大专	72441.3	17
山东	山东淄博	155881322	12/22/69	女	2007/8/2	大专	72440.57	12
天津	天津市区二支	5049157332	3/18/76	女	2015/12/21	大专	72418.5	9
江西	江西南昌	476398352	7/17/76	女	2012/8/30	高中	72391.7	7
山东	山东德州	330725842	7/1/73	女	2009/9/16	大专	72382.96	12
湖北	湖北恩施	572930102	12/21/70	女	2013/8/9	大专	72339.31	5
四川	四川青羊	160709132	1/2/80	女	2007/9/1	本科	72323.28	0
河北	河北衡水	562195202	10/10/77	女	2013/6/29	高中	72307.4	1
陕西	陕西西安	237227752	2/27/77	女	2008/7/1	大专	72301.41	9
四川	四川绵阳	266886862	9/29/86	男	2008/11/25	中专	72285.02	2
广西	广西南宁	149144912	8/24/68	女	2007/7/11	大专	72250.84	2
辽宁	辽宁营口	485557662	3/15/65	女	2012/11/25	高中	72247.2	9
四川	四川青羊	76490242	3/17/71	女	2006/2/27	大专	72236.14	10
四川	四川高新	845352852	7/8/86	女	2015/8/10	大专	72235.5	20
河北	河北石家庄	5123978812	12/13/82	女	2016/4/18	本科	72211	9
广东	广东广州	75370622	1/10/62	女	2006/2/21	大专	72195.01	12
浙江	浙江金华	487589222	9/13/66	女	2012/12/5	高中	72177.29	4
辽宁	辽宁盘锦	283047622	4/6/79	女	2009/3/6	大专	72165.82	12
上海	上海黄浦	322185102	11/5/81	男	2009/8/4	本科	72156	19
天津	天津市区一支	5125167252	2/9/83	女	2016/4/20	中专	72150	5
浙江	浙江金华	5076136972	5/15/70	男	2016/3/5	大专	72140.8	7
湖北	湖北武汉	90890622	4/9/76	女	2006/7/12	中专	72091.5	1
辽宁	辽宁营口	291932682	3/11/76	女	2009/4/9	高中	72081.6	5
贵州	贵州贵阳	235926142	12/2/68	男	2008/6/25	大专	72038.3	17
辽宁	辽宁沈阳	504860662	12/28/80	女	2013/2/28	高中	72021	15
安徽	安徽芜湖	532255972	12/6/79	女	2013/6/10	中专	72016.8	14
新疆	新疆伊犁	5167771092	12/5/83	女	2016/6/10	高中	72015	4
天津	天津市区二支	672361242	1/28/91	男	2014/9/24	本科	72000	0
重庆	重庆永川	5149357432	5/5/71	女	2016/5/27	大专	71988	20
河南	河南驻马店	115036782	11/24/71	男	2007/1/10	大专	71959	14
湖北	湖北十堰	5193877732	11/24/76	女	2016/7/27	高中	71941	15
浙江	浙江金华	732123152	9/27/75	女	2015/3/31	高中	71935.8	5
四川	四川高新	5019599542	10/29/78	女	2015/11/16	本科	71884.2	17
吉林	吉林长春	478537792	8/29/78	女	2012/9/21	本科	71857	5
湖北	湖北荆州	141545142	2/15/79	女	2007/6/20	大专	71809.69	12
四川	四川高新	461296082	8/7/87	女	2012/3/31	大专	71809.5	15
重庆	重庆渝中	163445922	1/6/77	男	2007/9/17	本科	71791.13	12
广西	广西南宁	749266192	5/23/82	女	2015/4/25	本科	71775.2	10
山东	山东烟台	533414532	6/21/81	女	2013/6/13	中专	71755.21	7
苏州	苏州昆山	5222780062	10/6/76	女	2016/8/24	高中	71744.5	7
云南	云南昆明	228563352	10/1/83	男	2008/6/1	本科	71718.76	3
四川	四川成都	5213919552	7/17/82	女	2016/8/12	高中	71706.5	14
山东	山东济南	18703502	3/5/65	女	2004/9/1	中专	71682.72	11
广东	广东惠州	162799722	4/26/77	女	2007/9/12	高中	71676.53	14
四川	四川青羊	180289452	7/17/74	女	2007/11/17	中专	71666.28	4
深圳	深圳福田	5103645022	4/20/80	女	2016/3/22	高中	71589	9
湖北	湖北咸宁	422086862	10/29/81	女	2011/4/25	本科	71588.61	10
苏州	苏州张家港	164563172	8/18/48	女	2007/9/21	大专	71578.63	2
北京	北京良乡	5063345642	3/23/72	女	2016/1/27	大专	71573	4
黑龙江	黑龙江佳木斯	151692872	8/19/62	女	2007/7/23	中专	71570.27	1
福建	福建福州	5123870942	10/27/84	女	2016/4/18	高中	71564	12
江苏	江苏徐州	5072047532	3/2/66	女	2016/3/2	高中	71563.5	5
四川	四川高新	515147132	9/12/73	女	2013/4/15	大专	71555.05	11
天津	天津武清	5177551822	1/5/76	男	2016/6/21	高中	71548	7
四川	四川雅安	679998282	5/9/83	女	2014/11/3	高中	71537.9	6
深圳	深圳福田第二	576915612	12/17/86	女	2013/9/6	中专	71510.14	5
辽宁	辽宁本溪	5012960962	12/10/76	女	2015/11/8	本科	71504	1
四川	四川青羊	881964562	10/4/87	女	2015/10/8	大专	71491.5	4
黑龙江	黑龙江绥化	404101252	6/26/71	女	2010/11/12	大专	71483.74	11
贵州	贵州贵阳	480922182	12/13/89	男	2012/10/23	本科	71466.49	14
江西	江西上饶	345612622	12/18/75	女	2009/11/25	高中	71462.17	10
河南	河南南阳	742820782	10/20/81	男	2015/4/10	大专	71460.2	17
广东	广东广州	344400472	10/17/79	女	2009/11/23	大专	71459.7	7
北京	北京海淀	65950042	10/26/77	女	2005/11/21	大专	71433.13	4
天津	天津市区二支	398323262	12/6/61	女	2010/9/28	高中	71422.98	7
内蒙古	内蒙呼和浩特	824871202	3/11/70	女	2015/8/3	高中	71408.9	8
河北	河北保定	5141210332	10/25/77	女	2016/5/18	大专	71408	2
河南	河南南阳	168644982	11/19/64	女	2007/10/8	高中	71396.63	18
安徽	安徽合肥	5137871552	2/14/78	女	2016/5/12	中专	71379.1	9
福建	福建福州	322014962	10/13/76	女	2009/8/3	中专	71357.93	7
浙江	浙江温州	21839602	10/17/77	男	2004/10/29	大专	71356.78	8
四川	四川高新	678252	1/13/76	女	2002/3/1	大专	71353.4	5
青岛	青岛一支	5168584272	3/21/66	女	2016/6/12	大专	71350	1
河南	河南南阳	606075072	9/5/80	女	2014/1/18	本科	71229	4
广东	广东东莞	239651082	12/15/81	男	2008/7/10	中专	71228.61	7
浙江	浙江湖州	494387732	2/10/70	女	2012/12/25	大专	71216.21	12
山西	山西临汾	561741602	12/3/90	男	2013/6/28	大专	71192.33	3
吉林	吉林延边	5171914952	2/22/68	男	2016/6/15	大专	71152.6	41
山东	山东济南	712654482	4/6/80	女	2015/3/17	本科	71145.6	11
河北	河北邢台	5198964312	4/3/64	男	2016/8/1	高中	71143.9	8
四川	四川高新	5199536342	1/15/91	男	2016/8/1	本科	71109	7
山东	山东烟台	5221795592	12/8/80	女	2016/8/23	本科	71079	13
甘肃	甘肃酒泉	547752112	7/15/78	男	2013/6/24	高中	71069	1
四川	四川青羊	5230212852	7/1/92	女	2016/9/5	本科	71053	3
辽宁	辽宁沈阳	721676182	3/5/65	男	2015/3/25	高中	71019.8	7
青岛	青岛一支	5129216812	4/6/78	女	2016/4/27	本科	71000	0
山东	山东淄博	235831512	10/22/62	女	2008/6/25	高中	70983.5	8
广东	广东广州	736839582	11/15/72	男	2015/4/7	高中	70941.5	15
青岛	青岛一支	5069968852	11/2/79	男	2016/3/1	大专	70930	13
湖北	湖北荆州	5122675162	8/13/72	男	2016/4/16	大专	70921.2	18
海南	海南海口	555648052	5/3/73	女	2013/6/27	大专	70916.19	4
安徽	安徽合肥	463144522	5/2/79	女	2012/5/2	本科	70912.89	4
大连	大连开发区	333092782	2/25/65	女	2009/9/25	高中	70832.17	9
内蒙古	内蒙古通辽	569491692	3/28/65	女	2013/7/16	本科	70831.12	5
山东	山东威海	719178742	2/21/69	女	2015/3/24	大专	70819.5	12
北京	北京海淀	659744402	8/18/93	男	2014/8/21	大专	70807.5	4
河南	河南郑州	824854452	1/15/75	女	2015/8/3	本科	70791.58	1
河北	河北石家庄	610926742	8/22/65	女	2014/3/4	高中	70780	2
四川	四川高新	425454232	12/4/90	女	2011/5/30	中专	70774.2	8
福建	福建福州	543747752	9/4/68	女	2013/6/21	高中	70747.08	5
广东	广东广州	38374682	11/5/69	女	2005/4/11	本科	70738.6	16
北京	北京平谷	729994592	1/12/81	女	2015/3/30	大专	70720	1
安徽	安徽合肥	573004212	8/13/84	女	2013/8/9	大专	70719.67	6
青岛	青岛开发区	242666592	11/11/81	女	2008/7/25	初中	70698.14	4
天津	天津市区一支	796951252	2/2/80	女	2015/7/21	中专	70692	5
上海	上海黄浦	462979552	11/1/79	女	2012/4/28	本科	70685.52	8
北京	北京东城	519030202	9/24/86	女	2013/5/3	大专	70657.32	7
四川	四川青羊	480514362	9/9/79	女	2012/10/16	中专	70611.7	4
四川	四川青羊	808092572	10/10/81	女	2015/7/28	大专	70611.36	12
云南	云南昆明	5193379092	12/12/67	女	2016/7/27	本科	70609	8
河南	河南郑州	14355252	3/15/64	女	2004/6/8	大专	70603	15
山东	山东临沂	5165304412	8/10/77	女	2016/6/7	中专	70591	24
大连	大连海川	18030642	3/27/55	女	2004/8/30	大专	70568	3
四川	四川青羊	398317122	3/20/70	女	2010/9/28	高中	70561.62	11
辽宁	辽宁营口	695069152	2/4/76	女	2014/12/22	大专	70537.93	13
新疆	鄯善	5040324932	7/14/75	女	2015/12/1	高中	70522	8
江苏	江苏南京	426824512	6/18/75	女	2011/6/14	中专	70506	12
北京	北京海淀	555030182	12/23/88	女	2013/6/30	大专	70496.3	6
上海	上海黄浦	3010022	6/26/65	女	2003/3/10	中专	70493.83	10
辽宁	辽宁沈阳	880387302	1/24/72	女	2015/9/30	高中	70483	7
山东	山东德州	705393162	9/15/76	女	2015/1/21	中专	70475.5	16
安徽	安徽合肥	243579232	12/1/73	女	2008/8/1	高中	70462.19	4
北京	北京良乡	412068232	7/29/62	女	2011/1/1	高中	70456.41	4
湖北	湖北宜昌	544729062	2/20/87	女	2013/6/22	大专	70447.18	9
黑龙江	黑龙江佳木斯	311987122	10/12/67	女	2009/6/30	初中	70435.5	10
陕西	陕西西安	809507932	12/28/76	女	2015/7/28	本科	70389.48	8
山东	山东烟台	583860672	1/10/80	女	2013/11/12	高中	70383.48	11
福建	福建福州	5111744072	1/11/74	男	2016/3/25	大专	70358	7
河南	河南信阳	75460782	5/4/62	女	2006/2/22	大专	70347.39	9
浙江	浙江嘉兴	871392262	10/2/56	女	2015/9/14	中专	70300	1
浙江	浙江金华	5061714782	12/17/90	女	2016/1/18	高中	70277.5	16
黑龙江	黑龙江黑河	455150882	8/31/57	男	2012/3/7	本科	70267.42	0
湖北	湖北武汉	167496062	11/14/67	女	2007/10/1	本科	70254.9	18
四川	四川高新	8960792	9/15/64	女	2003/11/17	大专	70252.83	10
辽宁	辽宁本溪	874095282	3/8/70	女	2015/9/19	大专	70248	9
辽宁	辽宁本溪	285708632	1/5/85	女	2009/3/18	中专	70237.28	1
浙江	浙江温州	5191678442	1/24/79	女	2016/7/25	高中	70189	14
河南	河南郑州	84786462	2/23/70	女	2006/5/18	本科	70174.12	20
上海	上海黄浦	689776162	10/14/77	女	2014/12/9	大专	70160.2	16
安徽	安徽合肥	25126022	8/17/71	女	2004/11/1	大专	70158.96	3
浙江	浙江金华	612756762	8/28/74	女	2014/3/12	高中	70144.5	9
山东	山东临沂	335615482	9/18/64	女	2009/10/6	中专	70125.7	4
深圳	深圳福田第二	29825702	4/3/71	女	2005/1/26	大专	70087.94	1
甘肃	甘肃陇南	5207852032	7/11/65	女	2016/8/6	高中	70087.5	10
北京	北京海淀	702485532	5/6/95	女	2015/1/5	高中	70067	7
深圳	深圳宝安	83757702	1/6/72	女	2006/4/30	高中	70061.74	7
山东	山东威海	559363082	5/13/80	男	2013/6/28	初中	70061.46	16
北京	北京海淀	862557722	3/11/94	男	2015/8/24	大专	70030.2	6
湖北	湖北黄石	62597052	4/6/75	女	2005/10/25	中专	70022.97	19
江西	江西宜春	241521712	9/23/73	女	2008/7/22	中专	70018.38	4
广西	广西桂林	880398882	9/20/89	女	2015/9/30	高中	70000	0
江西	江西九江	5086705402	4/14/90	女	2016/3/10	高中	70000	0
河北	河北秦皇岛	696915222	12/23/68	女	2014/12/23	大专	70000	0
大连	大连明锐	709634502	4/8/74	女	2015/2/15	中专	70000	0
四川	四川绵阳	5039392042	7/14/67	女	2015/11/30	高中	69971.3	8
辽宁	辽宁本溪	876876182	7/27/64	男	2015/9/23	大专	69960.6	4
湖南	湖南郴州	628509702	11/11/74	女	2014/5/14	大专	69949.37	11
四川	四川绵阳	5197355192	9/15/77	女	2016/7/30	高中	69949.3	14
深圳	深圳福田第二	445910622	5/23/72	女	2011/12/2	硕士	69927.14	2
广东	广东茂名	372952942	10/7/75	女	2010/4/30	大专	69914.01	6
四川	四川绵阳	552333302	4/13/75	女	2013/6/26	高中	69899.3	16
安徽	安徽合肥	473761902	7/29/83	女	2012/8/6	大专	69885.07	27
江西	江西南昌	5173975492	10/15/83	女	2016/6/16	高中	69873.8	11
江苏	江苏连云港	241092402	3/23/66	女	2008/7/21	高中	69861.19	8
山东	山东淄博	627909282	7/29/79	女	2014/5/12	中专	69860.21	10
河北	河北秦皇岛	5113995982	4/12/80	女	2016/3/29	高中	69858.8	8
浙江	浙江绍兴	5069696892	11/21/82	女	2016/2/29	大专	69822	10
甘肃	甘肃张掖	369387532	10/5/75	女	2010/4/9	大专	69820.19	8
江苏	江苏无锡	5190839922	9/15/92	男	2016/7/22	大专	69807.8	6
江苏	江苏扬州	91772832	8/24/68	女	2006/7/24	高中	69792.57	5
山东	山东济南	5169038462	12/2/81	女	2016/6/13	本科	69787	7
苏州	苏州昆山	5126176232	12/19/70	女	2016/4/22	高中	69767.5	6
广东	广东广州	209417782	10/22/77	男	2008/3/21	大专	69759.51	3
浙江	浙江金华	474654722	1/9/75	女	2012/8/15	大专	69736.55	8
苏州	苏州张家港	5193687332	3/3/85	女	2016/7/27	本科	69724.32	10
福建	福建厦门	5064852292	1/4/78	女	2016/2/3	本科	69723	8
山东	山东烟台	548725182	12/22/80	女	2013/6/25	初中	69708.29	7
河北	河北唐山	636339072	3/17/86	男	2014/6/13	大专	69703.9	10
吉林	吉林长春	343635082	8/15/76	男	2009/11/17	大专	69699.81	0
江苏	江苏南京	789699142	11/29/81	女	2015/7/14	大专	69695	9
山东	山东烟台	5155627052	11/16/71	女	2016/5/31	中专	69693.4	12
河南	河南三门峡	5088807692	4/7/74	男	2016/3/11	大专	69680	8
安徽	安徽合肥	5069130262	9/12/77	女	2016/2/27	大专	69652.5	20
四川	四川成都	683688052	2/5/78	女	2014/11/18	大专	69650	13
重庆	重庆永川	656406762	11/30/77	女	2014/8/14	高中	69649.27	10
湖北	湖北襄阳	58125142	6/24/67	女	2005/9/12	高中	69627.76	17
浙江	浙江金华	5199898032	8/31/93	女	2016/8/1	高中	69621.5	6
青岛	青岛开发区	148036602	6/21/74	女	2007/7/10	高中	69604.99	6
福建	福建福州	336196782	1/17/73	女	2009/10/10	大专	69591.75	10
湖北	湖北恩施	5216805042	3/20/76	女	2016/8/16	高中	69573.9	15
江苏	江苏常州	274547492	8/15/64	女	2009/1/1	高中	69514.73	2
海南	海南三亚	585329352	5/1/88	男	2013/11/21	大专	69512.3	2
大连	大连明锐	336570132	2/22/76	女	2009/10/14	大专	69498.32	3
湖北	湖北武汉	5152718922	12/1/82	女	2016/5/30	大专	69498	13
天津	天津蓟州	296601662	6/21/68	女	2009/5/4	高中	69442.4	2
江西	江西南昌	69121312	1/31/78	男	2005/12/12	高中	69440.34	7
黑龙江	黑龙江哈尔滨	627419812	2/28/74	女	2014/5/8	大专	69428	3
四川	四川青羊	650486692	5/21/82	女	2014/8/1	本科	69416.81	7
四川	四川高新	676036632	8/21/79	女	2014/10/8	中专	69414.4	10
北京	北京海淀	590470352	4/14/88	女	2013/12/11	大专	69408.95	4
湖北	湖北武汉	45973482	10/23/69	男	2005/6/1	大专	69335.09	8
贵州	贵州遵义	5197289972	6/18/66	女	2016/7/30	大专	69316	9
浙江	浙江温州	874645182	9/21/84	女	2015/9/20	高中	69304	7
山东	山东菏泽	342376452	10/30/78	男	2009/11/11	大专	69298.91	13
辽宁	辽宁盘锦	5137331232	6/16/78	女	2016/5/12	本科	69272	5
四川	四川青羊	87003782	7/27/79	女	2006/6/12	高中	69268.9	18
山东	山东济南	5126282302	11/9/80	女	2016/4/22	大专	69251.5	15
江西	江西南昌	5029682072	10/13/76	女	2015/11/24	高中	69240.5	18
云南	云南曲靖	5163656312	9/8/74	女	2016/6/6	高中	69195	7
上海	上海黄浦	5173749782	11/13/77	女	2016/6/16	本科	69191	6
青岛	青岛一支	141774312	3/10/79	女	2007/6/20	中专	69152.45	12
吉林	吉林四平	5189012292	5/31/75	男	2016/7/15	高中	69132.5	7
山东	山东东营	107298972	10/25/79	女	2006/12/13	中专	69078.01	13
佛山	佛山禅城	298780742	9/12/75	女	2009/5/11	高中	69070.48	8
四川	四川高新	160898662	2/7/83	女	2007/9/3	大专	69040.8	8
四川	四川德阳	18949422	10/4/54	女	2004/9/16	中专	69026.55	9
江苏	江苏泰州	5147684892	7/10/65	女	2016/5/26	高中	68981	5
山东	山东威海	5179257942	1/26/66	女	2016/6/21	高中	68980	7
山西	山西阳泉	628804472	10/16/64	女	2014/5/16	高中	68931.74	6
四川	四川青羊	3582112	2/13/69	女	2003/4/14	高中	68930.83	14
甘肃	甘肃武威	5041209702	12/11/73	女	2015/12/3	高中	68899.9	3
江苏	江苏南京	680639042	8/5/78	女	2014/11/7	中专	68899.4	15
浙江	浙江嘉兴	318494632	4/30/70	男	2009/7/25	中专	68872.33	5
山东	山东济宁	442350652	1/2/76	女	2011/10/22	中专	68862.29	8
山西	山西阳泉	340883212	1/12/72	女	2009/11/6	大专	68859.36	2
北京	北京海淀	651248042	8/8/91	女	2014/8/4	本科	68848.45	3
苏州	苏州张家港	61805972	5/6/71	女	2005/7/26	高中	68838.96	9
福建	福建福州	644956022	9/6/88	女	2014/6/30	大专	68836.2	6
福建	福建漳州	615105282	7/8/79	女	2014/3/18	本科	68823	6
四川	四川高新	745462672	8/16/80	女	2015/4/14	大专	68810	21
四川	四川雅安	488389782	2/4/82	女	2012/12/7	大专	68800.29	9
北京	北京东城	499290352	6/3/86	女	2013/1/13	大专	68800.09	0
山东	山东威海	517199482	11/23/76	女	2013/4/25	初中	68790.9	15
内蒙古	内蒙呼和浩特	5140709962	1/17/82	女	2016/5/17	高中	68767	5
河南	河南驻马店	5125874362	11/12/80	男	2016/4/21	本科	68758	4
四川	四川青羊	660156962	6/1/85	女	2014/8/22	本科	68754.15	15
浙江	浙江温州	5188817392	10/30/81	男	2016/7/14	高中	68750	17
河北	河北廊坊	5158253322	9/1/83	女	2016/6/1	高中	68746	10
四川	四川青羊	5134659682	6/20/72	女	2016/5/6	大专	68738.5	6
四川	四川青羊	380164442	9/19/74	女	2010/6/12	大专	68724.09	5
四川	四川绵阳	457078602	5/5/76	男	2012/3/16	大专	68714.51	5
上海	上海黄浦	5219011602	12/24/73	女	2016/8/18	大专	68710	4
青岛	青岛一支	94361132	1/1/78	女	2006/8/24	本科	68700.16	14
天津	天津蓟州	5178762972	10/26/73	男	2016/6/21	中专	68700	2
青岛	青岛一支	614746312	10/8/82	男	2014/3/17	本科	68668.78	6
山西	山西忻州	825576162	2/20/73	女	2015/8/3	大专	68663.6	3
浙江	浙江绍兴	5092136502	6/30/78	女	2016/3/14	本科	68658.5	11
江苏	江苏南京	5070045912	4/27/84	男	2016/3/1	本科	68652	8
大连	大连明锐	761478282	2/27/67	女	2015/6/5	本科	68646.4	19
北京	北京海淀	489677442	5/23/85	男	2012/12/13	大专	68618.8	0
湖北	湖北武汉	5072176242	3/5/87	男	2016/3/2	本科	68615.7	10
浙江	浙江金华	34976612	9/4/58	女	2005/3/25	高中	68609.87	10
辽宁	辽宁沈阳	103952822	3/10/76	男	2006/11/20	大专	68599.3	18
甘肃	甘肃金昌	576422102	1/16/78	女	2013/9/4	中专	68582.68	5
大连	大连海川	5387842	2/9/69	女	2003/7/1	大专	68576.95	11
湖北	湖北襄阳	429806252	5/15/72	女	2011/7/1	大专	68565.68	16
福建	福建宁德	5105661182	8/7/75	男	2016/3/22	高中	68558.2	6
深圳	深圳宝安	533363312	6/15/67	女	2013/6/13	大专	68552.17	10
福建	福建南平	5159498952	3/5/63	女	2016/6/2	高中	68529.3	3
黑龙江	黑龙江绥化	385650732	8/13/64	女	2010/7/8	中专	68512.23	4
湖北	湖北荆州	215384332	7/15/66	女	2008/4/8	高中	68512.01	11
宁波	宁波城区	108072602	6/27/80	女	2006/12/18	大专	68507.25	3
苏州	苏州昆山	5194793312	2/24/73	女	2016/7/28	高中	68475	10
广西	广西南宁	5087663632	1/23/84	男	2016/3/10	本科	68466.3	9
苏州	苏州张家港	5225896802	4/19/82	女	2016/8/30	高中	68454	20
深圳	深圳福田	591964292	9/16/76	男	2013/12/15	大专	68442.19	7
江西	江西赣州	440829082	1/24/71	女	2011/9/30	高中	68442.03	3
北京	北京海淀	453005302	4/2/91	女	2012/3/1	高中	68441.3	7
湖南	湖南长沙	825075112	2/12/74	男	2015/8/3	中专	68421	1
浙江	浙江金华	552344882	8/28/71	女	2013/6/26	大专	68412.08	5
四川	四川高新	5190636852	10/26/71	女	2016/7/21	高中	68411.2	13
江苏	江苏泰州	699746642	10/8/68	女	2014/12/29	初中	68411.1	9
四川	四川青羊	141383242	3/13/83	女	2007/6/19	本科	68410.11	17
福建	福建泉州	420076522	5/17/77	女	2011/3/29	大专	68355.73	1
内蒙古	内蒙呼和浩特	5199995032	8/16/75	女	2016/8/1	大专	68346.2	10
浙江	浙江杭州	850921492	12/8/72	女	2015/8/10	高中	68337	4
上海	上海黄浦	5028588832	3/8/76	女	2015/11/24	中专	68310.68	9
大连	大连开发区	74031742	2/8/69	女	2006/1/23	初中	68302.26	1
大连	大连海川	597099152	6/20/77	女	2013/12/25	大专	68294.55	12
青岛	青岛一支	300166102	11/23/80	女	2009/5/16	大专	68288.51	9
苏州	苏州新区	22288732	2/2/64	女	2004/11/5	本科	68280.16	4
辽宁	辽宁沈阳	472721562	4/24/77	男	2012/7/25	本科	68262.75	9
青岛	青岛一支	5213390102	6/9/71	男	2016/8/11	大专	68254.5	7
北京	北京海淀	352082	3/4/78	男	2002/1/18	大专	68250.29	4
广西	广西南宁	603824252	10/26/74	男	2014/1/1	大专	68232.9	14
湖北	湖北襄阳	5022333682	5/6/73	男	2015/11/18	高中	68224.3	11
河北	河北唐山	759811832	10/3/81	女	2015/6/2	中专	68220	8
广东	广东广州	353597052	9/18/82	女	2009/12/30	大专	68200.34	18
吉林	吉林通化	5136276972	5/15/80	女	2016/5/10	高中	68174	7
四川	四川高新	488216302	12/15/88	女	2012/12/7	高中	68169.5	11
江西	江西南昌	5147089252	1/30/84	女	2016/5/26	高中	68155.6	12
广东	广东惠州	622542182	7/13/74	女	2014/4/8	大专	68143.5	3
河南	河南周口	93572152	4/14/63	女	2006/8/16	高中	68123	10
广西	广西南宁	856451192	8/5/77	女	2015/8/10	中专	68120.5	20
天津	天津滨海新区	408504492	10/16/53	女	2010/12/10	大专	68114.26	4
重庆	重庆永川	5130403452	12/14/78	女	2016/4/29	中专	68112	13
青岛	青岛一支	757800792	10/13/81	女	2015/5/27	大专	68107	7
浙江	浙江绍兴	5145107572	8/28/82	女	2016/5/24	大专	68086.9	4
广东	广东惠州	23035702	5/17/72	男	2004/11/16	大专	68084.22	1
河南	河南三门峡	162540822	12/15/67	男	2007/9/11	高中	68081	10
四川	四川高新	394977112	10/14/87	女	2010/9/9	大专	68072	8
辽宁	辽宁本溪	749084472	6/17/69	女	2015/4/25	高中	68063.5	4
云南	云南昆明	695434262	8/10/70	男	2014/12/22	大专	68058	4
辽宁	辽宁营口	544258712	10/26/84	女	2013/6/21	中专	68044	11
天津	天津市区二支	631582302	11/4/82	女	2014/5/28	大专	68014.5	11
湖北	湖北武汉	337667832	2/12/71	女	2009/10/22	高中	68011	8
江西	江西南昌	534460042	8/16/79	女	2013/6/14	高中	68010.6	7
辽宁	辽宁营口	408893192	11/25/67	女	2010/12/14	高中	68000.43	3
四川	四川青羊	605380022	6/26/79	男	2014/1/13	大专	67977.1	6
广西	广西南宁	5211435632	5/8/81	女	2016/8/9	高中	67974	13
新疆	新疆昌吉	296154902	12/25/72	女	2009/5/1	本科	67971.35	8
湖北	湖北襄阳	403181772	10/4/78	女	2010/11/4	高中	67944.8	21
辽宁	辽宁沈阳	543729332	7/19/82	女	2013/6/21	本科	67933	3
四川	四川高新	327257432	4/26/72	女	2009/8/27	大专	67915.69	21
山东	山东枣庄	285698722	10/8/75	女	2009/3/18	大专	67910.99	10
重庆	重庆永川	645374722	6/4/73	男	2014/7/2	中专	67874.5	19
湖南	湖南长沙	173516202	5/23/68	女	2007/11/1	大专	67863.17	9
大连	大连开发区	745798752	6/22/63	女	2015/4/15	初中	67862.5	14
大连	大连开发区	80657472	5/2/69	女	2006/3/30	高中	67856.45	4
湖北	湖北宜昌	5122375092	9/30/74	女	2016/4/15	高中	67846.5	16
山东	山东泰安	184860732	11/11/69	女	2007/11/25	高中	67840.63	12
山东	山东济南	378458082	10/16/75	女	2010/5/31	大专	67838.89	10
山东	山东潍坊	849929302	4/22/66	女	2015/8/10	中专	67836.5	12
深圳	深圳南山	5143443132	2/7/87	男	2016/5/20	高中	67830.7	7
四川	四川高新	1643782	12/29/70	女	2002/8/9	本科	67824.15	0
山东	山东济南	55072362	3/15/71	女	2005/8/17	大专	67815.55	14
湖北	湖北武汉	46426382	9/30/71	女	2005/6/7	大专	67804.11	18
苏州	苏州新区	260054852	10/29/82	女	2008/11/1	大专	67803.46	3
福建	福建龙岩	260067832	6/8/77	女	2008/11/1	大专	67800.05	2
北京	北京良乡	5223224282	4/14/72	女	2016/8/25	大专	67790	10
山东	山东济南	5175595482	6/7/77	女	2016/6/20	大专	67783	12
湖北	湖北襄阳	590399312	7/16/80	女	2013/12/11	中专	67758.88	18
四川	四川高新	247528872	5/11/74	女	2008/8/25	大专	67756.25	14
河北	河北邢台	801397202	6/16/72	男	2015/7/24	高中	67750	6
深圳	深圳罗湖	5216945592	12/28/63	女	2016/8/16	本科	67750	1
山东	山东烟台	307950662	7/20/80	女	2009/6/24	大专	67735.64	17
山东	山东滨州	311378462	3/26/80	女	2009/6/27	中专	67718.53	14
青岛	青岛一支	566854272	10/11/55	女	2013/6/30	大专	67713.05	3
山西	山西太原	230519402	4/8/76	女	2008/6/10	高中	67711.34	8
山东	山东济南	621806792	3/11/84	女	2014/4/2	本科	67693	16
宁波	宁波城区	279767072	4/28/63	女	2009/2/19	高中	67684.61	2
青岛	青岛一支	615001442	11/16/82	女	2014/3/18	大专	67663.45	13
湖南	湖南郴州	5020628462	7/20/74	女	2015/11/17	大专	67660	8
湖北	湖北荆门	244809802	4/18/75	女	2008/8/6	大专	67627.1	7
云南	云南昆明	527937452	7/9/83	女	2013/6/5	大专	67625.04	9
四川	四川成都	9333442	11/24/64	女	2003/12/9	高中	67608.9	7
湖北	湖北襄阳	5140901192	4/4/80	女	2016/5/17	高中	67608	8
浙江	浙江杭州	5117169772	6/23/84	男	2016/4/6	大专	67592	14
新疆	新疆乌鲁木齐	230445292	5/17/70	女	2008/6/1	大专	67560.88	4
河南	河南三门峡	649030022	7/3/72	女	2014/7/30	大专	67553.4	11
四川	四川青羊	420679042	3/27/85	男	2011/4/1	本科	67551.57	3
浙江	浙江杭州	234939392	6/1/79	女	2008/6/25	大专	67550.69	7
山东	山东济南	281921862	8/1/77	女	2009/3/2	大专	67544	10
山西	山西太原	286090492	9/11/69	男	2009/3/20	大专	67488.4	5
大连	大连明锐	694877522	11/22/72	女	2014/12/22	大专	67464.5	5
辽宁	辽宁铁岭	5019880572	10/19/71	女	2015/11/16	大专	67462	6
四川	四川青羊	7865462	2/16/69	女	2003/9/18	大专	67460.15	15
四川	四川高新	345464402	12/4/77	女	2009/11/25	本科	67447.5	9
浙江	浙江杭州	399487262	10/18/87	女	2010/10/9	本科	67419.95	3
山西	山西忻州	660530312	12/25/64	女	2014/8/23	高中	67395.91	3
四川	四川青羊	3635702	8/1/72	女	2003/4/15	大专	67395.62	13
贵州	贵州六盘水	5153217492	7/19/81	女	2016/5/30	本科	67385.9	14
辽宁	辽宁盘锦	94846132	8/7/74	女	2006/8/25	本科	67366.73	5
广西	广西南宁	614149932	9/5/88	女	2014/3/17	大专	67363.75	15
河南	河南郑州	604427742	9/27/81	女	2014/1/6	大专	67353.8	2
广东	广东东莞	739832902	12/22/76	女	2015/4/9	高中	67351.5	7
湖北	湖北武汉	20901702	3/16/63	女	2004/10/17	大专	67342.96	14
大连	大连海川	233173572	12/26/62	女	2008/6/23	大专	67342.25	7
湖北	湖北荆州	340768762	12/23/83	女	2009/11/5	高中	67320.59	10
江苏	江苏南京	710493822	8/3/88	女	2015/2/28	本科	67284.6	15
甘肃	甘肃兰州	338655282	9/17/71	女	2009/10/25	大专	67274.87	10
湖北	湖北武汉	131156232	4/12/76	女	2007/4/20	本科	67269	14
甘肃	甘肃兰州	312381262	10/19/70	女	2009/7/1	中专	67254	6
四川	四川高新	5088222022	8/3/92	男	2016/3/11	大专	67240.1	14
宁波	宁波象山	818026212	3/9/79	男	2015/7/31	大专	67229.21	6
深圳	深圳南山	5181677472	9/10/91	男	2016/6/24	高中	67215.4	11
浙江	浙江杭州	571426252	10/26/84	女	2013/7/30	大专	67212.5	4
深圳	深圳罗湖	5175253732	10/26/73	女	2016/6/18	高中	67190	10
云南	云南昆明	5075618392	12/9/82	女	2016/3/4	本科	67189	10
黑龙江	黑龙江绥化	778735072	6/12/83	女	2015/6/30	大专	67189	1
天津	天津市区一支	25397202	9/29/72	女	2004/12/6	中专	67179.38	10
云南	云南昆明	471548352	4/9/78	女	2012/7/2	大专	67174.33	4
山东	山东滨州	754252132	8/1/70	女	2015/5/15	大专	67162	6
四川	四川高新	130213862	10/27/77	女	2007/4/17	大专	67148.23	9
云南	云南昆明	264420552	3/9/72	女	2008/11/1	大专	67135.51	7
福建	福建南平	537208142	6/13/65	女	2013/6/16	初中	67130.55	12
湖北	湖北襄阳	450083022	6/12/80	女	2012/1/1	高中	67127.51	15
青海	西宁	5098904762	5/6/70	女	2016/3/18	大专	67125	7
内蒙古	内蒙古赤峰	358194292	12/5/70	女	2010/1/22	本科	67121	2
青岛	青岛即墨	24184352	12/22/68	女	2004/11/28	高中	67106.35	1
黑龙江	黑龙江哈尔滨	75538662	4/26/77	女	2006/2/23	本科	67099.29	8
四川	四川绵阳	701779872	4/1/83	男	2014/12/31	大专	67098	3
河南	河南驻马店	556218742	1/5/66	女	2013/6/27	高中	67091	12
北京	北京东城	5147451912	10/18/92	男	2016/5/26	本科	67031	4
山东	山东威海	532025132	3/24/78	女	2013/6/9	大专	67013.24	15
内蒙古	内蒙呼和浩特	347128752	2/15/74	女	2009/12/1	高中	66994.61	10
山东	山东烟台	5209435812	3/16/87	女	2016/8/8	大专	66960.5	23
苏州	苏州新区	5199467452	4/2/71	男	2016/8/1	本科	66931.5	15
陕西	陕西渭南	375209902	8/8/69	女	2010/5/15	大专	66925.89	4
山东	山东泰安	616010382	7/11/85	女	2014/3/20	中专	66920.22	14
湖北	湖北武汉	299431412	1/14/69	女	2009/5/14	高中	66890.6	13
北京	北京东城	5163567482	10/26/66	女	2016/6/6	本科	66878	8
山东	山东烟台	95538112	12/22/68	女	2006/9/1	中专	66868.36	16
四川	四川青羊	555226552	7/24/86	女	2013/6/27	本科	66866.59	6
河南	河南洛阳	355971802	10/2/78	女	2010/1/4	大专	66839.24	2
重庆	重庆渝中	5136673072	9/28/88	女	2016/5/11	本科	66820.3	16
云南	云南昆明	484041532	12/19/76	女	2012/11/21	高中	66816.9	4
河南	河南周口	37738392	2/3/66	女	2005/4/1	大专	66806.31	12
海南	海南海口	415730642	8/18/75	女	2011/3/1	本科	66775.61	0
浙江	浙江台州	667787862	8/20/71	女	2014/9/9	高中	66759.75	1
辽宁	辽宁沈阳	212163512	10/2/70	女	2008/3/25	硕士	66748.27	4
河北	河北石家庄	647603352	8/20/76	女	2014/7/25	中专	66719	5
湖南	湖南长沙	140481212	10/21/77	女	2007/7/1	大专	66708.41	6
四川	四川成都	71763202	4/6/69	男	2005/12/30	高中	66693.38	5
浙江	浙江杭州	5137422832	5/30/70	男	2016/5/12	本科	66672	8
浙江	浙江杭州	752302222	3/14/65	女	2015/5/6	高中	66665.5	9
青岛	青岛一支	690415522	5/26/72	男	2014/12/12	大专	66651.8	3
苏州	苏州昆山	700745672	1/25/79	女	2014/12/30	大专	66635.3	18
广东	广东广州	5213635822	7/27/81	女	2016/8/11	大专	66627.5	25
山东	山东烟台	730019852	10/20/81	女	2015/3/30	本科	66622	13
湖北	湖北荆州	5188786582	8/21/70	女	2016/7/14	高中	66615.3	18
山东	山东烟台	272810702	12/1/82	女	2008/12/25	大专	66600.61	21
北京	北京良乡	795585712	3/24/71	女	2015/7/20	大专	66570.5	2
湖北	湖北武汉	5123694112	8/17/55	女	2016/4/18	硕士	66563.7	6
四川	四川成都	882424302	2/4/71	女	2015/10/9	高中	66563.2	21
上海	上海陆家嘴	616519942	2/21/83	女	2014/3/21	本科	66555	1
江苏	江苏徐州	5140012822	4/6/77	女	2016/5/16	本科	66554.1	8
山东	山东烟台	95547322	1/8/80	女	2006/9/1	中专	66537	7
贵州	贵州贵阳	492603492	3/21/69	女	2012/12/21	高中	66527.19	11
广东	广东惠州	364520782	6/13/83	女	2010/3/17	大专	66521.03	12
福建	福建厦门	515912522	10/22/77	女	2013/4/22	中专	66512	6
福建	福建漳州	5122351552	1/23/65	女	2016/4/15	高中	66509.6	10
北京	北京平谷	673972972	3/6/77	女	2014/9/25	大专	66496	2
苏州	苏州张家港	484596872	9/22/65	女	2012/11/23	高中	66495.07	4
浙江	浙江湖州	5199797432	3/14/85	女	2016/8/1	大专	66494	11
苏州	苏州新区	5003513882	8/24/87	男	2015/10/22	本科	66492	5
北京	北京平谷	65956182	10/13/70	女	2005/11/21	大专	66452.29	12
四川	四川成都	301793882	10/21/81	女	2009/5/20	高中	66435.87	5
深圳	深圳宝安	303121182	5/16/70	女	2009/5/25	大专	66400.37	4
福建	福建福州	5218007122	5/15/72	男	2016/8/17	高中	66398.9	9
山西	山西运城	5200731152	7/1/73	女	2016/8/2	高中	66383.5	9
苏州	苏州昆山	798463612	7/8/74	女	2015/7/22	大专	66355.81	6
黑龙江	黑龙江哈尔滨	28138462	2/27/68	男	2005/1/4	大专	66355.03	14
广东	广东广州	184692692	11/14/76	女	2007/11/25	大专	66351.54	10
山西	山西大同	5173599262	8/23/70	女	2016/6/16	高中	66344	2
重庆	重庆巴南	5129373632	4/25/84	女	2016/4/27	大专	66340.7	18
浙江	浙江杭州	5199507332	9/19/78	女	2016/8/1	大专	66339	5
四川	四川青羊	23300742	10/15/73	女	2004/11/19	大专	66326.45	8
上海	上海黄浦	576244152	2/5/87	女	2013/9/3	中专	66326	6
陕西	陕西汉中	5043085312	1/26/76	女	2015/12/8	高中	66304.5	5
北京	北京良乡	5088423292	2/10/74	女	2016/3/11	大专	66298.11	1
陕西	陕西西安	420726492	6/7/78	女	2011/4/2	大专	66297.5	6
四川	四川青羊	391027742	5/2/80	女	2010/8/17	本科	66279	6
江西	江西赣州	5063769922	2/6/80	女	2016/1/29	中专	66260.36	4
江苏	江苏扬州	5214234092	8/20/64	女	2016/8/12	高中	66260	2
佛山	佛山南海	5122722312	1/27/76	女	2016/4/16	大专	66259.5	12
山东	山东威海	5209028772	11/29/82	女	2016/8/8	中专	66259	6
江西	江西南昌	5195757912	8/17/78	女	2016/7/28	高中	66241	7
辽宁	辽宁本溪	660843502	10/16/77	女	2014/8/24	中专	66238.32	11
浙江	浙江温州	5214791652	8/17/78	女	2016/8/12	中专	66229	8
四川	四川青羊	134492	7/16/63	女	2001/12/30	高中	66213.29	5
湖南	湖南益阳	450007512	8/8/68	女	2012/1/1	中专	66211	13
江苏	江苏无锡	5143964412	4/30/76	女	2016/5/22	高中	66202.5	5
辽宁	辽宁本溪	38896252	5/22/64	女	2005/4/17	高中	66199.51	17
福建	福建泉州	431271162	12/1/82	男	2011/7/25	大专	66179.8	5
河南	河南安阳	147356902	7/13/62	男	2007/7/2	高中	66166.46	4
浙江	浙江金华	673337382	1/7/91	女	2014/9/25	中专	66151	6
重庆	重庆万州	591461842	7/14/81	女	2013/12/13	大专	66138.47	8
河北	河北沧州	851115492	3/21/82	男	2015/8/10	大专	66060	2
黑龙江	黑龙江牡丹江	129792092	4/19/71	女	2007/4/11	大专	66026.5	20
江西	江西上饶	399419292	10/5/85	女	2010/10/8	大专	66018.08	1
辽宁	辽宁鞍山	433138722	9/25/73	女	2011/8/8	大专	66004.21	13
河南	河南周口	507868362	2/25/76	女	2013/3/19	高中	66002	4
河南	河南濮阳	482030492	9/15/84	女	2012/11/1	高中	66000	0
吉林	吉林长春	312506592	10/8/56	女	2009/7/1	大专	65996.1	2
广东	广东广州	636347582	1/22/82	女	2014/6/13	高中	65995	3
吉林	吉林长春	290353052	5/17/73	男	2009/4/1	本科	65981.93	8
浙江	浙江杭州	5150354642	12/17/93	女	2016/5/27	本科	65979	8
云南	云南昆明	468470312	3/2/71	男	2012/6/19	高中	65939.5	10
吉林	吉林辽源	5175325322	8/4/67	女	2016/6/19	高中	65936	14
四川	四川德阳	136928082	2/28/82	男	2007/5/25	本科	65921.02	4
青岛	青岛一支	150574652	10/13/79	女	2007/7/18	大专	65919.04	7
重庆	重庆涪陵	591744332	8/23/70	女	2013/12/14	本科	65869.45	12
山西	山西临汾	613901642	12/8/63	女	2014/3/15	高中	65748.02	14
辽宁	辽宁营口	88838272	12/25/65	女	2006/6/25	大专	65736.98	9
四川	四川青羊	5175714222	9/7/70	男	2016/6/20	本科	65717	8
广西	广西玉林	541031752	3/8/72	女	2013/6/18	高中	65714.11	1
云南	云南大理	5090788472	12/21/72	女	2016/3/12	中专	65680	2
湖北	湖北随州	769950082	8/10/64	男	2015/6/24	大专	65676	8
四川	四川德阳	5088873882	9/1/89	女	2016/3/11	中专	65655	6
上海	上海黄浦	400006732	10/7/77	女	2010/10/12	大专	65646.91	8
北京	北京海淀	649491432	6/13/89	男	2014/7/31	本科	65640	3
辽宁	辽宁朝阳	50706662	11/3/68	男	2005/7/1	大专	65636.72	4
四川	四川泸州	522278652	4/7/72	女	2013/5/26	大专	65636.07	11
福建	福建漳州	324065642	8/18/81	女	2009/8/14	中专	65631.73	9
浙江	浙江嘉兴	134275582	5/16/77	女	2007/5/8	大专	65621.21	2
湖北	湖北荆州	822633362	11/5/84	女	2015/8/1	本科	65619.3	3
大连	大连开发区	5152750632	9/4/76	女	2016/5/30	初中	65610	3
陕西	陕西安康	486320682	5/17/73	女	2012/12/1	高中	65604.5	10
吉林	吉林松原	864041752	2/9/72	女	2015/8/27	大专	65561	6
广西	广西百色	603402752	7/26/69	男	2014/1/1	本科	65550.1	14
湖北	湖北荆州	69753832	8/24/66	女	2005/12/20	中专	65536.98	18
四川	四川绵阳	5154222872	1/6/76	女	2016/5/30	大专	65531	8
大连	大连开发区	23689442	4/8/61	女	2004/11/23	初中	65523.43	4
山东	山东淄博	745979772	7/23/62	男	2015/4/15	本科	65523	1
江苏	江苏无锡	789850432	10/26/81	男	2015/7/15	高中	65520	8
深圳	深圳南山	407325142	9/7/86	女	2010/12/2	高中	65520	2
四川	四川眉山	835851322	12/27/77	女	2015/8/7	中专	65498	4
湖北	湖北随州	192389192	7/8/78	女	2007/12/10	高中	65488.68	8
浙江	浙江绍兴	488487482	9/26/74	女	2012/12/10	高中	65476.36	8
河北	河北邢台	423891622	8/1/67	男	2011/5/13	本科	65436.5	7
四川	四川绵阳	667026242	10/27/80	女	2014/9/5	大专	65433.11	1
青岛	青岛城阳	377418712	5/20/79	女	2010/5/25	本科	65406.92	12
河南	河南商丘	214365482	4/14/71	女	2008/4/2	大专	65398.89	14
青岛	青岛城阳	641294582	3/28/87	女	2014/6/24	大专	65397.03	10
辽宁	辽宁盘锦	777953902	5/1/73	女	2015/6/30	大专	65390	4
安徽	安徽合肥	764004052	11/2/77	女	2015/6/12	高中	65385.5	2
深圳	深圳龙岗	5097351862	4/26/96	女	2016/3/17	高中	65385	6
四川	四川高新	5108264812	9/4/83	女	2016/3/24	大专	65382.99	9
安徽	安徽合肥	62495582	7/4/71	女	2005/10/1	高中	65377.11	9
四川	四川成都	503889962	2/26/72	女	2013/2/22	高中	65373	4
重庆	重庆江津	5158070192	10/9/77	女	2016/6/1	大专	65339.2	19
山西	山西太原	5117173372	7/6/81	女	2016/4/6	大专	65287.5	12
四川	四川绵阳	299948512	8/2/82	女	2009/5/15	本科	65281.26	19
浙江	浙江嘉兴	135625072	11/8/83	男	2007/5/18	高中	65271	7
湖北	湖北武汉	20890122	3/5/61	女	2004/10/16	大专	65261.65	23
四川	四川青羊	5073909572	6/10/83	女	2016/3/4	大专	65212.55	1
浙江	浙江温州	5015840082	3/27/80	女	2015/11/11	高中	65143.82	4
山东	山东日照	502323312	6/18/70	男	2013/1/31	中专	65141.5	8
四川	四川高新	5165946232	3/10/84	女	2016/6/8	本科	65127.7	15
山东	山东济宁	830890642	3/21/63	女	2015/8/5	高中	65120	0
吉林	吉林长春	629110822	1/17/68	男	2014/5/19	大专	65118.38	6
福建	福建泉州	420529422	5/11/71	女	2011/3/31	高中	65115.67	6
辽宁	辽宁盘锦	286255462	3/19/79	女	2009/3/20	大专	65115.4	3
山东	山东淄博	675604252	4/18/70	女	2014/10/1	大专	65099	3
浙江	浙江金华	871633712	3/30/79	男	2015/9/15	高中	65081.8	3
安徽	安徽合肥	61167312	4/19/66	女	2005/10/1	高中	65076.55	11
苏州	苏州昆山	5187919952	1/8/82	女	2016/7/8	硕士	65027.5	14
青岛	青岛一支	5171937592	7/3/81	女	2016/6/15	大专	65023	6
苏州	苏州常熟	284645402	10/26/72	女	2009/3/13	高中	65012.49	3
青岛	青岛开发区	184966942	12/20/70	女	2007/11/25	高中	64982.54	10
四川	四川高新	838311492	8/4/83	女	2015/8/8	大专	64944.21	25
山东	山东淄博	5185786852	4/16/71	女	2016/6/30	中专	64913.5	17
四川	四川高新	416880962	6/24/87	女	2011/3/5	大专	64908.1	0
湖北	湖北荆州	50632552	9/2/72	女	2005/7/1	大专	64905.2	14
河北	河北石家庄	5158032112	11/10/70	女	2016/6/1	大专	64895	6
山东	山东济南	330848802	5/16/81	女	2009/9/16	大专	64884.7	10
黑龙江	黑龙江牡丹江	477833532	11/8/72	女	2012/9/12	高中	64874.61	6
河南	河南三门峡	5207342592	6/26/65	女	2016/8/6	高中	64857.8	15
重庆	重庆巴南	5192808932	1/11/87	女	2016/7/26	本科	64856	8
河北	河北邢台	5147301392	8/23/64	女	2016/5/26	高中	64853.54	1
黑龙江	黑龙江牡丹江	5141433342	7/17/98	男	2016/5/18	高中	64849	14
大连	大连明锐	811695252	4/7/69	女	2015/7/29	高中	64840	1
黑龙江	黑龙江哈尔滨	153718292	9/8/78	女	2007/7/25	大专	64828.93	2
四川	四川成都	52690072	7/9/70	女	2005/7/26	大专	64823.58	7
广东	广东清远	636037462	2/2/71	女	2014/6/12	高中	64818.9	21
青岛	青岛即墨	292438202	12/2/75	女	2009/4/14	初中	64803.2	13
黑龙江	黑龙江哈尔滨	795573432	10/22/80	女	2015/7/20	本科	64801.9	8
河南	河南郑州	99037922	10/28/65	女	2006/10/1	大专	64782.92	8
天津	天津市区二支	708617052	3/23/64	男	2015/2/9	中专	64769	11
宁波	宁波宁海	5167079422	1/4/71	女	2016/6/8	初中	64754.5	9
云南	云南昆明	420509602	1/6/77	女	2011/4/1	高中	64749.95	2
山东	山东淄博	800489032	10/30/79	女	2015/7/24	中专	64745.9	14
江西	江西南昌	428705752	8/12/83	女	2011/6/25	高中	64745.15	5
江苏	江苏南京	583309102	5/29/88	女	2013/11/7	大专	64742.05	6
山西	山西临汾	370872262	9/27/60	女	2010/4/21	大专	64735.24	5
浙江	浙江嘉兴	507551402	3/19/70	男	2013/3/18	高中	64730.44	3
辽宁	辽宁辽阳	5200614212	9/1/80	女	2016/8/2	大专	64721	15
广西	广西南宁	5039843532	2/18/76	男	2015/11/30	高中	64703	6
山东	山东济宁	5208184782	9/3/74	男	2016/8/7	大专	64698	10
黑龙江	黑龙江哈尔滨	376230152	10/25/71	女	2010/5/21	大专	64696.48	9
浙江	浙江杭州	378179362	3/18/74	女	2010/5/31	本科	64659.34	3
重庆	重庆永川	347343272	1/2/80	女	2009/12/2	高中	64651.4	0
湖北	湖北武汉	838914982	8/28/74	女	2015/8/8	高中	64645	4
吉林	吉林通化	553070362	12/27/76	男	2013/6/26	大专	64642.38	2
北京	北京平谷	5142666232	5/19/87	女	2016/5/19	大专	64639	8
浙江	浙江杭州	5013853902	10/28/69	女	2015/11/9	中专	64635	4
湖南	湖南郴州	5205858582	10/5/79	女	2016/8/5	本科	64628.4	18
云南	云南昆明	5176233702	1/12/88	女	2016/6/20	大专	64624	5
宁波	宁波城区	385725542	11/26/81	女	2010/7/9	大专	64617.26	2
北京	北京海淀	452122392	7/12/91	男	2012/2/3	大专	64610	8
浙江	浙江金华	425305312	1/28/74	男	2011/5/26	高中	64607.11	7
湖北	湖北荆州	5061651292	12/12/72	女	2016/1/16	高中	64605	11
福建	福建福州	5101944372	11/9/84	女	2016/3/21	大专	64604.5	10
四川	四川高新	363198652	2/17/74	男	2010/3/10	大专	64603.9	10
四川	四川成都	3511072	5/30/74	女	2003/4/8	中专	64583.56	9
广东	广东韶关	847324952	12/9/76	女	2015/8/10	高中	64564	17
浙江	浙江杭州	498759572	6/7/76	女	2013/1/7	大专	64552.49	5
黑龙江	黑龙江哈尔滨	859074932	12/7/72	女	2015/8/13	大专	64549.5	4
山东	山东威海	506337852	9/16/79	女	2013/3/12	大专	64508.24	4
四川	四川绵阳	158344562	10/25/77	女	2007/8/20	大专	64504.13	7
深圳	深圳南山	5233643162	1/5/95	男	2016/9/14	中专	64502.7	3
北京	北京平谷	579904192	9/22/56	女	2013/9/30	大专	64501.89	13
青岛	青岛一支	640814052	9/8/87	女	2014/6/24	本科	64481.05	9
河南	河南周口	73400892	4/5/78	女	2006/1/10	大专	64464.8	13
河南	河南驻马店	322620552	9/1/73	女	2009/8/6	高中	64436.77	14
青岛	青岛即墨	204877902	1/29/58	女	2008/2/25	高中	64430.34	4
四川	四川青羊	682324882	11/17/88	女	2014/11/14	大专	64428	2
广东	广东东莞	701609462	12/4/77	男	2014/12/31	本科	64425.5	8
山东	山东烟台	5008061042	8/19/78	男	2015/11/2	本科	64424	14
深圳	深圳福田	255879112	1/5/78	女	2008/10/8	本科	64421.4	4
湖南	湖南长沙	521108512	7/18/86	男	2013/5/20	本科	64397.62	3
北京	北京东城	394680942	7/26/86	女	2010/9/7	大专	64387.6	5
四川	四川青羊	5184378032	8/25/76	女	2016/6/27	大专	64382.5	9
山东	山东淄博	227376462	5/29/74	女	2008/6/1	大专	64373.1	9
浙江	浙江温州	546841842	1/2/84	女	2013/6/24	本科	64365.72	5
黑龙江	黑龙江哈尔滨	89830462	5/24/70	女	2006/6/30	大专	64347.91	3
江西	江西抚州	5189605162	12/10/72	女	2016/7/19	高中	64344	18
宁波	宁波宁海	5015709572	10/4/79	女	2015/11/11	中专	64338.4	10
四川	四川德阳	728772532	3/5/86	男	2015/3/26	大专	64321.22	6
山东	山东日照	287437882	1/2/66	男	2009/3/24	高中	64272.97	8
河北	河北石家庄	229074312	8/15/75	女	2008/6/5	本科	64267.23	8
安徽	安徽合肥	350944552	4/23/64	男	2009/12/1	高中	64250.2	11
北京	北京海淀	648866722	2/9/90	男	2014/7/29	高中	64240	6
湖南	湖南永州	5129247622	5/20/68	女	2016/4/27	高中	64207	8
山东	山东淄博	422851282	11/3/76	女	2011/5/2	大专	64206.41	14
四川	四川青羊	16010392	11/18/72	女	2004/7/19	本科	64205.11	3
四川	四川青羊	5194771572	4/28/93	女	2016/7/28	本科	64196	4
辽宁	辽宁沈阳	134659812	2/17/68	男	2007/5/10	本科	64186.11	4
江苏	江苏无锡	633597112	12/7/91	男	2014/6/6	大专	64178.78	0
湖南	湖南长沙	566011972	10/3/74	女	2013/6/29	高中	64174	8
江西	江西赣州	5063707332	8/9/72	女	2016/1/29	大专	64160	3
黑龙江	黑龙江哈尔滨	19471962	4/30/76	女	2004/10/1	大专	64136.65	10
福建	福建福州	5138734582	7/5/91	女	2016/5/13	大专	64136	12
浙江	浙江温州	725601262	9/19/79	女	2015/3/25	大专	64122	4
河北	河北唐山	261073702	6/24/73	女	2008/11/4	高中	64119.73	18
青岛	青岛一支	272590742	11/3/69	女	2008/12/25	大专	64093.02	3
辽宁	辽宁盘锦	53557902	3/27/77	女	2005/7/29	高中	64071.86	10
福建	福建莆田	542187242	9/22/72	女	2013/6/20	初中	64032.81	3
江苏	江苏无锡	5152423352	2/6/61	女	2016/5/30	高中	64030	11
陕西	陕西渭南	633787342	4/16/74	女	2014/6/6	高中	64014	1
四川	四川高新	735246272	12/18/75	女	2015/4/3	高中	63997.5	10
云南	云南昆明	5224702892	3/17/71	女	2016/8/26	本科	63980	7
深圳	深圳福田	590569722	7/10/79	女	2013/12/11	本科	63944.01	1
浙江	浙江嘉兴	510012272	3/29/51	男	2013/3/25	初中	63926.22	5
深圳	深圳福田	390395222	6/21/79	女	2010/8/11	大专	63901.42	5
辽宁	辽宁沈阳	5149242292	12/10/66	女	2016/5/27	大专	63898.8	8
江苏	江苏无锡	5105871522	11/30/87	女	2016/3/23	大专	63881.6	9
云南	云南昆明	5169987622	5/25/71	女	2016/6/14	本科	63870	12
江苏	江苏常州	664170532	10/3/71	女	2014/8/29	高中	63861	12
辽宁	辽宁营口	5189570752	12/22/80	男	2016/7/19	本科	63860	16
浙江	浙江嘉兴	5234732872	7/3/75	女	2016/9/18	高中	63850	3
黑龙江	黑龙江哈尔滨	520525812	4/3/77	女	2013/5/15	本科	63836	4
黑龙江	黑龙江哈尔滨	5091959742	10/24/88	女	2016/3/14	高中	63834	10
苏州	苏州新区	409145252	3/2/75	女	2010/12/15	大专	63802	6
湖北	湖北宜昌	5195005452	8/19/76	女	2016/7/28	中专	63768.5	15
北京	北京东城	828070802	8/22/65	男	2015/8/4	大专	63750	2
江苏	江苏扬州	492286532	6/18/86	女	2012/12/20	高中	63740	7
四川	四川青羊	5140668212	3/19/84	女	2016/5/17	本科	63732.9	16
北京	北京东城	4058872	4/23/77	女	2003/5/13	大专	63728.03	4
上海	上海黄浦	66339712	6/14/78	女	2005/11/24	本科	63719.66	9
四川	四川高新	5147149072	5/8/72	女	2016/5/26	大专	63712.6	16
山东	山东临沂	5051113672	4/16/78	女	2015/12/23	中专	63689.26	8
深圳	深圳宝安	5205412562	10/10/74	女	2016/8/4	大专	63685.5	11
北京	北京东城	1196052	2/25/67	女	2002/5/23	大专	63682.6	6
云南	云南曲靖	247389862	4/16/75	女	2008/8/24	大专	63678.54	9
福建	福建福州	722964812	1/27/82	女	2015/3/25	高中	63660.5	10
四川	四川高新	5110542962	6/3/82	男	2016/3/25	大专	63660	18
山西	山西长治	375513882	5/1/57	女	2010/5/17	硕士	63641	5
宁波	宁波城区	5078148562	10/4/73	女	2016/3/7	大专	63631.5	10
北京	北京海淀	803358692	7/13/87	女	2015/7/26	本科	63618	9
山东	山东济南	587442832	10/20/85	女	2013/11/30	大专	63598.31	12
内蒙古	内蒙呼和浩特	249501672	1/31/74	男	2008/9/1	大专	63591.05	2
浙江	浙江杭州	5203516042	11/9/69	男	2016/8/3	中专	63590	12
山东	山东济南	356189392	4/5/68	女	2010/1/5	中专	63588.25	13
深圳	深圳福田第二	517703602	2/7/81	女	2013/4/26	大专	63583.32	1
陕西	陕西西安	395176552	10/20/73	女	2010/9/13	高中	63577.44	0
江苏	江苏南京	196020202	3/23/77	女	2008/1/1	高中	63520.25	10
浙江	浙江杭州	499735712	7/11/79	女	2013/1/16	大专	63519.13	11
吉林	吉林吉林	243595282	10/27/62	女	2008/8/1	大专	63517.33	6
安徽	安徽芜湖	5146516322	6/17/69	女	2016/5/25	高中	63511	9
重庆	重庆永川	799052452	6/18/73	女	2015/7/22	高中	63441.23	17
江苏	江苏南京	445467632	9/3/81	女	2011/11/28	大专	63428.22	7
贵州	贵州贵阳	248407042	8/25/84	女	2008/8/26	本科	63426.79	0
河南	河南郑州	544648112	6/3/82	男	2013/6/22	本科	63420	8
山东	山东济南	651641482	8/27/72	男	2014/8/5	本科	63401.11	8
山东	山东德州	535335412	10/10/76	女	2013/6/14	中专	63397.44	10
黑龙江	黑龙江齐齐哈尔	718932822	5/24/83	女	2015/3/24	大专	63380.56	10
浙江	浙江温州	5190684902	11/29/82	女	2016/7/21	高中	63380	16
青岛	青岛一支	784341252	10/4/86	女	2015/7/9	大专	63353.3	8
山东	山东济南	183261282	7/27/77	女	2007/11/23	大专	63333	3
上海	上海陆家嘴	308592122	9/2/81	女	2009/6/25	中专	63331.12	13
山东	山东日照	5160597662	8/1/76	男	2016/6/3	中专	63324.2	12
重庆	重庆永川	5201624092	3/5/93	女	2016/8/2	大专	63319	28
湖北	湖北襄阳	5122405972	6/10/73	女	2016/4/15	本科	63318	13
江苏	江苏无锡	324470392	4/5/65	女	2009/8/18	高中	63316.41	7
辽宁	辽宁丹东	432449812	3/31/57	男	2011/8/2	高中	63303.96	15
吉林	吉林长春	492352132	8/11/67	女	2012/12/21	高中	63295.78	7
佛山	佛山顺德龙江	5206858492	1/1/85	女	2016/8/5	高中	63277.7	13
陕西	陕西渭南	5082873452	2/25/64	男	2016/3/9	高中	63256.5	17
辽宁	辽宁铁岭	5192788022	10/26/67	女	2016/7/26	本科	63254.9	14
四川	四川青羊	700202612	11/3/82	女	2014/12/30	本科	63220	7
甘肃	甘肃兰州	611981462	7/1/90	男	2014/3/10	本科	63218.81	29
山东	山东淄博	168730402	2/2/69	女	2007/10/9	初中	63215	11
黑龙江	黑龙江佳木斯	5149729092	4/8/70	女	2016/5/27	中专	63191.5	3
四川	四川巴中	5145594372	9/5/71	男	2016/5/24	高中	63182	11
浙江	浙江杭州	574665492	10/17/87	女	2013/8/26	大专	63173.71	4
宁波	宁波象山	828197532	6/21/80	男	2015/8/4	高中	63161	4
北京	北京平谷	866793622	8/4/81	女	2015/9/6	本科	63161	2
安徽	安徽滁州	5156023222	8/4/71	女	2016/5/31	大专	63156	13
广东	广东湛江	306742282	7/11/55	女	2009/6/22	大专	63155.91	1
湖北	湖北荆州	853406922	12/19/71	女	2015/8/10	高中	63151.5	12
黑龙江	黑龙江七台河	5190343082	1/10/87	男	2016/7/20	大专	63136	1
四川	四川高新	460012192	12/8/87	女	2012/3/27	本科	63133.32	14
北京	北京海淀	437497582	1/20/88	男	2011/9/13	本科	63130.65	7
安徽	安徽合肥	864417472	4/23/86	女	2015/8/28	大专	63103.7	7
四川	四川高新	5108181382	1/7/72	女	2016/3/24	大专	63102.4	12
广西	广西南宁	539523162	8/2/76	女	2013/6/18	高中	63094.3	7
四川	四川高新	5142389772	3/26/83	女	2016/5/19	硕士	63083.4	9
重庆	重庆渝中	388385852	5/10/71	女	2010/7/29	大专	63045.43	5
宁波	宁波城区	363765302	7/3/71	女	2010/3/15	中专	63037.17	2
浙江	浙江金华	323293412	5/23/80	女	2009/8/10	中专	63020.37	6
山东	山东淄博	105946142	4/10/67	女	2006/12/4	大专	63017.77	9
山东	山东威海	92587772	4/20/76	女	2006/8/1	中专	63013.4	9
山西	山西忻州	596695102	10/11/80	女	2013/12/24	高中	63013.26	0
山东	山东滨州	374875222	12/2/68	女	2010/5/13	中专	63009.9	14
山西	山西临汾	694438302	10/13/87	女	2014/12/22	大专	63000	0
河南	河南周口	530137052	2/11/69	女	2013/6/8	高中	63000	0
山西	山西临汾	871907962	7/5/77	男	2015/9/15	大专	63000	0
辽宁	辽宁阜新	48199042	10/1/70	女	2005/6/23	大专	62993.07	4
江苏	江苏盐城	16693162	6/6/67	女	2004/7/31	大专	62984.98	10
重庆	重庆渝中	5201392982	4/12/82	女	2016/8/2	大专	62961.1	12
江西	江西抚州	397204342	1/4/83	女	2010/9/25	中专	62954.56	8
苏州	苏州张家港	497352722	5/4/71	女	2012/12/31	初中	62946	4
湖北	湖北襄阳	82321822	10/26/67	女	2006/4/22	高中	62924.53	21
四川	四川成都	5224289482	7/2/81	女	2016/8/26	中专	62919.9	11
青岛	青岛一支	5213555092	2/16/84	女	2016/8/11	大专	62899.5	14
江苏	江苏徐州	5071005942	9/29/83	女	2016/3/2	高中	62899.5	4
深圳	深圳宝安	508977372	1/13/80	男	2013/3/22	大专	62875.31	5
北京	北京海淀	122583662	10/3/72	女	2007/3/13	大专	62864.17	4
湖南	湖南长沙	216173312	1/29/73	女	2008/4/14	高中	62848.78	13
四川	四川青羊	520282962	7/8/83	男	2013/5/14	大专	62837.47	3
广西	广西柳州	5204606652	7/2/75	女	2016/8/3	高中	62824	16
福建	福建厦门	339030302	3/17/78	女	2009/10/27	中专	62803.3	21
青岛	青岛一支	516797802	2/27/81	女	2013/4/24	大专	62787.55	6
辽宁	辽宁丹东	231868192	5/31/66	男	2008/6/18	本科	62779.01	7
安徽	安徽马鞍山	689072872	10/25/73	女	2014/12/5	中专	62773	7
广西	广西南宁	5210347792	6/13/82	女	2016/8/9	中专	62772.7	11
重庆	重庆渝中	541543412	1/21/88	男	2013/6/20	本科	62755.93	12
湖北	湖北武汉	788948402	8/29/70	男	2015/7/14	高中	62744.2	13
湖南	湖南长沙	456754802	2/21/63	女	2012/3/15	大专	62710.5	7
山东	山东威海	414104532	4/16/74	女	2011/1/14	高中	62708.85	8
湖北	湖北十堰	5142841192	1/15/76	女	2016/5/19	高中	62700	2
吉林	吉林长春	284690482	11/11/76	男	2009/3/13	大专	62699.5	6
山东	山东济南	450766762	8/12/77	女	2012/1/4	本科	62696	3
湖北	湖北襄阳	5145593472	1/9/74	男	2016/5/24	高中	62695.5	16
江西	江西赣州	5151926582	6/18/83	女	2016/5/29	高中	62694.8	6
辽宁	辽宁沈阳	5125900672	3/1/82	男	2016/4/21	本科	62689	1
山东	山东济南	5205152	8/16/65	女	2003/7/1	本科	62664.98	7
河北	河北唐山	254469192	3/9/71	女	2008/9/25	高中	62660.11	11
浙江	浙江金华	5067913712	4/12/72	男	2016/2/24	高中	62655.6	8
四川	四川高新	491800832	11/23/70	女	2012/12/20	大专	62645.3	10
北京	北京海淀	167728302	10/2/82	女	2007/10/1	本科	62634.71	7
山东	山东淄博	724831402	4/4/85	女	2015/3/25	大专	62631.3	14
湖南	湖南长沙	5152466902	2/26/92	女	2016/5/30	本科	62626	5
福建	福建福州	821930772	6/16/71	男	2015/7/31	高中	62618	3
北京	北京海淀	511557432	6/11/90	男	2013/4/1	大专	62612.15	0
河南	河南平顶山	545987962	9/2/59	男	2013/6/24	高中	62594.75	0
山东	山东济宁	5207725122	12/24/68	男	2016/8/6	大专	62589.4	17
湖北	湖北黄石	297037812	9/12/73	男	2009/5/4	大专	62583.83	15
深圳	深圳宝安	637268732	6/9/81	女	2014/6/16	高中	62579.38	2
内蒙古	内蒙古通辽	569503972	2/23/76	女	2013/7/16	大专	62578.54	0
四川	四川绵阳	580988912	7/1/78	女	2013/10/16	大专	62552.55	8
北京	北京平谷	621671552	6/15/89	女	2014/4/1	高中	62548.5	2
苏州	苏州太仓	5223277802	3/25/86	女	2016/8/25	大专	62543	9
广东	广东广州	833017802	10/26/79	女	2015/8/6	大专	62511.5	4
江西	江西新余	173901132	11/30/64	女	2007/11/1	高中	62509.34	3
山西	山西临汾	559117162	7/29/70	女	2013/6/28	本科	62489.61	2
江西	江西南昌	727506092	9/13/87	女	2015/3/25	高中	62479.9	27
黑龙江	黑龙江佳木斯	5169558842	7/29/76	女	2016/6/13	中专	62474.4	8
四川	四川青羊	99322	8/7/69	女	2001/12/30	大专	62462.25	2
贵州	贵州贵阳	665939422	4/23/85	女	2014/9/2	本科	62461	17
福建	福建厦门	682842682	8/27/78	女	2014/11/17	中专	62449	16
北京	北京海淀	648788842	7/15/91	男	2014/7/29	大专	62433	5
广东	广东珠海	572478872	1/11/81	女	2013/8/6	本科	62432.97	8
北京	北京海淀	556514212	9/25/87	女	2013/6/30	本科	62432.8	5
广东	广东惠州	18670702	12/14/64	女	2004/9/10	大专	62428.28	5
山西	山西太原	173836232	8/1/62	女	2007/11/1	大专	62419.88	13
河南	河南南阳	15769912	10/15/71	男	2004/7/1	大专	62356.75	0
湖北	湖北黄石	123065592	3/8/60	女	2007/3/18	中专	62316.88	12
上海	上海陆家嘴	250418082	5/1/73	男	2008/9/9	大专	62314.11	12
四川	四川高新	5033760642	6/18/71	男	2015/11/27	大专	62313.7	9
四川	四川高新	5013833962	6/7/77	男	2015/11/9	大专	62288	4
湖北	湖北武汉	5058572702	1/28/74	女	2016/1/8	大专	62282	13
云南	云南昆明	5227800522	3/29/79	女	2016/8/31	中专	62256.5	8
佛山	佛山顺德龙江	402608982	5/11/88	女	2010/10/29	中专	62253.05	6
甘肃	甘肃金昌	408130172	11/15/68	女	2010/12/8	本科	62248.65	6
湖北	湖北荆州	47394982	11/26/75	女	2005/6/18	大专	62229.55	14
浙江	浙江杭州	5074307542	1/26/91	女	2016/3/4	本科	62220	4
湖南	湖南株洲	5146617892	7/13/79	男	2016/5/25	大专	62215.5	6
贵州	贵州贵阳	5196496732	1/20/82	女	2016/7/29	本科	62212	11
四川	四川高新	5203867832	6/26/72	女	2016/8/3	大专	62206	8
四川	四川高新	246149382	10/17/85	女	2008/8/15	本科	62202.85	14
浙江	浙江湖州	18811112	11/7/71	女	2004/9/17	高中	62177.52	8
湖北	湖北武汉	206603112	3/14/82	女	2008/3/10	大专	62177.29	13
黑龙江	黑龙江哈尔滨	505289972	2/27/74	女	2013/3/4	大专	62173	12
山东	山东烟台	745057922	3/3/83	女	2015/4/13	高中	62163.2	5
陕西	陕西安康	572356612	6/24/67	女	2013/8/6	高中	62147.15	3
陕西	陕西宝鸡	5163612832	6/12/66	男	2016/6/6	高中	62146.44	4
河北	河北保定	853648102	8/10/69	女	2015/8/10	高中	62135.6	13
河北	河北石家庄	681191312	1/20/77	女	2014/11/10	高中	62132.5	12
四川	四川高新	5205831372	10/10/71	女	2016/8/5	高中	62129	9
河北	河北承德	665352952	8/25/72	男	2014/8/31	中专	62128.24	8
北京	北京海淀	281605602	7/24/86	女	2009/3/1	本科	62098.94	4
云南	云南昆明	152482552	12/10/71	女	2007/7/24	中专	62098.53	9
湖北	湖北武汉	623736882	1/24/83	女	2014/4/17	大专	62089.5	7
湖北	湖北襄阳	58648382	10/14/69	女	2005/9/15	大专	62073.96	15
上海	上海黄浦	5157854452	1/12/85	女	2016/6/1	硕士	62071.5	13
深圳	深圳福田第二	621098762	1/6/82	女	2014/3/31	本科	62052.22	14
四川	四川高新	5188908022	4/11/84	女	2016/7/15	本科	62047	19
山东	山东威海	16431192	7/27/74	女	2004/7/1	高中	62046.39	9
四川	四川青羊	663375412	7/31/84	女	2014/8/27	硕士	62039	3
山东	山东临沂	251418782	12/31/66	女	2008/9/12	高中	61989.9	8
河北	河北石家庄	668394152	9/30/71	男	2014/9/11	中专	61979.6	12
湖南	湖南长沙	390584752	11/5/73	女	2010/8/12	高中	61977.71	10
北京	北京海淀	426929052	7/21/88	女	2011/6/15	大专	61960	5
云南	云南昆明	317680392	5/11/77	女	2009/7/24	大专	61954.5	7
贵州	贵州黔南	5155561832	5/4/83	女	2016/5/31	中专	61953.2	20
山东	山东潍坊	5214739032	4/10/65	男	2016/8/12	高中	61947	16
广西	广西南宁	5205225832	6/15/71	女	2016/8/4	本科	61909.1	23
山东	山东临沂	271364912	10/14/78	女	2008/12/21	大专	61882.9	5
湖北	湖北武汉	678175102	8/8/83	女	2014/10/23	本科	61881.7	11
大连	大连海川	554165692	10/30/79	男	2013/6/26	中专	61880.66	0
青岛	青岛一支	5150355542	3/26/70	女	2016/5/27	本科	61872	11
广东	广东惠州	533285432	10/29/77	女	2013/6/13	大专	61860.23	0
湖南	湖南常德	558725392	12/28/67	女	2013/6/28	高中	61851.22	0
苏州	苏州新区	254813782	2/9/84	女	2008/10/1	中专	61849	13
江西	江西景德镇	400260192	11/21/75	女	2010/10/12	大专	61848.74	12
山东	山东淄博	695150102	9/2/73	男	2014/12/22	高中	61844.2	10
江西	江西南昌	871316752	4/5/88	女	2015/9/14	高中	61826	14
辽宁	辽宁阜新	113180802	3/11/63	女	2007/1/4	高中	61821.14	6
山东	山东威海	293613782	5/23/79	女	2009/4/20	大专	61816.18	6
山西	山西临汾	5199941582	11/4/70	女	2016/8/1	高中	61815.1	15
河南	河南三门峡	95574952	2/22/69	女	2006/9/1	本科	61812.22	0
河南	河南商丘	406004682	5/22/74	女	2010/11/25	高中	61808.7	5
云南	云南昆明	503586682	1/22/78	女	2013/2/18	大专	61802.02	6
广东	广东广州	5104405652	1/20/73	女	2016/3/22	高中	61793	5
四川	四川青羊	609117242	1/21/72	女	2014/2/12	大专	61786.25	8
辽宁	辽宁沈阳	694867612	7/17/85	男	2014/12/22	大专	61783.2	16
河北	河北唐山	189668722	5/1/75	女	2007/12/13	高中	61772.76	7
云南	云南昆明	437171412	10/3/80	女	2011/9/9	中专	61770.38	4
湖南	湖南长沙	731992652	4/18/79	女	2015/3/31	本科	61762.5	11
湖南	湖南郴州	5185012652	10/9/65	女	2016/6/29	高中	61762	7
河南	河南商丘	483064692	3/4/67	女	2012/11/14	高中	61756.92	9
山东	山东临沂	5221029562	3/11/87	女	2016/8/22	中专	61733	7
山东	山东菏泽	536942402	12/2/72	女	2013/6/15	中专	61730.75	11
四川	四川青羊	592554802	1/27/88	男	2013/12/17	硕士	61727	3
安徽	安徽宿州	477439392	10/2/61	女	2012/9/7	大专	61715.7	16
江苏	江苏南京	774842092	3/12/84	女	2015/6/26	大专	61697.7	6
黑龙江	黑龙江佳木斯	132804532	10/13/62	女	2007/4/25	本科	61675	2
青岛	青岛一支	616276122	4/12/81	女	2014/3/21	本科	61674	12
湖北	湖北荆门	838016722	10/28/66	女	2015/8/8	高中	61670.5	6
四川	四川青羊	575698022	3/23/82	女	2013/8/30	大专	61670.05	7
四川	四川高新	737974552	8/21/86	女	2015/4/8	本科	61640.1	9
浙江	浙江杭州	585867942	11/20/82	男	2013/11/25	高中	61630.76	3
陕西	陕西西安	516469262	10/11/68	男	2013/4/24	大专	61622.91	4
福建	福建福州	269085762	10/30/87	女	2008/12/9	高中	61598.99	7
四川	四川德阳	582231492	9/27/90	女	2013/10/28	高中	61595.81	15
湖北	湖北黄冈	5021515032	11/10/73	女	2015/11/18	中专	61595.7	7
河北	河北承德	5157378522	5/6/63	女	2016/6/1	高中	61595	5
深圳	深圳南山	824944612	3/2/92	男	2015/8/3	本科	61578	7
湖北	湖北十堰	810007312	2/1/80	女	2015/7/28	高中	61573.8	3
安徽	安徽阜阳	682186572	9/16/81	女	2014/11/13	高中	61568.4	7
山东	山东滨州	446478942	3/27/83	女	2011/12/8	中专	61565.05	4
江西	江西新余	5084925892	1/8/79	女	2016/3/10	高中	61549.3	1
黑龙江	黑龙江哈尔滨	32702902	11/18/74	男	2005/3/1	大专	61526.18	7
浙江	浙江杭州	5150242202	9/9/71	女	2016/5/27	大专	61508.35	9
江西	江西南昌	44666702	10/10/63	女	2005/5/27	高中	61501.96	5
四川	四川绵阳	350972882	10/20/81	女	2009/12/21	大专	61491.13	7
山东	山东临沂	274424532	6/6/69	女	2009/1/1	中专	61455.25	9
湖北	湖北武汉	613374632	11/7/86	女	2014/3/13	高中	61439.2	17
四川	四川高新	673681972	11/11/83	女	2014/9/25	大专	61433.1	8
浙江	浙江杭州	5175351632	6/26/78	女	2016/6/19	大专	61423.5	12
青岛	青岛一支	862526322	12/19/80	女	2015/8/24	本科	61416	1
广东	广东广州	5173493192	5/13/80	男	2016/6/16	高中	61412	9
河南	河南郑州	690662142	2/18/93	男	2014/12/13	本科	61396	11
江西	江西南昌	5193607572	9/20/64	男	2016/7/27	高中	61382.2	11
江苏	江苏南京	602170782	10/19/80	女	2013/12/31	大专	61378.5	8
河南	河南三门峡	41772752	8/22/72	女	2005/5/1	大专	61376.65	10
福建	福建福州	5116731922	11/20/74	女	2016/4/5	本科	61349	10
四川	四川高新	347622962	1/3/75	女	2009/12/4	大专	61305.77	11
山东	山东淄博	866125232	10/9/85	男	2015/9/2	高中	61301.4	18
河北	河北石家庄	609362462	4/16/75	女	2014/2/18	大专	61299.02	7
广西	广西梧州	5118258512	6/8/77	女	2016/4/8	高中	61280.5	24
天津	天津市区二支	504543702	8/1/74	男	2013/2/26	本科	61278.05	2
江苏	江苏镇江	496888242	10/17/63	女	2012/12/31	本科	61269.47	3
浙江	浙江嘉兴	422928462	1/10/89	女	2011/5/3	大专	61260.64	10
河南	河南郑州	808903742	11/2/75	女	2015/7/28	高中	61252	12
内蒙古	内蒙呼和浩特	249499302	6/9/69	女	2008/9/1	高中	61248.56	4
广西	广西南宁	230444592	4/25/77	女	2008/6/11	本科	61241.08	10
云南	云南昆明	691712392	7/8/72	女	2014/12/17	大专	61233.5	11
北京	北京海淀	529890432	8/28/88	男	2013/6/8	本科	61230.82	0
青岛	青岛一支	32453912	10/17/78	女	2005/2/28	大专	61219.7	10
四川	四川青羊	380744072	5/2/76	男	2010/6/18	大专	61216.52	10
河北	河北石家庄	9282222	6/2/76	女	2003/12/1	大专	61214.81	9
山东	山东威海	327440822	9/12/78	女	2009/8/28	大专	61194.81	11
辽宁	辽宁沈阳	5136281472	11/8/78	女	2016/5/10	硕士	61189.89	10
江苏	江苏泰州	5190308672	12/25/77	男	2016/7/20	高中	61184.2	16
上海	上海陆家嘴	363805912	4/11/79	男	2010/3/15	大专	61182.29	5
黑龙江	黑龙江哈尔滨	226239122	7/15/77	女	2008/5/24	大专	61162.6	9
深圳	深圳福田	5030302152	2/11/87	女	2015/11/25	大专	61160	5
河南	河南南阳	462325812	11/2/78	女	2012/4/19	本科	61145.66	7
山西	山西朔州	5152933762	9/8/72	男	2016/5/30	高中	61144.7	3
天津	天津市区二支	649632812	7/27/74	女	2014/7/31	高中	61137.52	5
江苏	江苏宿迁	521843202	3/10/80	女	2013/5/22	高中	61134.63	7
河北	河北保定	157810712	12/11/64	女	2007/8/16	高中	61134	3
广西	广西桂林	433908582	10/2/79	男	2011/8/11	大专	61120.83	10
四川	四川青羊	659625212	2/3/70	女	2014/8/21	本科	61111.94	3
四川	四川高新	277266562	2/5/79	女	2009/1/17	本科	61096.6	3
新疆	新疆昌吉	297484572	5/11/68	女	2009/5/5	大专	61095.54	12
四川	四川高新	5041134442	3/15/72	女	2015/12/3	大专	61079.5	10
浙江	浙江杭州	289933922	4/8/73	男	2009/3/30	高中	61054	3
河北	河北石家庄	451818412	8/28/69	男	2012/1/1	中专	61053.71	2
江西	江西南昌	5148950392	12/24/81	女	2016/5/26	高中	61050	12
深圳	深圳宝安	15259652	4/15/71	女	2004/6/30	高中	61046.53	2
青岛	青岛开发区	564412522	11/20/79	女	2013/6/29	中专	61034.95	15
大连	大连海川	75955692	3/28/63	女	2006/2/24	大专	61021.82	7
福建	福建泉州	5146283342	6/10/66	女	2016/5/25	高中	61001	9
湖北	湖北黄石	654010792	10/13/70	女	2014/8/9	高中	60981.75	9
安徽	安徽芜湖	5027201822	12/24/76	女	2015/11/23	高中	60976.76	6
重庆	重庆永川	5203159822	10/29/82	女	2016/8/2	本科	60964.6	13
山东	山东淄博	415713892	3/21/71	女	2011/2/17	大专	60964.54	8
湖南	湖南长沙	141245902	10/2/61	女	2007/7/1	高中	60959.92	3
山东	山东烟台	5134752112	12/7/81	女	2016/5/6	本科	60937.6	15
上海	上海黄浦	5096695502	8/24/72	女	2016/3/16	中专	60915	5
广东	广东广州	5049827262	1/15/69	女	2015/12/22	高中	60910	6
黑龙江	黑龙江牡丹江	500572842	5/13/76	女	2013/1/23	高中	60905.97	3
深圳	深圳福田	5031878662	6/14/72	女	2015/11/26	大专	60881	11
湖北	湖北襄阳	207828242	4/26/82	男	2008/3/18	高中	60861.5	17
黑龙江	黑龙江哈尔滨	5066885692	2/26/88	男	2016/2/19	中专	60857	5
山西	山西太原	155254242	3/12/68	女	2007/8/1	高中	60839.85	13
广东	广东江门	5182029192	12/1/66	男	2016/6/24	高中	60816	4
山东	山东日照	72119102	4/7/73	女	2005/12/31	高中	60815.19	2
黑龙江	黑龙江牡丹江	817986572	5/20/77	女	2015/7/31	大专	60814	4
甘肃	甘肃武威	736267492	1/12/87	女	2015/4/7	中专	60810.2	4
青岛	青岛一支	5021683692	10/28/81	女	2015/11/18	大专	60799	20
北京	北京东城	93290362	11/21/76	女	2006/8/11	大专	60798.24	12
江西	江西南昌	5194422552	2/27/70	男	2016/7/28	高中	60762.5	10
内蒙古	内蒙古通辽	569478712	5/18/67	女	2013/7/16	中专	60738.57	1
陕西	陕西西安	705657502	8/28/74	女	2015/1/22	本科	60738.5	2
山东	山东德州	343891882	6/25/72	女	2009/11/19	本科	60702.35	6
贵州	贵州贵阳	622223822	3/9/76	女	2014/4/4	本科	60689.7	10
湖北	湖北恩施	5217141392	10/5/67	女	2016/8/16	高中	60687.6	13
安徽	安徽宿州	379153132	1/1/64	女	2010/6/1	本科	60681.14	10
四川	四川青羊	211214032	10/14/83	女	2008/3/25	大专	60678.36	6
湖南	湖南长沙	433163982	9/5/73	女	2011/8/8	大专	60673.46	5
山东	山东威海	385862182	3/3/82	女	2010/7/12	中专	60672.73	17
广西	广西梧州	353449802	4/24/68	女	2009/12/30	中专	60660.41	13
北京	北京平谷	550754642	10/16/67	女	2013/6/30	高中	60658.24	15
四川	四川高新	380627252	10/15/87	女	2010/6/17	高中	60644	21
广东	广东广州	353606262	7/22/66	女	2009/12/30	中专	60639.75	12
青岛	青岛一支	326216392	7/14/71	女	2009/8/25	大专	60629.84	15
广西	广西玉林	408047822	12/22/77	女	2010/12/8	高中	60624.64	8
四川	四川青羊	250446412	2/3/64	女	2008/9/9	高中	60620.2	5
福建	福建福州	5225005662	5/13/94	女	2016/8/29	高中	60608.6	2
河南	河南周口	40527802	9/11/71	女	2005/4/28	高中	60600	3
山东	山东日照	468732282	6/16/78	男	2012/6/20	大专	60599.1	9
云南	云南昆明	168535702	11/11/73	女	2007/10/8	中专	60593.52	2
湖北	湖北武汉	168057542	1/1/79	男	2007/10/6	大专	60572.36	6
河南	河南郑州	5161194132	9/20/68	女	2016/6/3	大专	60557.83	16
广东	广东惠州	471679822	12/1/88	女	2012/7/4	大专	60546.3	9
山东	山东淄博	551595542	10/9/79	女	2013/6/25	高中	60530	7
苏州	苏州新区	5124784722	4/18/78	女	2016/4/19	本科	60524.88	4
河南	河南郑州	649928282	8/10/63	女	2014/7/31	本科	60513.6	10
浙江	浙江金华	5028753822	2/18/78	女	2015/11/24	大专	60502.5	8
辽宁	辽宁沈阳	829641922	9/26/65	女	2015/8/5	大专	60500.51	1
黑龙江	黑龙江哈尔滨	627668802	2/14/72	男	2014/5/9	本科	60475.84	5
北京	北京海淀	213474062	11/1/81	男	2008/4/1	高中	60468.41	5
云南	云南昆明	5103979572	11/17/72	男	2016/3/22	本科	60457.5	7
苏州	苏州吴江	5048870902	10/7/73	女	2015/12/21	高中	60444.37	3
四川	四川青羊	21091932	3/21/73	男	2004/10/19	高中	60423.99	10
浙江	浙江温州	691144072	11/27/72	女	2014/12/16	大专	60419.5	12
浙江	浙江杭州	335096982	5/18/74	女	2009/9/30	中专	60414.93	5
广东	广东广州	5783628662	6/5/84	女	2018/6/25	大专	60389.05	3
深圳	深圳罗湖	385247382	8/26/67	女	2010/7/5	本科	60378.35	4
四川	四川雅安	689364572	1/18/95	女	2014/12/8	高中	60374.85	5
苏州	苏州姑苏	575203112	6/13/82	女	2013/8/29	大专	60366.64	6
河北	河北唐山	633271912	5/28/78	女	2014/6/5	大专	60346.34	14
海南	海南海口	464620042	10/11/79	女	2012/5/14	大专	60345.88	10
山东	山东淄博	5156955142	6/8/88	女	2016/6/1	本科	60333	5
重庆	重庆巴南	258975572	3/1/66	女	2008/10/24	大专	60314.9	8
安徽	安徽合肥	244249022	1/1/62	女	2008/8/1	高中	60304.57	11
广西	广西北海	539101662	7/7/81	女	2013/6/18	中专	60293.05	3
云南	云南昆明	394157702	8/15/77	女	2010/9/2	本科	60287.89	5
四川	四川高新	407844612	2/11/81	女	2010/12/7	高中	60263	11
广西	广西南宁	441638852	6/5/84	男	2011/10/10	大专	60252.26	5
青岛	青岛一支	480833692	4/15/79	女	2012/10/22	大专	60246.68	8
四川	四川成都	228265512	10/21/66	女	2008/6/3	高中	60235.87	16
湖北	湖北襄阳	284506392	3/20/72	女	2009/3/12	大专	60234.44	10
辽宁	辽宁丹东	372355592	6/28/78	女	2010/4/28	大专	60231.11	9
浙江	浙江温州	20365482	2/14/75	女	2004/9/30	大专	60227.58	4
浙江	浙江金华	5015136642	4/30/74	男	2015/11/10	高中	60218.5	10
辽宁	辽宁营口	198206122	6/26/69	女	2008/1/4	大专	60214	16
山西	山西临汾	5189845412	5/10/76	女	2016/7/19	高中	60207.1	9
青岛	青岛一支	5218750512	12/13/78	女	2016/8/18	大专	60205.9	12
山东	山东泰安	5168372132	8/29/78	女	2016/6/12	大专	60190.65	6
苏州	苏州新区	5028979532	9/16/67	女	2015/11/24	高中	60184	7
福建	福建福州	304677922	12/23/81	女	2009/6/1	本科	60175.47	8
湖北	湖北荆州	5062975782	3/17/87	女	2016/1/25	高中	60174.2	10
北京	北京海淀	311460112	7/29/86	男	2009/7/1	本科	60171.02	7
陕西	陕西西安	5194133352	9/11/82	女	2016/7/27	本科	60163.3	14
福建	福建福州	765512642	7/14/79	女	2015/6/17	高中	60131.2	19
四川	四川高新	5170290392	7/30/85	男	2016/6/14	大专	60130	14
福建	福建三明	731692442	12/12/64	女	2015/3/31	高中	60130	1
四川	四川高新	5084851532	2/7/79	女	2016/3/10	高中	60127	9
江苏	江苏徐州	787780632	11/6/69	女	2015/7/13	大专	60115.5	3
四川	四川高新	457888102	2/6/88	女	2012/3/21	本科	60114.17	8
江西	江西南昌	5150901262	6/9/91	女	2016/5/27	高中	60104.4	11
四川	四川高新	5132683402	9/10/80	女	2016/5/4	本科	60100	3
重庆	重庆渝中	163306912	2/24/68	女	2007/9/17	大专	60077.37	10
山东	山东滨州	454648162	10/15/75	女	2012/3/6	中专	60076.78	7
大连	大连海川	701136742	3/3/75	女	2014/12/31	本科	60075.5	9
广西	广西南宁	346091482	12/3/73	女	2009/11/26	本科	60072.7	0
青岛	青岛一支	5015907172	9/12/76	女	2015/11/11	大专	60071	8
重庆	重庆永川	704066562	11/8/71	女	2015/1/13	高中	60066.5	13
福建	福建龙岩	257653442	9/25/71	女	2008/10/20	大专	60064.07	1
湖北	湖北武汉	582237632	6/21/86	女	2013/10/28	高中	60035	14
宁波	宁波宁海	5193725412	7/26/87	女	2016/7/27	高中	60032.5	9
福建	福建福州	429619092	5/5/79	女	2011/6/29	中专	60022.5	14
四川	四川青羊	582251312	9/12/71	女	2013/10/29	大专	60012.36	5
山西	山西长治	325429782	5/26/75	女	2009/8/24	本科	60011.44	2
四川	四川高新	513561632	12/10/75	男	2013/4/2	大专	60004.15	6
黑龙江	黑龙江佳木斯	5104468172	10/24/84	男	2016/3/22	大专	60000	0
佛山	佛山禅城	648554232	1/16/70	女	2014/7/29	高中	60000	0
云南	云南昆明	5075497852	8/1/76	男	2016/3/4	中技	60000	0
黑龙江	黑龙江黑河	484472242	4/11/81	女	2012/11/22	高中	60000	0
广东	广东中山	522535182	6/16/81	女	2013/5/24	大专	60000	0
四川	四川高新	752059372	11/24/70	男	2015/5/5	大专	60000	0
湖北	湖北十堰	577259232	10/12/85	女	2013/9/7	大专	60000	0
广东	广东惠州	452448562	9/7/72	女	2012/2/14	高中	60000	0
黑龙江	黑龙江伊春	875374702	11/24/83	女	2015/9/21	本科	60000	0
山东	山东济南	603606662	5/15/69	女	2014/1/1	中专	60000	0
黑龙江	黑龙江哈尔滨	729159832	3/25/75	男	2015/3/27	大专	60000	0
大连	大连银洲	5048094902	6/11/78	女	2015/12/18	中专	60000	0
河北	河北沧州	328308382	8/23/64	女	2009/8/31	高中	59972.6	17
四川	四川高新	5033391682	9/12/85	男	2015/11/27	大专	59969	5
宁波	宁波宁海	813247252	8/19/76	女	2015/7/30	高中	59968	9
山东	山东日照	286044712	10/17/84	女	2009/3/19	中专	59958.65	3
四川	四川高新	427694442	4/10/71	女	2011/6/22	中专	59949	3
浙江	浙江温州	873215442	9/1/77	男	2015/9/17	高中	59945	4
浙江	浙江温州	82040732	6/12/81	女	2006/4/19	大专	59940.74	3
河南	河南郑州	868281422	5/14/72	女	2015/9/8	大专	59917.12	17
山东	山东淄博	716654372	9/26/86	男	2015/3/23	本科	59906	19
广东	广东惠州	7351432	12/8/80	女	2003/8/31	中专	59895.15	9
黑龙江	黑龙江哈尔滨	96603712	6/7/72	女	2006/9/8	本科	59887.72	0
四川	四川高新	132314092	6/21/82	女	2007/4/25	中专	59881.58	18
四川	四川高新	745049412	4/19/79	女	2015/4/13	本科	59869.83	10
湖北	湖北荆门	5146129222	10/31/59	女	2016/5/25	大专	59850	10
湖北	湖北武汉	5015122102	5/12/79	女	2015/11/10	大专	59847.8	7
甘肃	甘肃酒泉	426598412	11/1/67	女	2011/6/10	高中	59846.87	1
湖北	湖北十堰	5197980742	7/2/62	男	2016/8/1	高中	59846.5	8
宁波	宁波宁海	520111852	2/17/68	女	2013/5/13	高中	59841	7
黑龙江	黑龙江哈尔滨	317251082	9/27/63	女	2009/7/24	大专	59831.05	10
浙江	浙江湖州	106065332	2/19/77	女	2006/12/4	大专	59830.34	6
重庆	重庆渝中	676596442	2/15/91	女	2014/10/10	大专	59829	10
贵州	贵州遵义	5158659462	4/25/84	女	2016/6/1	高中	59827.4	17
黑龙江	黑龙江牡丹江	143438662	7/12/70	女	2007/6/24	大专	59800.9	2
云南	云南昆明	441095792	7/3/70	女	2011/10/8	高中	59800.13	3
山东	山东烟台	408803032	6/30/79	女	2010/12/13	初中	59799	6
大连	大连海川	482248082	1/2/79	女	2012/11/5	本科	59790	3
山东	山东济南	653623492	9/10/86	女	2014/8/8	本科	59789.66	4
陕西	陕西西安	5036105882	6/16/80	女	2015/11/30	中专	59788.7	12
山东	山东威海	728557042	10/18/83	男	2015/3/26	中专	59783	5
河南	河南焦作	397762752	12/27/64	女	2010/9/25	高中	59749.81	4
青岛	青岛一支	404466362	7/20/81	女	2010/11/16	本科	59746.46	17
重庆	重庆巴南	5193249482	9/18/87	男	2016/7/26	大专	59728	7
云南	云南昆明	528145132	10/7/76	女	2013/6/5	中技	59727.12	7
宁波	宁波宁海	620669452	10/30/72	女	2014/3/31	高中	59702.57	5
山东	山东烟台	546677572	3/1/65	女	2013/6/24	初中	59700.1	4
广西	广西南宁	484221852	12/29/74	女	2012/11/22	大专	59695.64	3
辽宁	辽宁沈阳	5143799422	7/26/79	女	2016/5/21	中专	59694.69	9
青岛	青岛一支	5014771282	8/29/54	男	2015/11/10	本科	59688	1
四川	四川高新	5222470022	3/24/91	男	2016/8/24	本科	59686	7
江苏	江苏无锡	323692022	9/20/67	女	2009/8/12	高中	59685.92	5
湖南	湖南永州	5113097572	1/30/93	男	2016/3/28	高中	59674.16	13
江西	江西鹰潭	676665112	1/4/76	女	2014/10/10	高中	59670.22	11
青岛	青岛即墨	370467512	4/24/69	女	2010/4/19	高中	59649.72	5
广西	广西南宁	577262302	11/26/78	女	2013/9/7	大专	59635.54	13
山东	山东日照	441593772	8/25/77	女	2011/10/10	中专	59596.91	11
重庆	重庆永川	5203393702	12/3/73	男	2016/8/3	高中	59582.5	16
重庆	重庆渝中	5128760822	4/22/82	男	2016/4/26	本科	59549.5	5
山东	山东济宁	5211319592	4/15/73	女	2016/8/9	高中	59548	14
青岛	青岛开发区	186069812	8/24/69	男	2007/12/1	大专	59541.16	6
山西	山西临汾	5034256512	1/2/75	男	2015/11/27	中专	59540	4
北京	北京海淀	475168752	8/14/91	男	2012/9/1	大专	59528.2	2
河南	河南周口	102876612	12/15/70	女	2006/11/7	大专	59492.65	7
四川	四川绵阳	448342462	2/22/81	女	2011/12/23	大专	59482.93	3
北京	北京海淀	221560232	11/22/85	女	2008/6/1	本科	59481.53	9
广东	广东广州	5088840302	4/1/83	女	2016/3/11	高中	59474.3	11
山东	山东威海	413769152	9/15/71	女	2011/1/10	高中	59468.95	10
贵州	贵州贵阳	334767742	5/7/89	女	2009/9/29	大专	59467.8	10
江苏	江苏泰州	5204647432	9/26/88	女	2016/8/4	高中	59459	9
山西	山西太原	796282162	1/8/79	女	2015/7/20	中专	59457	7
山东	山东威海	181018972	10/27/77	女	2007/11/20	高中	59452	7
山东	山东菏泽	374576682	12/15/73	女	2010/5/10	中专	59451.94	0
湖北	湖北武汉	734417652	8/21/82	女	2015/4/2	大专	59438.8	4
山东	山东烟台	60382102	8/8/76	女	2005/9/29	大专	59417.18	9
浙江	浙江杭州	5047343342	10/21/84	女	2015/12/16	本科	59416.1	3
山东	山东临沂	625873952	1/2/72	女	2014/4/29	中专	59410.9	11
北京	北京海淀	5181662032	1/17/94	女	2016/6/24	大专	59410.6	26
北京	北京海淀	785614532	10/16/92	男	2015/7/10	本科	59410	7
山东	山东烟台	142568732	7/31/73	女	2007/6/22	高中	59397.7	22
青岛	青岛一支	551678862	10/30/81	男	2013/6/25	大专	59389.8	6
广东	广东惠州	7149892	10/12/77	男	2003/9/5	大专	59382.67	1
江西	江西南昌	534345592	12/9/89	女	2013/6/14	大专	59374.56	13
湖北	湖北十堰	813457302	7/1/78	女	2015/7/30	大专	59354.5	11
湖北	湖北襄阳	5087160492	1/8/81	女	2016/3/10	高中	59350	2
苏州	苏州昆山	5027162842	3/11/74	女	2015/11/23	高中	59321	5
山西	山西太原	5154548282	11/26/71	女	2016/5/31	大专	59319	4
重庆	重庆江津	554575882	7/8/75	女	2013/6/27	中专	59309.3	15
江西	江西宜春	493345022	7/14/60	女	2012/12/24	高中	59305.4	4
重庆	重庆永川	596721062	4/26/85	男	2013/12/24	本科	59290	16
陕西	陕西宝鸡	573030172	11/9/70	女	2013/8/9	中专	59284.65	6
佛山	佛山顺德龙江	482590302	5/9/76	女	2012/11/9	中专	59269.45	15
河北	河北唐山	5148638552	11/2/69	男	2016/5/26	中专	59262	4
山东	山东烟台	5231139302	4/26/67	女	2016/9/7	大专	59255	6
陕西	陕西西安	260867422	9/24/69	女	2008/11/1	大专	59245.98	8
黑龙江	黑龙江哈尔滨	5198583582	12/4/93	女	2016/8/1	本科	59237.3	9
湖北	湖北武汉	5176783022	1/18/80	男	2016/6/20	本科	59227.7	6
湖北	湖北武汉	20881882	11/11/53	男	2004/10/16	本科	59220.64	3
重庆	重庆巴南	5222765592	9/20/85	女	2016/8/24	大专	59212.2	12
辽宁	辽宁葫芦岛	453704122	8/10/78	女	2012/3/1	大专	59195.05	2
北京	北京海淀	508269342	10/14/91	女	2013/4/1	大专	59192	1
辽宁	辽宁营口	532630022	6/9/74	女	2013/6/10	初中	59189.88	9
江苏	江苏镇江	5203353822	12/26/83	女	2016/8/3	中专	59186	12
辽宁	辽宁朝阳	364346602	6/19/59	女	2010/3/16	大专	59173.2	1
山东	山东烟台	5216030912	11/8/88	女	2016/8/15	本科	59172.7	5
广东	广东茂名	728736662	8/26/88	女	2015/3/26	本科	59171	17
大连	大连海川	5107128922	5/12/82	女	2016/3/23	本科	59155	11
湖北	湖北黄石	58693462	8/12/60	女	2005/9/16	高中	59142.93	10
北京	北京东城	678399802	8/20/85	女	2014/10/24	本科	59129	10
广东	广东广州	79655372	4/11/76	女	2006/3/24	大专	59128.2	4
重庆	重庆渝中	5184330952	2/23/90	女	2016/6/27	硕士	59124.4	16
辽宁	辽宁沈阳	482659942	5/2/66	女	2012/11/9	高中	59106.62	1
浙江	浙江湖州	5163610062	10/28/68	女	2016/6/6	高中	59095	14
湖北	湖北十堰	5176941712	10/10/70	女	2016/6/20	中专	59081	5
湖北	湖北黄石	5112335142	10/6/68	女	2016/3/25	高中	59064	9
四川	四川高新	5216967332	2/8/80	女	2016/8/16	高中	59061	18
辽宁	辽宁沈阳	9692412	10/13/71	女	2004/1/5	大专	59059.5	6
安徽	安徽合肥	133262872	1/11/69	女	2007/5/1	高中	59057.86	8
浙江	浙江金华	5202515232	6/19/64	男	2016/8/2	中专	59049.5	9
吉林	吉林辽源	480408152	4/16/64	女	2012/10/12	高中	59039.9	14
四川	四川青羊	190706692	10/14/73	女	2007/12/18	大专	59039.7	4
四川	四川绵阳	434885422	8/22/74	女	2011/8/22	高中	59021.4	9
辽宁	辽宁朝阳	209344372	3/28/77	女	2008/3/21	大专	59020.97	11
湖北	湖北恩施	858805152	8/21/78	女	2015/8/12	高中	59010.2	22
湖北	湖北武汉	569876622	7/12/83	女	2013/7/19	本科	59003.71	6
山东	山东日照	63734392	9/12/72	女	2005/10/31	高中	59001.85	8
四川	四川绵阳	5205173212	5/3/73	男	2016/8/4	中专	59001	10
山东	山东泰安	822943482	9/11/82	男	2015/8/1	高中	58996.97	8
深圳	深圳福田第二	693898312	11/27/82	女	2014/12/20	本科	58995.6	8
辽宁	辽宁沈阳	279425822	2/2/68	女	2009/2/16	高中	58957.94	6
湖北	湖北武汉	5164565592	10/27/74	女	2016/6/7	大专	58950	9
青岛	青岛一支	8033502	9/1/56	女	2003/9/23	大专	58941.98	6
广东	广东广州	5176073212	3/6/77	女	2016/6/20	大专	58907.5	8
湖北	湖北襄阳	388281042	1/10/81	女	2010/8/1	中专	58900.51	13
山东	山东威海	5102170082	12/19/76	男	2016/3/21	中专	58897	14
山东	山东德州	287314922	5/30/77	女	2009/3/24	本科	58864.38	11
山东	山东威海	5202045672	1/24/79	女	2016/8/2	中专	58863.1	10
四川	四川高新	5069526432	8/3/84	男	2016/2/29	本科	58862	7
安徽	安徽安庆	780389512	10/23/81	女	2015/7/1	高中	58861.5	12
湖北	湖北荆门	532874542	5/1/62	女	2013/6/11	高中	58858.64	8
贵州	贵州黔东南	736339232	10/4/63	女	2015/4/7	中专	58834.1	17
江西	江西赣州	5151954692	9/27/76	女	2016/5/29	高中	58815	15
北京	北京良乡	670739872	5/20/84	女	2014/9/22	高中	58791.5	13
安徽	安徽合肥	468245882	7/26/80	女	2012/6/18	中专	58790.43	12
佛山	佛山顺德龙江	797123062	9/21/82	女	2015/7/21	高中	58781.6	12
湖北	湖北襄阳	95835252	12/8/54	女	2006/9/1	大专	58764.01	10
四川	四川成都	207866482	7/23/70	女	2008/3/17	中专	58759.4	10
四川	四川绵阳	332089982	7/29/79	男	2009/9/23	中专	58748.03	6
云南	云南昆明	836245462	2/1/75	女	2015/8/7	大专	58729.22	6
四川	四川巴中	486141062	6/9/73	男	2012/11/28	高中	58710	8
重庆	重庆江津	689760112	3/19/71	女	2014/12/9	高中	58705.6	10
苏州	苏州新区	180407942	11/11/62	女	2007/11/16	高中	58685.56	0
浙江	浙江温州	5784388322	7/31/86	女	2018/6/25	高中	58683.8	7
河南	河南许昌	325758052	12/1/70	男	2009/8/24	大专	58680.7	8
广东	广东广州	5219240982	6/25/92	女	2016/8/18	本科	58670	25
北京	北京良乡	859425392	9/6/80	男	2015/8/14	大专	58669	13
河北	河北唐山	513521022	11/11/80	女	2013/4/2	高中	58668	5
湖北	湖北恩施	5196911942	10/24/87	女	2016/7/29	高中	58650.4	13
湖北	湖北鄂州	502503632	1/15/82	女	2013/2/1	高中	58628.42	5
陕西	陕西西安	645732022	8/25/76	女	2014/7/9	大专	58627.74	8
湖北	湖北荆州	84485552	11/9/75	女	2006/5/12	高中	58606.64	11
浙江	浙江温州	576114352	10/17/85	男	2013/9/2	本科	58601.98	4
山东	山东威海	823244392	8/5/67	女	2015/8/1	本科	58591.5	13
苏州	苏州太仓	244736392	1/11/64	女	2008/8/6	高中	58590.41	5
黑龙江	黑龙江绥化	881900362	7/2/78	女	2015/10/8	大专	58540.2	13
四川	四川高新	188346592	8/16/77	男	2007/12/10	大专	58535.06	15
辽宁	辽宁沈阳	5201453702	6/16/75	男	2016/8/2	本科	58529	11
广东	广东惠州	432718622	4/7/71	女	2011/8/3	高中	58524.51	6
苏州	苏州张家港	617907672	8/25/89	女	2014/3/25	本科	58512.42	0
河北	河北唐山	34046252	8/25/68	女	2005/3/16	中专	58510	9
湖南	湖南邵阳	5130345432	4/15/62	女	2016/4/29	高中	58506.33	9
山东	山东烟台	472001252	8/29/80	女	2012/7/11	本科	58484.8	9
山东	山东临沂	274372612	7/8/70	女	2009/1/1	高中	58479.1	11
浙江	浙江杭州	5199096692	8/2/62	男	2016/8/1	大专	58478	11
江苏	江苏南京	789697742	10/6/83	女	2015/7/14	中专	58460.8	2
湖北	湖北武汉	767209792	8/7/76	女	2015/6/19	中专	58459.86	3
山西	山西临汾	692490762	2/14/69	女	2014/12/18	大专	58456.5	6
宁波	宁波象山	31601432	9/28/68	女	2005/2/16	本科	58445.41	4
北京	北京东城	261564842	7/2/82	女	2008/11/6	高中	58442.06	11
四川	四川绵阳	97609582	3/3/74	女	2006/9/19	本科	58441.67	3
四川	四川青羊	76976912	1/23/70	男	2006/3/1	大专	58430.6	5
河北	河北廊坊	369436382	10/17/62	男	2010/4/10	本科	58429.71	2
福建	福建南平	454884172	10/16/82	女	2012/3/7	中专	58398	2
山东	山东潍坊	669272592	10/27/79	女	2014/9/16	中专	58374.52	13
山东	山东烟台	123752132	10/2/69	女	2007/3/19	高中	58372.99	10
山东	山东济南	877971512	7/19/88	女	2015/9/24	中专	58343	6
四川	四川成都	22370382	5/28/62	女	2004/11/8	高中	58332.2	8
福建	福建福州	502263852	9/30/75	女	2013/1/31	高中	58314.7	7
青岛	青岛一支	5187405942	9/1/68	女	2016/7/5	大专	58310.5	5
广西	广西柳州	839212822	11/27/82	女	2015/8/8	高中	58309	3
山东	山东日照	347105862	3/21/78	女	2009/12/1	高中	58308.25	23
青岛	青岛一支	404596162	5/17/62	女	2010/11/18	大专	58299.86	5
安徽	安徽六安	5191963072	5/11/92	女	2016/7/25	高中	58278.93	8
山东	山东烟台	664214912	4/8/84	女	2014/8/29	本科	58273.05	8
深圳	深圳福田	613825162	12/5/80	女	2014/3/15	中专	58262.26	7
江苏	江苏扬州	5042651062	6/27/63	女	2015/12/7	高中	58248	3
福建	福建厦门	250015702	1/16/84	女	2008/9/8	中专	58246.73	22
四川	四川绵阳	5197397842	9/6/88	女	2016/7/30	本科	58246	13
四川	四川高新	646518902	1/9/86	男	2014/7/18	本科	58235.14	7
湖北	湖北十堰	203786342	4/13/69	男	2008/2/12	本科	58234.8	14
辽宁	辽宁沈阳	404556522	11/16/81	男	2010/11/17	大专	58222.5	15
江西	江西南昌	430985602	5/3/87	女	2011/7/21	高中	58202.29	8
四川	四川高新	538989312	1/7/74	女	2013/6/18	大专	58179.27	7
湖北	湖北武汉	121739692	1/24/71	女	2007/3/7	大专	58175	6
青岛	青岛一支	5213974872	11/4/75	女	2016/8/12	大专	58170.5	12
山东	山东济南	418713082	7/17/80	女	2011/3/23	大专	58166.36	14
河南	河南南阳	739572332	3/11/77	女	2015/4/9	中专	58157.5	8
山东	山东临沂	458163052	12/2/68	男	2012/3/22	初中	58121	5
河南	河南郑州	618047382	9/24/89	女	2014/3/25	大专	58119.34	12
浙江	浙江金华	691245812	2/12/84	女	2014/12/16	本科	58090.5	2
天津	天津市区一支	541973422	1/9/84	女	2013/6/20	本科	58074.8	5
辽宁	辽宁营口	5115266952	9/19/70	女	2016/3/31	本科	58070	1
山东	山东威海	39260662	7/25/73	女	2005/4/19	中专	58064.47	10
湖北	湖北恩施	5197695142	4/15/66	女	2016/7/31	大专	58062	9
山东	山东日照	496191792	10/6/73	男	2012/12/29	中专	58060.95	5
广东	广东肇庆	825276922	8/4/85	女	2015/8/3	本科	58056.6	9
辽宁	辽宁沈阳	628093372	7/29/71	女	2014/5/12	本科	58055.3	13
湖南	湖南株洲	5198348802	8/21/86	女	2016/8/1	中专	58054.3	11
福建	福建福州	5211230762	7/13/77	女	2016/8/9	初中	58029	10
苏州	苏州新区	578999092	11/22/80	男	2013/9/24	本科	58028.7	10
山东	山东烟台	531153802	11/12/86	女	2013/6/9	中专	58019.23	10
广西	广西玉林	285102072	11/1/63	女	2009/3/16	大专	58013.71	10
山东	山东临沂	477154532	11/29/85	女	2012/9/4	中专	58001.6	21
山东	山东济宁	5125697602	3/19/92	女	2016/4/21	中专	58000	0
深圳	深圳福田	292631502	7/19/78	女	2009/4/15	本科	57993.45	6
黑龙江	黑龙江绥化	732816802	11/7/78	女	2015/3/31	本科	57992.2	2
山东	山东东营	105947812	10/17/75	男	2006/12/4	大专	57989.69	8
广东	广东广州	408575532	2/26/76	女	2010/12/10	中专	57988.5	1
宁波	宁波宁海	495604352	12/21/79	女	2012/12/28	高中	57986.6	1
辽宁	辽宁沈阳	628083462	10/19/78	男	2014/5/12	本科	57986.02	3
湖南	湖南常德	428477282	7/27/71	男	2011/6/24	大专	57976.39	8
湖南	湖南长沙	206226692	11/28/63	女	2008/3/7	大专	57962.65	7
四川	四川青羊	296729092	11/30/71	女	2009/5/4	大专	57955.96	3
山东	山东济南	640785022	3/6/82	女	2014/6/24	大专	57938.56	11
河北	河北石家庄	5175864742	6/24/77	女	2016/6/20	本科	57910	12
四川	四川眉山	415223452	10/29/71	女	2011/1/30	本科	57887	11
四川	四川高新	5202677522	9/6/66	女	2016/8/2	本科	57857	16
北京	北京海淀	648608522	9/10/88	男	2014/7/29	本科	57850	1
浙江	浙江金华	635729712	11/19/80	女	2014/6/12	高中	57833.97	9
四川	四川高新	752185402	1/29/88	女	2015/5/6	本科	57827.3	4
浙江	浙江绍兴	874687192	8/6/76	女	2015/9/20	中专	57825	6
山西	山西大同	385783602	1/10/75	女	2010/7/9	高中	57820.7	5
四川	四川青羊	5174342652	3/23/73	女	2016/6/17	大专	57805	8
湖北	湖北武汉	257617572	4/23/79	女	2008/10/20	中专	57798.93	8
广西	广西梧州	5177779332	5/25/72	女	2016/6/21	高中	57795.1	16
深圳	深圳罗湖	770215122	12/23/82	女	2015/6/24	本科	57784.95	6
江西	江西南昌	5193464322	9/6/94	女	2016/7/27	高中	57782	7
贵州	贵州贵阳	660103372	3/27/78	女	2014/8/22	中专	57771	8
北京	北京良乡	554834782	2/21/73	女	2013/6/30	本科	57767.66	9
黑龙江	黑龙江绥化	273556972	1/21/69	女	2009/1/1	大专	57762.06	6
山东	山东济南	577030062	11/22/77	女	2013/9/6	大专	57743	13
广东	广东东莞	669953962	4/10/79	男	2014/9/18	高中	57740.5	6
湖北	湖北荆州	5198192882	11/1/74	男	2016/8/1	高中	57736.6	16
山东	山东德州	832440272	10/27/68	女	2015/8/6	大专	57728	1
江西	江西南昌	652913092	2/23/87	女	2014/8/7	大专	57708.94	12
河南	河南驻马店	155965342	1/6/63	男	2007/8/3	高中	57702.6	15
陕西	陕西西安	582670712	9/24/70	女	2013/11/1	大专	57696	2
云南	云南昆明	435714042	2/10/78	女	2011/9/1	大专	57690.72	4
吉林	吉林吉林	5148081962	2/5/82	女	2016/5/26	大专	57686	3
浙江	浙江绍兴	31482242	11/11/62	女	2005/2/7	高中	57674.4	4
河北	河北邢台	449459982	1/29/75	女	2011/12/30	高中	57664.93	7
河北	河北唐山	5011953782	11/4/63	女	2015/11/7	大专	57661	14
苏州	苏州新区	870908932	11/2/63	女	2015/9/11	高中	57654	8
安徽	安徽蚌埠	91667322	7/14/60	女	2006/7/1	大专	57652.38	11
黑龙江	黑龙江齐齐哈尔	5159386512	3/20/74	女	2016/6/2	大专	57628	7
四川	四川青羊	5124849042	12/21/80	女	2016/4/19	本科	57610	9
河南	河南南阳	252785022	2/28/75	女	2008/9/23	中专	57608.11	6
广东	广东惠州	241164142	9/3/81	男	2008/7/21	高中	57593	7
山东	山东济南	490897132	2/12/76	女	2012/12/18	本科	57588.39	10
苏州	苏州昆山	5189689492	3/21/70	女	2016/7/19	中专	57587	10
浙江	浙江温州	455731212	3/30/82	女	2012/3/9	本科	57579.3	8
山东	山东淄博	475884322	12/30/68	男	2012/8/27	高中	57569.72	10
新疆	新疆石河子	478486572	6/21/75	女	2012/9/21	高中	57531.16	4
四川	四川高新	776215442	10/18/88	女	2015/6/29	本科	57525.71	13
山东	山东临沂	516837442	11/28/73	女	2013/4/24	初中	57519.4	7
青岛	青岛即墨	467406382	10/19/67	女	2012/6/7	中专	57466.36	9
江西	江西南昌	751283372	1/29/83	女	2015/4/30	大专	57448	6
辽宁	辽宁营口	283561652	6/21/70	女	2009/3/10	中专	57442.4	14
北京	北京良乡	455611322	8/4/80	女	2012/3/9	大专	57402.09	0
北京	北京东城	167663402	3/15/82	男	2007/10/1	本科	57396.75	4
山东	山东济宁	492898262	3/20/73	男	2012/12/22	本科	57360	4
云南	云南昆明	384925952	4/14/70	女	2010/6/30	高中	57358.59	1
青岛	青岛一支	357071602	4/19/65	女	2010/1/12	大专	57358.48	4
安徽	安徽滁州	5192382852	3/21/69	男	2016/7/26	高中	57348.5	8
湖南	湖南衡阳	5060799132	12/5/75	女	2016/1/13	高中	57339.7	15
江西	江西赣州	5019261392	1/8/76	男	2015/11/15	大专	57330	3
重庆	重庆渝中	607965522	6/22/74	女	2014/1/27	大专	57329.54	5
四川	四川青羊	5196384292	6/13/86	女	2016/7/29	大专	57314	15
四川	四川成都	5217352632	11/20/78	女	2016/8/16	高中	57313	11
湖北	湖北宜昌	38752772	7/22/68	女	2005/4/15	大专	57308.73	2
山东	山东临沂	55796442	2/8/66	女	2005/8/25	高中	57274	9
湖北	湖北荆州	620071402	5/13/78	女	2014/3/29	高中	57272	15
苏州	苏州常熟	872928212	1/27/66	女	2015/9/17	高中	57259.11	3
福建	福建厦门	601969942	8/31/81	女	2013/12/31	大专	57257.08	17
新疆	新疆库尔勒	374722532	12/25/66	女	2010/5/12	大专	57249.16	4
四川	四川青羊	5106084562	12/17/75	女	2016/3/23	大专	57241	6
四川	四川宜宾	232128762	11/14/69	女	2008/6/19	中技	57231.05	4
黑龙江	黑龙江齐齐哈尔	676285622	11/26/62	女	2014/10/9	高中	57220.7	6
湖北	湖北武汉	282142522	6/3/75	女	2009/3/3	本科	57217.66	7
四川	四川高新	521068872	5/11/72	女	2013/5/20	高中	57217.5	11
山西	山西阳泉	680943022	6/14/72	女	2014/11/10	高中	57217	9
黑龙江	黑龙江绥化	385645292	6/26/72	女	2010/7/8	本科	57207.66	15
吉林	吉林长春	5124677752	12/23/62	女	2016/4/19	中专	57200	1
山西	山西太原	466303512	8/10/79	女	2012/5/29	大专	57194.17	3
山东	山东威海	181987302	4/16/73	女	2007/11/22	高中	57192.62	8
重庆	重庆江津	5091430292	1/18/75	女	2016/3/14	高中	57180	12
四川	四川高新	817427462	2/14/82	男	2015/7/31	大专	57177	10
湖南	湖南益阳	540540612	4/11/79	女	2013/6/19	高中	57158.07	8
佛山	佛山顺德龙江	508707862	5/23/88	女	2013/3/22	大专	57156.07	12
湖北	湖北荆州	404358752	2/28/74	男	2010/11/15	大专	57149.5	12
青岛	青岛开发区	366968672	4/21/76	女	2010/3/25	高中	57147.41	8
湖北	湖北襄阳	5211341332	11/20/74	男	2016/8/9	高中	57129.5	12
河南	河南周口	5211849942	4/1/69	女	2016/8/10	大专	57125	7
山东	山东济南	10701352	11/5/72	女	2004/3/1	大专	57104.96	11
山东	山东济南	71246102	4/26/66	女	2005/12/29	大专	57096.3	9
佛山	佛山顺德龙江	5079638942	11/19/72	女	2016/3/7	高中	57083.5	7
黑龙江	黑龙江哈尔滨	489763832	12/16/78	女	2012/12/13	高中	57081.73	14
辽宁	辽宁沈阳	496357462	10/6/75	女	2012/12/30	大专	57078.74	6
广东	广东广州	529426922	8/25/63	女	2013/6/7	中专	57072.67	4
山东	山东济宁	536352862	8/23/83	女	2013/6/15	大专	57067.9	24
云南	云南昆明	148194732	6/18/78	女	2007/7/1	本科	57060.99	8
山东	山东济南	8183122	9/22/69	女	2003/3/1	中专	57056.29	10
河南	河南驻马店	798314692	11/18/74	男	2015/7/22	高中	57053.5	6
青岛	青岛一支	5214259502	10/28/80	女	2016/8/12	大专	57053	13
吉林	吉林延边	602520542	3/5/73	女	2013/12/31	高中	57002.62	8
青岛	青岛城阳	740324742	2/9/92	男	2015/4/10	大专	56994	12
浙江	浙江嘉兴	636195592	1/25/82	女	2014/6/13	本科	56987	8
山东	山东济南	365818352	5/27/78	女	2010/3/24	大专	56971.65	9
浙江	浙江金华	5120403382	11/17/82	女	2016/4/12	高中	56951	12
浙江	浙江金华	276583792	5/8/64	女	2009/1/9	高中	56948.96	3
四川	四川青羊	134477122	9/3/78	女	2007/5/9	大专	56944.6	2
贵州	贵州贵阳	5126569702	4/10/80	女	2016/4/22	大专	56934.4	9
浙江	浙江温州	615965302	7/13/89	女	2014/3/20	高中	56931.68	7
福建	福建福州	5199789262	4/1/84	女	2016/8/1	高中	56931.5	9
云南	云南昆明	265182872	6/30/70	女	2008/11/24	本科	56931.46	2
青岛	青岛一支	5154384192	8/12/71	女	2016/5/31	大专	56926	15
江苏	江苏泰州	864996402	12/25/78	女	2015/8/31	大专	56860	4
湖南	湖南长沙	537510722	11/25/75	女	2013/6/17	本科	56838.81	9
四川	四川乐山	827526072	10/3/75	女	2015/8/4	本科	56810	2
宁波	宁波慈溪	101966072	2/8/59	女	2006/10/25	高中	56807.58	5
湖北	湖北武汉	20876442	1/19/76	男	2004/10/16	中专	56800.14	11
广东	广东广州	695343402	8/11/76	女	2014/12/22	本科	56791.3	6
四川	四川高新	5192494322	2/7/90	女	2016/7/26	大专	56790.1	11
北京	北京良乡	5207094172	9/19/75	女	2016/8/6	大专	56786.5	12
山东	山东威海	430642682	11/10/82	女	2011/7/15	中专	56775.14	11
浙江	浙江杭州	814722072	6/30/95	女	2015/7/30	高中	56775	5
湖北	湖北武汉	158649242	7/21/82	男	2007/8/22	大专	56768.42	6
苏州	苏州昆山	5106187932	9/10/84	女	2016/3/23	大专	56767.5	9
四川	四川绵阳	5197350692	8/1/85	女	2016/7/30	高中	56761.8	11
浙江	浙江杭州	5199126602	3/20/77	女	2016/8/1	中专	56745.5	13
山东	山东济南	5123666972	7/29/84	女	2016/4/18	大专	56744	14
北京	北京海淀	806687122	2/27/84	女	2015/7/27	大专	56743.5	9
山东	山东济南	709573372	11/3/72	女	2015/2/15	大专	56739.7	13
重庆	重庆江津	5034783192	2/12/73	女	2015/11/28	高中	56721	2
广东	广东中山	123198462	11/30/83	女	2007/3/19	高中	56720.55	2
天津	天津滨海新区	862317942	4/2/80	男	2015/8/21	本科	56694.4	2
佛山	佛山顺德龙江	519783312	11/12/84	女	2013/5/10	高中	56672.04	11
新疆	新疆哈密	5165766772	2/14/84	女	2016/6/8	中专	56670	8
四川	四川南充	688198202	4/23/88	女	2014/12/1	本科	56665.2	11
辽宁	辽宁沈阳	5188834632	11/18/76	女	2016/7/14	大专	56661	1
江西	江西上饶	5190232512	3/11/69	男	2016/7/20	本科	56656.5	10
辽宁	辽宁朝阳	442732512	6/5/61	女	2011/10/26	大专	56653.19	6
浙江	浙江杭州	375318212	11/14/87	女	2010/5/17	本科	56652.6	0
山东	山东烟台	202230572	5/28/72	女	2008/1/25	大专	56650.73	5
黑龙江	黑龙江绥化	5042630222	2/21/86	男	2015/12/7	大专	56641	2
辽宁	辽宁锦州	43041292	10/25/63	男	2005/5/16	大专	56633.23	14
山东	山东东营	497652932	11/7/70	男	2012/12/31	大专	56628.91	0
安徽	安徽滁州	5178247092	2/28/72	女	2016/6/21	高中	56620	3
贵州	贵州贵阳	5089210162	7/11/84	女	2016/3/11	大专	56616	3
浙江	浙江嘉兴	82054412	2/13/72	女	2006/4/19	高中	56612.92	4
湖北	湖北武汉	457924942	8/3/68	女	2012/3/21	大专	56605.7	10
大连	大连明锐	5126194372	4/28/73	男	2016/4/22	大专	56579	6
广东	广东广州	677675722	6/20/75	女	2014/10/21	大专	56574	8
重庆	重庆渝中	5154272722	9/13/79	女	2016/5/31	大专	56565	16
山东	山东东营	514422352	3/17/85	女	2013/4/8	大专	56555.02	7
湖北	湖北武汉	336840612	9/21/64	女	2009/10/16	高中	56551.94	5
苏州	苏州姑苏	454409082	6/21/82	女	2012/3/5	高中	56545.81	6
湖北	湖北黄石	5139158862	12/15/80	女	2016/5/14	高中	56543.5	6
四川	四川青羊	214779442	10/14/85	女	2008/4/7	大专	56541.5	9
上海	上海陆家嘴	147838832	1/4/60	女	2007/7/4	高中	56539.52	5
北京	北京东城	320085572	8/9/88	男	2009/8/1	高中	56535.17	5
湖北	湖北恩施	763026512	6/14/69	男	2015/6/10	高中	56534	3
湖南	湖南娄底	750906952	5/5/73	女	2015/4/30	高中	56519.3	4
宁波	宁波宁海	43299492	1/15/75	女	2005/5/17	中专	56500.71	6
山东	山东临沂	780100882	6/28/85	女	2015/6/30	高中	56498.6	23
天津	天津市区二支	423523442	3/17/68	女	2011/5/9	高中	56491.35	9
北京	北京海淀	473760232	10/10/89	女	2012/8/7	本科	56487	7
四川	四川高新	192273072	5/18/65	男	2007/12/21	本科	56465.99	8
吉林	吉林四平	440195162	7/18/67	女	2011/9/26	中专	56461.4	7
浙江	浙江杭州	5145278932	11/19/76	女	2016/5/24	中专	56452.2	7
湖南	湖南益阳	5146028622	8/17/77	女	2016/5/25	高中	56442.2	6
北京	北京海淀	248572982	9/18/88	男	2008/9/1	大专	56425.3	5
河北	河北石家庄	584857332	5/9/82	女	2013/11/19	本科	56421.6	16
辽宁	辽宁沈阳	754629522	4/10/66	女	2015/5/18	大专	56420	1
河南	河南三门峡	522035802	7/24/66	男	2013/5/23	大专	56415	3
江西	江西南昌	5177592602	12/3/68	女	2016/6/21	高中	56399.5	7
北京	北京海淀	544225912	12/1/88	女	2013/6/30	大专	56391.37	6
河南	河南郑州	5208510192	4/19/83	女	2016/8/8	本科	56391	16
苏州	苏州新区	5171273062	3/4/70	女	2016/6/15	高中	56375.88	2
江苏	江苏泰州	486688862	8/24/58	女	2012/11/30	大专	56374.88	8
广东	广东广州	8333712	11/10/84	女	2003/10/10	中专	56373.33	13
四川	四川青羊	192788772	2/12/73	女	2007/12/24	大专	56372.94	11
四川	四川青羊	818085942	1/8/86	女	2015/7/31	本科	56362.67	2
新疆	新疆昌吉	474547812	5/22/63	女	2012/8/14	大专	56355.16	20
四川	四川内江	5123675072	8/28/71	男	2016/4/18	高中	56350	1
浙江	浙江温州	5177646052	8/1/77	女	2016/6/21	高中	56330	12
浙江	浙江金华	568912762	8/3/76	女	2013/7/4	高中	56320.68	0
北京	北京海淀	5143058802	4/18/66	女	2016/5/20	本科	56318	3
湖北	湖北武汉	241261142	10/1/69	女	2008/7/21	中专	56307.88	9
辽宁	辽宁沈阳	477510432	3/14/53	女	2012/9/7	高中	56261.22	0
江西	江西赣州	582570642	9/24/70	男	2013/10/31	高中	56230.5	1
河南	河南郑州	202006842	11/30/76	女	2008/1/24	大专	56227.18	9
浙江	浙江金华	5189023162	9/25/82	女	2016/7/15	高中	56226.8	12
深圳	深圳福田第二	49332612	12/30/71	女	2005/6/29	大专	56226.02	6
山东	山东滨州	277265862	8/27/67	男	2009/1/1	中专	56224.48	1
广东	广东广州	5048359592	2/2/78	女	2015/12/18	高中	56219.5	15
山东	山东烟台	366906842	11/28/78	女	2010/3/25	中专	56210.5	5
山东	山东威海	453425402	10/11/82	女	2012/2/28	中专	56208	12
云南	云南昆明	5075614792	6/11/71	女	2016/3/4	高中	56200	16
湖北	湖北荆州	486476442	3/8/72	女	2012/11/29	大专	56194.72	14
广东	广东珠海	5047649782	1/26/76	女	2015/12/17	大专	56176.4	9
四川	四川青羊	464380262	11/10/73	女	2012/5/11	大专	56167.38	3
陕西	陕西西安	5019140852	8/25/79	女	2015/11/15	本科	56166	6
黑龙江	黑龙江齐齐哈尔	764835042	3/21/66	女	2015/6/15	高中	56161	2
四川	四川高新	5031756252	7/5/84	男	2015/11/26	本科	56158	10
湖北	湖北黄冈	5193960192	5/13/79	女	2016/7/27	高中	56150.5	10
山东	山东日照	451820782	1/16/62	女	2012/1/20	高中	56136.18	8
黑龙江	黑龙江齐齐哈尔	176200102	2/16/64	女	2007/11/5	高中	56135.97	3
四川	四川青羊	666882762	11/22/78	女	2014/9/4	大专	56119.05	8
黑龙江	黑龙江佳木斯	617311022	8/28/87	女	2014/3/24	高中	56114.76	6
上海	上海陆家嘴	10093392	10/17/60	女	2004/2/23	大专	56112.89	1
浙江	浙江湖州	28228622	9/13/69	女	2005/1/5	高中	56106.76	2
深圳	深圳福田第二	657346062	11/6/78	女	2014/8/17	大专	56091.13	6
四川	四川内江	738488582	2/18/82	女	2015/4/9	本科	56090.2	10
云南	云南玉溪	325650442	10/2/72	女	2009/8/24	高中	56087.26	5
上海	上海黄浦	585030812	8/31/69	女	2013/11/19	大专	56083.17	6
大连	大连开发区	179658602	9/14/70	男	2007/11/14	大专	56082.78	1
山东	山东烟台	5047921742	11/1/73	男	2015/12/18	本科	56081	5
北京	北京东城	346912	5/16/48	女	2002/1/18	中专	56069.1	9
湖南	湖南长沙	5217666272	7/24/68	男	2016/8/17	大专	56058.1	14
青岛	青岛一支	705498672	7/21/64	女	2015/1/22	大专	56036.5	8
苏州	苏州张家港	5226408042	11/21/65	女	2016/8/30	高中	56033.4	6
江苏	江苏南京	9399042	8/19/75	女	2003/12/16	大专	56033.02	3
甘肃	甘肃张掖	665857772	6/26/77	男	2014/9/1	大专	56026.1	0
甘肃	甘肃酒泉	608978232	6/7/63	男	2014/2/9	大专	56011.59	0
河南	河南周口	5165523822	8/19/78	女	2016/6/8	大专	56004	6
湖南	湖南岳阳	5043470612	5/5/69	女	2015/12/9	高中	56001	9
北京	北京海淀	702465712	11/7/90	女	2015/1/5	高中	56000	0
天津	天津武清	425924852	4/28/82	女	2011/6/3	高中	56000	0
天津	天津市区一支	5144661552	4/20/82	女	2016/5/24	大专	56000	0
湖南	湖南岳阳	687595682	10/19/90	男	2014/11/27	本科	56000	0
北京	北京良乡	792732102	10/25/72	女	2015/7/17	大专	56000	0
青岛	青岛开发区	356830152	9/2/67	女	2010/1/11	高中	55999.68	6
广东	广东广州	664056782	5/22/77	女	2014/8/29	大专	55998.21	13
山东	山东枣庄	5200052152	9/3/80	女	2016/8/1	中专	55994.5	9
北京	北京海淀	5086879462	8/25/90	男	2016/3/10	大专	55992	4
辽宁	辽宁沈阳	5166508292	3/31/89	男	2016/6/8	大专	55977	2
安徽	安徽滁州	5132212942	9/4/71	女	2016/5/3	大专	55973	7
江西	江西南昌	433093642	10/22/82	女	2011/8/8	中专	55969.72	10
贵州	贵州遵义	308558622	9/2/64	女	2009/6/26	大专	55965.17	2
湖南	湖南株洲	5198302552	12/24/77	女	2016/8/1	高中	55946.6	12
北京	北京海淀	127793332	6/1/81	男	2007/4/1	本科	55941.11	6
浙江	浙江温州	126291852	1/22/79	女	2007/3/25	大专	55935.93	6
重庆	重庆渝中	734618492	12/15/78	女	2015/4/3	本科	55926	16
陕西	陕西西安	5177842822	5/8/70	女	2016/6/21	高中	55926	13
河北	河北保定	161820512	10/1/68	女	2007/9/6	高中	55921.73	7
四川	四川青羊	477460882	10/19/87	女	2012/9/7	本科	55917.2	13
四川	四川高新	5216887572	3/24/81	女	2016/8/16	本科	55910.8	16
山东	山东潍坊	284565152	4/20/76	女	2009/3/13	高中	55890.73	16
河北	河北保定	498529702	5/20/85	女	2013/1/5	大专	55879.55	3
北京	北京海淀	425758212	9/30/87	男	2011/6/1	大专	55879	7
湖北	湖北黄冈	5143898222	2/6/89	女	2016/5/21	高中	55858	17
福建	福建泉州	570051502	1/20/84	男	2013/7/22	大专	55854.06	7
四川	四川成都	5048193702	6/4/77	女	2015/12/18	大专	55853.6	9
广东	广东湛江	271798692	11/30/66	女	2008/12/24	高中	55851.25	11
江苏	江苏南京	539417922	6/27/85	女	2013/6/18	大专	55848	5
湖北	湖北十堰	294339262	12/1/73	女	2009/4/23	高中	55847.21	12
苏州	苏州新区	870847802	9/15/80	女	2015/9/11	大专	55834	7
福建	福建福州	517028372	10/7/71	男	2013/4/25	高中	55829.23	10
苏州	苏州姑苏	5172672812	12/23/78	女	2016/6/15	中专	55826	4
湖北	湖北襄阳	58276432	7/28/64	女	2005/9/13	高中	55825.12	11
云南	云南大理	586928802	10/8/79	女	2013/11/28	中专	55822.14	7
北京	北京海淀	473099652	11/3/89	男	2012/8/1	本科	55822.11	4
山东	山东潍坊	541845022	12/24/74	男	2013/6/20	中专	55815.39	8
佛山	佛山禅城	883069802	5/15/85	女	2015/10/10	高中	55811	4
山东	山东烟台	335848422	4/8/74	女	2009/10/9	高中	55808.13	7
苏州	苏州太仓	255988392	6/29/70	女	2008/10/9	高中	55787.54	4
广东	广东广州	2314272	9/4/68	女	2002/12/9	大专	55784.71	9
广西	广西柳州	746761912	9/10/74	男	2015/4/17	中专	55775.59	11
苏州	苏州常熟	5086707202	9/11/81	女	2016/3/10	大专	55765	2
四川	四川高新	189502082	11/19/84	女	2007/12/12	大专	55762.6	29
山东	山东淄博	5228247442	2/18/87	女	2016/9/1	本科	55759	2
辽宁	辽宁营口	5188897152	2/13/72	女	2016/7/15	本科	55748	8
安徽	安徽马鞍山	150197262	8/21/72	男	2007/7/1	高中	55745.73	2
广东	广东肇庆	534296742	8/4/69	女	2013/6/14	中专	55715.07	6
山东	山东济南	569136492	3/12/80	女	2013/7/10	大专	55691.3	7
广东	广东广州	5046275442	2/5/80	女	2015/12/14	本科	55685	2
苏州	苏州昆山	95931552	9/9/76	女	2006/9/4	高中	55679.5	8
四川	四川青羊	5188396782	6/9/94	女	2016/7/12	本科	55670.4	6
北京	北京海淀	661689842	5/30/83	男	2014/8/25	本科	55662.5	5
江苏	江苏南通	395223032	12/30/64	女	2010/9/13	高中	55652.63	4
大连	大连开发区	273467512	3/25/69	女	2009/1/1	高中	55645.98	9
安徽	安徽滁州	781718482	2/23/95	男	2015/7/3	高中	55641.46	3
江苏	江苏无锡	5190835352	1/17/82	女	2016/7/22	高中	55636.5	9
山东	山东烟台	602783212	6/20/70	女	2013/12/31	中专	55631.32	7
广东	广东清远	755317732	7/7/70	女	2015/5/20	大专	55630.1	2
山东	山东济宁	378142792	2/11/72	男	2010/5/31	大专	55619.42	4
黑龙江	黑龙江大庆	48756752	7/6/73	女	2005/6/3	大专	55615.22	6
四川	四川高新	718516492	1/23/83	女	2015/3/24	大专	55611.5	2
黑龙江	黑龙江牡丹江	5162329122	3/26/83	女	2016/6/6	高中	55610	3
云南	云南昆明	387874892	8/10/57	女	2010/7/26	大专	55591.3	9
湖北	湖北十堰	5141661822	10/30/74	女	2016/5/18	高中	55588	8
青岛	青岛城阳	5111698792	5/15/87	女	2016/3/25	大专	55580	3
云南	云南昆明	622666812	11/27/75	女	2014/4/9	中专	55578.72	3
广西	广西梧州	350657322	8/27/70	女	2009/12/20	大专	55573.87	7
河北	河北保定	5196175822	4/2/63	男	2016/7/29	高中	55566.1	13
江西	江西赣州	214040982	3/21/73	女	2008/4/1	大专	55561.11	2
佛山	佛山顺德龙江	85324082	1/29/66	女	2006/5/24	高中	55556.01	12
大连	大连海川	645651072	7/22/80	女	2014/7/8	大专	55552.64	7
山东	山东淄博	505161572	6/19/77	女	2013/3/2	初中	55535	8
浙江	浙江温州	477838972	11/21/85	女	2012/9/12	高中	55530.7	11
四川	四川乐山	841148082	1/9/83	女	2015/8/9	大专	55527.33	3
天津	天津市区二支	827813302	4/18/80	女	2015/8/4	大专	55520.59	0
陕西	陕西西安	279678582	6/14/60	男	2009/2/18	大专	55515.68	2
广东	广东广州	489265852	8/2/79	女	2012/12/11	大专	55509.35	7
四川	四川内江	233735752	10/5/67	男	2008/6/24	大专	55507.46	8
苏州	苏州昆山	870683532	2/10/82	女	2015/9/11	大专	55506	9
河南	河南平顶山	5208039732	10/20/66	男	2016/8/7	高中	55501	16
湖北	湖北武汉	511326862	10/22/76	男	2013/3/27	中专	55499.14	10
江苏	江苏南京	5191889682	8/27/85	女	2016/7/25	大专	55486.5	13
苏州	苏州姑苏	550007672	6/28/88	女	2013/6/25	大专	55486.1	5
湖北	湖北武汉	625056642	12/8/85	女	2014/4/25	大专	55484	14
青岛	青岛一支	5087951932	10/15/69	女	2016/3/11	本科	55479	10
江西	江西南昌	94187922	10/2/71	女	2006/8/22	大专	55476.91	6
吉林	吉林延边	339562752	4/27/68	女	2009/10/30	大专	55475.83	12
四川	四川高新	790113102	12/1/85	女	2015/7/15	本科	55472.65	13
浙江	浙江温州	5156241732	8/29/75	女	2016/5/31	大专	55470	9
重庆	重庆永川	5126698412	5/23/72	女	2016/4/22	大专	55465.9	16
江苏	江苏盐城	19826192	11/26/59	女	2004/9/28	高中	55460.16	7
四川	四川高新	783790652	12/15/76	女	2015/7/8	大专	55456	9
青岛	青岛一支	5212268752	10/20/84	女	2016/8/10	大专	55430.1	12
四川	四川青羊	15242902	5/11/73	女	2004/6/29	大专	55428.9	4
江西	江西南昌	21067372	10/16/73	男	2004/10/18	大专	55417.25	4
云南	云南昆明	646541792	7/2/79	女	2014/7/18	大专	55411.9	1
山西	山西太原	155600232	7/5/77	女	2007/8/1	大专	55404.43	4
天津	天津市区一支	870716332	11/4/87	女	2015/9/11	大专	55404	3
苏州	苏州昆山	5223787242	10/19/81	女	2016/8/25	高中	55396.5	9
山东	山东济南	626711782	3/18/78	女	2014/5/4	本科	55395	11
湖南	湖南湘潭	442032022	12/10/68	女	2011/10/11	高中	55388.63	7
湖北	湖北随州	515331222	8/24/76	女	2013/4/16	中专	55381	6
山东	山东烟台	5218006222	2/24/83	女	2016/8/17	大专	55376	12
四川	四川高新	5038332312	10/8/83	女	2015/11/30	大专	55371	8
北京	北京东城	22446862	12/10/75	男	2004/11/9	大专	55366.96	8
辽宁	辽宁葫芦岛	5202311262	4/3/75	女	2016/8/2	大专	55360.3	11
四川	四川高新	620176912	11/28/85	女	2014/3/29	大专	55359.05	8
云南	云南昆明	372408212	11/12/76	女	2010/5/1	大专	55345.2	3
湖北	湖北武汉	572547542	9/26/69	男	2013/8/7	中专	55343.48	8
四川	四川青羊	67783132	3/11/74	女	2005/11/30	大专	55337.85	8
山东	山东日照	5172776112	1/20/82	女	2016/6/15	中专	55324.5	15
上海	上海陆家嘴	615390142	4/18/85	女	2014/3/19	硕士	55316.5	5
四川	四川雅安	5143598152	5/23/92	男	2016/5/20	大专	55309	2
江苏	江苏南京	5147471852	9/9/82	女	2016/5/26	本科	55293	9
四川	四川高新	738446572	1/21/67	女	2015/4/9	中专	55280	28
山东	山东烟台	531340962	11/13/76	女	2013/6/9	高中	55278.68	1
浙江	浙江金华	668907482	12/24/92	女	2014/9/15	高中	55264.95	7
河南	河南濮阳	5026147492	6/18/74	女	2015/11/21	中专	55262	2
苏州	苏州昆山	759429972	3/29/80	女	2015/6/1	大专	55240	5
苏州	苏州新区	498309742	2/2/76	女	2013/1/4	大专	55199.5	4
山东	山东烟台	5037409462	12/29/86	男	2015/11/30	本科	55196.5	3
山东	山东淄博	100437932	10/28/67	女	2006/10/9	中专	55190.36	10
湖北	湖北武汉	422827692	8/12/81	女	2011/5/1	硕士	55178.9	11
江苏	江苏泰州	525158922	4/10/66	女	2013/5/30	高中	55168	5
黑龙江	黑龙江佳木斯	131408292	5/8/70	女	2007/4/23	大专	55166.84	16
山东	山东烟台	13163892	7/26/57	女	2004/5/1	本科	55166.23	11
福建	福建泉州	187569892	5/1/82	男	2007/12/5	高中	55159.34	5
湖北	湖北武汉	738785722	2/22/82	女	2015/4/9	本科	55147.2	14
浙江	浙江杭州	290147742	9/12/80	女	2009/4/1	本科	55145.63	6
江苏	江苏盐城	586881352	7/21/75	女	2013/11/28	高中	55143.6	16
四川	四川绵阳	708840082	12/31/85	女	2015/2/10	本科	55129.63	12
山东	山东菏泽	5195395252	6/5/76	女	2016/7/28	高中	55111.1	20
山东	山东烟台	196467932	5/14/79	女	2008/1/1	中专	55106.42	11
湖北	湖北武汉	5120188542	12/14/81	女	2016/4/12	本科	55090	19
安徽	铜陵	222571542	8/30/63	女	2008/5/1	高中	55087.7	4
江西	江西新余	300832122	4/25/73	女	2009/5/19	高中	55086.34	7
四川	四川高新	866061032	8/26/86	女	2015/9/2	大专	55081.5	13
山东	山东菏泽	5217190342	8/16/73	女	2016/8/16	高中	55080	9
四川	四川青羊	104231542	5/21/75	女	2006/11/22	高中	55078.17	4
天津	天津蓟州	122701182	8/5/72	女	2007/3/14	高中	55070	7
四川	四川宜宾	811409692	5/18/81	女	2015/7/29	高中	55053.3	6
山东	山东济宁	704511922	3/5/78	男	2015/1/16	高中	55052	7
河北	河北唐山	5197447692	7/1/77	男	2016/7/30	中专	55051	9
苏州	苏州新区	5169143632	9/26/82	女	2016/6/13	高中	55044.4	13
河南	河南郑州	158020762	1/15/70	男	2007/8/17	本科	55035.42	1
湖南	湖南郴州	338934972	10/14/63	女	2009/10/1	高中	55030.43	3
浙江	浙江金华	825874972	3/11/74	女	2015/8/3	高中	55030	5
黑龙江	黑龙江七台河	5136493612	9/28/64	女	2016/5/11	大专	55028.6	3
湖北	湖北武汉	5182203252	2/24/72	男	2016/6/24	高中	55016.5	9
天津	天津市区二支	800052182	12/3/82	男	2015/7/23	大专	55000	0
深圳	深圳福田	641068482	1/31/91	女	2014/6/24	大专	55000	0
北京	北京东城	610374472	6/19/83	男	2014/2/27	本科	55000	0
陕西	陕西西安	5213767232	11/8/72	女	2016/8/11	本科	54996.5	8
山东	山东淄博	5198644302	2/13/74	男	2016/8/1	大专	54996	10
广东	广东惠州	557448342	4/16/81	女	2013/6/27	大专	54993.54	8
青岛	青岛一支	376043962	1/21/78	女	2010/5/20	大专	54984.93	11
四川	四川青羊	5113696812	3/4/79	女	2016/3/29	本科	54958.5	7
安徽	安徽安庆	630953822	12/19/80	女	2014/5/25	高中	54951.32	11
黑龙江	黑龙江哈尔滨	267481842	8/21/76	女	2008/12/1	中专	54949.9	12
四川	四川雅安	189628112	3/11/65	男	2007/12/13	大专	54949.85	10
新疆	新疆昌吉	5091517322	8/11/84	女	2016/3/14	大专	54943.1	8
湖北	湖北襄阳	58056472	2/26/74	女	2005/9/12	高中	54931.6	8
深圳	深圳福田第二	622050342	12/21/68	女	2014/4/3	大专	54921.2	10
浙江	浙江金华	726388842	3/19/80	女	2015/3/25	高中	54893.79	5
广东	广东惠州	17251572	4/22/71	女	2004/8/13	大专	54871.76	2
福建	福建泉州	465064702	2/9/71	男	2012/5/18	中专	54857.77	8
吉林	吉林通化	591047882	3/13/71	女	2013/12/13	本科	54854	9
江苏	江苏连云港	5202687492	2/17/83	男	2016/8/2	高中	54843.5	11
山东	山东滨州	443880462	5/12/72	男	2011/11/14	中专	54840.55	5
四川	四川高新	526358792	4/3/82	女	2013/6/3	本科	54835.99	1
湖南	湖南长沙	558027272	1/25/74	女	2013/6/27	高中	54823.01	4
福建	福建福州	756134072	1/30/93	男	2015/5/22	本科	54818	2
广西	广西梧州	548791752	1/5/68	女	2013/6/25	高中	54811	3
苏州	苏州昆山	5028339512	1/24/74	女	2015/11/24	高中	54810	2
四川	四川青羊	5175039792	2/22/86	女	2016/6/17	本科	54804	3
深圳	深圳罗湖	864746712	6/6/82	女	2015/8/28	本科	54794.36	13
江苏	江苏泰州	5173538542	11/11/74	男	2016/6/16	高中	54780	3
青岛	青岛一支	627002782	11/8/79	女	2014/5/6	本科	54777.11	5
四川	四川高新	104762	2/16/74	女	2001/12/30	大专	54772.74	7
北京	北京海淀	571420112	7/5/89	男	2013/7/31	大专	54762	7
北京	北京平谷	404084502	3/8/74	女	2010/11/12	高中	54742.1	7
四川	四川高新	125647052	7/30/72	女	2007/3/23	大专	54705.65	12
河南	河南郑州	495765552	8/14/75	女	2012/12/28	大专	54704.55	9
福建	福建福州	157908412	1/9/77	女	2007/8/17	高中	54693.92	8
河南	河南洛阳	5216854962	12/19/80	女	2016/8/16	大专	54689	11
云南	云南昆明	149685602	6/12/76	女	2007/7/1	大专	54683.1	4
广东	广东广州	2898642	8/8/67	女	2003/3/1	本科	54681.58	5
重庆	重庆涪陵	5203389132	6/3/74	女	2016/8/3	大专	54676.5	19
贵州	贵州贵阳	5081033152	6/30/79	男	2016/3/8	大专	54672	17
苏州	苏州张家港	783871602	11/9/66	女	2015/7/8	本科	54664	14
山西	山西忻州	730909602	10/21/85	女	2015/3/30	高中	54662	8
山东	山东威海	532090032	2/26/73	女	2013/6/9	高中	54656.7	13
四川	四川高新	668376702	9/7/85	女	2014/9/11	大专	54653.82	11
河北	河北廊坊	81389092	12/11/63	男	2006/4/5	中专	54648.1	12
福建	福建福州	5051083762	9/9/84	女	2015/12/23	大专	54640	8
浙江	浙江嘉兴	676935592	2/28/84	女	2014/10/11	大专	54639.8	10
山东	山东日照	834017532	7/11/75	男	2015/8/7	中专	54631	8
佛山	佛山顺德龙江	5053502392	11/20/83	女	2015/12/28	大专	54625.5	9
宁波	宁波城区	5091864542	12/20/79	女	2016/3/14	大专	54610	3
山东	山东潍坊	385660642	11/12/68	男	2010/7/9	中专	54589.75	8
辽宁	辽宁沈阳	416833512	6/9/77	女	2011/3/4	大专	54578	4
青岛	青岛开发区	672350632	1/22/86	女	2014/9/24	本科	54573	11
广西	广西玉林	579756942	10/4/65	女	2013/9/26	高中	54573	2
山东	山东临沂	5205926572	7/27/76	女	2016/8/5	高中	54544.5	13
上海	上海黄浦	863714882	3/16/80	女	2015/8/26	高中	54539	2
贵州	贵州贵阳	5193773462	6/13/78	男	2016/7/27	中专	54534.8	11
湖北	湖北荆州	403039692	1/28/72	女	2010/11/3	高中	54521	11
河南	河南驻马店	177402342	12/25/73	女	2007/11/7	中专	54519.74	11
山东	山东东营	802141102	8/20/85	女	2015/7/25	中专	54512.9	7
北京	北京海淀	474910552	8/20/89	男	2012/8/16	本科	54510.5	5
山西	山西朔州	5149914022	2/10/75	女	2016/5/27	高中	54508.5	16
湖北	湖北十堰	5122803942	11/17/77	女	2016/4/16	中专	54507	8
湖北	湖北十堰	5192771752	11/8/81	女	2016/7/26	高中	54503.5	13
浙江	浙江湖州	5030752742	3/26/83	女	2015/11/25	本科	54500.6	3
四川	四川高新	688555772	1/8/81	女	2014/12/3	大专	54499.8	13
四川	四川绵阳	5173114262	11/12/82	女	2016/6/16	大专	54485	7
北京	北京海淀	5081218082	7/20/91	男	2016/3/8	大专	54478.6	2
大连	大连明锐	5162599282	4/7/80	女	2016/6/6	中专	54473	11
河南	河南南阳	12613022	7/16/75	女	2004/4/15	大专	54472.5	7
浙江	浙江杭州	813224362	8/21/66	男	2015/7/30	高中	54469.5	11
江西	江西吉安	5152201242	1/9/86	女	2016/5/29	高中	54450	2
河北	河北唐山	5195037162	1/14/85	女	2016/7/28	中专	54426	11
黑龙江	黑龙江佳木斯	5126521652	8/30/73	女	2016/4/22	高中	54407	10
黑龙江	黑龙江黑河	450841572	2/26/77	女	2012/1/5	中专	54397.22	2
四川	四川青羊	5190385732	7/21/83	女	2016/7/20	本科	54394.5	13
山东	山东济南	5225942	1/9/59	女	2003/7/1	中专	54388.86	0
湖北	湖北黄石	363905982	12/17/69	女	2010/3/15	高中	54388.69	16
山东	山东德州	581326392	6/30/76	女	2013/10/22	中专	54374.7	3
深圳	深圳罗湖	5209074052	12/8/89	男	2016/8/8	本科	54371	11
黑龙江	黑龙江佳木斯	523542722	11/22/75	女	2013/5/27	中专	54363.05	1
福建	福建福州	669651382	6/23/72	女	2014/9/17	高中	54360.31	7
北京	北京海淀	621650062	2/28/90	男	2014/4/1	高中	54360	10
江苏	江苏南京	521129032	10/20/85	女	2013/5/20	中专	54347.3	11
四川	四川绵阳	5185035292	10/8/73	女	2016/6/29	大专	54337	12
辽宁	辽宁营口	5015164752	7/15/85	女	2015/11/10	本科	54335	8
苏州	苏州新区	289187652	4/12/72	女	2009/3/25	本科	54329.82	10
天津	天津市区一支	415006562	2/14/79	女	2011/1/27	本科	54310.97	5
河北	河北承德	5198230962	9/28/69	女	2016/8/1	中专	54305.9	7
河南	河南南阳	5015185592	3/12/69	女	2015/11/10	高中	54299	4
广东	广东汕头	511686532	5/20/65	女	2013/3/28	初中	54286.03	6
河北	河北石家庄	681000382	12/4/80	女	2014/11/10	大专	54278	6
青岛	青岛一支	759071702	9/16/82	女	2015/5/30	本科	54274.5	2
辽宁	辽宁营口	5143892822	2/15/77	女	2016/5/21	高中	54270	2
重庆	重庆永川	5179381182	11/24/86	女	2016/6/21	大专	54264	7
湖北	湖北武汉	432722392	10/5/80	女	2011/8/1	高中	54260.1	15
湖北	湖北襄阳	5018178052	3/7/75	女	2015/11/13	高中	54254	14
河北	河北廊坊	494869662	8/12/78	女	2012/12/25	本科	54240.64	4
山东	山东临沂	861991772	5/1/63	男	2015/8/21	中专	54233	5
河北	河北沧州	401766412	1/24/76	男	2010/10/25	高中	54223	10
山东	山东济南	651570442	7/29/81	女	2014/8/5	高中	54211.1	11
山东	山东济南	5169258772	7/4/81	女	2016/6/13	本科	54204.5	13
黑龙江	黑龙江哈尔滨	19488712	7/12/64	女	2004/10/1	大专	54193.23	14
四川	四川青羊	4628592	10/5/58	女	2003/6/11	高中	54180.82	0
四川	四川青羊	5179862582	10/3/74	女	2016/6/22	高中	54177	14
天津	天津市区二支	854273782	3/15/61	女	2015/8/10	高中	54176.5	5
苏州	苏州新区	5145180062	10/14/78	男	2016/5/24	大专	54175	5
四川	四川高新	5041592232	11/18/88	男	2015/12/4	硕士	54172.6	2
四川	四川雅安	18505732	7/21/69	女	2004/9/1	本科	54161.6	10
深圳	深圳福田	849444302	8/21/89	女	2015/8/10	本科	54156.1	2
上海	上海陆家嘴	5213469932	9/15/82	女	2016/8/11	本科	54155.5	5
四川	四川高新	837804302	8/31/75	男	2015/8/8	本科	54154.5	10
江西	江西宜春	5002570122	1/16/85	女	2015/10/19	本科	54153	13
四川	四川青羊	5193665592	9/14/87	女	2016/7/27	本科	54139	10
陕西	陕西汉中	5156054032	10/20/71	女	2016/5/31	大专	54134	13
湖北	湖北十堰	388899882	8/19/70	女	2010/8/1	大专	54130.27	12
深圳	深圳南山	5184790542	9/24/82	女	2016/6/28	中专	54110	8
黑龙江	黑龙江哈尔滨	223906652	7/21/64	女	2008/5/16	大专	54109.2	11
深圳	深圳罗湖	5141693532	9/6/77	男	2016/5/18	本科	54106	5
山东	山东日照	63860422	12/29/70	女	2005/10/31	高中	54105.16	9
青岛	青岛一支	5209126672	3/28/63	女	2016/8/8	大专	54105	10
山东	山东淄博	272962692	5/9/70	女	2008/12/25	大专	54074.86	5
苏州	苏州昆山	5154307132	7/12/76	女	2016/5/31	大专	54064	4
湖南	湖南永州	421507932	5/23/84	女	2011/4/16	高中	54061.91	10
甘肃	甘肃兰州	793285072	10/13/78	女	2015/7/17	高中	54052.18	2
苏州	苏州昆山	5204827862	6/10/83	女	2016/8/4	硕士	54048.5	16
江西	江西南昌	93951912	2/13/64	女	2006/8/21	高中	54026.72	3
天津	天津市区一支	834409302	1/2/83	女	2015/8/7	高中	54013.5	5
山东	山东泰安	486560462	4/5/69	女	2012/11/30	大专	54013.26	0
上海	上海陆家嘴	5066650912	12/11/80	男	2016/2/17	中技	54012.5	4
青岛	青岛一支	740909812	5/23/76	女	2015/4/10	硕士	54010	10
湖北	湖北武汉	5236946632	10/15/63	男	2016/9/20	本科	54010	4
浙江	浙江杭州	5199474722	6/27/81	女	2016/8/1	本科	53990	14
广西	广西柳州	604752242	12/7/80	女	2014/1/8	大专	53983.5	15
四川	四川青羊	556328722	3/20/77	女	2013/6/27	高中	53968.95	9
河北	河北石家庄	67378382	6/18/78	女	2005/11/29	中专	53967.25	1
山东	山东滨州	5207030752	9/1/69	男	2016/8/5	中专	53966.7	11
广东	广东东莞	5187729552	4/26/75	女	2016/7/7	大专	53949.4	10
安徽	安徽合肥	5194842262	3/10/87	男	2016/7/28	高中	53946.5	8
山西	山西朔州	5185889252	9/3/67	男	2016/6/30	高中	53932.1	6
浙江	浙江金华	5198744972	3/10/74	男	2016/8/1	高中	53915.5	14
山东	山东济南	685055262	2/15/81	女	2014/11/19	中专	53904	11
河南	河南南阳	186924392	12/9/63	女	2007/12/4	大专	53891.27	11
安徽	安徽合肥	99953632	10/29/75	女	2006/10/1	中专	53878.24	9
安徽	安徽滁州	150305842	4/8/64	女	2007/7/1	高中	53870.73	8
四川	四川青羊	5173414332	8/23/88	女	2016/6/16	高中	53868	10
广东	广东广州	5177994172	10/5/80	女	2016/6/21	高中	53867.6	7
辽宁	辽宁沈阳	646011712	1/5/75	男	2014/7/11	本科	53859	6
浙江	浙江温州	5129824152	5/18/83	男	2016/4/28	大专	53857.8	10
四川	四川青羊	478678202	6/20/89	女	2012/9/24	大专	53838.6	11
山东	山东临沂	5183182322	7/18/75	男	2016/6/25	高中	53834.1	10
佛山	佛山顺德龙江	90293272	5/22/76	女	2006/6/30	中专	53828.39	9
山西	山西阳泉	327932662	5/17/79	女	2009/8/26	中专	53813.82	0
云南	云南昆明	5117780782	12/4/77	男	2016/4/7	大专	53807	2
黑龙江	黑龙江哈尔滨	287273612	8/11/73	女	2009/3/24	中专	53802.7	6
苏州	苏州新区	5094944032	12/8/77	女	2016/3/15	中专	53796.5	4
广东	广东惠州	225693962	12/25/78	女	2008/5/23	本科	53787.51	3
黑龙江	黑龙江哈尔滨	5214256802	8/14/78	男	2016/8/12	本科	53773.6	16
四川	四川乐山	5212482692	7/20/64	女	2016/8/10	高中	53761.5	9
湖北	湖北武汉	439492572	7/30/79	女	2011/9/24	大专	53761.38	6
河南	河南安阳	284234512	8/16/76	女	2009/3/11	高中	53747.99	5
云南	云南昆明	466423402	10/28/79	女	2012/6/1	本科	53744.04	0
广西	广西贵港	483309912	11/14/74	女	2012/11/16	大专	53729.74	13
北京	北京良乡	221187582	7/18/81	女	2008/5/5	大专	53717.72	6
四川	四川绵阳	54601042	12/8/74	女	2005/8/10	高中	53716.46	5
青岛	青岛城阳	5221240802	8/4/70	女	2016/8/22	大专	53709	7
山东	山东淄博	5040685722	8/6/94	女	2015/12/2	高中	53707	2
山东	山东济南	5066951882	9/2/84	男	2016/2/19	大专	53700	2
浙江	浙江温州	119327672	3/18/85	女	2007/2/6	大专	53698.78	5
辽宁	辽宁营口	5137812632	7/19/75	女	2016/5/12	本科	53690	1
辽宁	辽宁丹东	627933842	5/6/74	女	2014/5/12	中专	53675.44	7
辽宁	辽宁营口	5012784132	5/8/79	女	2015/11/8	大专	53662.9	8
广西	广西玉林	638690932	7/8/77	女	2014/6/19	中专	53660	13
重庆	重庆永川	5063942112	3/22/76	女	2016/1/30	高中	53647	20
福建	福建福州	616617642	10/23/77	女	2014/3/21	中专	53621.25	3
四川	四川成都	5230106782	4/10/67	女	2016/9/5	高中	53620	5
浙江	浙江衢州	650372942	1/4/65	女	2014/8/1	高中	53614.5	4
佛山	佛山顺德龙江	417754662	2/18/82	女	2011/3/15	大专	53610.5	10
浙江	浙江杭州	427626472	5/15/78	女	2011/6/21	高中	53609.69	6
黑龙江	黑龙江哈尔滨	602157802	12/28/75	女	2013/12/31	大专	53601.69	12
海南	海南海口	5187426782	6/23/71	女	2016/7/6	高中	53596.8	8
天津	天津市区二支	119580432	12/30/61	男	2007/2/9	高中	53586.43	6
广东	广东广州	691765982	9/7/82	男	2014/12/17	大专	53582.5	3
山东	山东烟台	477288102	10/7/81	女	2012/9/5	初中	53581.8	9
山东	山东烟台	225906112	10/13/64	女	2008/5/23	高中	53580.43	17
云南	云南昆明	530241862	10/3/77	女	2013/6/8	大专	53580.25	9
北京	北京海淀	444401332	6/23/89	女	2011/11/22	大专	53578.4	11
河北	河北石家庄	815821172	5/17/60	女	2015/7/30	中专	53570	4
四川	四川宜宾	394178222	8/22/75	女	2010/9/3	中专	53562	7
河南	河南郑州	5161663762	2/21/66	女	2016/6/3	本科	53560	3
深圳	深圳宝安	127624592	4/8/68	女	2007/4/1	大专	53544.8	2
安徽	安徽滁州	5201279642	11/28/82	女	2016/8/2	高中	53538.8	9
山西	山西晋中	647362872	10/1/67	男	2014/7/24	中专	53514.05	4
河南	河南三门峡	5207132252	6/13/68	女	2016/8/6	高中	53508	6
四川	四川绵阳	300385362	2/19/82	女	2009/5/18	本科	53503.27	6
苏州	苏州姑苏	660101972	11/14/72	女	2014/8/22	高中	53498.42	5
四川	四川绵阳	86499662	2/19/68	女	2006/6/5	大专	53497.36	4
北京	北京海淀	589805032	9/25/89	女	2013/12/9	本科	53495.05	7
湖北	湖北襄阳	106268542	5/18/66	女	2006/12/6	高中	53494.24	11
四川	四川成都	70403802	7/2/71	女	2005/12/23	高中	53485.25	6
云南	云南昆明	297223302	2/28/76	女	2009/5/5	本科	53477.34	3
辽宁	辽宁葫芦岛	117479232	8/22/78	女	2007/1/25	高中	53468.67	6
山东	山东济南	345347582	10/25/77	女	2009/11/25	大专	53459.88	8
湖南	湖南长沙	139908422	12/25/75	女	2007/7/1	大专	53458.35	4
黑龙江	黑龙江哈尔滨	5202153542	3/20/69	女	2016/8/2	大专	53454.5	18
云南	云南红河	5048745792	7/17/88	男	2015/12/20	本科	53450.4	6
湖南	湖南长沙	5113100272	6/1/75	男	2016/3/28	高中	53441.2	6
辽宁	辽宁葫芦岛	46644942	4/4/65	女	2005/6/9	高中	53438.6	9
新疆	新疆昌吉	818963412	5/8/73	女	2015/7/31	高中	53434.5	15
浙江	浙江杭州	667648852	7/19/68	女	2014/9/9	高中	53424	6
重庆	重庆巴南	261620532	7/6/69	男	2008/11/6	大专	53413.57	10
河南	河南周口	37737692	1/19/70	女	2005/4/1	大专	53406	6
四川	四川青羊	5017695822	9/4/84	男	2015/11/13	大专	53386	7
大连	大连明锐	831758202	8/10/78	女	2015/8/6	大专	53383	5
湖北	湖北随州	5216196802	9/23/69	男	2016/8/15	大专	53382.6	18
湖北	湖北武汉	222599872	8/12/78	女	2008/5/10	大专	53378.34	14
山东	山东烟台	500686592	10/19/68	女	2013/1/23	高中	53373.64	9
湖北	湖北武汉	5149223252	7/23/77	女	2016/5/27	高中	53347.5	10
江苏	江苏无锡	790538642	4/30/83	女	2015/7/15	高中	53345	8
福建	福建泉州	5002277322	6/17/85	女	2015/10/17	高中	53339.7	12
云南	云南昆明	5169539802	10/15/87	女	2016/6/13	本科	53334	10
江苏	江苏无锡	481456032	11/28/83	女	2012/10/26	大专	53324.43	2
佛山	佛山顺德龙江	875158512	3/16/87	女	2015/9/21	高中	53314.8	11
四川	四川巴中	402966282	4/23/63	女	2010/11/2	大专	53312.9	9
青岛	青岛即墨	148098432	7/28/69	女	2007/7/10	中专	53308.77	4
大连	大连海川	5153319962	9/10/83	女	2016/5/30	中专	53300	4
贵州	贵州贵阳	5149571372	6/24/69	女	2016/5/27	大专	53291	9
深圳	深圳罗湖	5063085452	9/30/83	女	2016/1/26	本科	53286.5	9
四川	四川青羊	71751892	7/29/70	女	2005/12/30	大专	53277.19	8
陕西	陕西西安	501993372	5/17/77	女	2013/1/30	中专	53272.51	5
苏州	苏州新区	5095087282	1/25/80	女	2016/3/15	大专	53269.5	7
广东	广东广州	637343542	2/18/77	女	2014/6/16	大专	53266.98	9
浙江	浙江温州	725092672	6/4/71	女	2015/3/25	高中	53263	5
广西	广西南宁	5005439342	8/13/75	男	2015/10/26	本科	53259	8
宁波	宁波城区	439165702	11/10/72	男	2011/9/23	大专	53249	6
天津	天津市区二支	490889592	10/7/60	女	2012/12/18	大专	53243.23	4
云南	云南昆明	835128912	4/25/91	男	2015/8/7	高中	53232.5	6
河北	河北沧州	5190880702	10/21/81	男	2016/7/22	高中	53230	4
深圳	深圳福田第二	5007294112	10/8/84	女	2015/10/30	大专	53229.5	10
安徽	安徽合肥	30295352	6/27/54	女	2005/1/1	大专	53195.75	5
黑龙江	黑龙江牡丹江	255264312	9/18/74	女	2008/10/6	高中	53187.5	11
浙江	浙江金华	5220137522	10/29/73	女	2016/8/19	高中	53186	3
大连	大连明锐	5151274792	11/14/84	男	2016/5/28	大专	53177.9	1
吉林	吉林长春	157629692	8/3/49	女	2007/8/15	初中	53177.77	10
黑龙江	黑龙江牡丹江	599815152	2/17/82	女	2013/12/28	大专	53164	1
浙江	浙江金华	490397752	10/3/64	女	2012/12/17	高中	53163.5	6
江西	江西上饶	248717162	4/23/71	女	2008/9/1	大专	53155.56	3
湖北	湖北荆州	5070498232	10/4/82	女	2016/3/1	高中	53136	7
江苏	江苏南京	21051322	6/4/61	女	2004/10/19	大专	53132.47	10
河北	河北邢台	276066692	9/10/60	女	2009/1/6	高中	53118.62	9
四川	四川乐山	15182472	4/4/69	女	2004/6/29	高中	53108.24	22
河南	河南南阳	132815142	12/5/73	女	2007/4/25	高中	53085.65	9
广东	广东广州	5160230502	2/18/75	女	2016/6/3	高中	53085.5	13
江西	江西吉安	231701822	11/13/74	女	2008/6/18	本科	53084.18	3
广东	广东东莞	500590292	11/19/85	女	2013/1/23	中专	53074.8	4
河南	河南郑州	444385282	8/21/79	男	2011/11/22	大专	53070.05	7
四川	四川乐山	291843222	9/7/69	女	2009/4/9	初中	53056.07	8
甘肃	甘肃兰州	635948972	10/12/83	女	2014/6/12	大专	53055.05	4
湖南	湖南娄底	749994042	4/26/68	女	2015/4/28	大专	53035	7
湖北	湖北十堰	5064029142	3/5/72	女	2016/1/30	高中	53034.8	9
广东	广东广州	5092237102	6/27/94	女	2016/3/14	大专	53025.5	4
黑龙江	黑龙江大庆	220943062	9/2/70	女	2008/5/4	高中	53023.08	11
江西	江西南昌	263167092	3/21/74	女	2008/11/14	大专	53022.84	6
江西	江西景德镇	763900212	6/22/80	女	2015/6/12	中专	53022.74	7
河北	河北石家庄	711128442	9/27/68	女	2015/3/5	大专	53019	7
四川	四川青羊	679922	12/13/68	女	2002/3/1	高中	53016.2	5
青岛	青岛开发区	664923642	8/6/85	女	2014/8/30	中专	53013.38	0
陕西	陕西渭南	427935892	5/5/86	女	2011/6/23	本科	53013.23	8
山西	山西忻州	661300172	10/22/84	女	2014/8/25	高中	53013.11	0
浙江	浙江温州	648775862	2/20/79	女	2014/7/29	本科	53013.05	0
苏州	苏州昆山	5092258912	9/10/80	女	2016/3/14	大专	53009.1	6
山东	山东烟台	5080095832	3/3/69	女	2016/3/8	高中	53002	8
广东	广东中山	664553092	11/29/69	男	2014/8/29	高中	53000	0
山西	山西太原	298102442	2/10/72	女	2009/5/8	大专	52989.32	8
苏州	苏州张家港	5188777512	4/9/80	男	2016/7/14	大专	52988.3	12
吉林	吉林吉林	683677442	9/19/69	女	2014/11/18	大专	52986	8
江西	江西南昌	5030182512	3/4/83	女	2015/11/25	高中	52950	8
湖北	湖北武汉	852899732	11/3/79	男	2015/8/10	中专	52949	7
福建	福建泉州	151680592	2/28/81	女	2007/7/23	高中	52940.39	7
山东	山东滨州	648737622	5/6/83	男	2014/7/29	高中	52938.03	6
宁波	宁波象山	593918942	3/11/89	女	2013/12/20	大专	52936.17	18
山东	山东菏泽	345869152	1/9/68	女	2009/11/25	大专	52934.43	15
江西	江西南昌	61636262	11/27/63	女	2005/9/1	高中	52930.56	10
湖北	湖北随州	190422532	2/10/74	男	2007/12/10	高中	52926.52	4
大连	大连明锐	636382752	10/29/90	男	2014/6/13	大专	52905	5
四川	四川青羊	5173985462	12/20/78	女	2016/6/16	大专	52902.5	5
湖北	湖北荆州	5127331162	7/4/82	女	2016/4/24	中专	52894	15
河南	河南驻马店	206539882	5/6/63	女	2008/3/10	高中	52882.12	16
陕西	陕西西安	604179452	12/20/68	女	2014/1/3	硕士	52861.92	4
四川	四川青羊	378508602	11/20/76	女	2010/6/1	本科	52842.01	9
北京	北京海淀	367947882	4/10/88 1:00	男	2010/4/1	高中	52838.1	8
大连	大连海川	5573332	10/5/58	女	2003/7/1	高中	52804.79	2
湖南	湖南永州	805544612	12/25/85	女	2015/7/27	高中	52804	4
江西	江西吉安	5005203662	6/19/87	女	2015/10/25	高中	52800	3
福建	福建福州	100139122	7/5/70	女	2006/10/8	高中	52793.56	12
广西	广西南宁	5161941122	2/8/72	女	2016/6/4	高中	52786.5	6
福建	福建福州	5180057482	4/12/78	女	2016/6/22	大专	52784	10
山东	山东潍坊	207323422	3/2/67	女	2008/3/14	高中	52783.9	1
佛山	佛山顺德龙江	512358692	12/17/84	女	2013/3/29	大专	52779.49	6
大连	大连开发区	551302172	3/4/80	女	2013/6/25	高中	52777.98	6
四川	四川内江	5041758122	12/10/69	女	2015/12/4	高中	52774.88	13
四川	四川高新	5230505652	8/12/79	女	2016/9/6	大专	52774	7
重庆	重庆渝中	5152844932	4/28/87	女	2016/5/30	大专	52768.3	13
深圳	深圳福田	595083642	10/15/75	男	2013/12/23	大专	52767.47	4
江西	江西赣州	770296072	1/6/81	女	2015/6/24	高中	52762.5	8
山东	山东烟台	776731842	1/3/83	女	2015/6/29	大专	52740	1
福建	福建泉州	238889732	9/3/76	男	2008/7/1	中专	52736.99	5
湖北	湖北武汉	5018085622	4/27/80	男	2015/11/13	本科	52727.5	15
四川	四川高新	604730052	4/14/82	女	2014/1/8	大专	52722.7	0
山东	山东济南	658634692	9/29/80	男	2014/8/19	大专	52712.8	8
青岛	青岛城阳	5129941092	1/4/89	女	2016/4/28	大专	52710	5
辽宁	辽宁营口	73062442	5/5/73	女	2006/1/4	大专	52709.2	13
重庆	重庆巴南	5111674282	5/12/93	女	2016/3/25	高中	52704.2	8
苏州	苏州昆山	846325922	12/24/80	女	2015/8/10	本科	52703.5	9
浙江	浙江杭州	5019071962	4/25/88	女	2015/11/15	大专	52700	2
重庆	重庆渝中	728321032	4/28/85	女	2015/3/26	本科	52696.3	16
四川	四川高新	752040252	6/28/78	女	2015/5/5	大专	52688	1
上海	上海陆家嘴	724681782	1/10/73	男	2015/3/25	大专	52675.7	7
山东	山东泰安	693855602	10/15/88	女	2014/12/20	中专	52670.6	3
山东	山东临沂	675845702	3/21/83	女	2014/10/6	高中	52664.93	12
广东	广东广州	670467022	10/10/86	女	2014/9/19	高中	52661	5
河北	河北石家庄	5197507512	4/16/82	女	2016/7/30	大专	52660	13
四川	四川绵阳	219473682	9/28/64	女	2008/4/25	大专	52642.95	7
山东	山东济宁	649313482	8/15/67	女	2014/7/30	本科	52640.97	2
宁波	宁波宁海	500358052	8/23/83	女	2013/1/21	高中	52638.11	6
陕西	陕西宝鸡	239726862	3/1/80	女	2008/7/10	大专	52617.17	12
重庆	重庆渝中	5201756472	1/27/87	女	2016/8/2	本科	52585	15
苏州	苏州新区	521961692	4/24/81	女	2013/5/23	中专	52585	8
河北	河北秦皇岛	525416152	7/6/55	男	2013/5/31	大专	52582.61	0
浙江	浙江金华	5044081622	8/9/75	女	2015/12/10	高中	52581	7
云南	云南昆明	5235313972	12/16/87	女	2016/9/19	中专	52578	1
江西	江西南昌	5176744042	6/22/87	男	2016/6/20	高中	52577	8
吉林	吉林松原	5142229282	6/4/82	女	2016/5/19	本科	52573	9
海南	海南三亚	5196856622	10/30/85	女	2016/7/29	高中	52564.14	5
浙江	浙江舟山	5050857082	1/4/71	女	2015/12/22	高中	52560	3
山东	山东威海	5064278462	11/17/83	女	2016/2/1	硕士	52559.5	6
山东	山东威海	258599852	1/2/76	女	2008/10/23	高中	52545.35	4
江苏	江苏无锡	284395712	12/13/77	女	2009/3/12	大专	52544.23	6
浙江	浙江湖州	5199737612	9/10/81	女	2016/8/1	高中	52538.5	14
四川	四川高新	5153082412	7/13/92	男	2016/5/30	大专	52534	2
大连	大连海川	744094762	8/10/83	女	2015/4/11	大专	52533	11
青岛	青岛一支	5192198822	11/4/79	男	2016/7/25	大专	52524	3
山东	山东济南	89234782	2/14/76	女	2006/6/29	大专	52519.41	10
山东	山东济宁	43163552	4/10/71	女	2005/5/16	本科	52512.59	5
浙江	浙江绍兴	360805752	5/7/69	女	2010/2/9	大专	52509.37	5
河北	河北唐山	5145949762	8/10/78	男	2016/5/25	高中	52500	5
浙江	浙江嘉兴	5134761182	9/8/73	女	2016/5/6	大专	52500	5
湖北	湖北随州	877949322	2/4/70	女	2015/9/24	中专	52500	1
河北	河北邯郸	5094227922	5/28/84	女	2016/3/15	高中	52499	10
贵州	贵州遵义	707722832	2/12/82	女	2015/2/2	大专	52496	11
广东	广东清远	827965292	5/8/64	女	2015/8/4	高中	52482	1
广西	广西柳州	840754912	5/31/81	男	2015/8/9	高中	52474	9
山东	山东威海	5122182962	12/12/83	女	2016/4/15	大专	52470	10
湖北	湖北武汉	32710442	12/25/79	女	2005/3/1	大专	52468.44	6
深圳	深圳福田第二	693684492	4/28/73	女	2014/12/20	本科	52458.76	6
辽宁	辽宁丹东	614617212	5/28/65	女	2014/3/17	本科	52453.82	7
四川	四川成都	193303502	12/5/70	女	2007/12/24	高中	52453.5	14
广西	广西百色	637484922	8/21/86	女	2014/6/17	高中	52444.9	14
辽宁	辽宁锦州	5141438812	5/3/79	女	2016/5/18	本科	52429	10
河南	河南焦作	651137362	10/18/86	女	2014/8/4	大专	52391.21	1
黑龙江	黑龙江鹤岗	490928532	12/19/71	女	2012/12/18	高中	52388.32	8
山西	山西临汾	433203622	7/26/70	女	2011/8/8	大专	52375.12	5
山东	山东济南	504727792	10/10/81	男	2013/2/27	本科	52362.9	10
内蒙古	内蒙古赤峰	744840062	3/11/66	女	2015/4/11	中专	52359	5
苏州	苏州新区	5208374212	9/11/84	女	2016/8/8	大专	52358.6	16
四川	四川青羊	5195148702	11/1/79	女	2016/7/28	本科	52358.4	9
苏州	苏州张家港	61844912	11/29/64	女	2005/8/2	高中	52355.55	7
山东	山东威海	199074652	1/26/75	女	2008/1/8	大专	52349.25	6
内蒙古	内蒙古通辽	5146005982	1/5/70	女	2016/5/25	高中	52347.9	7
四川	四川高新	709614682	12/24/81	女	2015/2/15	本科	52345	1
山东	山东临沂	5067900072	11/14/87	女	2016/2/24	中专	52331.6	22
青岛	青岛一支	4686652	12/12/69	女	2003/6/18	大专	52289	9
苏州	苏州新区	5075341932	1/6/84	女	2016/3/4	本科	52286	9
北京	北京海淀	691012872	12/26/78	男	2014/12/15	大专	52272.5	9
河南	河南南阳	328531412	12/23/63	女	2009/8/31	大专	52264.08	10
四川	四川高新	5229992542	4/12/83	女	2016/9/5	本科	52232.4	7
福建	福建厦门	5062505252	6/4/87	男	2016/1/21	大专	52229.5	10
山东	山东枣庄	250609982	6/25/70	女	2008/9/10	中专	52221.06	8
辽宁	辽宁鞍山	5020049162	1/22/77	女	2015/11/16	高中	52211	10
江西	江西赣州	748000722	7/10/85	女	2015/4/22	中专	52206	21
安徽	安徽合肥	22679802	12/17/72	女	2004/11/1	大专	52201.37	7
四川	四川成都	5124940642	3/15/81	女	2016/4/19	大专	52200.4	4
山东	山东枣庄	5199745782	4/18/86	女	2016/8/1	中专	52195.6	13
深圳	深圳福田第二	733337672	12/21/90	男	2015/4/1	本科	52180	5
青岛	青岛一支	5211371242	12/25/74	女	2016/8/9	大专	52179.8	11
湖北	湖北孝感	5192682922	12/15/80	男	2016/7/26	高中	52170	17
湖南	湖南长沙	214799262	2/11/71	女	2008/4/7	中专	52161.43	4
湖南	湖南长沙	141280102	8/23/77	女	2007/7/1	本科	52159.07	4
福建	福建龙岩	5080517342	11/6/81	女	2016/3/8	本科	52153	6
四川	四川高新	631589142	11/4/69	女	2014/5/28	大专	52145.2	9
吉林	吉林长春	5063924942	4/2/79	女	2016/1/30	本科	52142.4	1
江西	江西赣州	5151876732	3/16/77	女	2016/5/28	高中	52134.2	11
广西	广西柳州	5079596292	10/22/74	女	2016/3/7	高中	52131	3
云南	云南曲靖	5229086932	2/16/85	女	2016/9/2	大专	52120	13
河北	河北石家庄	643241422	4/20/83	女	2014/6/26	大专	52114.5	7
江苏	江苏南京	5157393962	1/22/95	女	2016/6/1	本科	52099	11
四川	四川高新	225139322	3/2/78	女	2008/5/21	大专	52093.3	10
黑龙江	黑龙江哈尔滨	101281902	1/7/71	女	2006/10/19	大专	52093.06	9
辽宁	辽宁沈阳	5546672	9/19/60	女	2003/7/1	大专	52085.21	7
山东	山东济南	5157372152	11/20/82	男	2016/6/1	本科	52085.1	2
浙江	浙江金华	5067750522	12/31/90	女	2016/2/24	高中	52076	8
湖北	湖北宜昌	97251312	9/20/73	女	2006/9/16	中专	52075.75	9
大连	大连明锐	5084283102	2/23/87	女	2016/3/10	本科	52071	5
重庆	重庆永川	5210065862	12/11/75	男	2016/8/8	大专	52069	16
湖北	湖北宜昌	226093272	10/24/73	女	2008/5/23	中专	52063.27	9
福建	福建福州	5065072602	10/20/75	女	2016/2/4	高中	52059	5
深圳	深圳龙岗	580233432	2/10/64	女	2013/9/30	中专	52048	7
山东	山东潍坊	537451962	9/30/72	女	2013/6/17	中专	52047.5	11
山东	山东烟台	241593452	8/5/73	女	2008/7/22	高中	52047.48	10
辽宁	辽宁沈阳	692726772	1/25/72	女	2014/12/19	大专	52045.13	5
宁波	宁波城区	160628182	12/12/67	女	2007/9/1	高中	52039.6	1
山东	山东烟台	5055042622	12/16/86	女	2016/1/1	中专	52030.5	16
山西	山西晋城	584421182	7/9/80	女	2013/11/15	中专	52026.46	0
湖北	湖北黄石	661064162	7/26/64	女	2014/8/25	高中	52010.3	11
辽宁	辽宁鞍山	798255932	1/3/92	女	2015/7/22	高中	52000	0
辽宁	辽宁沈阳	5137956782	3/12/79	女	2016/5/12	大专	52000	0
山东	山东烟台	5120286442	7/26/80	女	2016/4/12	中专	51980.5	12
湖南	湖南衡阳	5217864842	8/15/88	男	2016/8/17	高中	51973.5	7
四川	四川青羊	573685582	1/26/78	女	2013/8/19	大专	51962.05	5
江苏	江苏南京	457227522	9/29/78	女	2012/3/19	大专	51956.05	8
苏州	苏州张家港	5209358752	11/4/76	女	2016/8/8	中专	51952.2	4
黑龙江	黑龙江七台河	5047622572	9/22/75	女	2015/12/17	高中	51950	1
山东	山东烟台	44583382	11/20/68	女	2005/5/27	高中	51937.32	10
北京	北京海淀	586135082	5/27/87	男	2013/11/30	大专	51927	6
四川	四川高新	558629092	9/30/80	女	2013/6/28	大专	51925.06	11
重庆	重庆渝中	5015848252	5/11/77	女	2015/11/11	中专	51922.5	12
河南	河南周口	298787582	9/26/76	女	2009/5/11	本科	51917.5	16
苏州	苏州新区	875649652	5/26/77	男	2015/9/22	高中	51910	3
湖南	湖南株洲	5107593982	10/19/75	女	2016/3/23	高中	51909	6
江苏	江苏南京	675904462	1/19/80	女	2014/10/7	本科	51908	11
山西	山西太原	509825112	8/22/79	女	2013/3/25	大专	51902.89	4
苏州	苏州太仓	454759812	6/24/72	女	2012/3/6	高中	51891.46	2
新疆	新疆库尔勒	5200084832	7/29/74	女	2016/8/1	本科	51875.9	11
江苏	江苏镇江	5037204592	3/7/79	女	2015/11/30	高中	51866	5
苏州	苏州新区	489310932	9/3/69	女	2012/12/12	高中	51857.21	5
四川	四川高新	571250672	11/24/81	女	2013/7/29	本科	51856.28	10
重庆	重庆渝中	440423632	7/8/80	女	2011/9/29	大专	51854.4	11
黑龙江	黑龙江哈尔滨	556057542	2/8/75	女	2013/6/27	本科	51853	8
河南	河南平顶山	5165672472	9/29/66	男	2016/6/8	高中	51852	16
湖北	湖北武汉	171923862	11/17/74	女	2007/10/25	高中	51846.21	4
江西	江西吉安	656026302	9/30/70	男	2014/8/13	高中	51845	1
重庆	重庆渝中	5179349472	2/23/83	女	2016/6/21	本科	51835	10
江苏	江苏泰州	5043322862	12/2/78	女	2015/12/8	高中	51830	2
四川	四川青羊	14221682	6/3/82	女	2004/6/3	大专	51812.41	4
河南	河南洛阳	5147091052	8/30/81	女	2016/5/26	大专	51791.6	19
湖北	湖北武汉	5199785662	5/5/78	女	2016/8/1	大专	51785.8	11
湖北	湖北武汉	837758522	11/13/74	男	2015/8/8	高中	51757	15
青岛	青岛即墨	859297962	3/13/74	女	2015/8/14	高中	51753.3	2
河南	河南周口	5153703392	2/21/81	男	2016/5/30	本科	51741.1	8
辽宁	辽宁沈阳	5164866562	10/12/78	女	2016/6/7	中专	51740.5	7
北京	北京海淀	5194812352	3/16/79	女	2016/7/28	本科	51740	10
四川	四川高新	733796712	2/4/83	女	2015/4/2	本科	51737	11
广东	广东广州	570503702	9/29/79	女	2013/7/24	大专	51733	4
青岛	青岛城阳	5077606442	1/2/85	女	2016/3/7	大专	51731.9	10
四川	四川青羊	5225307532	9/28/82	男	2016/8/29	本科	51723	6
山东	山东临沂	5207497612	1/15/77	女	2016/8/6	中专	51714.5	15
新疆	新疆伊犁	5198413122	11/15/73	男	2016/8/1	高中	51710	14
北京	北京东城	5165412282	12/26/86	女	2016/6/7	硕士	51691	5
宁波	宁波城区	666036422	11/21/72	男	2014/9/2	本科	51679	8
河北	河北保定	5142668032	4/27/60	女	2016/5/19	高中	51670	1
山东	山东威海	728504422	4/18/70	男	2015/3/26	本科	51657.5	8
辽宁	辽宁营口	5126240622	10/20/88	女	2016/4/22	大专	51655.7	12
湖北	湖北武汉	256502422	1/14/70	女	2008/10/10	高中	51655	7
广西	广西南宁	674889382	3/26/79	女	2014/9/29	大专	51653.7	16
四川	四川青羊	5108734372	7/17/90	男	2016/3/24	大专	51640	2
湖北	湖北武汉	615880582	8/25/82	女	2014/3/20	高中	51630	1
深圳	深圳福田	460961672	8/30/79	女	2012/3/31	本科	51625.6	10
浙江	浙江金华	454343482	1/13/84	女	2012/3/5	大专	51619.63	1
广东	广东广州	517641772	9/22/81	女	2013/4/26	中专	51613	7
四川	四川青羊	7578232	6/25/71	女	2003/9/15	大专	51605	5
山东	山东济南	752905712	7/22/86	女	2015/5/8	中专	51588.9	13
吉林	吉林吉林	5136416552	4/21/78	男	2016/5/10	大专	51559.7	15
宁波	宁波宁海	692123282	11/10/79	女	2014/12/18	本科	51542	3
山东	山东威海	569397762	10/15/84	女	2013/7/15	中专	51537	3
四川	四川青羊	271921652	7/11/83	女	2008/12/24	本科	51531.29	15
大连	大连开发区	20691652	6/14/74	女	2004/10/12	高中	51528.5	8
黑龙江	黑龙江鹤岗	441502912	4/29/82	女	2011/10/10	高中	51515.26	11
山东	山东威海	837436122	8/19/84	女	2015/8/8	中专	51493	13
四川	四川青羊	271457172	12/22/61	女	2008/12/22	大专	51478	8
湖北	湖北黄石	5195281982	11/2/85	男	2016/7/28	高中	51448	7
陕西	陕西咸阳	676891212	11/15/82	女	2014/10/10	高中	51438.1	4
山东	山东滨州	648969862	11/12/83	女	2014/7/29	本科	51437.11	4
湖北	湖北武汉	101820222	8/21/78	女	2006/10/25	大专	51432.58	11
浙江	浙江杭州	5074541422	12/11/76	女	2016/3/4	大专	51423	11
山东	山东临沂	5162228522	6/7/74	男	2016/6/5	中专	51415.1	7
浙江	浙江嘉兴	5198680582	11/22/83	女	2016/8/1	中专	51407.8	6
苏州	苏州张家港	882508322	5/6/69	女	2015/10/9	高中	51405	4
山东	山东枣庄	286961122	5/13/79	女	2009/3/23	中专	51402.4	5
苏州	苏州昆山	5017623262	10/4/89	女	2015/11/13	高中	51399.5	2
山东	山东枣庄	844166662	10/21/73	女	2015/8/10	本科	51399.37	11
北京	北京良乡	173972172	3/7/64	女	2007/11/1	高中	51395.28	4
浙江	浙江杭州	5178199042	3/9/84	女	2016/6/21	本科	51395	10
江苏	江苏南京	534968902	4/2/78	女	2013/6/14	大专	51391.55	7
山东	山东临沂	482587232	10/27/67	女	2012/11/9	初中	51374.81	7
新疆	新疆石河子	612300792	2/26/72	女	2014/3/10	大专	51373.78	5
湖北	湖北武汉	137596472	12/20/68	男	2007/6/1	本科	51360.17	8
重庆	重庆渝中	5119726182	11/6/85	男	2016/4/11	大专	51358	5
辽宁	辽宁沈阳	822543202	6/9/76	男	2015/8/1	大专	51353.92	10
山西	山西长治	707517252	8/21/93	女	2015/1/30	高中	51350	1
青岛	青岛一支	5145163792	11/3/75	女	2016/5/24	硕士	51350	1
山西	山西阳泉	789055312	7/25/92	男	2015/7/14	本科	51350	1
四川	四川高新	745885842	1/30/90	女	2015/4/15	高中	51345	7
黑龙江	黑龙江哈尔滨	731466342	5/1/80	女	2015/3/31	本科	51342	7
河南	河南郑州	322329552	1/12/69	女	2009/8/5	大专	51319.72	10
江苏	江苏无锡	73298452	7/28/64	女	2006/1/7	高中	51312.68	5
河南	河南开封	714946612	9/28/92	女	2015/3/20	高中	51305	10
苏州	苏州张家港	5218024362	6/15/74	男	2016/8/17	高中	51293	8
山东	山东临沂	5161668262	5/13/81	女	2016/6/4	中专	51281	8
苏州	苏州昆山	5043835972	11/26/78	女	2015/12/10	高中	51277.5	3
甘肃	甘肃武威	583703242	4/18/81	女	2013/11/11	大专	51269.5	4
四川	四川高新	5176474852	3/2/90	女	2016/6/20	大专	51261	10
山东	山东烟台	755259672	5/6/86	女	2015/5/20	本科	51250	12
山西	山西运城	5190701172	7/22/81	男	2016/7/21	大专	51243.2	6
福建	福建福州	5225026502	7/8/81	女	2016/8/29	高中	51241	7
辽宁	辽宁沈阳	563705192	9/17/78	女	2013/6/29	中专	51234	7
云南	云南昆明	372602212	11/21/75	女	2010/5/1	中专	51231.35	4
四川	四川青羊	666151842	6/9/82	女	2014/9/2	大专	51223.68	0
山东	山东菏泽	654267322	5/7/78	男	2014/8/10	中专	51221.91	0
山东	山东烟台	706804752	1/21/66	女	2015/1/26	中专	51220.3	18
贵州	贵州贵阳	681528092	11/10/77	女	2014/11/12	高中	51215	14
山东	山东烟台	5209745852	7/6/60	女	2016/8/8	中专	51210.9	10
浙江	浙江金华	863700232	2/28/81	男	2015/8/26	大专	51192.5	10
江苏	江苏南京	520295942	3/16/84	女	2013/5/14	大专	51192.1	10
北京	北京海淀	578357632	10/30/88	女	2013/9/30	本科	51189	1
福建	福建福州	775290522	8/19/79	女	2015/6/26	高中	51189	1
四川	四川绵阳	753964202	12/10/81	女	2015/5/13	高中	51188.5	4
河南	河南郑州	816851602	11/27/78	女	2015/7/31	大专	51181.09	6
河北	河北沧州	359132192	9/15/65	女	2010/1/25	高中	51177.7	6
大连	大连海川	593924112	2/6/76	女	2013/12/20	大专	51177.05	8
重庆	重庆渝中	5163485922	3/8/84	女	2016/6/6	大专	51177	6
山东	山东滨州	783006142	5/11/83	女	2015/7/7	高中	51175	2
四川	四川高新	5044924712	2/18/80	女	2015/12/12	高中	51172.4	11
福建	福建三明	5119887572	6/22/68	男	2016/4/11	高中	51160	2
安徽	安徽合肥	531766232	8/10/66	女	2013/6/9	高中	51151.3	5
山东	山东烟台	5080595302	2/3/84	男	2016/3/8	本科	51148.6	24
福建	福建福州	603482032	2/20/86	女	2014/1/1	大专	51118.29	10
山西	山西大同	5014733202	9/4/73	女	2015/11/10	高中	51106.8	16
湖北	湖北襄阳	662675892	7/23/84	女	2014/8/26	高中	51098.05	16
山东	山东枣庄	746922142	6/17/79	女	2015/4/18	大专	51097.5	10
山东	山东威海	701290132	4/8/70	女	2014/12/31	本科	51097.2	7
河南	河南郑州	750140862	8/17/72	女	2015/4/29	本科	51089.5	11
北京	北京东城	5172209552	3/29/74	女	2016/6/15	大专	51086	11
河南	河南周口	713578702	10/10/69	女	2015/3/18	中专	51080	4
河南	河南洛阳	340133172	11/19/69	女	2009/11/2	高中	51072	4
江西	江西南昌	535472052	7/14/78	女	2013/6/14	中专	51066.96	4
湖北	湖北武汉	576062432	10/27/89	男	2013/9/2	大专	51061.38	0
河南	河南南阳	30002952	11/19/68	女	2005/1/27	中技	51060.67	0
深圳	深圳罗湖	476625152	1/8/78	女	2012/8/31	大专	51050	1
黑龙江	黑龙江双鸭山	267480172	10/22/64	女	2008/12/1	高中	51044.85	4
江苏	江苏南京	44269492	9/23/76	男	2005/5/26	本科	51036.2	7
江苏	江苏南京	724050932	11/21/94	女	2015/3/25	大专	51025	4
江苏	江苏常州	274580292	8/13/71	女	2009/1/1	高中	51019.56	7
山东	山东济南	73416942	9/18/74	女	2006/1/11	本科	51016.81	10
河北	河北石家庄	523893182	6/20/76	男	2013/5/28	本科	51014	10
天津	天津市区二支	47854722	3/21/64	女	2005/6/21	大专	51012.57	14
山东	山东临沂	5156159202	4/18/78	女	2016/5/31	大专	51011.4	9
山东	山东济南	185569462	3/10/80	男	2007/11/25	高中	51002.5	9
深圳	深圳福田第二	636402572	9/11/81	女	2014/6/13	本科	51002.2	0
江西	江西新余	820283172	12/11/74	女	2015/7/31	高中	51001.98	0
佛山	佛山禅城	831039562	1/16/78	女	2015/8/5	大专	51000	0
新疆	新疆石河子	734705582	1/20/89	女	2015/4/3	大专	50998.94	3
四川	四川乐山	640191712	12/20/79	女	2014/6/23	中专	50996.91	4
辽宁	辽宁沈阳	5050463682	12/5/81	女	2015/12/22	本科	50991	6
湖北	湖北武汉	5192888692	12/7/77	女	2016/7/26	大专	50989	9
广东	广东广州	5154989802	10/14/75	女	2016/5/31	中专	50985.5	6
四川	四川青羊	649045372	4/19/73	女	2014/7/30	本科	50968.14	14
大连	大连明锐	821023302	1/9/74	女	2015/7/31	大专	50956	9
青岛	青岛一支	51745332	6/8/73	女	2005/7/15	大专	50948.75	9
苏州	苏州太仓	5164899172	3/23/87	男	2016/6/7	本科	50945	2
四川	四川高新	575225302	11/3/82	男	2013/8/29	本科	50937.35	16
湖北	湖北荆州	5090668832	1/20/88	男	2016/3/12	高中	50933	7
山东	山东淄博	5198611692	12/15/75	女	2016/8/1	中专	50929.4	11
山东	山东潍坊	865205752	8/8/72	男	2015/8/31	本科	50928.2	6
河南	河南南阳	341597382	4/5/76	女	2009/11/10	初中	50909.79	6
四川	四川成都	5222786432	10/9/74	女	2016/8/24	中专	50894	6
大连	大连明锐	131108082	9/12/82	女	2007/4/20	本科	50893.75	7
山东	山东济宁	74488412	9/20/71	女	2006/1/26	大专	50886.97	5
山东	山东淄博	5019747292	12/4/85	女	2015/11/16	大专	50883	11
山西	山西晋中	274329902	11/19/80	女	2009/1/1	中专	50879.98	1
北京	北京海淀	247118682	10/9/75	女	2008/8/22	硕士	50875.46	5
贵州	贵州贵阳	5193920312	9/27/85	女	2016/7/27	中专	50874.9	16
深圳	深圳罗湖	5216031812	9/10/83	女	2016/8/15	大专	50870	13
新疆	新疆石河子	5175618122	11/10/86	女	2016/6/20	高中	50866.5	9
青岛	青岛一支	343786372	8/18/82	女	2009/11/18	大专	50850.48	8
安徽	安徽亳州	678754032	1/14/73	男	2014/10/25	中专	50850	3
河北	河北石家庄	5160675622	4/13/70	女	2016/6/3	高中	50849	9
天津	天津市区一支	627725192	3/3/65	女	2014/5/9	高中	50843.1	8
江苏	江苏南通	5179359442	2/10/66	女	2016/6/21	高中	50840.7	3
江西	江西南昌	735434132	12/8/69	男	2015/4/3	高中	50831	7
江苏	江苏泰州	5230897252	11/22/78	女	2016/9/7	高中	50820	5
山东	山东烟台	5195567512	11/9/80	女	2016/7/28	本科	50810.6	6
湖南	湖南永州	5177469292	1/16/63	女	2016/6/21	中专	50810	3
黑龙江	黑龙江牡丹江	458086842	3/17/78	女	2012/3/22	中专	50798.92	5
广西	广西南宁	5198139362	4/2/86	女	2016/8/1	本科	50798	7
山东	山东淄博	5185925532	11/11/81	男	2016/6/30	高中	50791	3
内蒙古	内蒙呼和浩特	248772152	7/24/74	男	2008/9/1	大专	50770.9	8
福建	福建泉州	868900962	8/7/76	男	2015/9/9	高中	50768.4	5
山东	山东菏泽	497854472	12/2/70	女	2012/12/31	中专	50766.5	11
苏州	苏州新区	689068132	5/19/75	女	2014/12/5	本科	50759	5
浙江	浙江嘉兴	871897082	2/29/84	女	2015/9/15	大专	50752	9
河南	河南南阳	479683372	9/1/73	男	2012/9/29	本科	50745	1
山东	山东济南	5065289242	3/30/63	男	2016/2/5	高中	50743.5	10
天津	天津市区二支	656263982	7/28/78	女	2014/8/14	中专	50724.5	7
广东	广东清远	5217371672	6/15/79	女	2016/8/16	中专	50724.3	9
青岛	青岛一支	462060772	9/20/57	男	2012/4/16	大专	50721.48	7
辽宁	辽宁丹东	399213982	11/10/73	女	2010/10/8	中专	50706.06	8
青岛	青岛一支	480936832	10/25/70	男	2012/10/23	大专	50699.6	8
四川	四川青羊	605648132	9/24/73	男	2014/1/15	大专	50692.68	4
湖南	湖南邵阳	5207035252	3/4/80	男	2016/8/5	高中	50688	8
北京	北京东城	792060912	4/15/82	女	2015/7/16	本科	50687	14
江苏	江苏徐州	533620812	8/21/76	男	2013/6/13	高中	50686	6
山东	山东烟台	535322432	7/7/83	女	2013/6/14	中专	50685.2	10
云南	云南曲靖	5190227042	10/10/87	女	2016/7/20	本科	50682.4	10
广东	广东茂名	376334962	10/17/54	男	2010/5/21	高中	50681.34	1
四川	四川高新	5096011032	4/2/85	女	2016/3/16	中专	50674.5	16
江苏	江苏常州	86977822	6/12/66	女	2006/6/12	高中	50672.58	5
江苏	江苏南京	345328462	5/14/88	男	2009/11/25	大专	50669.7	8
深圳	深圳宝安	121795382	12/3/79	女	2007/3/7	大专	50669.35	8
佛山	佛山顺德龙江	841947942	6/7/84	男	2015/8/9	高中	50668.4	6
山东	山东烟台	444227152	1/18/72	男	2011/11/19	中专	50649.13	2
浙江	浙江金华	518569762	5/11/77	女	2013/5/2	高中	50637.05	12
湖北	湖北十堰	515047062	10/6/80	女	2013/4/12	中专	50634.4	13
北京	北京东城	5189423902	8/19/71	女	2016/7/18	硕士	50610	7
浙江	浙江温州	706792472	10/6/78	女	2015/1/26	高中	50599.3	6
安徽	安徽滁州	348538672	3/9/68	女	2009/12/1	高中	50598.5	9
佛山	佛山禅城	797135342	2/9/68	女	2015/7/21	高中	50593	8
青岛	青岛一支	79235272	1/17/78	女	2006/3/24	大专	50587.47	8
福建	福建漳州	5202059242	2/21/77	男	2016/8/2	大专	50585.1	8
四川	四川泸州	704591202	2/28/79	女	2015/1/16	中专	50547.5	7
河北	河北石家庄	799449662	5/11/61	女	2015/7/23	本科	50544	14
福建	福建福州	583280072	8/15/73	女	2013/11/7	高中	50526.64	10
吉林	吉林延边	582798142	11/4/78	女	2013/11/1	中专	50525.22	11
黑龙江	黑龙江哈尔滨	217409052	3/21/64	女	2008/4/21	本科	50525.21	17
山东	山东威海	415353252	11/27/80	女	2011/2/8	中专	50524.6	15
黑龙江	黑龙江牡丹江	224145732	4/23/62	女	2008/5/19	大专	50521.89	4
湖北	湖北咸宁	5210407612	9/3/82	女	2016/8/9	硕士	50514	8
宁波	宁波宁海	5173775122	2/5/78	女	2016/6/16	初中	50511.6	8
福建	福建漳州	577507522	3/3/63	男	2013/9/9	大专	50501.46	8
吉林	吉林四平	748002122	1/3/67	女	2015/4/22	高中	50500.8	6
山东	山东济南	5105375652	11/12/82	女	2016/3/22	本科	50497.5	9
山东	山东济宁	579540752	12/1/71	男	2013/9/25	中专	50485.07	4
云南	云南昆明	282230312	9/2/79	女	2009/3/3	大专	50482.3	5
山东	山东东营	444752762	12/3/81	女	2011/11/24	大专	50481.44	10
福建	福建福州	302512522	10/15/78	女	2009/5/21	初中	50470.75	7
苏州	苏州园区	707292822	9/10/84	女	2015/1/29	大专	50467.5	1
湖南	湖南永州	5180468122	8/13/93	男	2016/6/23	高中	50467	8
福建	福建泉州	602665692	8/24/76	女	2013/12/31	高中	50466.6	4
青岛	青岛即墨	5213260492	3/29/77	女	2016/8/11	大专	50466.3	8
山东	山东滨州	605702422	8/18/76	女	2014/1/15	大专	50466	1
` 

export const companyTargetData = `区域	分公司	销售目标(万元)
中心区	青岛	2360
北区	内蒙古	400
中心区	佛山	450
北区	安徽	1810
北区	河北	2210
中心区	北京	2160
省会	青海	40
南区	福建	1200
南区	浙江	2060
南区	江西	2660
南区	湖南	1460
中心区	天津	750
北区	山东	7280
中心区	深圳	1410
省会	陕西	1000
北区	辽宁	2210
中心区	上海	1000
南区	江苏	2760
北区	黑龙江	5720
南区	广西	1100
省会	新疆	600
北区	河南	2710
省会	四川	7330
南区	湖北	5170
北区	吉林	1560
省会	重庆	1610
中心区	苏州	2060
中心区	宁波	500
省会	云南	1960
省会	贵州	600
北区	山西	1660
南区	广东	3060
省会	甘肃	450
中心区	大连	550
省会	海南	170
`


export function excelToLuckyArray(excelData) {
    const rows = excelData.trim().split('\n').map(row => row.trim().split('\t'));
    const header = rows[0].map(col => ({ v: col }));
    const data = rows.slice(1).map(row => row.map(col => ({ v: col })));
    return [header, ...data];
}

export function excelToArray(excelData) {
    const rows = excelData.trim().split('\n').map(row => row.trim().split('\t'));
    const header = rows[0]
    const data = rows.slice(1).map(row => row.map(col => (col)));
    return [header, ...data];
}

// 生成表1
export function pivotTable(data, config) {
    const headers = data.shift();
    const rows = config.rows;
    const values = config.values;
    const result = [];

    // Helper function to calculate sum or count based on type
    function getCellValue(type, values) {
        switch (type) {
            case "sum":
                return values.reduce((sum, curr) => sum + (parseFloat(curr) || 0), 0);
            case "count":
                return values.length;
            default:
                return null;
        }
    }

    // Filter and sort data based on row columns
    const sortedData = data
        .filter((row) => row[rows[0]] != undefined)
        .sort((a, b) => {
            if (a[rows[0]] < b[rows[0]]) return -1;
            if (a[rows[0]] > b[rows[0]]) return 1;
            return 0;
        });

    // Loop through the sorted data and group rows by row columns
    let currentRowValue = null;
    let currentRowIndex = -1;
    sortedData.forEach((row) => {
        if (row[rows[0]] !== currentRowValue) {
            currentRowIndex++;
            currentRowValue = row[rows[0]];
            result[currentRowIndex] = [{ v: currentRowValue }];
            for (let i = 0; i < values.length; i++) {
                result[currentRowIndex].push({ v: "" });
            }
        }

        // Calculate cell values based on type
        for (let i = 0; i < values.length; i++) {
            const column = values[i].column;
            const type = values[i].type;
            const value = row[column];
            const cellValue = getCellValue(type, [value])
            if (result[currentRowIndex][i + 1].v === "") {
                
                result[currentRowIndex][i + 1].v = cellValue;
            } else {
                result[currentRowIndex][i + 1].v += cellValue;
            }
        }
    });

    // Add header row
    result.unshift(
        [{ v: headers[rows[0]] }].concat(
            values.map((value) => ({ v: `${headers[value.column]}` }))
        )
    );

    return result;
}

// 添加排名
export function sortTable(data) {
    
    // 按照销售额从高到底排序
    data.sort(function (a, b) {
        // 四舍五入
        b[1].v = Math.round(b[1].v) || b[1].v || 0
        a[1].v = Math.round(a[1].v) || a[1].v || 0
        return b[1].v - a[1].v;
    });

    // 添加排名列
    data[0].unshift({ v: "排名" });
    for (var i = 1; i < data.length; i++) {
        data[i].unshift({ "v": i });
    }

    return data
}

// 表1基础上增加一列“销售目标”
export function addSalesTargetToTable(table, salesTargetData) {
    table[0].splice(1, 0, { v: salesTargetData[0][0] });
    table[0].push({ v: salesTargetData[0][2] });

    // 将销售目标数据转换为对象
    const salesTargetObj = {};
    for (let i = 1; i < salesTargetData.length; i++) {
        salesTargetObj[salesTargetData[i][1]] = {
            area: salesTargetData[i][0],
            salesTarget: salesTargetData[i][2]
        };
    }

    // 遍历结果表，为每个“分公司”添加“销售目标”列
    for (let i = 1; i < table.length; i++) {
        const companyName = table[i][1].v;
        const area = salesTargetObj[companyName].area || "";
        const salesTarget = parseFloat(salesTargetObj[companyName].salesTarget) || 0;
        table[i].splice(1, 0, { v: area });
        table[i].push({ v: salesTarget });
    }

    return table;
}

// 汇总所有区域数据
export function summary(data) {
    // 获取需要汇总的列数
    const colCount = data[0].length - 1;

    // 计算汇总数据
    const summaryRow = [{ v: "" }, { v: "合计" }, { v: "" }];
    for (let i = 3; i <= colCount; i++) {
        let sum = 0;
        for (let j = 1; j < data.length; j++) {
            sum += parseInt(data[j][i]['v']) || 0;
        }
        summaryRow.push({ "v": parseInt(sum) || 0 });
    }

    // 插入汇总数据到第二行
    data.splice(1, 0, summaryRow);
    return data
}

// 汇总各个区域数据
export function summaryArea(data) {
    // 获取所有的区域
    const regions = [];
    for (let i = 3; i < data.length; i++) {
        const region = data[i][1]['v'];
        if (!regions.includes(region)) {
            regions.push(region);
        }
    }

    // 按区域汇总数据
    for (let i = 0; i < regions.length; i++) {
        const region = regions[i];
        let sumSales = 0;
        let sumCustomers = 0;
        let countSalesmen = 0;
        let sumTargets = 0;
        for (let j = 3; j < data.length; j++) {
            if (data[j][1]['v'] === region) {
                sumSales += parseInt(data[j][3]['v']) || 0;
                sumCustomers += parseInt(data[j][4]['v']) || 0;
                countSalesmen += parseInt(data[j][5]['v']) || 0;
                sumTargets += parseInt(data[j][6]['v']) || 0;
            }
        }
        const summary = [{ "v": "" }, { "v": region }, { v: "" }, { "v": parseInt(sumSales) || 0 }, { "v": sumCustomers }, { "v": countSalesmen }, { "v": sumTargets }];
        data.splice(2, 0, summary);
    }

    return data
}

// 添加达成率
export function targetRate(data, title = '达成率(销售额/销售目标)') {
    data[0].push({ v: title })
    // 计算并添加达成率列
    for (let i = 1; i < data.length; i++) {
        const sumSales = data[i][3].v;  // 第四列
        const salesTarget = data[i][6].v * 10000;  // 第七列
        const achievementRate = ((sumSales / salesTarget) * 100).toFixed(0) + "%";  // 计算并格式化达成率
        data[i].push({ v: achievementRate });  // 添加达成率列
    }

    return data
}

// 计算结果
export function askAIData(data, salesTargetData) {

    const config = {
        rows: [0],
        values: [
            {
                column: data[0].length - 2,
                type: "sum",
            },
            {
                column: data[0].length - 1,
                type: "sum",
            },
            {
                column: 2,
                type: "count",
            },
        ],
    };

    let resultTable = pivotTable(data, config)
    resultTable = sortTable(resultTable)
    resultTable = addSalesTargetToTable(resultTable, salesTargetData); // 添加“销售目标”列
    resultTable = summary(resultTable)
    resultTable = summaryArea(resultTable)
    resultTable = targetRate(resultTable)
    return resultTable
}

function getAjax(url, data = {}, success, error) {
    $.ajax({
        url,
        data,
        beforeSend: function (request) {
            request.setRequestHeader("Authorization", `Bearer ${remoteT1}.${remoteT2}`)
        },
        success(res) {
            success?.(res)
        },
        error(err) {
            error?.(err)
        }
    })
}


export function getAirTable(url, columnId = 0, sort = 1, success,fail) {
    const host = 'https://api.airtable.com'
    const urls = url.replace('https://airtable.com/', '').split('/')
    const baseId = urls[0]
    const tableId = urls[1]
    const viewId = urls[2].split('?')[0]
    let direction = 'asc'
    if (!sort) {
        direction = 'desc'
    }

    const tablesUrl = `${host}/v0/meta/bases/${baseId}/tables`
    getAjax(tablesUrl, {}, (res) => {
        const table = res.tables.find(item => item.id === tableId)
        const fields = table.fields

        const tableUrl = `${host}/v0/${baseId}/${tableId}`
        const query = {
            view: viewId,
            sort: [{
                field: fields[columnId].name,
                direction
            }]
        }
        getAjax(tableUrl, query, (result) => {
            const records = result.records
            const data = new Array()
            data.length = records.length
            for (let i = 0; i < records.length; i++) {
                data[i] = []
                for (let j = 0; j < fields.length; j++) {
                    const record = records[i].fields[fields[j].name]
                    if (record) {
                        data[i].push(record)
                    } else {
                        data[i].push(null)
                    }
                }
            }
            success && success(data)
        }, (err) => {
            console.dir(err)
            fail && fail(err)
        })
    }, (err) => {
        console.dir(err)
        fail && fail(err)
    })

}